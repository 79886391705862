import { createFileName } from 'js/utils/creators'
import logger from 'js/utils/logger'
import firebase, { getUid, updateDataFirebase } from './firebase'

export const getRole = () =>
    firebase
        .database()
        .ref(`users/${getUid()}/roles`)
        .once('value')
        .then((snapshot) => !!snapshot.val())
        .catch((e) => logger(e))

export const getUserName = async (uid) =>
    firebase
        .database()
        .ref(`users/${uid || (await getUid())}/full_name`)
        .once('value')
        .then((snapshot) => snapshot.val())

export const removeTempPhoto = async (profilePictureRefPath) => {
    try {
        const previousPicture = firebase.storage().ref(profilePictureRefPath)
        await previousPicture.delete()

        return {
            tempProfilePicture: '',
            tempProfilePictureRefPath: '',
        }
    } catch (e) {
        logger('error while deleting prev picture', e)
    }
}

export const setDailyRoomFirebase = async (dailyRoom) =>
    firebase
        .database()
        .ref(`users/${await getUid()}`)
        .update({ dailyRoom })

export const uploadPhoto = async ({ photo, profile }) => {
    const { profilePictureRefPath } = profile
    const { name } = photo

    const uid = getUid()

    const fileNameForRef = createFileName(uid, name)
    const newProfilePictureRefPath = `profile-pictures/${fileNameForRef}`

    const storageRef = firebase.storage().ref()
    const profilePictureRef = storageRef.child(newProfilePictureRefPath)
    const metadata = {
        contentType: photo.type,
    }

    try {
        const previousPicture = storageRef.child(profilePictureRefPath)
        await previousPicture.delete()
    } catch (e) {
        logger('error while deleting prev picture', e)
    }

    try {
        const pictureUploaded = await profilePictureRef.put(photo, metadata)
        if (pictureUploaded) {
            const profile_picture = await profilePictureRef.getDownloadURL()

            return {
                profile_picture,
                profilePictureRefPath: newProfilePictureRefPath,
            }
        }
    } catch (e) {
        throw new Error('error while uploading new picture')
    }
}

export const uploadTempPhoto = async (photo) => {
    const { name } = photo

    const uid = getUid()

    const newProfilePictureRefPath = `profile-pictures/temp/${createFileName(uid, name)}`

    const storageRef = firebase.storage().ref()
    const profilePictureRef = storageRef.child(newProfilePictureRefPath)
    const metadata = {
        contentType: photo.type,
    }

    try {
        const pictureUploaded = await profilePictureRef.put(photo, metadata)
        if (pictureUploaded) {
            const tempProfilePicture = await profilePictureRef.getDownloadURL()

            return {
                tempProfilePicture,
                tempProfilePictureRefPath: newProfilePictureRefPath,
            }
        }
    } catch (e) {
        logger(`error of temp picture uploading: ${e}`)
        throw new Error('error while uploading new temp picture')
    }
}

export const updateUserData = (data) => firebase.database().ref(`users/${getUid()}`).update(data)

export const updateZipCodeFirebase = (zipCode) => updateDataFirebase({ path: `users/${getUid()}`, data: { zipCode } })

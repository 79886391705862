export const APPROVE_CONNECTIONS_REQUEST = 'APPROVE_CONNECTIONS_REQUEST'

export const GET_CONNECTIONS_REQUEST = 'GET_CONNECTIONS_REQUEST'

export const REJECT_CONNECTIONS_REQUEST = 'REJECT_CONNECTIONS_REQUEST'

export const REMOVE_CONNECTIONS_REQUEST = 'REMOVE_CONNECTIONS_REQUEST'

export const SET_CONNECTIONS = 'SET_CONNECTIONS'

export const SET_CONNECTIONS_REQUEST = 'SET_CONNECTIONS_REQUEST'

export const SET_FIND_CARE_SLIDE = 'SET_FIND_CARE_SLIDE'

export const SET_PREFERRED_SERVICE = 'SET_PREFERRED_SERVICE'

export const SET_PREFERRED_SPECIALTIES = 'SET_PREFERRED_SPECIALTIES'

export const SET_STAFF = 'SET_STAFF'

export const SET_SPECIALTIES = 'SET_SPECIALTIES'

export const SET_SERVICES = 'SET_SERVICES'

export const SET_SUGGESTED_STAFF = 'SET_SUGGESTED_STAFF'

export const SAVE_SUGGESTED_STAFF = 'SAVE_SUGGESTED_STAFF'

export const TOGGLE_FIND_CARE = 'TOGGLE_FIND_CARE'

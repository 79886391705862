import { any, object } from 'prop-types'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import withRouter from './withRouter'

const WithSendLocation = ({ children, location }) => {
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location.pathname, location.search])

    return <>{children}</>
}

WithSendLocation.propTypes = {
    children: any.isRequired,
    location: object.isRequired,
}

export default withRouter(WithSendLocation)

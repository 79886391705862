import { handleActions } from 'redux-actions'

import { countQuizSum } from 'js/utils/counters'

import { completePHQ2, initPHQ2, startPHQ2, setPHQ2Slide, togglePHQ2, setPHQ2Answer } from './phq2.actions'

const defaultState = {
    isPHQ2Open: false,
    isPHQ2Started: false,
    currentSlide: 0,
    isPHQ2Completed: false,
    answers: {},
}

const reducer = handleActions(
    {
        [completePHQ2]: (state) => ({ ...state, isPHQ2Completed: true }),
        [togglePHQ2]: (state) => ({
            ...state,
            isPHQ2Open: !state.isPHQ2Open,
        }),
        [initPHQ2]: (state, { payload }) => ({ ...state, ...payload }),
        [startPHQ2]: (state) => ({
            ...state,
            isPHQ2Started: !state.isPHQ2Started,
        }),
        [setPHQ2Answer]: (state, { payload: { key, data } }) => {
            const answers = { ...state.answers, [key]: data }
            return {
                ...state,
                answers,
                sum: countQuizSum(answers),
            }
        },
        [setPHQ2Slide]: (state, { payload }) => ({
            ...state,
            currentSlide: payload,
        }),
    },
    defaultState,
)

export default reducer

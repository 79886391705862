import { handleActions } from 'redux-actions'

import { changeFeatureOptionsAction } from './featureOptions.actions'

const defaultState = {
    goals: true,
    resources: true,
}

const featureOptionsReducer = handleActions(
    {
        [changeFeatureOptionsAction]: (state, { payload }) => ({ ...state, ...payload }),
    },
    defaultState,
)

export default featureOptionsReducer

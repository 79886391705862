import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { setFeedbackFirebase } from 'js/APIs/firebase/callFeedback.firebase'

import {
    SET_CALL_TYPE,
    SET_FEEDBACK,
    SET_MENTOR_UID,
    SET_OWNER,
    SKIP_FEEDBACK,
    TOGGLE_FEEDBACK_POPUP,
    TOGGLE_FEEDBACK_SUCCESS,
} from './callFeedback.types'

export const setFeedbackSuccess = createAction(SET_FEEDBACK)
export const toggleFeedbackSuccess = createAction(TOGGLE_FEEDBACK_SUCCESS)
export const toggleFeedbackPopup = createAction(TOGGLE_FEEDBACK_POPUP)

export const setFeedback =
    ({ author, callType, feedback, owner, rating }) =>
    async (dispatch) => {
        try {
            await setFeedbackFirebase({
                author,
                callType,
                feedback,
                owner,
                rating,
            })
            dispatch(setFeedbackSuccess())
            dispatch(toggleFeedbackSuccess())
        } catch (e) {
            logger(`Error of video call feedback setting: ${e}`)
        }
    }

export const setCallType = createAction(SET_CALL_TYPE)

export const setMentorUid = createAction(SET_MENTOR_UID)

export const skipFeedback = createAction(SKIP_FEEDBACK)

export const setOwner = createAction(SET_OWNER)

import { setHowYouFeelRating, signOnHowYouFeel } from 'js/APIs/firebase/HowYouFeel.firebase'
import logger from 'js/utils/logger'
import { createAction } from 'redux-actions'

import { HOW_YOU_FEEL_CHECK_IN_SUCCESS, HOW_YOU_FEEL_TOGGLE, HOW_YOU_FEEL_UPDATE } from './howYouFeel.types'

export const howYouFeelCheckInSuccess = createAction(HOW_YOU_FEEL_CHECK_IN_SUCCESS)

export const checkInHowYouFeel = (rating, description) => async (dispatch) => {
    try {
        await setHowYouFeelRating(rating, description)
        dispatch(howYouFeelCheckInSuccess())
    } catch (e) {
        logger(`Error ou check in 'How you feel': ${e}`)
    }
}

export const updateHowYouFeel = createAction(HOW_YOU_FEEL_UPDATE)

export const signOnHowYouFeelUpdate = () => (dispatch) => signOnHowYouFeel((value) => dispatch(updateHowYouFeel(value)))

export const toggleHowYouFeel = createAction(HOW_YOU_FEEL_TOGGLE)

import { createSelector } from 'reselect'

export const selectGroupCalls = ({ groupCalls }) => groupCalls

export const selectGroupCallRoomId = createSelector(selectGroupCalls, ({ groupCallRoomId }) => groupCallRoomId)

export const selectJoinError = createSelector(selectGroupCalls, ({ isJoinError }) => isJoinError)

export const selectGroupCallRoomUrl = createSelector(selectGroupCalls, ({ groupCallRoomUrl }) => groupCallRoomUrl)

export const selectJoinedCalls = createSelector(selectGroupCalls, ({ joindedCalls }) => joindedCalls)

export const selectOwnerToken = createSelector(selectGroupCalls, ({ ownerToken }) => ownerToken)

export const selectStartedCallDailyCoName = createSelector(
    selectGroupCalls,
    ({ startedCallDailyCoName }) => startedCallDailyCoName,
)

export const selectIsCreatedMeetingStarted = createSelector(
    selectGroupCalls,
    ({ isCreatedMeetingStarted }) => isCreatedMeetingStarted,
)

const { createSelector } = require('reselect')

export const selectSettings = ({ settings }) => settings

export const selectAwayMessage = createSelector(selectSettings, ({ away }) => away)

export const selectAvailabilitySettings = createSelector(selectSettings, ({ availability }) => availability)

export const selectBlockedUsers = createSelector(selectSettings, ({ blockedUsers }) => blockedUsers)

export const selectCallSettings = createSelector(selectSettings, ({ callSettings }) => callSettings)

export const selectContactMethod = createSelector(selectSettings, ({ contactMethod }) => contactMethod)

export const selectDelay = createSelector(selectSettings, ({ delay }) => delay)

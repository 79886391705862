import React from 'react'
import { func } from 'prop-types'

const IconLevel2 = ({ onClick }) => (
    <svg onClick={onClick} width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40.4507" cy="40.2689" r="37.5" stroke="#FFB500" strokeWidth="5" />
        <circle cx="27.2714" cy="44.3103" r="5.3179" fill="#FFB500" />
        <circle cx="54.0923" cy="44.3103" r="5.3179" fill="#FFB500" />
        <path
            d="M30.6736 60.8592C30.6736 60.8592 33.4981 60.7285 40.4094 60.7285C47.3208 60.7285 50.2277 60.8592 50.2277 60.8592"
            stroke="#FFB500"
            strokeWidth="5"
            strokeLinecap="round"
        />
    </svg>
)

IconLevel2.propTypes = {
    onClick: func,
}

IconLevel2.defaultProps = {
    onClick: null,
}

export default IconLevel2

import { handleActions, combineActions } from 'redux-actions'
import {
    loadPeerSupporterLearningSuccess,
    peerSupporterLearningSetSuccess,
    togglePeerSupporterLearning,
} from './peerSupporterLearning.actions'

const defaultState = {
    isPeerSupporterLearning: false,
    isPeerSupporterLearningOpen: false,
}

const staffReducer = handleActions(
    {
        [combineActions(loadPeerSupporterLearningSuccess, peerSupporterLearningSetSuccess)]: (state, { payload }) => ({
            ...state,
            ...payload,
        }),

        [togglePeerSupporterLearning]: (state) => ({
            ...state,
            isPeerSupporterLearningOpen: !state.isPeerSupporterLearningOpen,
        }),
    },
    defaultState,
)

export default staffReducer

export const COMPLETE_PHQ2 = 'COMPLETE_PHQ2'

export const INIT_PHQ2 = 'INIT_PHQ2'

export const SET_PHQ2_ANSWER = 'SET_PHQ2_ANSWER'

export const SET_PHQ2_SLIDE = 'SET_PHQ2_SLIDE'

export const START_PHQ2 = 'START_PHQ2'

export const TOGGLE_PHQ2 = 'TOGGLE_PHQ2'

export const UPDATE_PHQ2 = 'UPDATE_PHQ2'

import firebase, { getUid, updateDataFirebase } from './firebase'

export const checkIsScheduledMeetingExist = async (id) => {
    let isExist = false
    let data = null

    await firebase
        .database()
        .ref(`scheduledMeetings`)
        .once('value', (snapshot) => {
            isExist = snapshot.child(id).exists()
            data = snapshot.child(id).val()

            return isExist
        })

    return { isExist, data }
}

export const leaveGuestListFirebase = async (meetingId) => {
    const uid = await getUid()

    await updateDataFirebase({ data: { [uid]: null }, path: `/scheduledMeetings/${meetingId}/guests` })
    await updateDataFirebase({ data: { [meetingId]: null }, path: `/users/${uid}/scheduledMeetings` })
}

export const userToJoinedList = async (meetingId) =>
    updateDataFirebase({ data: { [await getUid()]: true }, path: `/scheduledMeetings/${meetingId}/joined` })

export const removeScheduleMeetingFromList = (id) =>
    firebase
        .database()
        .ref(`users/${getUid()}/scheduledMeetings`)
        .update({ [id]: null })

export const signOnScheduleMeetingListFirebase = async (callback) =>
    firebase
        .database()
        .ref(`users/${getUid()}/scheduledMeetings`)
        .on('value', async (snapshot) => {
            callback(snapshot.val())
        })

export const signOnScheduledMeetingFirebase = (id, callback) =>
    firebase
        .database()
        .ref(`scheduledMeetings/${id}`)
        .on('value', (snapshot) => callback(snapshot.val()))

import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { isToday, isYesterday } from 'js/utils/dateUtils'
import { updateCheckInStreak, updateLastCheckInDate } from 'js/APIs/firebase/checkIn.firebase'

import {
    CHECK_IN_STREAK_INIT,
    CHECK_IN_STREAK_SET,
    LAST_CHECK_IN_DATE_INIT,
    LAST_CHECK_IN_DATE_SET_SUCCESS,
    SET_CHECK_IN_DATA,
} from './checkIn.types'

export const initCheckInStreak = createAction(CHECK_IN_STREAK_INIT)
export const setCheckInStreak = (streak) => async (dispatch) => {
    try {
        await updateCheckInStreak(streak)
        dispatch(createAction(CHECK_IN_STREAK_SET))
    } catch (e) {
        logger(`Error of check in streak setting:`, e)
    }
}
export const setCheckInData = createAction(SET_CHECK_IN_DATA)

export const lastCheckInDateSetSuccess = createAction(LAST_CHECK_IN_DATE_SET_SUCCESS)

export const initLastCheckInDate = createAction(LAST_CHECK_IN_DATE_INIT)

export const setLastCheckInDate = () => (dispatch) => {
    try {
        updateLastCheckInDate().then(() => dispatch(lastCheckInDateSetSuccess()))
    } catch (e) {
        logger('Error of last check in date setting :', e)
    }
}

export const checkCheckIn = (checkInStreak, lastCheckInDate) => (dispatch) => {
    if (checkInStreak === -1 || lastCheckInDate === -1) {
        return
    }
    if (!checkInStreak || !lastCheckInDate) {
        dispatch(setCheckInStreak(1))
        dispatch(setLastCheckInDate())

        return
    }
    if (isToday(lastCheckInDate)) {
        return
    }
    if (isYesterday(lastCheckInDate)) {
        dispatch(setCheckInStreak(checkInStreak + 1))
        dispatch(setLastCheckInDate())
    } else {
        dispatch(setCheckInStreak(1))
        dispatch(setLastCheckInDate())
    }
}

export const countProgress = ({ hasSteps, checkIn, steps = [] }) => {
    if (!hasSteps) {
        return checkIn?.progress || 0
    }

    const completedSteps = steps.reduce((acc, { isCompleted }) => (isCompleted ? acc + 1 : acc), 0)

    return Math.ceil((completedSteps / steps.length) * 100) || 0
}

export const countQuizSum = (quiz) => Object.keys(quiz).reduce((sum, question) => sum + quiz[question], 0)

export const convertToEmojiRating = (value) => Math.floor(value / 25)

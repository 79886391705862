import { createSelector } from 'reselect'

const selectStaff = (state) => state.peerSupporterLearning

export const selectIsStaffLearningOpen = createSelector(
    selectStaff,
    ({ isPeerSupporterLearningOpen }) => isPeerSupporterLearningOpen,
)
export const selectIsPeerSupporterLearning = createSelector(
    selectStaff,
    ({ isPeerSupporterLearning }) => isPeerSupporterLearning,
)

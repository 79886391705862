import React from 'react'
import { useNavigate } from 'react-router-dom'

import useNotificationsBadge from 'js/hooks/useNotificationsBadge'
import useScreenSize from 'js/hooks/useScreenSize'
import { INBOX_PATH } from 'js/var/paths'

import HeaderDesktop from './HeaderDesktop'
// TODO: fix collapsing header for tablet
const Header = () => {
    const navigate = useNavigate()
    const badge = useNotificationsBadge()

    const toInbox = () => navigate(INBOX_PATH)

    const { isMobile } = useScreenSize()

    return !isMobile && <HeaderDesktop badge={badge} toInbox={toInbox} />
}

export default Header

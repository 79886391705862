import { handleActions } from 'redux-actions'
import { activateEmailInvitation, toggleEmailInvitationPopup } from './emailInvitation.actions'

const defaultState = {
    isInvitation: false,
    isEmailInvitationPopup: false,
    staffUid: '',
}

const emailInvitationReducer = handleActions(
    {
        [activateEmailInvitation]: (state, { payload: { isInvitation, staffUid } }) => ({
            ...state,
            isInvitation,
            staffUid,
        }),
        [toggleEmailInvitationPopup]: (state) => ({ ...state, isEmailInvitationPopup: !state?.isEmailInvitationPopup }),
    },
    defaultState,
)

export default emailInvitationReducer

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { array, func, object } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { connectSearchBox } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import Box from 'js/components/Box'
import ProfileCheck from 'js/components/ProfileCheck'
import Search from 'js/components/Search'
import useScreenSize from 'js/hooks/useScreenSize'
import withInstanceSearch from 'js/HOCs/withInstanceSearch'
import { searchClient } from 'js/APIs/algoliaClient'
import { selectConnections } from 'js/redux/staff/staff.selectors'

const useStyles = makeStyles(({ fonts, palette }) => ({
    fieldTitle: {
        ...fonts.s16l208,
        fontWeight: 700,
        marginBottom: '4px',
    },
    list: {
        border: `1px solid ${palette.whiteSmoke}`,
        borderRadius: '8px',
        overflow: 'hidden',
        marginTop: '8px',

        '&:last-child': {
            borderBottom: 'none',
        },
    },
    section: {
        backgroundColor: palette.white,
        marginBottom: '8px',

        '&.isTablet, &.isDesktop': {
            marginBottom: 0,
            padding: '16px 0',
        },
    },
    sectionTitle: {
        ...fonts.s20l26,
        marginBottom: '4px',
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
    },
}))

const AlgoliaSearch = connectSearchBox(Search)

const BlockUsers = ({ blockedUsers, setBlockedUsers, hits }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()
    const contacts = useSelector(selectConnections)
    const [users, setUsers] = useState([])

    useEffect(() => {
        setUsers(hits.filter(({ uid }) => contacts.includes(uid)))
    }, [hits, contacts])

    const createOnChange = (uid) => () =>
        setBlockedUsers(blockedUsers[uid] ? { ...blockedUsers, [uid]: null } : { ...blockedUsers, [uid]: true })

    return (
        <Box className={classNames(classes.section, screenSize)}>
            <p className={classes.sectionTitle}>Blocked users</p>
            <p className={classes.text}>Which clients will not have permission to contact you?</p>
            <AlgoliaSearch style={{ marginTop: '8px' }} />
            <div className={classes.list}>
                {users.map(({ full_name, profile_picture, uid }) => (
                    <ProfileCheck
                        isChecked={!!blockedUsers[uid]}
                        full_name={full_name}
                        key={uid}
                        onChange={createOnChange(uid)}
                        profile_picture={profile_picture}
                        uid={uid}
                    />
                ))}
            </div>
        </Box>
    )
}

BlockUsers.propTypes = {
    blockedUsers: object.isRequired,
    hits: array.isRequired,
    setBlockedUsers: func.isRequired,
}

export default withInstanceSearch(BlockUsers, searchClient, 'users')

import classNames from 'classnames'
import Linkify from 'react-linkify'
import parse from 'html-react-parser'
import React, { memo, useEffect, useRef, useState } from 'react'
import { bool, number, object, string } from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { addIdToPath } from 'js/utils/creators'
import { CALL_TYPE } from 'js/var/const'
import { EXPLORE_PATH, JOIN_MEETING_PATH, SCHEDULE_MEETING_INFO_PATH } from 'js/var/paths'
import { selectScheduledMeetings } from 'js/redux/scheduledMeetings/scheduledMeetings.selectors'
import { selectUid } from 'js/redux/profile/user'
import { toTimeAgoMessage } from 'js/utils/dateUtils'
import useOnScreen from 'js/hooks/useOnScreen'
import { removeDelayedNotification } from 'js/redux/notifications/notifications.actions'

const useStyles = makeStyles(({ fonts, palette }) => ({
    Message: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '30px',
        maxWidth: '250px',
        marginBottom: '24px',
    },
    right: {
        alignSelf: 'flex-end',
    },
    left: {
        alignSelf: 'flex-start',
    },
    message: {
        ...fonts.s16l208,
        borderRadius: '16px',
        overflow: 'hidden',
        padding: '8px 16px',

        '&.isDesktop': {
            ...fonts.b2.dskt,
        },

        '& a': {
            textDecoration: 'underline',
        },
    },
    myMessage: {
        backgroundColor: palette.dodgerBlue,
        color: palette.white,

        '& a': {
            color: palette.white,
        },
    },
    notMyMessages: {
        backgroundColor: palette.messageBackground,
        color: palette.black,

        '& a': {
            color: palette.linkBlue,
        },
    },
    time: {
        ...fonts.b4.mob,
        display: 'flex',
        color: palette.darkGrey,

        '&.isDesktop': {
            ...fonts.b3.dskt,
        },
    },
    loader: {
        color: palette.primPurple,
        animationDuration: '1s',
    },
}))

const Message = ({
    cloudTaskIds,
    chatId,
    createdAt,
    groupCallRoomId,
    isDesktop,
    invitationType,
    isInvitation,
    isLoading,
    isMy,
    mid,
    msg,
    scheduledCallRoomId,
    uid,
}) => {
    const currentUserUid = useSelector(selectUid)
    const dispatch = useDispatch()
    const classes = useStyles()
    const created = toTimeAgoMessage(createdAt)
    const navigate = useNavigate()
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const scheduledMeetings = useSelector(selectScheduledMeetings)
    const [isDelayedNotificationRemoved, setDelayedNotificationRemoved] = useState(false)

    useEffect(() => {
        if (cloudTaskIds && !isMy && isVisible && chatId && !isDelayedNotificationRemoved) {
            dispatch(removeDelayedNotification({ chatId, messageId: mid, cloudTaskIds }))
            setDelayedNotificationRemoved(true)
        }
    }, [chatId, cloudTaskIds, isMy, isVisible, mid])

    const toJoinMeeting = () => {
        if (!isInvitation) {
            return
        }

        if (invitationType === CALL_TYPE.scheduledMeeting) {
            if (!scheduledMeetings.find(({ id }) => id === scheduledCallRoomId)) {
                return navigate(addIdToPath(EXPLORE_PATH, scheduledCallRoomId))
            }

            return navigate(addIdToPath(SCHEDULE_MEETING_INFO_PATH, scheduledCallRoomId))
        }

        if (currentUserUid !== uid) {
            navigate(addIdToPath(JOIN_MEETING_PATH, groupCallRoomId))
        }
    }

    return (
        <div
            key={mid}
            className={classNames(isMy ? classes.right : classes.left, classes.Message)}
            onClick={toJoinMeeting}
            ref={ref}
        >
            <div
                className={classNames(classes.message, isMy ? classes.myMessage : classes.notMyMessages, {
                    isDesktop,
                })}
            >
                <Linkify> {parse(msg)}</Linkify>
            </div>
            <div className={classNames(isMy ? classes.right : classes.left, classes.time, { isDesktop })}>
                {isLoading ? (
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.loader}
                        size={16}
                        thickness={4}
                    />
                ) : (
                    created
                )}
            </div>
        </div>
    )
}

Message.propTypes = {
    chatId: string.isRequired,
    cloudTaskIds: object,
    createdAt: number.isRequired,
    groupCallRoomId: string,
    isDesktop: bool.isRequired,
    invitationType: string,
    isLoading: bool,
    isMy: bool,
    mid: string.isRequired,
    isInvitation: bool,
    msg: string.isRequired,
    scheduledCallRoomId: string,
    uid: string.isRequired,
}

Message.defaultProps = {
    cloudTaskIds: null,
    groupCallRoomId: '',
    invitationType: '',
    isLoading: false,
    isInvitation: false,
    isMy: true,
    scheduledCallRoomId: '',
}

export default memo(Message)

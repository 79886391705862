/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { array, func, object, string, bool } from 'prop-types'

import OutsideClick from 'js/components/Reusable/OutsideClick'

import { percentToHex } from 'js/utils/format'
import useScreenSize from 'js/hooks/useScreenSize'
import AddedRecipient from './AddedRecipient'

const useStyles = makeStyles(({ fonts, others, palette }) => ({
    ChatUsersSearch: {
        backgroundColor: palette.white,
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '53px',
        padding: '32px 16px',
        width: '100%',
        marginBottom: '8px',

        '&.isDesktop': {
            marginBottom: 0,
            padding: '32px 0px',
        },

        '&.isDesktop.isInvite': {
            border: 'none',
            paddingBottom: '24px',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: '18px',

            '&::after': {
                boxShadow: 'none',
            },
        },

        '&.isMobile.isInvite': {
            marginBottom: 0,
            marginTop: '16px',
            minHeight: 0,
            padding: 0,
        },
    },
    input: {
        ...fonts.b2.mob,
        alignItems: 'center',
        border: 'none',
        borderRadius: '4px',
        color: palette.black,
        display: 'inline-flex',
        marginLeft: '4px',
        padding: '2px',

        '&:focus': {
            outline: 'none',
        },
    },
    list: {
        ...others.scroll,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        maxHeight: '74px',
        overflow: 'auto',

        '&.isInvite': {
            height: 'fit-content',
            paddingBottom: 0,
        },
    },
    placeholder: {
        ...fonts.b2.mob,
        color: palette.midGrey,

        '&.isInvite': {
            alignItems: 'center',
            display: 'flex',
            height: 'fit-content',
            padding: 0,
            ...fonts.b2.mob,
        },
    },
    searchField: {
        ...fonts.s16l208,
        color: palette.black,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        minHeight: '40px',
        padding: '8px 16px',
        background: palette.fuchsiaBlue + percentToHex(10),
        border: 'none',
        borderBottom: `1px solid ${palette.inputBorder}`,
        outline: 'none',
        width: '100%',

        '&:placeholder': {
            ...fonts.s16l208,
            color: palette.black + percentToHex(50),
        },

        '&:disabled': {
            opacity: 0.5,
        },
    },
    searchFieldFocus: {
        borderBottom: `1px solid ${palette.fuchsiaBlue}`,
        boxShadow: '0px 0px 0px 4px rgba(114, 83, 204, 0.5)',
    },
    to: {
        ...fonts.s16l208,
        alignItems: 'center',
        color: palette.black,
        display: 'flex',
        marginRight: '8px',
        width: '17px',
    },
}))

const ChatUsersSearch = ({
    deleteRecipient,
    inputRef,
    isLastSelected,
    isInvite,
    searchString,
    selectedRecipients,
    setIsLastSelected,
    setSearchString,
}) => {
    const screenSize = useScreenSize()
    const classes = useStyles()
    const defaultValue = useRef(searchString)
    const selectedLength = selectedRecipients.length
    const [isFocus, setIsFocus] = useState(false)

    const setInputFocus = useCallback(() => {
        if (inputRef?.current) {
            setIsFocus(true)
            inputRef.current.focus()
        }
    }, [inputRef])

    useEffect(() => {
        if (!isInvite) {
            setInputFocus()
        }
    }, [isInvite, setInputFocus])

    const handleInput = ({ target: { innerHTML } }) => {
        setSearchString(innerHTML)
    }

    const handleKeyDown = (e) => {
        const isBackSpaceAction = e.keyCode === 8 && searchString.length === 0 && selectedLength > 0
        if (isBackSpaceAction && isLastSelected) {
            deleteRecipient(selectedRecipients[selectedLength - 1].uid)

            return setIsLastSelected(false)
        }

        if (isBackSpaceAction) {
            return setIsLastSelected(true)
        }
        setIsLastSelected(false)
    }

    return (
        <OutsideClick callback={() => setIsFocus(false)}>
            <div
                className={classNames(classes.ChatUsersSearch, {
                    ...screenSize,
                    isInvite,
                })}
                onClick={() => {
                    setInputFocus()
                }}
            >
                {!isInvite && <p className={classes.to}>To:</p>}
                <div
                    className={classNames(classes.searchField, {
                        isInvite,
                        [classes.searchFieldFocus]: isInvite && isFocus,
                    })}
                    onClick={(e) => {
                        setInputFocus()
                        e.stopPropagation()
                        setIsFocus(true)
                    }}
                >
                    <div className={classNames(classes.list, { ...screenSize, isInvite })}>
                        {selectedRecipients.map(({ full_name, uid }, i) => (
                            <AddedRecipient
                                deleteRecipient={() => deleteRecipient(uid)}
                                isDesktop={false}
                                key={uid}
                                name={full_name}
                                isLast={i === selectedLength - 1}
                                isInvite={isInvite}
                                isLastSelected={isLastSelected}
                                setIsLastSelected={setIsLastSelected}
                            />
                        ))}
                        <span
                            className={classNames(classes.input, { isInvite })}
                            contentEditable
                            dangerouslySetInnerHTML={{ __html: defaultValue.current }}
                            onInput={handleInput}
                            onKeyDown={handleKeyDown}
                            ref={inputRef}
                            role="textbox"
                            tabIndex={0}
                        />
                        {selectedLength === 0 && searchString.length === 0 && (
                            <span className={classNames(classes.placeholder, { isInvite })}>Enter Name(s)</span>
                        )}
                    </div>
                </div>
            </div>
        </OutsideClick>
    )
}

ChatUsersSearch.propTypes = {
    deleteRecipient: func.isRequired,
    inputRef: object.isRequired,
    isInvite: bool,
    isLastSelected: bool.isRequired,
    searchString: string.isRequired,
    selectedRecipients: array.isRequired,
    setIsLastSelected: func.isRequired,
    setSearchString: func.isRequired,
}

ChatUsersSearch.defaultProps = {
    isInvite: false,
}

export default ChatUsersSearch

import { handleActions } from 'redux-actions'
import { closeError, setError } from './error.actions'

const defaultState = {
    isError: false,
    code: '',
    message: '',
    title: '',
}

const errorReducer = handleActions(
    {
        [closeError]: () => defaultState,
        [setError]: (state, { payload }) => ({
            ...state,
            ...payload,
            isError: true,
        }),
    },
    defaultState,
)

export default errorReducer

const palette = {
    alto: '#CFCFCF',
    black: '#11121A',
    blueLight: '#007AFF',
    blueRibbon: '#004BFF',
    chatBlue: '#DBEEFF',
    darkerGrey: '#393939',
    darkerStatusGreen: '#32B04E',
    darkestPurple: '#411AA8',
    darkGrey: '#656670',
    dodgerBlue: '#256CF8',
    doveGrey: '#666666',
    disabledGrey: '#F7F7F8',
    dustBlue: '#E4EEFF',
    dustOrange: '#F58B51',
    electricViolet: '#7e48ff',
    electricViolet2: '#9747FF',
    errorRed: '#F55151',
    fuchsiaBlue: '#7253CC',
    godGrey: '#101010',
    gold: '#F0BE48',
    lightBlue: '#EDF7FF',
    linkBlue: '#0087FF',
    inputBorder: '#BBBBBB',
    marshmallow: '#FFFEF8',
    messageBackground: '#0000001a',
    midBlue: '#4F84DE',
    midGrey: '#95959D',
    midYellow: '#F5BC00',
    offWhite: '#F2F6FF',
    orange: '#F26419',
    persianRed: '#CC3B33',
    primBlue: '#78AAFF',
    primPurple: '#7D4BFF',
    primPurpleBG: '#F2EDFF',
    purpleDisabled: '#E0C9FF',
    ronchi: '#F0BE48',
    secondaryBlue: '#4BAAFF',
    secondaryGrey: '#BCBDC1',
    softBlue: '#F2F7FF',
    statusGreen: '#33C954',
    white: '#FFFFFF',
    whiteSmoke: '#EFEFEF',
    // old palette 2
    blue: {
        darkestBlue: '#172C4F',
        darkBlue: '#213D6E',
    },
    mint: {
        darkestMint: '#0A5C51',
        darkMint: '#0E8171',
        midMint: '#14B8A2',
        brightMint: '#29E3CA',
        dustyMint: '#7EF1E1',
        softMint: '#C8F9F2',
        lightMint: '#EBFFFC',
    },
    grey: {
        deepNavy: '#2B2D42',
        disabledGrey2: '#DDDDDF',
        backgroundWhite: '#f7fafc',
    },
    gradients: {
        blur: 'linear-gradient(269.51deg, #CCAAFF -32.44%, #78AAFF 95.23%)',
        primary: 'linear-gradient(103.66deg, #7253CC 0%, #4381F9 113.17%)',
        purple: 'linear-gradient(138.07deg, #6400D7 1.74%, #40008A 96.14%)',
    },
    // old palette
    coral: '#FE805C',
    creamBrulee: '#FFE29C',
    darkGreen: '#00808D',
    dimYellow: '#FFE9B4',
    malachite: '#00C853',
    red: '#FF0000',
    silver: '#BCBBBB',
}

export default palette

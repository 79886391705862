import { createAction } from 'redux-actions'

import { getTimestamp } from 'js/APIs/firebase/firebase'
import { getBookmarks, updateBookmark } from 'js/APIs/firebase/resources.firebase'
import { RESOURCE_BOOKMARK_POPUP_MESSAGES } from 'js/var/const'

import { getFeaturedResourceId, getResources } from 'js/APIs/contentfulClient'
import {
    ADD_RESOURCE_BOOKMARK,
    ADD_RESOURCE_BOOKMARK_ERROR,
    ADD_RESOURCE_BOOKMARK_SUCCESS,
    DELETE_RESOURCE_BOOKMARK,
    DELETE_RESOURCE_BOOKMARK_ERROR,
    DELETE_RESOURCE_BOOKMARK_SUCCESS,
    GET_RESOURCES,
    GET_RESOURCES_ERROR,
    GET_RESOURCES_SUCCESS,
    RESOURCE_BOOKMARK_POPUP_SET,
    RESOURCE_RECENTLY_VIEWED_ADD,
    RESOURCE_TAB_SET,
    RESOURCES_TYPE_SET,
} from './resources.types'

export const addRecentlyViewed = createAction(RESOURCE_RECENTLY_VIEWED_ADD)

export const setResourceBookmarkPopup = createAction(RESOURCE_BOOKMARK_POPUP_SET)

export const showResourceBookmark =
    ({ marginBottom = '93px', message }) =>
    (dispatch) => {
        dispatch(setResourceBookmarkPopup({ marginBottom, message, isOpen: true }))
        setTimeout(() => dispatch(setResourceBookmarkPopup({ isOpen: false })), 1500)
    }

const addBookmarkStart = createAction(ADD_RESOURCE_BOOKMARK)
export const addBookmarkError = createAction(ADD_RESOURCE_BOOKMARK_ERROR)
export const addBookmarkSuccess = createAction(ADD_RESOURCE_BOOKMARK_SUCCESS)

export const addResourceBookmark = (rid) => async (dispatch) => {
    dispatch(addBookmarkStart())
    try {
        await updateBookmark({ bookmark: { bookmarkedAt: getTimestamp() }, rid })
        dispatch(addBookmarkSuccess({ bookmark: { bookmarkedAt: +new Date() }, rid }))
        dispatch(showResourceBookmark({ message: RESOURCE_BOOKMARK_POPUP_MESSAGES.added }))
    } catch (e) {
        dispatch(addBookmarkError(`Error of resource bookmark adding: ${e}`))
        dispatch(showResourceBookmark({ message: RESOURCE_BOOKMARK_POPUP_MESSAGES.addedError }))
    }
}

const deleteBookmarkStart = createAction(DELETE_RESOURCE_BOOKMARK)
export const deleteBookmarkError = createAction(DELETE_RESOURCE_BOOKMARK_ERROR)
export const deleteBookmarkSuccess = createAction(DELETE_RESOURCE_BOOKMARK_SUCCESS)

export const deleteResourceBookmark = (rid) => async (dispatch) => {
    dispatch(deleteBookmarkStart())
    try {
        await updateBookmark({ bookmark: null, rid })
        dispatch(deleteBookmarkSuccess(rid))
        dispatch(showResourceBookmark({ message: RESOURCE_BOOKMARK_POPUP_MESSAGES.removed }))
    } catch (e) {
        dispatch(deleteBookmarkError(`Error of resource bookmark deleting: ${e}`))
        dispatch(showResourceBookmark({ message: RESOURCE_BOOKMARK_POPUP_MESSAGES.removedError }))
    }
}

export const getResourcesStart = createAction(GET_RESOURCES)
export const getResourcesError = createAction(GET_RESOURCES_ERROR)
export const getResourcesSuccess = createAction(GET_RESOURCES_SUCCESS)

export const loadResources = () => async (dispatch) => {
    dispatch(getResourcesStart())
    try {
        const resources = await getResources()

        if (!resources) {
            return dispatch(
                getResourcesError('error in getting resources from Contentful: response has no resource items'),
            )
        }
        const bookmarks = await getBookmarks()

        const bookmarkedResources = resources.map((resource) => ({
            ...resource,
            isBookmarked: bookmarks[resource.id],
        }))

        const featuredId = await getFeaturedResourceId()
        const featuredResource = bookmarkedResources.find(({ id }) => id === featuredId)

        if (featuredResource) {
            return dispatch(
                getResourcesSuccess({
                    bookmarks,
                    featuredResource,
                    resources: bookmarkedResources
                        .filter(({ id }) => id !== featuredId)
                        .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)),
                }),
            )
        }

        dispatch(getResourcesSuccess({ bookmarks, resources: bookmarkedResources }))
    } catch (e) {
        dispatch(getResourcesError(`error in getting resources from Contentful: ${e}`))
    }
}

export const setResourceTab = createAction(RESOURCE_TAB_SET)
export const setResourcesType = createAction(RESOURCES_TYPE_SET)

import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { any, func, object, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => ({
    Box: {
        padding: '32px 16px',
    },
}))

const Box = ({ className, onClick, children, style }) => {
    const classes = useStyles()

    return (
        <div className={classNames(classes.Box, className)} onClick={onClick} style={style}>
            {children}
        </div>
    )
}

Box.propTypes = {
    className: string,
    children: any,
    onClick: func,
    style: object,
}

Box.defaultProps = {
    className: '',
    children: null,
    onClick: null,
    style: {},
}

export default Box

import classNames from 'classnames'
import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'

import Box from 'js/components/Box'
import Radio from 'js/components/Radio'
import useScreenSize from 'js/hooks/useScreenSize'
import { func, string } from 'prop-types'
import { CONTACT_METHODS } from 'js/var/const'

const useStyles = makeStyles(({ fonts, palette }) => ({
    section: {
        backgroundColor: palette.white,
        marginBottom: '8px',

        '&.isTablet, &.isDesktop': {
            marginBottom: 0,
            padding: '16px 0',
        },
    },
    sectionTitle: {
        ...fonts.s20l26,
        marginBottom: '4px',
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
    },
}))

const ContactMethod = ({ contactMethod, setContactMethod }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()
    const createSwitch = useCallback((value) => () => setContactMethod(value), [setContactMethod])

    return (
        <Box className={classNames(classes.section, screenSize)}>
            <p className={classes.sectionTitle}>Contact Method</p>
            <p className={classes.text}>Choose how you will be contacted with communication alerts.</p>
            <Radio
                isSelected={contactMethod === CONTACT_METHODS.email}
                onClick={createSwitch(CONTACT_METHODS.email)}
                text="By email"
            />
            <Radio
                isSelected={contactMethod === CONTACT_METHODS.pushNotifications}
                onClick={createSwitch(CONTACT_METHODS.pushNotifications)}
                text="In the app"
            />
        </Box>
    )
}

ContactMethod.propTypes = {
    contactMethod: string.isRequired,
    setContactMethod: func.isRequired,
}

export default ContactMethod

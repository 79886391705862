import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import { ArrowBack } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'

import logo from 'media/logo.png'
import garden from 'media/garden.png'
import { ReactComponent as AttachedFileIcon } from 'media/attached-file.svg'
import { ReactComponent as ChatIcon } from 'media/chat.svg'
import { ReactComponent as EditCalendarIcon } from 'media/edit-calendar.svg'
import { ReactComponent as FlagIcon } from 'media/flag.svg'
import { ReactComponent as CheckBoxIcon } from 'media/check-box.svg'

import { FEATURE_OPTIONS } from 'js/var/const'
import { useFeature } from 'flagged'
import { FEATURE_NAME } from 'featureFlags'
import NewUserOption from './NewUserOption'

const useStyles = makeStyles(({ palette }) => ({
    NewUserOptions: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingTop: '8px',
        position: 'relative',
        width: '100%',

        '& .back': {
            height: '24px',
            left: '16px',
            position: 'absolute',
            top: '8px',
            width: '24px',

            '& path': {
                fill: palette.fuchsiaBlue,
            },
        },
    },
    image: {
        marginTop: 'auto',
        width: '100%',
    },
    logo: {
        height: '24px',
        marginBottom: '70px',
    },
    sliderBox: {
        width: '100%',

        '& .slick-dots': {
            '& li': {
                height: '10px',
                width: '10px',

                '& button': {
                    borderRadius: '5px',
                    backgroundColor: palette.alto,
                    height: '10px',
                    width: '10px',
                },

                '& button:before': {
                    display: 'none',
                },
            },

            '& li.slick-active': {
                '& button': {
                    backgroundColor: palette.fuchsiaBlue,
                },
            },
        },
    },
}))

const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const NewUserOptions = () => {
    const classes = useStyles()
    const isGoalsOn = useFeature(FEATURE_NAME.onboardingGoals)
    const isResourcesOn = useFeature(FEATURE_NAME.onboardingResources)
    const [activeIndex, setActiveIndex] = useState(0)
    let slider = useRef()

    const back = () => slider.slickPrev()

    const beforeChange = (current, next) => {
        setActiveIndex(next)
    }

    const next = () => slider.slickNext()

    const isWayBack = activeIndex > 0

    return (
        <div className={classes.NewUserOptions}>
            <img className={classes.logo} src={logo} alt="logo" />
            {isWayBack && <ArrowBack className="back" onClick={back} />}
            <div className={classes.sliderBox}>
                <Slider
                    ref={(c) => {
                        slider = c
                    }}
                    {...settings}
                    beforeChange={beforeChange}
                    initialSlide={0}
                >
                    <NewUserOption
                        Icon={EditCalendarIcon}
                        next={next}
                        p1="You can find a licensed and certified care provider that specializes in your specific concerns and needs."
                        p2="Schedule sessions with them and meet online or in-person!"
                        title="Schedule sessions and meet with professionals that can help"
                        progressTitle="Sessions earn progress towards new flowers"
                    />
                    <NewUserOption
                        Icon={ChatIcon}
                        next={next}
                        p1="We have a team of peer supporters who have gone through the same things you’re going through."
                        p2="Find someone you like and chat with them through text or video calls."
                        title="Chat with someone who’s been where you’re at"
                        progressTitle="Chats also earn progress towards new flowers"
                    />
                    <NewUserOption
                        Icon={FlagIcon}
                        option={isGoalsOn && FEATURE_OPTIONS.goals}
                        next={next}
                        p1="Create goals that help keep you on track."
                        p2="You can even encourage others to help motivate them. And they are able to encourage you!"
                        title="Set and achieve goals for yourself"
                        progressTitle="Goals earn progress towards new flower colors"
                    />
                    <NewUserOption
                        Icon={AttachedFileIcon}
                        option={isResourcesOn && FEATURE_OPTIONS.resources}
                        next={next}
                        p1="We’ve crafted and found a bunch of resources to help you on your mental health journey."
                        p2="We’ve got videos, articles, worksheets, and a lot more."
                        title="Utilize helpful resources"
                        progressTitle="Resources earn progress towards more plots"
                    />
                    <NewUserOption
                        hint="Don’t worry. Even if you forget to check-in, your garden will always look beautiful."
                        isCheckIn
                        Icon={CheckBoxIcon}
                        next={next}
                        p1="Each day you can check-in and provide insight into how your feeling."
                        p2="Track and view your progress over an extended period of time."
                        title="Check-in and track your progress"
                        progressTitle="Checking in keeps your garden watered"
                    />
                </Slider>
            </div>
            <img className={classes.image} src={garden} alt="Flowers with bees" />
        </div>
    )
}

export default NewUserOptions

const { GET, POST } = require('./axiosConfig')

// TODO update rest link as described in comment
// https://github.com/moxiegirls/moxie-girls-web/pull/51#discussion_r494889698

export const checkUsernameTakenAxios = (data) => GET('/checkUsernameTaken', data)
export const getChatAxios = (data) => GET('/getChat', data)
export const getChatsAxios = (data) => GET('/getChats', data)
export const getChatMessagesAxios = (data) => GET('/getChatMessages', data)
export const getEmailAxios = (data) => GET('/getEmail', data)

export const createChatAxios = (data) => POST('/createChat', data)
export const connectStaffAxios = (data) => POST('/connectStaff', data)
export const createConnectionAxios = (data) => POST('/createConnection', data)
export const createGoalReminderAxios = (data) => POST('/createGoalReminder', data)
export const createNotificationAxios = (data) => POST('/createNotification', data)
export const createScheduledMeetingAxios = (data) => POST('/createScheduledMeeting', data)
export const deleteScheduleMeetingAxios = (data) => POST('/deleteScheduleMeeting', data)
export const editScheduleMeetingAxios = (data) => POST('/editScheduleMeeting', data)
export const exportChatToEmailAxios = (data) => POST('/exportChatToEmail', data)
export const removeGoalReminderAxios = (data) => POST('/removeGoalReminder', data)
export const removeDelayedNotificationAxios = (data) => POST('/removeDelayedNotification', data)
export const sendFirstRequestNotificationAxios = (data) => POST('/sendFirstRequestNotification', data)
export const setEmailAxios = (data) => POST('/addEmail', data)
export const sendEmailAxios = (data) => POST('/sendEmail', data)
export const sendEmailInvitationAxios = (data) => POST('/sendEmailInvitation', data)
export const updateStaffWaitingRoomLinkAxios = (data) => POST('/updateStaffWaitingRoomLink', data)

import React from 'react'
import { makeStyles } from '@material-ui/core'
import { object, string } from 'prop-types'

const useStyles = makeStyles(({ fonts, palette }) => ({
    SectionEmpty: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 16px',
        marginBottom: '16px',
    },
    iconWrapper: {
        alignItems: 'center',
        backgroundColor: palette.offWhite,
        borderRadius: '50%',
        display: 'flex',
        height: '75px',
        justifyContent: 'center',
        marginBottom: '8px',
        width: '75px',

        '& svg': {
            height: '48px',
            width: '48px',

            '& path': {
                fill: 'rgba(102, 102, 102, 0.5)',
                fillOpacity: 1,
            },
        },
    },
    title: {
        ...fonts.s16l208,
        color: palette.godGrey,
        marginBottom: '8px',
        textAlign: 'center',
    },
    text: {
        ...fonts.s12l16,
        color: palette.doveGrey,
        textAlign: 'center',
    },
}))

const SectionEmpty = ({ Icon, text, title }) => {
    const classes = useStyles()

    return (
        <div className={classes.SectionEmpty}>
            <div className={classes.iconWrapper}>
                <Icon />
            </div>
            <p className={classes.title}>{title}</p>
            <p className={classes.text}>{text}</p>
        </div>
    )
}

SectionEmpty.propTypes = {
    Icon: object.isRequired,
    text: string.isRequired,
    title: string.isRequired,
}

export default SectionEmpty

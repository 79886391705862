import logger from 'js/utils/logger'

import firebase, { getUid } from './firebase'

export const updatePHQ2 = (PHQ2) =>
    firebase
        .database()
        .ref(`users/${getUid()}/phq2`)
        .update(PHQ2)
        .catch((e) => logger(`Error of updating phq2: ${e}`))

export default { updatePHQ2 }

import React from 'react'
import { ArrowBackRounded } from '@mui/icons-material'
import { bool, func, number, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { savePHQ2, setPHQ2Answer, setPHQ2Slide, togglePHQ2 } from 'js/redux/phq2/phq2.actions'
import { selectPHQ2 } from 'js/redux/phq2/phq2.selectors'

import classNames from 'classnames'
import AssessmentAnswer from '../../AssessmentAnswer'

const useStyles = makeStyles(({ fonts, palette }) => ({
    PHQ2Question: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '750px',
        padding: '40px 30px 0 30px',

        '&.isDesktop': {
            padding: '26px 60px 200px 60px',
        },
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '422px',
    },
    exit: {
        ...fonts.b4.mob,
        color: palette.black,

        '&.isDesktop': {
            ...fonts.b3.dskt,
        },
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',

        '&.isDesktop': {
            marginBottom: '50px',
        },
    },
    icon: {
        '& path': {
            fill: palette.black,
        },

        '&.isDesktop': {
            height: '32px',
            width: '32px',
        },
    },
    img: {
        height: 'auto',
        width: '100%',
    },
    imgWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '300px',
        justifyContent: 'center',
        maxWidth: '320px',
        width: '100%',

        '&.isDesktop': {
            height: '330px',
        },
    },
    question: {
        ...fonts.b1.mob,
        color: palette.black,
        marginBottom: '20px',
        marginTop: '32px',

        '&.isDesktop': {
            ...fonts.b1.dskt,
            marginTop: '50px',
        },
    },
}))

const PHQ2Question = ({ back, goToNextSlide, image, isDesktop, question, slideNumber }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const PHQ2 = useSelector(selectPHQ2)
    const selectedAnswer = PHQ2.answers[`question${slideNumber}`]
    const answer = selectedAnswer === 0 ? selectedAnswer : selectedAnswer || -1

    const handleExit = () => {
        dispatch(setPHQ2Slide(slideNumber))
        dispatch(togglePHQ2())
        dispatch(savePHQ2({ ...PHQ2, isPHQ2Open: false }))
    }

    const handleChange = (value) => {
        goToNextSlide()
        dispatch(setPHQ2Answer({ key: `question${slideNumber}`, data: value }))
    }

    return (
        <div className={classNames(classes.PHQ2Question, { isDesktop })}>
            <div className={classNames(classes.header, { isDesktop })}>
                <span>
                    {back && <ArrowBackRounded className={classNames(classes.icon, { isDesktop })} onClick={back} />}
                </span>
                <div className={classNames(classes.exit, { isDesktop })} onClick={handleExit}>
                    Save &amp; Exit
                </div>
            </div>
            <div className={classes.content}>
                <div className={classNames(classes.imgWrapper, { isDesktop })}>
                    <img alt="question illustration" className={classes.img} src={image} />
                </div>
                <p className={classNames(classes.question, { isDesktop })}>{question}</p>
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="Not at all"
                    value={0}
                />
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="Several days"
                    value={1}
                />
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="More than half the days"
                    value={2}
                />
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="Nearly everyday"
                    value={3}
                />
            </div>
        </div>
    )
}

PHQ2Question.propTypes = {
    back: func,
    goToNextSlide: func.isRequired,
    image: func.isRequired,
    isDesktop: bool.isRequired,
    question: string.isRequired,
    slideNumber: number.isRequired,
}

PHQ2Question.defaultProps = {
    back: null,
}

export default PHQ2Question

import React from 'react'
import { any, bool, string } from 'prop-types'
import { Tooltip as MUITooltip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ fonts, palette }) => ({
    tooltip: {
        ...fonts.b4.mob,
        backgroundColor: palette.white,
        borderRadius: '12px',
        color: palette.black,
        filter: 'drop-shadow(0px 6px 10px rgba(101, 101, 101, 0.25))',
        padding: '24px 32px',
    },
}))

const Tooltip = ({ children, disableHoverListener, disableTouchListener, title }) => {
    const classes = useStyles()

    return (
        <MUITooltip
            classes={{ tooltip: classes.tooltip }}
            disableHoverListener={disableHoverListener}
            disableTouchListener={disableTouchListener}
            enterTouchDelay={0}
            title={title}
        >
            <span>{children}</span>
        </MUITooltip>
    )
}

Tooltip.propTypes = {
    children: any.isRequired,
    disableHoverListener: bool,
    disableTouchListener: bool,
    title: string.isRequired,
}

Tooltip.defaultProps = {
    disableHoverListener: true,
    disableTouchListener: true,
}

export default Tooltip

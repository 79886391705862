import logger from 'js/utils/logger'
import { STATUS } from 'js/var/const'

import firebase from './firebase'
import { setUserStatus } from './peerToPeer.firebase'

export const signOut = async (cb) => {
    await setUserStatus({ status: STATUS.offline })
    await firebase
        .auth()
        .signOut()
        .then(() => {
            if (cb) {
                cb()
            }
        })
        .catch((e) => logger(e))
}

export const sendResetPasswordEmail = (emailAddress) => {
    const actionCodeSettings = {
        handleCodeInApp: true,
        url: `${process.env.REACT_APP_URL}?emailAddress=${emailAddress}`,
        iOS: {
            bundleId: 'com.moxie.dev',
        },
    }

    firebase
        .auth()
        .sendSignInLinkToEmail(emailAddress, actionCodeSettings)
        .catch((error) => logger(error))
}

export const checkEmailTaken = async (email) => {
    const authMethods = await firebase
        .auth()
        .fetchSignInMethodsForEmail(email)
        .catch((e) => logger('error of fetching auth methods: ', e))

    return authMethods.find((method) => method === 'password')
}

import firebase, { getUid } from './firebase'

export const createGroupCallRoom = async () => {
    const uid = await getUid()

    return firebase.database().ref(`callRooms`).push({
        creator: uid,
        isStarted: false,
    }).key
}

export const removeGroupCallRoom = (id) =>
    firebase
        .database()
        .ref(`callRooms`)
        .update({ [id]: null })

export const saveGroupCallRoomId = async (id) => {
    const uid = await getUid()

    return firebase.database().ref(`users/${uid}`).update({ groupCallRoom: id })
}

export const checkIsCallRoomExist = async (id) => {
    let isExist = false
    let data = null

    await firebase
        .database()
        .ref(`callRooms`)
        .once('value', (snapshot) => {
            isExist = snapshot.child(id).exists()
            data = snapshot.child(id).val()

            return isExist
        })

    return { isExist, data }
}

export const joinCall = async (id) => {
    const uid = await getUid()

    return firebase
        .database()
        .ref(`users/${uid}/joinedCalls`)
        .update({ [id]: true })
}

export const signOnMeetingOff = (id) => firebase.database().ref(`callRooms/${id}`).off('value')

export const unJoinCall = async (id) => {
    const uid = await getUid()
    await signOnMeetingOff(id)

    return firebase
        .database()
        .ref(`users/${uid}/joinedCalls`)
        .update({ [id]: null })
}

export const removeCall = async (id) => {
    const uid = await getUid()

    return firebase
        .database()
        .ref(`users/${uid}/joinedCalls`)
        .update({ [id]: null })
}

export const signOnMeeting = (id, cb) => firebase.database().ref(`callRooms/${id}`).on('value', cb)

export const startGroupMeetingFirebase = ({ id, roomName, url }) =>
    firebase.database().ref(`callRooms/${id}`).update({ roomUrl: url, roomName, isStarted: true })

export const setGroupCallFinished = (id) => firebase.database().ref(`callRooms/${id}`).update({ isFinished: true })

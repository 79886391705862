import classNames from 'classnames'
import React from 'react'
import { bool, func, string } from 'prop-types'
import { Link } from 'react-router-dom'
import { Link as MUILink, makeStyles } from '@material-ui/core'

import palette from 'styles/palette'
import { ACTIVE_COLOR, HEADER_BUTTON_WIDTH, ICON_COLOR } from 'js/var/const'
import { HOME_PATH } from 'js/var/paths'
import Button from 'js/components/Reusable/Button'

const useStyles = makeStyles(({ fonts, palette }) => ({
    HeaderLink: {
        alignItems: 'center',
        background: palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '76px',
        justifyContent: 'space-between',
        margin: '0 6px',
        padding: ' 17px 0 6px 0',
        position: 'relative',
        width: HEADER_BUTTON_WIDTH,

        '& svg': {
            height: '40px',
        },

        '& p': {
            ...fonts.menu,
            color: palette.primBlue,
        },

        '&.isActive': {
            '& p': {
                color: palette.primPurple,
            },
        },

        '&:hover': {
            textDecoration: 'none',
        },
    },
    indicator: {
        ...fonts.b4.mob,
        backgroundColor: palette.orange,
        borderRadius: '50%',
        color: palette.white,
        display: 'flex',
        height: '20px',
        justifyContent: 'center',
        paddingBottom: '4px',
        paddingTop: '2px',
        position: 'absolute',
        right: '10px',
        top: '7px',
        width: '20px',
    },
    text: {
        ...fonts.menu,
        color: palette.primPurple,
    },
}))

const choseWrapper = ({ isButton, isExternalLink }) => {
    if (isButton) {
        return Button
    }
    if (isExternalLink) {
        return MUILink
    }

    return Link
}

const HeaderLink = ({ badge, Icon, isActive, isExternalLink, onClick, path, title }) => {
    const classes = useStyles()

    const Wrapper = choseWrapper({ isButton: onClick, isExternalLink })

    const target = isExternalLink ? '_blank' : '_self'

    return (
        <Wrapper
            className={classNames(classes.HeaderLink, {
                isActive,
            })}
            href={path}
            onClick={onClick}
            target={target}
            to={path}
        >
            <Icon color={palette[isActive ? ACTIVE_COLOR : ICON_COLOR]} />
            {badge !== undefined && badge && <span className={classes.indicator}>{badge}</span>}
            <p className={classes.text}>{title}</p>
        </Wrapper>
    )
}

HeaderLink.propTypes = {
    badge: string,
    Icon: func.isRequired,
    isActive: bool,
    isExternalLink: bool,
    onClick: func,
    path: string,
    title: string.isRequired,
}

HeaderLink.defaultProps = {
    badge: undefined,
    isActive: false,
    isExternalLink: false,
    onClick: null,
    path: HOME_PATH,
}

export default HeaderLink

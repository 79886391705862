import { createAction } from 'redux-actions'

import { updateFCMToken } from 'js/APIs/firebase/firebase'

import {
    UPDATE_FIREBASE_CLOUD_MESSAGING_TOKEN,
    UPDATE_FIREBASE_CLOUD_MESSAGING_TOKEN_ERROR,
    UPDATE_FIREBASE_CLOUD_MESSAGING_TOKEN_SUCCESS,
} from './firebaseCloudMessaging.types'

const firebaseCloudMessagingTokenUpdate = createAction(UPDATE_FIREBASE_CLOUD_MESSAGING_TOKEN)
export const firebaseCloudMessagingTokenUpdateError = createAction(UPDATE_FIREBASE_CLOUD_MESSAGING_TOKEN_ERROR)
export const firebaseCloudMessagingTokenUpdateSuccess = createAction(UPDATE_FIREBASE_CLOUD_MESSAGING_TOKEN_SUCCESS)

export const updateFirebaseCloudMessagingToken = (data) => async (dispatch) => {
    dispatch(firebaseCloudMessagingTokenUpdate())
    try {
        updateFCMToken(data)
        dispatch(firebaseCloudMessagingTokenUpdateSuccess(data))
    } catch (error) {
        dispatch(firebaseCloudMessagingTokenUpdateError(error))
    }
}

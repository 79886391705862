export const COMPLETE_PHQ9 = 'COMPLETE_PHQ9'

export const INIT_PHQ9 = 'INIT_PHQ9'

export const SET_PHQ9_ANSWER = 'SET_PHQ9_ANSWER'

export const SET_PHQ9_SLIDE = 'SET_PHQ9_SLIDE'

export const START_PHQ9 = 'START_PHQ9'

export const TOGGLE_PHQ9 = 'TOGGLE_PHQ9'

export const UPDATE_PHQ9 = 'UPDATE_PHQ9'

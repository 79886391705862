import React from 'react'

const IconHintArrow = () => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.76398 8.77291H12.934L8.05398 13.6529C7.66398 14.0429 7.66398 14.6829 8.05398 15.0729C8.44398 15.4629 9.07398 15.4629 9.46398 15.0729L16.054 8.48291C16.444 8.09291 16.444 7.46291 16.054 7.07291L9.47398 0.472906C9.28715 0.285653 9.03349 0.18042 8.76898 0.18042C8.50446 0.18042 8.25081 0.285653 8.06398 0.472906C7.67398 0.862906 7.67398 1.49291 8.06398 1.88291L12.934 6.77291H1.76398C1.21398 6.77291 0.763977 7.22291 0.763977 7.77291C0.763977 8.32291 1.21398 8.77291 1.76398 8.77291Z"
            fill="white"
        />
    </svg>
)

export default IconHintArrow

import { combineActions, handleActions } from 'redux-actions'

import {
    setCallType,
    setFeedbackSuccess,
    setOwner,
    skipFeedback,
    toggleFeedbackPopup,
    toggleFeedbackSuccess,
} from './callFeedback.actions'

const defaultState = {
    callType: '',
    isFeedbackSuccess: false,
    isOpen: false,
    owner: '',
}

const callFeedbackReducer = handleActions(
    {
        [combineActions(skipFeedback, setFeedbackSuccess)]: () => defaultState,
        [setCallType]: (state, { payload }) => ({
            ...state,
            callType: payload,
        }),
        [setOwner]: (state, { payload }) => ({
            ...state,
            owner: payload,
        }),
        [toggleFeedbackPopup]: (state) => ({
            ...state,
            isOpen: !state.isOpen,
        }),
        [toggleFeedbackSuccess]: (state) => ({
            ...state,
            isFeedbackSuccess: !state.isFeedbackSuccess,
        }),
    },
    defaultState,
)

export default callFeedbackReducer

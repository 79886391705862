import React, { useEffect } from 'react'
import { bool, string } from 'prop-types'

import useNavigateSearchParams from 'js/hooks/useNavigateSearchPrams'

const NavigateWithSearch = ({ replace, to }) => {
    const navigate = useNavigateSearchParams(replace)
    useEffect(() => {
        navigate(to)
    }, [])

    return <div />
}

NavigateWithSearch.propTypes = {
    replace: bool.isRequired,
    to: string.isRequired,
}

export default NavigateWithSearch

import { handleActions } from 'redux-actions'

import {
    finishCreatedMeeting,
    setGroupCallRoomId,
    setJoinedCall,
    setJoiningCallError,
    startCreatedMeeting,
    unfolowCalls,
} from './groupCalls.actions'

const defaultState = {
    ownerToken: '',
    groupCallRoomId: '',
    groupCallRoomUrl: '',
    startedCallDailyCoName: '',
    isCreatedMeetingStarted: false,
    isJoinError: false,
    joindedCalls: [],
}

const groupCallsReducer = handleActions(
    {
        [finishCreatedMeeting]: (state) => ({
            ...state,
            groupCallRoomId: '',
            groupCallRoomUrl: '',
            isCreatedMeetingStarted: false,
        }),
        [setGroupCallRoomId]: (state, { payload }) => ({
            ...state,
            groupCallRoomId: payload,
        }),
        [setJoiningCallError]: (state, { payload }) => ({
            ...state,
            isJoinError: payload,
        }),
        [setJoinedCall]: (state, { payload: { id, data } }) => ({
            ...state,
            joindedCalls: [
                ...state.joindedCalls.filter((call) => call.id !== id),
                {
                    id,
                    data,
                },
            ],
        }),
        [startCreatedMeeting]: (state, { payload: { url, name, token } }) => ({
            ...state,
            ownerToken: token,
            isCreatedMeetingStarted: true,
            startedCallDailyCoName: name,
            groupCallRoomUrl: url,
        }),
        [unfolowCalls]: (state, { payload }) => ({
            ...state,
            joindedCalls: state.joindedCalls.filter((call) => !payload.includes(call.id)),
        }),
    },
    defaultState,
)

export default groupCallsReducer

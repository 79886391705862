import React from 'react'

const IconActivism = () => (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2137 3.643C10.4809 4.53712 3.66821 11.9149 3.66821 20.8839C3.66821 24.0342 4.5087 26.9881 5.97767 29.5338L19.2137 20.4046V3.643ZM19.2137 24.049L7.69609 31.993C10.5166 35.3671 14.5968 37.6521 19.2137 38.1248V24.049ZM38.3318 20.8839C38.3318 24.0655 37.4745 27.047 35.9783 29.6097L22.2137 20.116V3.5939C31.2195 4.21688 38.3318 11.7198 38.3318 20.8839ZM22.2137 38.1739C27.0343 37.8404 31.3123 35.5357 34.2474 32.0603L22.2137 23.7603V38.1739ZM41.3318 20.8839C41.3318 32.1128 32.229 41.2157 21 41.2157C9.77108 41.2157 0.668213 32.1128 0.668213 20.8839C0.668213 9.65493 9.77108 0.552063 21 0.552063C32.229 0.552063 41.3318 9.65493 41.3318 20.8839Z"
            className="fill-painted"
        />
    </svg>
)

export default IconActivism

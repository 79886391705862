import { combineActions, handleActions } from 'redux-actions'

import { DEFAULT_AVATAR } from 'js/var/images'

import { initData } from '../initApp.action'
import {
    removeTempProfilePhotoSuccess,
    resetProfileData,
    setProfileData,
    uploadProfilePhotoSuccess,
    updateProfileSuccess,
    getProfileDataSuccess,
    uploadTempProfilePhotoSuccess,
    setDailyRoom,
    setStaffRole,
    updateZipCodeSuccess,
} from './profile.actions'

const defaultState = {
    about: '',
    contentfulStaffId: '',
    dailyRoom: null,
    full_name: '',
    isStaff: false,
    major: '',
    pronouns: '',
    school: '',
    profile_picture: DEFAULT_AVATAR,
    profilePictureRefPath: '',
    tempProfilePicture: DEFAULT_AVATAR,
    tempProfilePicturePath: '',
    zipCode: '',
}

const reducer = handleActions(
    {
        [combineActions(
            removeTempProfilePhotoSuccess,
            setProfileData,
            updateProfileSuccess,
            uploadProfilePhotoSuccess,
            uploadTempProfilePhotoSuccess,
        )]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [resetProfileData]: () => defaultState,
        [getProfileDataSuccess]: (state, { payload }) =>
            payload?.uid
                ? {
                      ...state,
                      otherUser: payload,
                  }
                : { ...state, ...payload },
        [setDailyRoom]: (state, { payload }) => ({ ...state, dailyRoom: payload }),
        [setStaffRole]: (state) => ({
            ...state,
            isStaff: true,
        }),
        [updateZipCodeSuccess]: (state, { payload }) => ({
            ...state,
            zipCode: payload,
        }),
        '@@reactReduxFirebase/LOGIN': (state, action) => {
            if (!state.full_name) {
                action.asyncDispatch(initData())
            }

            return state
        },
    },
    defaultState,
)

export default reducer

import React from 'react'

const IconHealth = () => (
    <svg
        width="44"
        height="43"
        viewBox="0 0 44 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask id="path-1-inside-1" fill="white">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.0329 0.457397C18.339 0.457397 15.3446 3.45186 15.3446 7.14571V14.863L7.62735 14.863C3.93349 14.863 0.939026 17.8575 0.939026 21.5513C0.939026 25.2452 3.93348 28.2396 7.62734 28.2396H15.3446V35.9569C15.3446 39.6508 18.339 42.6452 22.0329 42.6452C25.7268 42.6452 28.7212 39.6508 28.7212 35.9569V28.2396H36.4385C40.1324 28.2396 43.1269 25.2452 43.1269 21.5513C43.1269 17.8575 40.1324 14.863 36.4385 14.863L28.7212 14.863V7.14572C28.7212 3.45186 25.7268 0.457397 22.0329 0.457397Z"
            />
        </mask>
        <path
            d="M15.3446 14.863V17.863H18.3446V14.863H15.3446ZM7.62735 14.863V17.863H7.62735L7.62735 14.863ZM15.3446 28.2396H18.3446V25.2396H15.3446V28.2396ZM28.7212 28.2396V25.2396H25.7212V28.2396H28.7212ZM36.4385 14.863V11.863V14.863ZM28.7212 14.863H25.7212V17.863L28.7212 17.863V14.863ZM18.3446 7.14571C18.3446 5.10871 19.9959 3.4574 22.0329 3.4574V-2.5426C16.6822 -2.5426 12.3446 1.795 12.3446 7.14571H18.3446ZM18.3446 14.863V7.14571H12.3446V14.863H18.3446ZM7.62735 17.863L15.3446 17.863V11.863L7.62735 11.863L7.62735 17.863ZM3.93903 21.5513C3.93903 19.5143 5.59034 17.863 7.62735 17.863V11.863C2.27663 11.863 -2.06097 16.2006 -2.06097 21.5513H3.93903ZM7.62734 25.2396C5.59034 25.2396 3.93903 23.5883 3.93903 21.5513H-2.06097C-2.06097 26.902 2.27663 31.2396 7.62734 31.2396V25.2396ZM15.3446 25.2396H7.62734V31.2396H15.3446V25.2396ZM18.3446 35.9569V28.2396H12.3446V35.9569H18.3446ZM22.0329 39.6452C19.9959 39.6452 18.3446 37.9939 18.3446 35.9569H12.3446C12.3446 41.3076 16.6822 45.6452 22.0329 45.6452V39.6452ZM25.7212 35.9569C25.7212 37.9939 24.0699 39.6452 22.0329 39.6452V45.6452C27.3836 45.6452 31.7212 41.3076 31.7212 35.9569H25.7212ZM25.7212 28.2396V35.9569H31.7212V28.2396H25.7212ZM36.4385 25.2396H28.7212V31.2396H36.4385V25.2396ZM40.1269 21.5513C40.1269 23.5883 38.4755 25.2396 36.4385 25.2396V31.2396C41.7893 31.2396 46.1269 26.902 46.1269 21.5513H40.1269ZM36.4385 17.863C38.4756 17.863 40.1269 19.5143 40.1269 21.5513H46.1269C46.1269 16.2006 41.7893 11.863 36.4385 11.863V17.863ZM28.7212 17.863H36.4385V11.863L28.7212 11.863V17.863ZM25.7212 7.14572V14.863H31.7212V7.14572H25.7212ZM22.0329 3.4574C24.0699 3.4574 25.7212 5.10871 25.7212 7.14572H31.7212C31.7212 1.79501 27.3836 -2.5426 22.0329 -2.5426V3.4574Z"
            className="fill-painted"
            mask="url(#path-1-inside-1)"
        />
    </svg>
)

export default IconHealth

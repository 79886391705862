import { createAction } from 'redux-actions'

import { signOnScheduledMeetingsFirebase } from 'js/APIs/firebase/explore.firebase'

import { SET_EXPLORE_MEETINGS } from './explore.types'

export const setExploreMeetings = createAction(SET_EXPLORE_MEETINGS)

const processExploreMeetings = (meetingsData) => (dispatch) => {
    if (!meetingsData) {
        return dispatch(setExploreMeetings([]))
    }
    const meetingsIds = Object.keys(meetingsData)

    dispatch(setExploreMeetings(meetingsIds.map((id) => ({ ...meetingsData[id], id }))))
}

export const initExploreMeetings = () => (dispatch) => {
    signOnScheduledMeetingsFirebase((value) => dispatch(processExploreMeetings(value)))
}

export default { initExploreMeetings }

import React from 'react'
import { func, object, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Search as SearchIcon } from '@mui/icons-material'

import Input from '../Input'

const useStyles = makeStyles(({ palette }) => ({
    Search: {
        height: '40px',
        position: 'relative',
        width: '100%',

        '& svg': {
            position: 'absolute',
            right: '12px',
            top: '8px',

            '& path': {
                fill: palette.fuchsiaBlue,
            },
        },
    },
}))

const Search = ({ placeholder, refine, style, value }) => {
    const classes = useStyles()

    const onChange = ({ target: { value } }) => refine(value)

    return (
        <div className={classes.Search} style={style}>
            <SearchIcon />
            <Input
                onChange={onChange}
                placeholder={placeholder}
                style={{ paddingRight: '43px', width: '100%' }}
                value={value}
            />
        </div>
    )
}

Search.propTypes = {
    placeholder: string,
    refine: func.isRequired,
    style: object,
    value: string,
}

Search.defaultProps = {
    placeholder: '',
    style: {},
    value: undefined,
}

export default Search

import React from 'react'

const IconHintMentor = () => (
    <svg
        width="37"
        height="38"
        viewBox="0 0 37 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.142426 18.7347C0.142426 8.6147 8.33743 0.401367 18.4574 0.401367C28.5958 0.401367 36.8091 8.6147 36.8091 18.7347C36.8091 28.8547 28.5958 37.068 18.4574 37.068C8.33743 37.068 0.142426 28.8547 0.142426 18.7347ZM12.0591 11.4014C10.5403 11.4014 9.30909 12.6326 9.30909 14.1514C9.30909 15.6702 10.5403 16.9014 12.0591 16.9014C13.5779 16.9014 14.8091 15.6702 14.8091 14.1514C14.8091 12.6326 13.5779 11.4014 12.0591 11.4014ZM24.8924 11.4014C23.3736 11.4014 22.1424 12.6326 22.1424 14.1514C22.1424 15.6702 23.3736 16.9014 24.8924 16.9014C26.4112 16.9014 27.6424 15.6702 27.6424 14.1514C27.6424 12.6326 26.4112 11.4014 24.8924 11.4014ZM18.4758 33.4014C10.3724 33.4014 3.80909 26.838 3.80909 18.7347C3.80909 10.6314 10.3724 4.06803 18.4758 4.06803C26.5791 4.06803 33.1424 10.6314 33.1424 18.7347C33.1424 26.838 26.5791 33.4014 18.4758 33.4014ZM24.6724 22.6397C25.0574 21.998 25.9191 21.7964 26.5607 22.1997C27.2024 22.603 27.4041 23.4464 27.0007 24.088C25.1307 27.0397 21.9591 28.818 18.4757 28.818C14.9924 28.818 11.8207 27.058 9.95074 24.0697C9.5474 23.428 9.74907 22.5847 10.3907 22.1814C11.0324 21.778 11.8757 21.9797 12.2791 22.6214C13.6357 24.7847 15.9457 26.068 18.4757 26.068C21.0057 26.068 23.3157 24.7847 24.6724 22.6397Z"
            fill="white"
        />
    </svg>
)

export default IconHintMentor

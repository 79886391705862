import * as Yup from 'yup'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import pbkdf2 from 'pbkdf2'
import { setIsLoading } from 'js/redux/navigation/navigation.actions'
import { CONTACT_US_NUMBER_FORMATTED } from 'js/var/const'

export const addSalt = (value, salt) => `${CryptoJS.HmacSHA1(`${value}`, salt)}`

export const addIdToPath = (path, id, params) => {
    let result = `${path}/${id}`

    if (params) {
        result += `?`
        Object.keys(params).forEach((key, i) => {
            result += `${key}=${params[key]}`

            if (params.length - 1 > i) {
                result += '&'
            }
        })
    }

    return result
}

export const createSalt = (length) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const hashPassword = (password, salt) =>
    pbkdf2.pbkdf2Sync(password, salt, 12000, 32, 'sha256').toString('base64')

export const createIsRequiredSchema = (errorMessage) => Yup.string().required(errorMessage)

export const createFileName = (uid, fileName) => uid + (fileName ? fileName.slice(-4) : 'default')

export const addLoading = (func, dispatch) => async (data) => {
    dispatch(setIsLoading(true))
    await func(data)
    dispatch(setIsLoading(false))
}

export const addZeroToTime = (t) => (t < 10 ? `0${t}` : `${t}`)

export const createTimeScaleMinutes = () => {
    const scale = []

    for (let i = 0; i < 60; i += 1) {
        scale.push(addZeroToTime(i))
    }

    return scale
}

export const createTimeScaleHours = () => {
    const scale = []

    for (let i = 1; i <= 12; i += 1) {
        scale.push(addZeroToTime(i))
    }

    return scale
}

export const prepareStringToSearch = (string) =>
    string
        .toLocaleLowerCase()
        .replace('(', '\\(')
        .replace(')', '\\)')
        .replace('{', '\\{')
        .replace('}', '\\}')
        .replace('+', '\\+')
        .replace('*', '\\*')
        .replace('$', '\\$')
        .replace('^', '\\^')
        .replace('?', '\\?')
        .replace(' ', '\\ ')

export const sumNames = (users) => {
    if (!Array.isArray(users)) {
        return null
    }

    return users.reduce(
        (tempStr, { full_name }, i, arr) => (i !== arr.length - 1 ? `${tempStr + full_name}, ` : tempStr + full_name),
        '',
    )
}
export const getNextHourDate = () => {
    const m = moment()
    const roundUp = m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour')

    return roundUp
}

export const createBlockedTooltip = (feature) =>
    `This clinician is not currently available for ${feature}. Please call the Mindfully front office.`

export const createPermanentMessage = (name) =>
    `${name} is not utilizing messaging. To connect with this clinician please call the front office at ${CONTACT_US_NUMBER_FORMATTED} to schedule a first appointment. For emergencies call 911.`

export const createTemporaryMessage = (name) =>
    `${name} is away from their messenger and will return on (INPUT DATE), please contact the front office at ${CONTACT_US_NUMBER_FORMATTED} or for emergencies call 911.`

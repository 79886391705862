import React from 'react'
import { string } from 'prop-types'

const IconHorisontalDots = ({ className }) => (
    <svg className={className} viewBox="0 128 512 384">
        <path d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" />
    </svg>
)

IconHorisontalDots.propTypes = {
    className: string,
}

IconHorisontalDots.defaultProps = {
    className: '',
}

export default IconHorisontalDots

import { handleActions } from 'redux-actions'

import { togglePHQStart, toggleWelcomePopup } from './popup.actions'

const defaultState = {
    isPHQStartOpen: false,
    isWelcomePopupOpen: false,
}

const reducer = handleActions(
    {
        [togglePHQStart]: (state) => ({
            ...state,
            isPHQStartOpen: !state.isPHQStartOpen,
        }),
        [toggleWelcomePopup]: (state) => ({
            ...state,
            isWelcomePopupOpen: !state.isWelcomePopupOpen,
        }),
    },
    defaultState,
)

export default reducer

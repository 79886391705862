import { handleActions } from 'redux-actions'

import { EMPTY_GOAL_STEP } from 'js/var/const'

import moment from 'moment'
import {
    deleteGoalStep,
    resetAssessmentData,
    setAssessmentData,
    setGoalStep,
    setWasRedirectedToMentors,
    toggleIsGoalVariantsOpen,
    toggleIsTipsOpen,
} from './assessment.actions'

const defaultState = {
    hasSteps: false,
    confidenceRating: 2,
    goalCategory: '',
    deadlineDate: moment().add(7, 'days').toDate(),
    initDate: null,
    isGoalVariantsOpen: false,
    isTipsOpen: false,
    importanceRating: 2,
    steps: new Array(3).fill().map(() => ({ ...EMPTY_GOAL_STEP })),
    title: '',
    wasRedirectedToMentors: false,
}

const reducer = handleActions(
    {
        [deleteGoalStep]: (state, action) => {
            const newSteps = [...state.steps]

            newSteps.splice(action.payload, 1)

            return {
                ...state,
                steps: newSteps,
            }
        },
        [resetAssessmentData]: () => ({
            ...defaultState,
            steps: new Array(3).fill().map(() => ({ ...EMPTY_GOAL_STEP })),
        }),
        [setAssessmentData]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [setGoalStep]: (state, { payload: { index, text } }) => {
            const newSteps = [...state.steps]

            newSteps[index].text = text

            return {
                ...state,
                steps: newSteps,
            }
        },
        [setWasRedirectedToMentors]: (state, action) => ({
            ...state,
            wasRedirectedToMentors: action.payload,
        }),
        [toggleIsGoalVariantsOpen]: (state) => ({
            ...state,
            isGoalVariantsOpen: !state.isGoalVariantsOpen,
        }),
        [toggleIsTipsOpen]: (state) => ({
            ...state,
            isTipsOpen: !state.isTipsOpen,
        }),
    },
    defaultState,
)

export default reducer

import classNames from 'classnames'
import React from 'react'
import { CheckRounded } from '@mui/icons-material'
import { bool, func, number, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ fonts, palette }) => ({
    icon: {
        height: '24px',
        width: '24px',

        '& svg > path': {
            fill: palette.midBlue,
        },

        '&.isDesktop': {
            height: '32px',
            width: '32px',

            '& svg': {
                height: '32px',
                width: '32px',
            },
        },
    },
    radio: {
        ...fonts.b2.mob,
        alignItems: 'center',
        backgroundColor: palette.softBlue,
        borderRadius: '24px',
        color: palette.black,
        display: 'flex',
        height: '32px',
        justifyContent: 'space-between',
        padding: '7px 16px',
        marginBottom: '13px',
        position: 'relative',
        width: '100%',

        '&.isChecked': {
            color: palette.midBlue,
        },

        '&.isDesktop': {
            ...fonts.b1.dskt,
            height: '48px',
        },
    },
}))

const AssessmentAnswer = ({ currentAnswer, handleClick, isDesktop, text, value }) => {
    const classes = useStyles()

    const isChecked = value === currentAnswer

    return (
        <div className={classNames(classes.radio, { isChecked, isDesktop })} onClick={() => handleClick(value)}>
            {text}
            <span> {isChecked && <CheckRounded className={classNames(classes.icon, { isDesktop })} />}</span>
        </div>
    )
}

AssessmentAnswer.propTypes = {
    currentAnswer: number.isRequired,
    handleClick: func.isRequired,
    isDesktop: bool.isRequired,
    text: string.isRequired,
    value: number.isRequired,
}

export default AssessmentAnswer

import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { bool, func, string } from 'prop-types'

import Check from 'js/components/Check'

const useStyles = makeStyles(({ palette }) => ({
    image: {
        backgroundColor: palette.dustBlue,
        height: '32px',
        marginRight: '8px',
        width: '32px',

        '& svg': {
            height: '40px',
            width: '40px',

            '& path': {
                fill: palette.disabledGrey,
            },
        },
    },
    text: {
        display: 'flex',
        flex: 1,
        marginRight: '16px',
    },
    ProfileCheck: {
        alignItems: 'center',
        borderBottom: `1px solid ${palette.whiteSmoke}`,
        display: 'flex',
        padding: '16px 20px',
    },
}))

const ProfileCheck = ({ isChecked, full_name, onChange, profile_picture }) => {
    const classes = useStyles()

    return (
        <div className={classes.ProfileCheck}>
            <Avatar className={classes.image} src={profile_picture} alt="Profile" />
            <p className={classes.text}>{full_name}</p>
            <Check isChecked={isChecked} onChange={onChange} />
        </div>
    )
}

ProfileCheck.propTypes = {
    isChecked: bool.isRequired,
    full_name: string.isRequired,
    onChange: func.isRequired,
    profile_picture: string.isRequired,
}

export default ProfileCheck

export const ANNOUNCE_PEER_TO_PEER_SUCCESS = 'ANNOUNCE_PEER_TO_PEER_SUCCESS'

export const CANCEL_PEER_TO_PEER_SUCCESS = 'CANCEL_PEER_TO_PEER_SUCCESS'

export const DECLINE_PEER_TO_PEER_SUCCESS = 'DECLINE_PEER_TO_PEER_SUCCESS'

export const FINISH_PEER_TO_PEER_SUCCESS = 'FINISH_PEER_TO_PEER_SUCCESS'

export const HANDLE_PEER_TO_PEER_ANNOUNCE = 'HANDLE_PEER_TO_PEER_ANNOUNCE'

export const HANDLE_PEER_TO_PEER_DECLINE = 'HANDLE_PEER_TO_PEER_DECLINE'

export const HANDLE_PEER_TO_PEER_CANCEL = 'HANDLE_PEER_TO_PEER_CANCEL'

export const HANDLE_PEER_TO_PEER_ACCEPT_SUCCESS = 'HANDLE_PEER_TO_PEER_ACCEPT_SUCCESS'

export const SET_RECONNECT_DATA = 'SET_RECONNECT_DATA'

import {
    ASSESSMENT_PATH,
    CARE_PATH,
    GOALS_HISTORY_PATH,
    COMMUNICATION_PATH,
    RATING_PATH,
    RESOURCES_PATH,
    START_PATH,
} from './paths'

import { SECOND } from './time'

export const APP_TITLE = 'Mindfully'

export const ASSESSMENT_SLIDES_LENGTH = 6

export const CONFIDENCE_SCALE = [
    'Not Very Confident',
    'Less Confident',
    'Neutral',
    'Somewhat Confident',
    'Very Confident',
]

export const CALL_TYPE = {
    groupCall: 'groupCall',
    peerToPeer: 'peerToPeer',
    scheduledMeeting: 'scheduledMeeting',
}

export const CONTACT_US_NUMBER = '+18888300347'

export const CONTACT_US_NUMBER_FORMATTED = '888-830-0347'

export const EMPTY_GOAL_STEP = { text: '', isCompleted: false, isEditable: true }

export const GOAL_CATEGORIES = {
    activism: 'activism',
    family: 'family',
    friends: 'friends',
    health: 'health',
    personal: 'personal',
    school: 'school',
    sports: 'sports',
    volunteer: 'volunteer',
    other: 'other',
}

export const HEADER_BUTTON_WIDTH = '72px'

export const HIDE_HEADER_PATHS = [
    ASSESSMENT_PATH,
    CARE_PATH,
    GOALS_HISTORY_PATH,
    COMMUNICATION_PATH,
    RATING_PATH,
    RESOURCES_PATH,
]

export const IMAGE_FORMATS = ['.jpg', '.gif', '.png']

export const IMPORTANCE_LEVELS = [
    'Not Very Important',
    'Less Important',
    'Neutral',
    'Somewhat Important',
    'Very Important',
]

export const SCHEDULE_DESCRIPTION_LIMIT = 256
export const SCHEDULE_NAME_LIMIT = 50

export const INTERESTS_LIMIT = 15

export const INTERESTS_TYPES = {
    aboutme: 'About Me',
    activism: 'Activism',
    health: 'Health',
    family: 'Family',
    personal: 'Personal',
    school: 'School',
    sports: 'Sports',
    volunteer: 'Volunteer',
}

export const PATH_AFTER_SIGN_OUT = START_PATH

export const RESOURCES_TYPES = {
    all: 'all',
    article: 'article',
    video: 'video',
}

export const CONTENTFUL_TYPES = {
    faq: 'faq',
    featuredResourceId: 'featuredResourceId',
    resource: 'resource',
    staff: 'staff',
    staffList: 'staffList',
    service: 'service',
    specialties: 'specialties',
    videoResource: 'videoResource',
}

export const RESOURCE_BOOKMARK_POPUP_MESSAGES = {
    added: 'Added to bookmarks.',
    addedError: 'Error of adding.',
    removed: 'Removed from bookmarks.',
    removedError: 'Error of removing',
}

export const SALT_LENGTH = 12

export const STATUS = {
    online: 'online',
    onCall: 'oncall',
    offline: 'offline',
    doNotDisturb: 'donotdisturb',
}

export const VIDEO_CALL_ANNOUNCE_LIMIT = 25 * SECOND

export const SUPPORTERS_TYPES = {
    all: 'all',
    supporter: 'supporter',
    counselor: 'counselor',
}

export const PEER_TO_PEER_USERS_LENGTH = 1

export const RESOURCE_HITS_MAX = 200
export const STAFF_HITS_MAX = 200

export const FEATURE_OPTIONS = {
    goals: 'goals',
    resources: 'resources',
}

export const DEFAULT_FEELING_RATING = 50

export const PEER_SUPPORTER_TITLES = {
    Supporter: true,
    'Peer Support Specialist': true,
}

export const COUNSELOR_TITLES = {
    Therapist: true,
    Counselor: true,
    'Clinical Counselor': true,
    'Nurse Practitioner': true,
    Intern: true,
    'Chief Nursing Offer, Nurse Practitioner': true,
    'Certified Nurse Practitioner': true,
    'Psychiatric Nurse Practitioner': true,
}

export const ANY_SERVICE = { name: 'I am open to anything', slug: 'any-service' }

export const MAX_STAFF_SUGGESTION_LENGTH = 6

export const CALL_TOOLTIP_TITLE =
    'This feature is not enabled at this time, please utilize messaging for communicating with this clinician.'

export const CONTACT_METHODS = {
    email: 'email',
    pushNotifications: 'push-notifications',
}

export const responseStatus = Object.freeze({
    ok: 200,
})

export const ACTIVE_COLOR = 'primPurple'

export const ICON_COLOR = 'primBlue'

export const WEBSITE_URL = 'https://mindfully.com/'

import LogRocket from 'logrocket'

import logger from 'js/utils/logger'
import { CALL_TYPE, STATUS } from 'js/var/const'
import { cleanPeerToPeerChanel, setUserStatus, signOnPeerToPeerChanel } from 'js/APIs/firebase/peerToPeer.firebase'
import {
    getEmail,
    getUid,
    getUserData,
    messaging,
    signStatusOnDisconnect,
    updateTimezone,
} from 'js/APIs/firebase/firebase'
import { getStaffProfile } from 'js/APIs/contentfulClient'
import { getRole } from 'js/APIs/firebase/profile.firebase'
import { signOnGoals } from 'js/APIs/firebase/goals.firebase'
import { signOnInterests } from 'js/APIs/firebase/interests.firebase'

import { getPeerSupporterLearning } from './peerSupporterLearning/peerSupporterLearning.actions'
import { initInterests, setUserInterests } from './interests/interests.actions'
import { initGoals } from './goals/goals.actions'
import { initLastCheckInDate, initCheckInStreak } from './checkIn/checkIn.actions'
import { initOverallAssessment } from './overallAssessment/overallAssessment.actions'
import { initPHQ2 } from './phq2/phq2.actions'
import { initPHQ9 } from './phq9/phq9.actions'
import { checkDailyRoom, setProfileData, setStaffRole } from './profile/profile.actions'
import { signOnHowYouFeelUpdate } from './howYouFeel/howYouFeel.actions'
import { updateFirebaseCloudMessagingToken } from './firebaseCloudMessaging/firebaseCloudMessaging.actions'
import { getChats } from './chats/chat.actions'
import { reconnectToGroupCall, setGroupCallRoomId, signOnCalls } from './groupCalls/groupCalls.actions'
import { handlePeerToPeerMessage, reconnectToCall } from './peerToPeer/peerToPeer.actions'
import { editScheduledMeetingSuccess, initScheduledMeetings } from './scheduledMeetings/scheduledMeetings.actions'
import { openVideoPopup } from './videoPopup/videoPopup.actions'
import { initExploreMeetings } from './explore/explore.actions'
import { initSuggestions, loadStaff, signOnConnections } from './staff/staff.actions'
import { changeFeatureOptionsAction } from './featureOptions/featureOptions.actions'
import { loadSettings } from './settings/settings.actions'
import { subsNotifications } from './notifications/notifications.actions'

const { REACT_APP_VAPID_KEY } = process.env

export const initData = () => async (dispatch) => {
    try {
        const uid = getUid()

        if (!uid) {
            return
        }

        await updateTimezone()

        if (messaging) {
            messaging.onMessage(({ data: { title, body } }) => {
                // eslint-disable-next-line no-new
                new Notification(title, { body })
            })

            Notification.requestPermission()
                .then(() => {
                    if (Notification.permission === 'granted') {
                        messaging
                            .getToken({
                                vapidKey: REACT_APP_VAPID_KEY,
                            })
                            .then((token) => dispatch(updateFirebaseCloudMessagingToken({ isUsed: true, token })))
                    }
                })
                .catch((err) => logger(`Error of FCM token getting: ${err}`))
        }
        dispatch(initInterests())
        signOnInterests((snapshot) => dispatch(setUserInterests(snapshot.val())))
        dispatch(signOnConnections())
        cleanPeerToPeerChanel()
        dispatch(getChats(uid))
        dispatch(subsNotifications(uid))
        signOnPeerToPeerChanel((message) => dispatch(handlePeerToPeerMessage(message)))
        setUserStatus({ uid, status: STATUS.online })
        signStatusOnDisconnect(STATUS.offline)
        const isStaff = await getRole()

        const {
            about,
            checkInStreak,
            contentfulStaffId,
            featureOptions,
            full_name,
            groupCallRoom,
            joinedCalls,
            lastCheckInDate,
            major,
            overallAssessment,
            phq2,
            phq9,
            profile_picture,
            profilePictureRefPath,
            pronouns,
            school,
            dailyRoom,
            videoCallReconnect,
            zipCode,
        } = await getUserData(uid)
        LogRocket.identify(uid, {
            name: full_name,
            email: getEmail(),
            uid,
        })
        if (phq2) {
            dispatch(initPHQ2(phq2))
        }
        if (phq9) {
            dispatch(initPHQ9(phq9))
        }
        if (overallAssessment) {
            dispatch(initOverallAssessment(overallAssessment))
        }
        if (groupCallRoom) {
            dispatch(setGroupCallRoomId(groupCallRoom))
        }

        dispatch(initLastCheckInDate(lastCheckInDate))
        dispatch(initCheckInStreak(checkInStreak))
        if (contentfulStaffId) {
            const staffData = await getStaffProfile(contentfulStaffId)

            dispatch(setProfileData({ ...staffData, contentfulStaffId, zipCode }))
        } else {
            dispatch(
                setProfileData({
                    about,
                    full_name,
                    profile_picture,
                    profilePictureRefPath,
                    major,
                    pronouns,
                    school,
                }),
            )
        }
        if (videoCallReconnect) {
            if (videoCallReconnect.callType === CALL_TYPE.groupCall) {
                dispatch(reconnectToGroupCall(videoCallReconnect))
            }
            if (videoCallReconnect.callType === CALL_TYPE.peerToPeer) {
                dispatch(reconnectToCall(videoCallReconnect))
            }
            if (videoCallReconnect.callType === CALL_TYPE.scheduledMeeting) {
                const { callType, roomName, uid, url, isOwner, id } = videoCallReconnect

                dispatch(openVideoPopup({ callType, id, roomName, uid, url, isOwner }))
                dispatch(editScheduledMeetingSuccess({ id, meetingData: { isStarted: false } }))
            }
        }

        if (joinedCalls) {
            dispatch(signOnCalls(Object.keys(joinedCalls)))
        }
        dispatch(initScheduledMeetings())
        dispatch(initExploreMeetings())
        if (isStaff) {
            dispatch(checkDailyRoom(dailyRoom, contentfulStaffId))
            dispatch(setStaffRole())
            dispatch(loadSettings())

            return
        }
        dispatch(loadStaff())
        dispatch(initSuggestions())
        if (featureOptions) {
            dispatch(changeFeatureOptionsAction(featureOptions))
        }
        signOnGoals(() => dispatch(initGoals(uid)))

        dispatch(getPeerSupporterLearning())
        dispatch(signOnHowYouFeelUpdate())
    } catch (e) {
        logger(`Data init error: ${e}`)
    }
}

export default { initData }

import { handleActions } from 'redux-actions'

import { initCheckInStreak, initLastCheckInDate } from './checkIn.actions'

const defaultState = {
    lastCheckInDate: -1,
    checkInStreak: -1,
}

const reducer = handleActions(
    {
        [initCheckInStreak]: (state, { payload }) => ({
            ...state,
            checkInStreak: payload,
        }),
        [initLastCheckInDate]: (state, { payload }) => ({
            ...state,
            lastCheckInDate: payload,
        }),
    },
    defaultState,
)

export default reducer

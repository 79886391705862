import { getDataFirebase, getUid, updateDataFirebase } from './firebase'

export const getSettings = (uid) => {
    const id = uid || getUid()

    return getDataFirebase({ path: `users/${id}/settings` })
}

export const updateSettings = (data) => {
    const uid = getUid()

    return updateDataFirebase({ path: `users/${uid}/settings`, data })
}

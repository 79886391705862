import { handleActions } from 'redux-actions'

import { togglePasswordUpdate } from './passwordUpdate.actions'

const defaultState = {
    isPasswordUpdatePopupOpen: false,
}

const reducer = handleActions(
    {
        [togglePasswordUpdate]: (state) => ({
            ...state,
            isPasswordUpdatePopupOpen: !state.isPasswordUpdatePopupOpen,
        }),
    },
    defaultState,
)
export default reducer

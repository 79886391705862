import React from 'react'
import { bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import img from 'media/how-you-feel.svg'
import { savePHQ2, startPHQ2 } from 'js/redux/phq2/phq2.actions'
import { selectPHQ2 } from 'js/redux/phq2/phq2.selectors'
import classNames from 'classnames'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    PHQ2Start: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingBottom: '90px',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '20px',
        width: '100%',

        '&.isDesktop': {
            alignItems: 'center',
            backgroundColor: palette.white,
            borderRadius: '30px',
            display: 'flex',
            flexDirection: 'column',
            height: '622px',
            minHeight: 0,
            padding: '64px 40px 57px 40px',
            width: '650px',
            zIndex: 20,
        },
    },
    button: {
        ...buttons.def.mob,
        marginTop: 'auto',
        maxWidth: '422px',
        width: '100%',

        '&.isDesktop': {
            ...buttons.def.dskt,
            maxWidth: '240px',
            width: '100%',
        },
    },
    exit: {
        ...fonts.b2.mob,
        color: palette.linkBlue,
        marginTop: '16px',
        textDecoration: 'underline',

        '&.isDesktop': {
            ...fonts.b2.dskt,
        },
    },
    img: {
        height: 'auto',
        width: '100%',
    },
    imgWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '320px',
        justifyContent: 'center',
        width: '320px',

        '&.isDesktop': {
            height: '220px',
            width: '220px',
        },
    },
    text: {
        ...fonts.b2.mob,
        color: palette.black,
        paddingBottom: '20px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.b2.dskt,
            paddingBottom: '40px',
        },
    },
    title: {
        ...fonts.h2.mob,
        color: palette.darkestPurple,
        paddingTop: '20px',
        paddingBottom: '12px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.h3.dskt,
            color: palette.midBlue,
            paddingTop: '17px',
        },
    },
}))

const PHQ2Start = ({ isDesktop, onStart, onExit }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const PHQ2 = useSelector(selectPHQ2)

    const handleStart = () => {
        if (onStart) {
            onStart()
        }
        if (!isDesktop) {
            dispatch(startPHQ2())
        }
    }

    const handleExit = () => {
        if (onExit) {
            onExit()
        }

        if (!isDesktop) {
            dispatch(savePHQ2({ ...PHQ2, isPHQ2Open: false }))
        }
    }

    return (
        <div className={classNames(classes.PHQ2Start, { isDesktop })}>
            <div className={classNames(classes.imgWrapper, { isDesktop })}>
                <img className={classes.img} src={img} alt="phq2-start" />
            </div>
            <p className={classNames(classes.title, { isDesktop })}>How have you been feeling lately?</p>
            <p className={classNames(classes.text, { isDesktop })}>
                Mindfully is a safe space where we can be honest about where we are in life. In order for us to give you
                the best experience, we want to learn more about how you have been feeling lately. Let’s get to know you
                better by answering a couple of quick questions.
            </p>
            <button className={classNames(classes.button, { isDesktop })} onClick={handleStart} type="button">
                Start
            </button>
            <div className={classNames(classes.exit, { isDesktop })} onClick={handleExit}>
                I’ll do this later
            </div>
        </div>
    )
}

PHQ2Start.propTypes = {
    isDesktop: bool,
    onExit: func,
    onStart: func,
}

PHQ2Start.defaultProps = {
    isDesktop: false,
    onExit: null,
    onStart: null,
}

export default PHQ2Start

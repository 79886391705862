import { combineActions, handleActions } from 'redux-actions'
import {
    announcePeerToPeerSuccess,
    cancelPeerToPeerSuccess,
    declinePeerToPeerSuccess,
    finishPeerToPeerSuccess,
    handlePeerToPeerAcceptSuccess,
    handlePeerToPeerAnnounce,
    handlePeerToPeerCancel,
    handlePeerToPeerDecline,
    setReconnectData,
} from './peerToPeer.actions'

const defaultState = {
    isDeclinePopupOpen: false,
    isIncomingCallPopupOpen: false,
    isOutgoingPopupOpen: false,
    roomName: '',
    remote: {},
    vid: '',
}

const peerToPeerReducers = handleActions(
    {
        [cancelPeerToPeerSuccess]: (state, { payload: { isConnectionTimeExpired } }) =>
            isConnectionTimeExpired
                ? {
                      ...state,
                      isDeclinePopupOpen: true,
                      isOutgoingPopupOpen: false,
                  }
                : defaultState,

        [combineActions(announcePeerToPeerSuccess, handlePeerToPeerAnnounce, setReconnectData)]: (
            state,
            { payload },
        ) => ({
            ...state,
            ...payload,
        }),
        [combineActions(declinePeerToPeerSuccess, finishPeerToPeerSuccess, handlePeerToPeerCancel)]: ({
            remote,
            vid,
        }) => ({
            ...defaultState,
            remote,
            vid,
        }),
        [handlePeerToPeerAcceptSuccess]: (state, { payload }) => ({
            ...state,
            ...payload,
            isOutgoingPopupOpen: false,
        }),
        [handlePeerToPeerDecline]: (state) => ({
            ...state,
            isDeclinePopupOpen: true,
            isOutgoingPopupOpen: false,
        }),
    },
    defaultState,
)

export default peerToPeerReducers

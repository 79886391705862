import { isToday } from 'js/utils/dateUtils'
import { createSelector } from 'reselect'

export const selectScheduledMeetingsData = ({ scheduledMeetings }) => scheduledMeetings

export const selectScheduledMeetings = createSelector(
    selectScheduledMeetingsData,
    ({ scheduledMeetings }) => scheduledMeetings,
)

export const selectTodaysScheduleMeetings = createSelector(selectScheduledMeetings, (meetings) =>
    meetings.filter(({ startAtUtc }) => isToday(+startAtUtc)),
)

export const createSelectScheduleMeetingById = (meetingId) =>
    createSelector(selectScheduledMeetings, (meetings) => meetings.find(({ id }) => id === meetingId))

export const selectIncomingMeetingId = createSelector(
    selectScheduledMeetingsData,
    ({ incomingMeetingId }) => incomingMeetingId,
)

export const selectIsIncomingMeeting = createSelector(
    selectScheduledMeetingsData,
    ({ isIncomingMeeting }) => isIncomingMeeting,
)

export const selectScheduleMeetingsIds = createSelector(selectScheduledMeetings, (meetings) =>
    meetings.map(({ id }) => id),
)

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { array, func, object } from 'prop-types'
import { connectSearchBox } from 'react-instantsearch-dom'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Box from 'js/components/Box'
import Check from 'js/components/Check'
import Search from 'js/components/Search'
import useScreenSize from 'js/hooks/useScreenSize'
import withInstanceSearch from 'js/HOCs/withInstanceSearch'
import { searchClient } from 'js/APIs/algoliaClient'
import { selectConnections } from 'js/redux/staff/staff.selectors'

import ProfileCheck from '../../../components/ProfileCheck'

const useStyles = makeStyles(({ fonts, palette }) => ({
    list: {
        border: `1px solid ${palette.whiteSmoke}`,
        borderRadius: '8px',
        marginTop: '8px',
        overflow: 'hidden',

        '&:last-child': {
            borderBottom: 'none',
        },
    },
    section: {
        backgroundColor: palette.white,
        marginBottom: '8px',

        '&.isTablet, &.isDesktop': {
            marginBottom: 0,
            padding: '16px 0',
        },
    },
    sectionTitle: {
        ...fonts.s20l26,
        marginBottom: '4px',
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
    },
}))

const AlgoliaSearch = connectSearchBox(Search)

const CallSettings = ({ callSettings, hits, setCallSettings }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()
    const contacts = useSelector(selectConnections)
    const [users, setUsers] = useState([])

    const toggleIsAllClientsCall = () =>
        setCallSettings({ ...callSettings, isAllClientsCall: !callSettings?.isAllClientsCall })

    useEffect(() => {
        setUsers(hits.filter(({ uid }) => contacts.includes(uid)))
    }, [hits, contacts])

    const createOnChange = (uid) => () => {
        const isChecked = callSettings.users.includes(uid)
        setCallSettings(
            isChecked
                ? { ...callSettings, users: callSettings.users.filter((id) => id !== uid) }
                : { ...callSettings, users: [...callSettings.users, uid] },
        )
    }

    return (
        <Box className={classNames(classes.section, screenSize)}>
            <p className={classes.sectionTitle}>Call Permissions</p>
            <p className={classes.text}>Which clients will have permission to contact you using the “call” feature?</p>
            <Check
                isChecked={callSettings?.isAllClientsCall}
                onChange={toggleIsAllClientsCall}
                text="All of my clients may call me"
            />
            {!callSettings?.isAllClientsCall && (
                <>
                    <AlgoliaSearch style={{ marginTop: '8px' }} />
                    <div className={classes.list}>
                        {users.map(({ full_name, profile_picture, uid }) => (
                            <ProfileCheck
                                full_name={full_name}
                                key={uid}
                                isChecked={callSettings.users.includes(uid)}
                                onChange={createOnChange(uid)}
                                profile_picture={profile_picture}
                                uid={uid}
                            />
                        ))}
                    </div>
                </>
            )}
        </Box>
    )
}

CallSettings.propTypes = {
    callSettings: object.isRequired,
    hits: array.isRequired,
    setCallSettings: func.isRequired,
}

export default withInstanceSearch(CallSettings, searchClient, 'users')

import React from 'react'

const IconCalendar = () => (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4629 1.27021V2.24019H5.46289V1.27021C5.46289 0.736722 5.01289 0.300232 4.46289 0.300232C3.91289 0.300232 3.46289 0.736722 3.46289 1.27021V2.24019H2.46289C1.35289 2.24019 0.472891 3.11317 0.472891 4.18014L0.462891 17.7598C0.462891 18.8268 1.35289 19.6998 2.46289 19.6998H16.4629C17.5629 19.6998 18.4629 18.8268 18.4629 17.7598V4.18014C18.4629 3.11317 17.5629 2.24019 16.4629 2.24019H15.4629V1.27021C15.4629 0.736722 15.0129 0.300232 14.4629 0.300232C13.9129 0.300232 13.4629 0.736722 13.4629 1.27021ZM13.4629 10.97H10.4629C9.91289 10.97 9.46289 11.4065 9.46289 11.94V14.8499C9.46289 15.3834 9.91289 15.8199 10.4629 15.8199H13.4629C14.0129 15.8199 14.4629 15.3834 14.4629 14.8499V11.94C14.4629 11.4065 14.0129 10.97 13.4629 10.97ZM3.46289 17.7598H15.4629C16.0129 17.7598 16.4629 17.3233 16.4629 16.7899V7.09008H2.46289V16.7899C2.46289 17.3233 2.91289 17.7598 3.46289 17.7598Z"
            fill="black"
        />
    </svg>
)

export default IconCalendar

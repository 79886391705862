import firebase, { getUid } from './firebase'

export const cleanPeerToPeerChanel = async () =>
    firebase
        .database()
        .ref(`users/${await getUid()}/peerToPeer`)
        .set(null)

export const signOnPeerToPeerChanel = async (callback) =>
    firebase
        .database()
        .ref(`users/${await getUid()}/peerToPeer`)
        .on('child_added', (snapshot) => callback(snapshot.val()))

export const sendToPeerToPeer = async ({ uid, data }) =>
    firebase
        .database()
        .ref(`users/${uid || (await getUid())}/peerToPeer`)
        .push(data)

export const setUserStatus = async ({ uid, status }) =>
    firebase
        .database()
        .ref(`users/${uid || (await getUid())}`)
        .update({ status })

export const setVideoCallReconnect = (data) => firebase.database().ref(`users/${getUid()}/videoCallReconnect`).set(data)

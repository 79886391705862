import { makeStyles } from '@material-ui/core'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material'
import classNames from 'classnames'
import { bool, func, object, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles(({ fonts, palette }) => ({
    Check: {
        ...fonts.s16l208,
        display: 'flex',
        color: palette.black,

        '& svg': {
            height: '24px',
            marginRight: '8px',
            width: '24px',

            '& path': {
                fill: palette.doveGrey,
            },
        },

        '&.isChecked': {
            fontWeight: 700,

            '& > svg > path': {
                fill: palette.fuchsiaBlue,
            },
        },

        '&.isDisabled': {
            opacity: 0.5,
        },
    },
}))

const Check = ({ className, isDisabled, isChecked, onChange, style, text }) => {
    const classes = useStyles()

    const Icon = isChecked ? CheckBoxRounded : CheckBoxOutlineBlankRounded

    const handleChange = (value) => !isDisabled && onChange(value)

    return (
        <div className={classNames(classes.Check, { isChecked, isDisabled, [className]: className })} style={style}>
            <Icon onClick={handleChange} />
            <p>{text}</p>
        </div>
    )
}

Check.propTypes = {
    className: string,
    isChecked: bool,
    isDisabled: bool,
    onChange: func,
    style: object,
    text: string,
}

Check.defaultProps = {
    className: '',
    isChecked: false,
    isDisabled: false,
    onChange: null,
    style: null,
    text: '',
}

export default Check

import { handleActions } from 'redux-actions'
import { setExploreMeetings } from './explore.actions'

const defaultState = {
    meetings: [],
}

const exploreReducers = handleActions(
    {
        [setExploreMeetings]: (state, { payload }) => ({ ...state, meetings: payload }),
    },
    defaultState,
)

export default exploreReducers

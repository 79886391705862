import { handleActions } from 'redux-actions'
import { closeVideoPopup, openVideoPopup } from './videoPopup.actions'

const defaultState = {
    isOwner: false,
    isOpen: false,
    url: '',
    callType: '',
}

const videoCallReducers = handleActions(
    {
        [openVideoPopup]: (state, { payload }) => ({
            ...state,
            ...payload,
            isOpen: true,
        }),
        [closeVideoPopup]: () => defaultState,
    },
    defaultState,
)

export default videoCallReducers

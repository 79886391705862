import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { CALL_TYPE } from 'js/var/const'
import { getUserData } from 'js/APIs/firebase/firebase'
import { getStaffProfile } from 'js/APIs/contentfulClient'
import { getRoom, getRoomData } from 'js/APIs/axiosDailyCo'
import {
    removeTempPhoto,
    setDailyRoomFirebase,
    updateUserData,
    updateZipCodeFirebase,
    uploadPhoto,
    uploadTempPhoto,
} from 'js/APIs/firebase/profile.firebase'
import { updateStaffWaitingRoomLinkAxios } from 'js/APIs/axiosApi'

import {
    GET_PROFILE_DATA_START,
    GET_PROFILE_DATA_ERROR,
    GET_PROFILE_DATA_SUCCESS,
    SET_PROFILE_DATA,
    REMOVE_TEMP_PROFILE_PHOTO_ERROR,
    REMOVE_TEMP_PROFILE_PHOTO_START,
    REMOVE_TEMP_PROFILE_PHOTO_SUCCESS,
    RESET_PROFILE_DATA,
    UPDATE_PROFILE_START,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE_SUCCESS,
    UPLOAD_PROFILE_PHOTO_START,
    UPLOAD_PROFILE_PHOTO_ERROR,
    UPLOAD_PROFILE_PHOTO_SUCCESS,
    UPLOAD_TEMP_PROFILE_PHOTO_ERROR,
    UPLOAD_TEMP_PROFILE_PHOTO_START,
    UPLOAD_TEMP_PROFILE_PHOTO_SUCCESS,
    SET_DAILY_ROOM,
    SET_STAFF_ROLE,
    UPDATE_ZIP_CODE,
} from './profile.types'

export const setProfileData = createAction(SET_PROFILE_DATA)
export const resetProfileData = createAction(RESET_PROFILE_DATA)

const getProfileDataStart = createAction(GET_PROFILE_DATA_START)
const getProfileDataError = createAction(GET_PROFILE_DATA_ERROR)
export const getProfileDataSuccess = createAction(GET_PROFILE_DATA_SUCCESS)

export const loadUserData = async (uid) => {
    const profileData = await getUserData(uid)
    const { contentfulStaffId, status } = profileData

    if (contentfulStaffId) {
        const contentfulProfileData = await getStaffProfile(contentfulStaffId)

        return { ...contentfulProfileData, uid, contentfulStaffId, settings: profileData?.settings, status }
    }

    return { ...profileData, uid }
}

export const getProfileData = (uid) => async (dispatch) => {
    dispatch(getProfileDataStart())

    try {
        dispatch(getProfileDataSuccess(await loadUserData(uid)))
    } catch (e) {
        logger('ger profile data error => ', e)
        dispatch(getProfileDataError(e))
    }
}

const updateProfileStart = createAction(UPDATE_PROFILE_START)
const updateProfileError = createAction(UPDATE_PROFILE_ERROR)
export const updateProfileSuccess = createAction(UPDATE_PROFILE_SUCCESS)

export const updateProfile = (data) => async (dispatch) => {
    dispatch(updateProfileStart())
    try {
        const res = await updateUserData(data)
        dispatch(updateProfileSuccess(res))
        dispatch(getProfileData())
    } catch (e) {
        logger('update profile error', e)
        dispatch(updateProfileError(e))
    }
}

const uploadProfilePhotoStart = createAction(UPLOAD_PROFILE_PHOTO_START)
const uploadProfilePhotoError = createAction(UPLOAD_PROFILE_PHOTO_ERROR)
export const uploadProfilePhotoSuccess = createAction(UPLOAD_PROFILE_PHOTO_SUCCESS)

export const uploadProfilePhoto = (data) => async (dispatch) => {
    dispatch(uploadProfilePhotoStart())
    try {
        const res = await uploadPhoto(data)
        dispatch(updateProfile(res))
        dispatch(uploadProfilePhotoSuccess(res))
    } catch (e) {
        logger('upload profile photo error', e)
        dispatch(uploadProfilePhotoError(e))
    }
}

const uploadTempProfilePhotoStart = createAction(UPLOAD_TEMP_PROFILE_PHOTO_START)
const uploadTempProfilePhotoError = createAction(UPLOAD_TEMP_PROFILE_PHOTO_ERROR)
export const uploadTempProfilePhotoSuccess = createAction(UPLOAD_TEMP_PROFILE_PHOTO_SUCCESS)

export const uploadTempProfilePhoto = (photo) => async (dispatch) => {
    dispatch(uploadTempProfilePhotoStart())
    try {
        const res = await uploadTempPhoto(photo)
        dispatch(uploadTempProfilePhotoSuccess(res))
    } catch (e) {
        logger('upload profile photo error', e)
        dispatch(uploadTempProfilePhotoError(e))
    }
}

const removeTempProfilePhotoStart = createAction(REMOVE_TEMP_PROFILE_PHOTO_START)
const removeTempProfilePhotoError = createAction(REMOVE_TEMP_PROFILE_PHOTO_ERROR)
export const removeTempProfilePhotoSuccess = createAction(REMOVE_TEMP_PROFILE_PHOTO_SUCCESS)

export const removeTempProfilePhoto = (photoRefPath) => async (dispatch) => {
    dispatch(removeTempProfilePhotoStart())
    try {
        const res = await removeTempPhoto(photoRefPath)
        dispatch(removeTempProfilePhotoSuccess(res))
    } catch (e) {
        logger('remove profile photo error', e)
        dispatch(removeTempProfilePhotoError(e))
    }
}

export const setDailyRoom = createAction(SET_DAILY_ROOM)

export const setStaffRole = createAction(SET_STAFF_ROLE)

export const checkDailyRoom = (dailyRoom, staffId) => async (dispatch) => {
    if (dailyRoom) {
        try {
            const {
                data: { id },
            } = await getRoomData(dailyRoom)

            if (id) {
                return dispatch(setDailyRoom(dailyRoom))
            }
        } catch (error) {
            logger('Error of getting room data: ', error)
        }
    }
    try {
        const {
            data: { name, url },
        } = await getRoom(CALL_TYPE.scheduledMeeting)

        if (url && staffId) {
            await updateStaffWaitingRoomLinkAxios({ id: staffId, link: url })
        }

        dispatch(setDailyRoom(name))
        await setDailyRoomFirebase(name)
    } catch (error) {
        logger('Error of daily room creation:', error)
    }
}

export const updateZipCodeSuccess = createAction(UPDATE_ZIP_CODE)

export const updateZipCode = (zipCode) => async (dispatch) => {
    try {
        await updateZipCodeFirebase(zipCode)
        dispatch(updateZipCodeSuccess(zipCode))
    } catch (error) {
        logger(`Error of zip code update: `, error)
    }
}

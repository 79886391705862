import React from 'react'

const IconFriends = () => (
    <svg
        width="47"
        height="43"
        viewBox="0 0 47 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="19.1104"
            cy="18.8756"
            r="16.6127"
            className="stroke-painted"
            strokeWidth="3"
        />
        <path
            d="M10.0489 14.3448C10.0489 12.6767 11.4012 11.3244 13.0694 11.3244C14.7376 11.3244 16.0899 12.6767 16.0899 14.3448"
            className="stroke-painted"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M22.1309 14.3448C22.1309 12.6767 23.4832 11.3244 25.1514 11.3244C26.8196 11.3244 28.1719 12.6767 28.1719 14.3448"
            className="stroke-painted"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4135 27.4329C14.243 25.087 16.4804 23.4064 19.1104 23.4064C21.7404 23.4064 23.9779 25.087 24.8074 27.4329C26.6164 25.9686 27.8504 23.8218 28.1176 21.3843C28.1777 20.8353 27.7242 20.3859 27.1719 20.3859H11.049C10.4967 20.3859 10.0432 20.8353 10.1033 21.3843C10.3705 23.8218 11.6044 25.9686 13.4135 27.4329Z"
            className="fill-painted"
        />
        <path
            d="M19.1104 29.4474C23.7775 29.4474 27.6205 25.919 28.1176 21.3843C28.1777 20.8353 27.7242 20.3859 27.1719 20.3859H11.049C10.4967 20.3859 10.0432 20.8353 10.1033 21.3843C10.6003 25.919 14.4433 29.4474 19.1104 29.4474Z"
            className="stroke-painted"
            strokeWidth="2"
        />
        <path
            d="M40.5094 28.1183L45.0109 29.7137"
            className="stroke-painted"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            d="M34.6138 35.9695L36.9755 40.0171"
            className="stroke-painted"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
)

export default IconFriends

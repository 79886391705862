export const SET_GROUP_CALL_ROOM_ID = 'SET_GROUP_CALL_ROOM_ID'

export const SET_JOINING_ERROR = 'SET_JOINING_ERROR'

export const SET_JOINED_CALL = 'SET_JOINED_CALL'

export const START_CREATED_MEETING = 'START_CREATED_MEETING'

export const FINISH_CREATED_MEETING = 'FINISH_CREATED_MEETING'

export const UNSUBSCRIBE_MEETING = 'UNSUBSCRIBE_MEETING'

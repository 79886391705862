import { getTime } from './dateUtils'

export const compareDates = (date1 = null, date2 = null) => {
    const a = getTime(date1)
    const b = getTime(date2)

    if (a < b) {
        return -1
    }
    if (a > b) {
        return 1
    }

    return 0
}

export const compareChats = (b, a) => {
    const al = a?.messages?.length
    const bl = b?.messages?.length

    if (!al) {
        return -1
    }

    if (!bl) {
        return 1
    }

    return compareDates(a.messages[al - 1]?.data?.createdAt, b.messages[bl - 1]?.data?.createdAt)
}

export const compareGoalsCreatedAt = ({ initDate: date1 }, { initDate: date2 }) => compareDates(date1, date2)

export const compareFAQs = (a = {}, b = {}) => {
    if (a.importance < b.importance) {
        return 1
    }
    if (a.importance > b.importance) {
        return -1
    }
    if (a?.question.toLowerCase() < b?.question.toLowerCase()) {
        return -1
    }
    if (a?.question.toLowerCase() > b?.question.toLowerCase()) {
        return 1
    }
    return 0
}

export const compareChatUsers = (users1, users2) => {
    if (!Array.isArray(users1) || !Array.isArray(users2) || users1.length !== users2.length) {
        return false
    }

    // .concat() to not mutate arguments
    const arr1 = users1.concat().sort()
    const arr2 = users2.concat().sort()

    for (let i = 0; i < arr1.length; i += 1) {
        if (arr1[i] !== arr2[i]) {
            return false
        }
    }

    return true
}

export const compareByStr = (fieldName) => (a, b) => {
    const nameA = a[fieldName].toUpperCase()
    const nameB = b[fieldName].toUpperCase()
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    return 0
}
export const compareByNum = (fieldName) => (a, b) => {
    const nameA = a[fieldName]
    const nameB = b[fieldName]
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    return 0
}

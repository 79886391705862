import { string } from 'prop-types'
import React from 'react'

import palette from 'styles/palette'

const Notifications = ({ color }) => (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.2 0.300049H2.8C1.26 0.300049 0.014 1.56005 0.014 3.10005L0 28.3L5.6 22.7H25.2C26.74 22.7 28 21.44 28 19.9V3.10005C28 1.56005 26.74 0.300049 25.2 0.300049ZM9.8 12.9H7V10.1H9.8V12.9ZM15.4 12.9H12.6V10.1H15.4V12.9ZM21 12.9H18.2V10.1H21V12.9Z"
            fill={color}
        />
    </svg>
)

Notifications.propTypes = {
    color: string,
}

Notifications.defaultProps = {
    color: palette.primPurple,
}

export default Notifications

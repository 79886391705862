import firebase, { getUid, updateDataFirebase } from './firebase'

export const removeCloudTaskIdsFromMessage = ({ messageId, chatId }) =>
    updateDataFirebase({
        path: `chats/${chatId}/messages/${messageId}`,
        data: {
            cloudTaskIds: null,
        },
    })

export const removeNotification = (nid) =>
    firebase
        .database()
        .ref(`notifications/${getUid()}`)
        .update({ [nid]: null })

export const removeChatLink = ({ id, uid }) => updateDataFirebase({ path: `users/${uid}/chats`, data: { [id]: null } })

export const postMessageToChatFirebase = ({ chatId, message }) =>
    firebase.database().ref(`chats/${chatId}/messages`).push(message)

export const signOnUserChats = (cb) => firebase.database().ref(`users/${getUid()}/chats`).on('child_added', cb)

export const subscribeChat = async (chatId, messagesCb, requestConnectionsCb) => {
    if (!chatId || !messagesCb || !requestConnectionsCb) {
        return
    }
    const db = await firebase.database()

    await db
        .ref(`chats/${chatId}/messages`)
        .on('child_added', (snapshot) => messagesCb({ mid: snapshot.key, data: snapshot.val() }))

    await db.ref(`chats/${chatId}`).on('value', (snapshot) => {
        const { connectionsRequest } = snapshot.val() || {}

        if (connectionsRequest) {
            requestConnectionsCb(connectionsRequest)
        }
    })
}

export const subsToNotifications = (cb) =>
    firebase
        .database()
        .ref(`notifications/${getUid()}`)
        .on('child_added', (snapshot) => {
            cb({ nid: snapshot.key, data: snapshot.val() })
        })

import moment from 'moment'
import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { CALL_TYPE } from 'js/var/const'
import {
    createScheduledMeetingAxios,
    deleteScheduleMeetingAxios,
    editScheduleMeetingAxios,
    sendEmailAxios,
} from 'js/APIs/axiosApi'
import {
    checkIsScheduledMeetingExist,
    leaveGuestListFirebase,
    removeScheduleMeetingFromList,
    signOnScheduledMeetingFirebase,
    signOnScheduleMeetingListFirebase,
    userToJoinedList,
} from 'js/APIs/firebase/scheduledMeetings.firebase'
import { getDailyCoToken } from 'js/APIs/axiosDailyCo'
import { getUid, updateDataFirebase } from 'js/APIs/firebase/firebase'
import { setVideoCallReconnect } from 'js/APIs/firebase/peerToPeer.firebase'

import { checkChatExist } from 'js/utils/checkers'
import {
    DELETE_SCHEDULE_MEETING_SUCCESS,
    EDIT_SCHEDULED_MEETING_SUCCESS,
    LEAVE_GUEST_LIST_SUCCESS,
    SET_INCOMING_MEETING,
    SET_SCHEDULED_MEETING_ID,
    START_CREATED_SCHEDULED_MEETING,
} from './scheduledMeetings.types'
import { closeVideoPopup, openVideoPopup } from '../videoPopup/videoPopup.actions'
import { sendMessageToChat } from '../chats/chat.actions'
import { setJoiningCallError } from '../groupCalls/groupCalls.actions'
import { setCallType, setOwner, toggleFeedbackPopup } from '../callFeedback/callFeedback.actions'

export const createScheduledMeeting =
    ({ chatsWithUsersIds, creatorName, creatorProfilePicture, meetingData }) =>
    async (dispatch) => {
        try {
            const {
                data: { id },
            } = await createScheduledMeetingAxios(meetingData)

            await Promise.all(
                meetingData.guests.map((guestUid) => {
                    const chatId = checkChatExist(chatsWithUsersIds, [guestUid])

                    return dispatch(
                        sendMessageToChat({
                            msg: `<span>${creatorName} has added you as a guest  to ‘<a>\n${meetingData.name}</a>’.</span>`,
                            chatId,
                            recipients: [guestUid],
                            full_name: creatorName,
                            profile_picture: creatorProfilePicture,
                            isInvitationSending: true,
                            invitationType: CALL_TYPE.scheduledMeeting,
                            scheduledCallRoomId: id,
                        }),
                    )
                }),
            )
        } catch (error) {
            logger('Error of scheduled meeting creation: ', error)
        }
    }

export const deleteScheduleMeetingSuccess = createAction(DELETE_SCHEDULE_MEETING_SUCCESS)

export const deleteScheduleMeeting = (meetingId) => async (dispatch) => {
    try {
        await removeScheduleMeetingFromList(meetingId)
        await deleteScheduleMeetingAxios({ meetingId })
        dispatch(deleteScheduleMeetingSuccess({ id: meetingId }))
    } catch (error) {
        logger('Error of schedule meeting deleting: ', error)
    }
}

export const editScheduledMeetingSuccess = createAction(EDIT_SCHEDULED_MEETING_SUCCESS)

export const editScheduledMeeting =
    ({ chatsWithUsersIds, creatorName, creatorProfilePicture, id, meetingData, newGuests, removedGuests }) =>
    async (dispatch) => {
        try {
            await editScheduleMeetingAxios({ id, meetingData, newGuests, removedGuests })
            await Promise.all(
                newGuests.map((guestUid) => {
                    const chatId = checkChatExist(chatsWithUsersIds, [guestUid])

                    return dispatch(
                        sendMessageToChat({
                            msg: `<span>${creatorName} has added you as a guest  to ‘<a>\n${meetingData.name}</a>’.</span>`,
                            chatId,
                            recipients: [guestUid],
                            full_name: creatorName,
                            profile_picture: creatorProfilePicture,
                            isInvitationSending: true,
                            invitationType: CALL_TYPE.scheduledMeeting,
                            scheduledCallRoomId: id,
                        }),
                    )
                }),
            )
            dispatch(editScheduledMeetingSuccess({ id, meetingData }))
        } catch (error) {
            logger('Error of scheduled meeting editing: ', error)
        }
    }

export const leaveGuestListSuccess = createAction(LEAVE_GUEST_LIST_SUCCESS)

export const leaveGuestList = (meetingId) => async (dispatch) => {
    try {
        await leaveGuestListFirebase(meetingId)
        dispatch(leaveGuestListSuccess({ meetingId }))
    } catch (error) {
        logger('Error of leaving guest list: ', error)
    }
}

export const startCreatedScheduledMeeting = createAction(START_CREATED_SCHEDULED_MEETING)

export const startScheduleMeeting =
    ({ dailyRoom, id, isOwner, userName }) =>
    async (dispatch) => {
        try {
            dispatch(setOwner(getUid()))
            dispatch(setCallType(CALL_TYPE.scheduledMeeting))

            const data = { name: dailyRoom, url: process.env.REACT_APP_DAILY_URL + dailyRoom }

            await updateDataFirebase({
                data: { isStarted: true, roomName: data.name, url: data.url },
                path: `scheduledMeetings/${id}`,
            })

            const {
                data: { token },
            } = await getDailyCoToken({ isOwner, roomName: data.name, userName })
            data.url += `?t=${token}`

            await dispatch(startCreatedScheduledMeeting({ url: data.url, name: data.name }))
            await setVideoCallReconnect({
                callType: CALL_TYPE.scheduledMeeting,
                url: data.url,
                isOwner,
                id,
                roomName: data.name,
            })

            return dispatch(
                openVideoPopup({
                    callType: CALL_TYPE.scheduledMeeting,
                    id,
                    roomName: data.name,
                    url: data.url,
                    isOwner,
                }),
            )
        } catch (e) {
            logger(`Error of starting scheduled meeting: ${e}`)
        }
    }

export const joinScheduleMeetingVideo =
    ({ id, roomName, isOwner, url, userName }) =>
    async (dispatch) => {
        try {
            if (isOwner) {
                const {
                    data: { token },
                } = await getDailyCoToken({ isOwner, roomName, userName })

                url += `?t=${token}`
            }
            dispatch(setCallType(CALL_TYPE.scheduledMeeting))
            await setVideoCallReconnect({
                callType: CALL_TYPE.scheduledMeeting,
                url,
                isOwner,
                id,
                roomName,
            })
            await userToJoinedList(id)
            return dispatch(openVideoPopup({ callType: CALL_TYPE.scheduledMeeting, id, roomName, url, isOwner }))
        } catch (e) {
            logger(`Error of starting scheduled meeting: ${e}`)
        }
    }

export const finishScheduleMeeting =
    ({ isRejected, meetingId, isOwner }) =>
    async (dispatch) => {
        dispatch(closeVideoPopup())
        if (!isRejected) {
            dispatch(toggleFeedbackPopup())
        }
        await setVideoCallReconnect(null)
        if (isOwner) {
            setTimeout(async () => {
                await dispatch(deleteScheduleMeeting(meetingId))
            }, 5000)
        }
    }

export const setScheduledMeeting = createAction(SET_SCHEDULED_MEETING_ID)

export const setIncomingMeetingAction = createAction(SET_INCOMING_MEETING)

export const updateScheduledMeeting = (id, meeting) => async (dispatch) => {
    try {
        const { isStarted, name, startAtUtc } = meeting

        if (!name || !startAtUtc) {
            dispatch(deleteScheduleMeetingSuccess({ id }))
            return removeScheduleMeetingFromList(id)
        }

        if (isStarted) {
            const uid = await getUid()
            const isOwner = uid === meeting.creator

            if (!isOwner) {
                dispatch(setIncomingMeetingAction(id))
            }
        }

        return dispatch(setScheduledMeeting({ ...meeting, id }))
    } catch (e) {
        logger(`Error of created schedule meeting init:`, e)
    }
}

export const signOnScheduledMeetings = (meetings) => async (dispatch) => {
    const meetingsIds = Object.keys(meetings || {})

    if (!meetingsIds.length) {
        return
    }

    Promise.allSettled(
        meetingsIds.map((meetingId) =>
            signOnScheduledMeetingFirebase(meetingId, (meeting) =>
                dispatch(updateScheduledMeeting(meetingId, { ...meeting, guests: Object.keys(meeting?.guests || {}) })),
            ),
        ),
    )
}

export const initScheduledMeetings = () => async (dispatch) => {
    await signOnScheduleMeetingListFirebase((meetingsIds) => dispatch(signOnScheduledMeetings(meetingsIds)))
}

export const joinScheduleMeeting =
    ({ email, id, userName }) =>
    async (dispatch) => {
        try {
            const { isExist, data } = await checkIsScheduledMeetingExist(id)

            if (!isExist) {
                return dispatch(setJoiningCallError(true))
            }

            if (data?.isStarted) {
                dispatch(setCallType(CALL_TYPE.scheduledMeeting))
                await userToJoinedList(id)
                await setVideoCallReconnect({ callType: CALL_TYPE.scheduledMeeting, url: data.url })
                await dispatch(openVideoPopup({ callType: CALL_TYPE.scheduledMeeting, url: data.url }))
            }
            const uid = await getUid()

            await updateDataFirebase({ path: `scheduledMeetings/${id}/guests`, data: { [uid]: true } })
            await updateDataFirebase({ path: `users/${uid}/scheduledMeetings`, data: { [id]: true } })

            const date = moment.utc(Number(data.startAtUtc)).local()

            await sendEmailAxios({
                to: email,
                subject: `Registration confirmation for Mindfully Session  ${data.name} on ${date.format(
                    'YYYY-MM-DD HH:mm',
                )}`,
                text: `Hey ${userName}, thank you for registering for ${data.name} with Mindfully!<br/>
                You are confirmed for the following Mindfully session:<br/>
                ${data.name}<br/>
                ${date.format('YYYY-MM-DD')}<br/>
                Starting at ${date.format('HH:mm')}<br/>
                <br/>
                If you have any questions, you can email bettsy@Mindfullyhealth.com. See you soon!`,
            })
        } catch (e) {
            logger(`Error of joining to meeting: ${e}`)
        }
    }

import { createSelector } from 'reselect'

const notificationsSelector = (state) => state.notifications

export const selectAllNotifications = createSelector(notificationsSelector, ({ notifications }) => notifications)

export const isQueriedSelector = createSelector(notificationsSelector, ({ isQueried }) => isQueried)

export const selectNewNotification = createSelector(notificationsSelector,
    ({ newNotification }) => newNotification)

export const ABOUT_APP_PATH = `/about-app`
export const ASSESSMENT_PATH = '/assessment'
export const CHAT_PATH = '/chat'
export const CHECK_IN_PATH = '/check-in'
export const CARE_PATH = '/support'
export const EXPLORE_PATH = '/explore'
export const FORGOT_PASSWORD_PATH = '/forgot-password'
export const GOALS_HISTORY_PATH = '/history'
export const HOME_PATH = '/home'
export const NEW_CHAT_PATH = `/new-chat`
export const MY_GOALS_PATH = '/goals'
export const PROFILE_PATH = '/profile'
export const RATING_PATH = '/rating'
export const RESOURCES_PATH = '/resources'
export const SETTINGS_PATH = '/settings'
export const SIGN_IN_PATH = '/sign-in'
export const SIGN_UP_PATH = '/signup'
export const START_PATH = '/start'
export const VIDEO_CALL_PATH = '/video-call'

export const NEW_USER_PATH = '/new-user'
export const NEW_USER_OPTIONS_PATH = `${NEW_USER_PATH}/options`

export const HELP_PATH = '/help'
export const CONTACT_US_PATH = `${HELP_PATH}/contact-us`
export const FAQ_PATH = `${HELP_PATH}/faq`
export const REPORT_PROBLEM_PATH = `${HELP_PATH}/report-problem`

export const COMMUNICATION_PATH = '/communication'
export const INBOX_PATH = `${COMMUNICATION_PATH}/inbox`

export const SESSIONS_PATH = '/sessions'
export const JOIN_MEETING_PATH = `${SESSIONS_PATH}/join`
export const NEW_SESSION_PATH = `${SESSIONS_PATH}/new-session`
export const SCHEDULE_MEETING_PATH = `${SESSIONS_PATH}/schedule`
export const SCHEDULE_MEETING_EDIT_PATH = `${SCHEDULE_MEETING_PATH}-edit`
export const SCHEDULE_MEETING_INFO_PATH = `${SCHEDULE_MEETING_PATH}-info`

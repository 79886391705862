import React from 'react'
import { makeStyles } from '@material-ui/core'
import { any, func, string } from 'prop-types'

const useStyles = makeStyles(({ fonts: { b1, b2 }, palette: { darkerGrey, lightBlue, midBlue, primPurple } }) => ({
    Option: {
        alignItems: 'center',
        display: 'flex',
        height: '48px',

        '& .title': {
            ...b2.dskt,
            color: darkerGrey,
        },

        '&:hover': {
            backgroundColor: lightBlue,

            '& .title': {
                ...b1.dskt,
                color: midBlue,
            },
        },
    },
    iconWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '48px',
        justifyContent: 'center',
        marginLeft: '8px',
        width: '40px',

        '& svg path': {
            fill: primPurple,
        },
    },
}))

const Option = ({ icon, onClick, title }) => {
    const classes = useStyles()

    return (
        <div className={classes.Option} onClick={onClick}>
            <div className={classes.iconWrapper}>{icon}</div>
            <span className="title">{title}</span>
        </div>
    )
}

Option.propTypes = {
    icon: any.isRequired,
    onClick: func.isRequired,
    title: string.isRequired,
}

export default Option

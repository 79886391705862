import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import logger from 'js/utils/logger'
import { APP_TITLE } from 'js/var/const'
import { checkCheckIn } from 'js/redux/checkIn/checkIn.actions'
import { selectCheckInStreak, selectLastCheckInDate } from 'js/redux/checkIn/checkIn.selectors'
import { selectAllNotifications } from 'js/redux/notifications/notifications.selectors'
import { selectUid } from 'js/redux/profile/user'
import { togglePasswordUpdate } from 'js/redux/passwordUpdate/passwordUpdate.actions'

import Router from '../router'

const AppFunc = () => {
    const dispatch = useDispatch()
    const checkInStreak = useSelector(selectCheckInStreak)
    const lastCheckInDate = useSelector(selectLastCheckInDate)
    const uid = useSelector(selectUid)

    const { length } = useSelector(selectAllNotifications)

    useEffect(() => {
        const handlePasswordUpdate = async () => {
            const href = window.location.href.split('#')[0]
            if (href.match('mode=signIn')) {
                dispatch(togglePasswordUpdate())
            }
        }

        logger('v.3.54.134')
        // TODO: hide help me find some one, for staff
        handlePasswordUpdate()
    }, [dispatch])

    useEffect(() => {
        document.title = `${length ? `(${length}) ` : ''}${APP_TITLE}`
    }, [length])

    useEffect(() => {
        if (!uid) {
            return
        }

        dispatch(checkCheckIn(checkInStreak, lastCheckInDate))
    }, [checkInStreak, dispatch, uid, lastCheckInDate])

    return <Router />
}

const App = React.memo(AppFunc)

export default App

export const DELETE_GOAL = 'DELETE_GOAL'

export const GOAL_CHECK_IN_SET = 'GOAL/CHECK_IN/SET'
export const GOAL_CHECK_IN_SET_ERROR = 'GOAL/CHECK_IN/SET/ERROR'
export const GOAL_CHECK_IN_SET_SUCCESS = 'GOAL/CHECK_IN/SET/SUCCESS'

export const GOAL_COMPLETED_SET = 'GOAL/COMPLETED/SET'

export const GOAL_DEADLINE_SET_SUCCESS = 'GOAL/DEADLINE/SET/SUCCESS'

export const GOAL_PROGRESS_SET = 'GOAL/PROGRESS/SET'

export const GOAL_STEP_NOT_EDITABLE_SET = 'GOAL_STEP/NOT_EDITABLE/SET'

export const GOAL_STEP_STATUS_TOGGLE = 'GOAL_STEP/STATUS/TOGGLE'

export const GOAL_STEPS_SET_SUCCESS = 'GOAL_STEPS/SET/SUCCESS'

export const GOALS_INIT_SUCCESS = 'GOALS/INIT/SUCCESS'

export const GOALS_LOADING = 'GOALS/LOADING'
export const GOALS_LOADING_ERROR = 'GOALS/LOADING/ERROR'
export const GOALS_LOADING_SUCCESS = 'GOALS/LOADING/SUCCESS'

export const GOAL_VARIANTS_INIT_SUCCESS = 'GOAL_VARIANTS/INIT/SUCCESS'

export const SET_GOAL_CONFIDENCE = 'SET_GOAL_CONFIDENCE'

export const UPDATE_GOAL = 'UPDATE_GOAL'

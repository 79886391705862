import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { checkIsVideoCallAvailable } from 'js/utils/checkers'
import { getUid } from 'js/APIs/firebase/firebase'
import { selectIsStaff } from 'js/redux/profile/profile.selectors'

const useIsCallAvailable = (remoteUid) => {
    const isStaff = useSelector(selectIsStaff)
    const [isVideoCallAvailable, setIsVideoCallAvailable] = useState(false)

    useEffect(() => {
        if (!remoteUid) {
            return
        }
        if (isStaff) {
            return setIsVideoCallAvailable(true)
        }

        checkIsVideoCallAvailable({ myUid: getUid(), remoteUid, setIsVideoCallAvailable })
    }, [remoteUid])

    return isVideoCallAvailable
}

export default useIsCallAvailable

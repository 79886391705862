import moment from 'moment'
import palette from 'styles/palette'
import ReactSwitch from 'react-switch'
import React, { useEffect, useState } from 'react'
import { array, bool, func, string } from 'prop-types'
import { MenuItem, Select, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import Box from 'js/components/Box'
import Button from 'js/components/Reusable/Button'
import WhiteMobileDrawer from 'js/components/Reusable/WhiteMobileDrawer'
import { percentToHex } from 'js/utils/format'
import { toMMMyy } from 'js/utils/dateUtils'
import { exportChatToEmail, initExportChatDate } from 'js/redux/chats/chat.actions'
import { selectExportChatDate } from 'js/redux/chats/chat.selectors'
import useScreenSize from 'js/hooks/useScreenSize'
import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import classNames from 'classnames'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',

        '&.isTablet, &.isDesktop': {
            backgroundColor: palette.white,
            borderRadius: '16px',
        },
    },
    button: {
        ...buttons.round,
        marginTop: '16px',
        marginBottom: '16px',
    },
    hint: {
        ...fonts.s12l16,
        color: palette.doveGrey,
    },
    specify: {
        display: 'flex',
        marginTop: '16px',
        justifyContent: 'space-between',
        width: '100%',
    },
    input: {
        width: 'calc(50% - 4px)',

        '& .MuiInputBase-input': {
            ...fonts.s16l208,
            alignItems: 'center',
            color: palette.black,
            boxSizing: 'border-box',
            display: 'flex',
            height: '40px',
            padding: '8px 16px',
            background: palette.fuchsiaBlue + percentToHex(10),
            border: 'none',
            borderBottom: `1px solid ${palette.inputBorder}`,
            outline: 'none',
        },

        '& .MuiSelect-icon > path': {
            fill: palette.primPurple,
        },
    },
    inputTitle: {
        ...fonts.s16l208,
        fontWeight: 700,
        marginBottom: '4px',
    },
    switch: {
        alignItems: 'center',
        display: 'flex',
        border: `1px solid ${palette.whiteSmoke}`,
        borderRadius: '8px',
        padding: '16px',
        width: '100%',
    },
    switchBody: {
        marginLeft: '8px',
    },
    switchTitle: {
        ...fonts.s16l208,
        color: palette.black,
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
        textAlign: 'center',
    },
    title: {
        ...fonts.s20l26,
        color: palette.black,
        marginBottom: '16px',
    },
}))

const createMothsScale = (start, end) => {
    const mStart = moment(start)
    const mEnd = moment(end)
    const scale = []

    let mTemp = moment(mStart)

    scale.push(
        <MenuItem key={mTemp.valueOf()} value={mTemp.valueOf()}>
            {toMMMyy(mTemp)}
        </MenuItem>,
    )
    while (!mTemp.isSame(mEnd, 'month') && !Number.isNaN(mTemp.month()) && !Number.isNaN(mEnd.month())) {
        mTemp = mTemp.add(1, 'month')
        scale.push(
            <MenuItem key={mTemp.valueOf()} value={mTemp.valueOf()}>
                {toMMMyy(mTemp)}
            </MenuItem>,
        )
    }

    return scale
}

const ExportChat = ({ chatId, isOpen, name, messages, onClose }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isSpecify, setSpecify] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const exportChatDate = useSelector(selectExportChatDate)
    const screenSize = useScreenSize()

    const min = moment(messages[0].data.createdAt).set('date', 1).startOf('day')
    const max = moment(messages[messages.length - 1].data.createdAt)
        .set('date', 1)
        .startOf('day')

    useEffect(() => {
        setStartDate(min.valueOf())
        setEndDate(max.valueOf())

        if (!exportChatDate) {
            dispatch(initExportChatDate())
        }
    }, [])

    const changeSpecify = () => setSpecify(!isSpecify)

    const handleStartDateChange = ({ target: { value } }) => {
        setStartDate(value)

        if (value > endDate) {
            setEndDate(value)
        }
    }

    const handleEndDateChange = ({ target: { value } }) => setEndDate(value)

    const exportChat = () => {
        if (isSent) {
            return
        }

        const data = isSpecify
            ? {
                  chatId,
                  end: moment(endDate).endOf('month').valueOf(),
                  start: moment(startDate).startOf('month').valueOf(),
              }
            : { chatId }

        dispatch(exportChatToEmail(data))
        setIsSent(true)
        onClose()
    }

    const Wrapper = screenSize?.isMobile ? WhiteMobileDrawer : BlackPopup

    return (
        <Wrapper isOpen={isOpen} onClose={onClose}>
            <Box className={classNames(classes.content, screenSize)} onClick={(e) => e.stopPropagation()}>
                <p className={classes.title}> Export Chat with {name}</p>
                <p className={classes.text}>
                    This will export the conversation to your email address associated with your account.
                </p>
                <div className={classes.switch}>
                    <ReactSwitch
                        checked={isSpecify}
                        checkedIcon={false}
                        height={24}
                        offColor={palette.whiteOff}
                        activeBoxShadow={`0 0 2px 3px ${palette.fuchsiaBlue}`}
                        onChange={changeSpecify}
                        onColor={palette.fuchsiaBlue}
                        uncheckedIcon={false}
                        width={48}
                    />
                    <div className={classes.switchBody}>
                        <p className={classes.switchTitle}>Specify a date range</p>
                        <p className={classes.hint}>Leave off to export entire conversation</p>
                    </div>
                </div>
                {isSpecify && (
                    <div className={classes.specify}>
                        <div className={classes.input}>
                            <p className={classes.inputTitle}>From</p>
                            <Select value={startDate} onChange={handleStartDateChange} style={{ width: '100%' }}>
                                {createMothsScale(min, max)}
                            </Select>
                        </div>
                        <div className={classes.input}>
                            <p className={classes.inputTitle}>To</p>
                            <Select value={endDate} onChange={handleEndDateChange} style={{ width: '100%' }}>
                                {createMothsScale(startDate, max)}
                            </Select>
                        </div>
                    </div>
                )}
                <Button className={classes.button} disabled={isSent} onClick={exportChat}>
                    Export
                </Button>
                {!!exportChatDate && (
                    <p className={classes.hint}>Last exported on {moment(+exportChatDate).format('MMM D, YYYY')}</p>
                )}
            </Box>
        </Wrapper>
    )
}

ExportChat.propTypes = {
    chatId: string.isRequired,
    isOpen: bool.isRequired,
    messages: array.isRequired,
    name: string.isRequired,
    onClose: func.isRequired,
}

export default ExportChat

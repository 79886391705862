import React from 'react'
import RatingElement from '@material-ui/lab/Rating'
import { Close } from '@mui/icons-material'
import { func, number } from 'prop-types'
import { makeStyles, InputBase } from '@material-ui/core'

import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import { useDispatch, useSelector } from 'react-redux'
import { toggleFeedbackPopup } from 'js/redux/callFeedback/callFeedback.actions'
import { selectIsFeedbackOpen } from 'js/redux/callFeedback/callFeedback.selectors'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    CallFeedbackDesktop: {
        alignItems: 'center',
        backgroundColor: palette.white,
        borderRadius: '30px',
        display: 'flex',
        flexDirection: 'column',
        height: '503px',
        padding: '72px 40px 0px 40px',
        position: 'relative',
        width: '466px',
    },
    close: {
        position: 'absolute',
        right: '29px',
        top: '29px',
        zIndex: 10,

        '& svg': {
            '& path': {
                fill: palette.black,
            },
        },
    },
    input: {
        backgroundColor: palette.softBlue,
        borderRadius: '24px',
        display: 'flex',
        height: '120px',
        overflow: 'hidden',
        width: '100%',

        '&:focus-within': {
            border: `1px solid ${palette.primBlue}`,
        },
    },
    inputInput: {},
    inputRoot: {
        display: 'flex',
        flex: 1,
        padding: '16px 10px',
        width: '100%',
    },
    rating: {
        display: 'flex',
        fontSize: '38px',
        justifyContent: 'space-between',
        marginBottom: '33px',
        width: '262px',

        '& .MuiRating-iconFilled': {
            opacity: 1,
        },

        '& .MuiRating-iconEmpty': {
            '& path': {
                opacity: 0.3,
            },
        },

        '& .MuiSvgIcon-fontSizeInherit': {
            '& path': {
                fill: palette.midYellow,
            },
        },
    },
    submit: {
        ...buttons.def.dskt,
        marginTop: '48px',
        width: '240px',
    },
    text: {
        ...fonts.h4.dskt,
        color: palette.midBlue,
        marginBottom: '16px',
    },
}))

const CallFeedbackWeb = ({ onChangeFeedback, onChangeRating, onSubmitFeedback, rating }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsFeedbackOpen)

    const close = () => dispatch(toggleFeedbackPopup())

    return (
        <BlackPopup isOpen={isOpen}>
            <div className={classes.CallFeedbackDesktop}>
                <div className={classes.close} onClick={close}>
                    <Close />
                </div>
                <span className={classes.text}>How was your session?</span>
                <RatingElement
                    size="large"
                    className={classes.rating}
                    name="simple-controlled"
                    value={rating}
                    onChange={onChangeRating}
                />
                <span className={classes.text}>What was most helpful for you?</span>
                <div className={classes.input}>
                    <InputBase
                        placeholder="Feedback..."
                        multiline
                        rowsMin={4}
                        rows={4}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                            focused: classes.inputFocused,
                        }}
                        onChange={onChangeFeedback}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>
                <button className={classes.submit} disabled={!rating} onClick={onSubmitFeedback} type="button">
                    Submit
                </button>
            </div>
        </BlackPopup>
    )
}

CallFeedbackWeb.propTypes = {
    onChangeFeedback: func.isRequired,
    onChangeRating: func.isRequired,
    onSubmitFeedback: func.isRequired,
    rating: number.isRequired,
}

export default CallFeedbackWeb

import React from 'react'
import { bool, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as SelectedFalse } from 'media/selected-false.svg'
import { ReactComponent as SelectedTrue } from 'media/selected-true.svg'

const useStyles = makeStyles(({ fonts, palette }) => ({
    Radio: {
        alignItems: 'center',
        display: 'flex',
        height: 'fit-content',
        marginBottom: '16px',
    },
    text: {
        ...fonts.s16l208,
        color: palette.black,
        marginLeft: '8px',
    },
    icon: {
        height: '24px',
    },
}))

const Radio = ({ isSelected, onClick, text }) => {
    const classes = useStyles()
    const Icon = isSelected ? SelectedTrue : SelectedFalse

    return (
        <div className={classes.Radio}>
            <i className={classes.icon} onClick={onClick}>
                <Icon />
            </i>
            <p className={classes.text}>{text}</p>
        </div>
    )
}

Radio.propTypes = {
    isSelected: bool.isRequired,
    onClick: func.isRequired,
    text: string.isRequired,
}

export default Radio

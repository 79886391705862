import { string } from 'prop-types'
import React from 'react'

const ArrowLeft = ({ fill }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.5825 6.62569H3.4125L8.2925 1.74569C8.6825 1.35569 8.6825 0.715689 8.2925 0.325689C8.10567 0.138436 7.85202 0.0332031 7.5875 0.0332031C7.32298 0.0332031 7.06933 0.138436 6.8825 0.325689L0.2925 6.91569C-0.0975 7.30569 -0.0975 7.93569 0.2925 8.32569L6.8825 14.9157C7.2725 15.3057 7.9025 15.3057 8.2925 14.9157C8.6825 14.5257 8.6825 13.8957 8.2925 13.5057L3.4125 8.62569H14.5825C15.1325 8.62569 15.5825 8.17569 15.5825 7.62569C15.5825 7.07569 15.1325 6.62569 14.5825 6.62569Z"
            fill={fill}
        />
    </svg>
)

ArrowLeft.propTypes = {
    fill: string,
}

ArrowLeft.defaultProps  = {
    fill: 'black',
}

export default ArrowLeft

import React from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import { any, bool, func, string } from 'prop-types'

const useStyles = makeStyles(({ palette: { white } }) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    paper: {
        background: white,
        borderRadius: '16px 16px 0px 0px',
        height: 'fit-content',
    },
    WhiteMobileDrawer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
}))

const WhiteMobileDrawer = ({ children, height, isOpen, onClose }) => {
    const classes = useStyles()

    return (
        <Drawer
            anchor="bottom"
            classes={{ WhiteMobileDrawer: classes.backgroundNone, paper: classes.paper }}
            open={isOpen}
            onClose={onClose}
        >
            <div className={classes.WhiteMobileDrawer} style={{ height, minHeight: height }}>
                <div className={classes.content}>{children}</div>
            </div>
        </Drawer>
    )
}

WhiteMobileDrawer.propTypes = {
    children: any.isRequired,
    height: string,
    isOpen: bool,
    onClose: func,
}

WhiteMobileDrawer.defaultProps = {
    height: 'fit-content',
    isOpen: false,
    onClose: null,
}

export default WhiteMobileDrawer

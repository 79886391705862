import { handleActions } from 'redux-actions'

import { toggleHowYouFeel, updateHowYouFeel } from './howYouFeel.actions'

const defaultState = {
    isOpen: false,
    lastCheckInDate: null,
    lastCheckInRating: 50,
}

const reducer = handleActions(
    {
        [toggleHowYouFeel]: (state) => ({
            ...state,
            isOpen: !state.isOpen,
        }),
        [updateHowYouFeel]: (state, { payload }) => ({
            ...state,
            ...payload,
        }),
    },
    defaultState,
)

export default reducer

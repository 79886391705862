import React, { lazy, Suspense, useState, useEffect } from 'react'

import { Route, Routes, HashRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import BookmarkPopup from 'js/components/InfoPopup'
import CallFeedback from 'js/popups/CallFeedback'
import FeedbackSuccessPopup from 'js/components/FeedbackSuccessPopup'
import DeclinePopup from 'js/popups/DeclinePopup'
import IncomingCallPopup from 'js/popups/IncomingCallPopup'
import Loader from 'js/components/Reusable/Loader'
import PeerSupporterLearning from 'js/components/PeerSupporterLearning'
import OutgoingCallPopup from 'js/popups/OutgoingCallPopup'
import OverallAssessment from 'js/components/OverallAssessment'
import PHQ2 from 'js/components/PHQ2'
import PHQ9 from 'js/components/PHQ9'
import PHQStart from 'js/components/PHQ2StartPopup'

import Header from 'js/components/Header'
import VideoPopup from 'js/popups/VideoPopup'
import {
    ASSESSMENT_PATH,
    CHAT_PATH,
    CHECK_IN_PATH,
    CARE_PATH,
    CONTACT_US_PATH,
    SESSIONS_PATH,
    EXPLORE_PATH,
    FAQ_PATH,
    FORGOT_PASSWORD_PATH,
    GOALS_HISTORY_PATH,
    HELP_PATH,
    HOME_PATH,
    JOIN_MEETING_PATH,
    NEW_SESSION_PATH,
    NEW_CHAT_PATH,
    PROFILE_PATH,
    RESOURCES_PATH,
    SCHEDULE_MEETING_EDIT_PATH,
    SCHEDULE_MEETING_PATH,
    SIGN_IN_PATH,
    SIGN_UP_PATH,
    START_PATH,
    MY_GOALS_PATH,
    INBOX_PATH,
    SCHEDULE_MEETING_INFO_PATH,
    REPORT_PROBLEM_PATH,
    ABOUT_APP_PATH,
    NEW_USER_OPTIONS_PATH,
    SETTINGS_PATH,
} from 'js/var/paths'
import { selectIsLoading } from 'js/redux/navigation/navigation.selectors'
import ComingSoonPopup from 'js/components/Reusable/popups/ComingSoonPopup'
import IncomingScheduleMeetingPopup from 'js/popups/IncomingScheduleMeetingPopup'
import SuggestedGoalsPopup from 'js/pages/Assessment/Goal/Hints/SuggestedGoalsPopup'
import { selectIsIncomingMeeting } from 'js/redux/scheduledMeetings/scheduledMeetings.selectors'
import { selectOutgoingPopupOpen } from 'js/redux/peerToPeer/peerToPeer.selectors'
import { selectVideoPopupOpen } from 'js/redux/videoPopup/videoPopup.selectors'
import NewUserOptions from 'js/components/NewUserOptions'
import { createSelectIsOption } from 'js/redux/featureOptions/featureOptions.selectors'
import { FEATURE_OPTIONS } from 'js/var/const'
import { selectIsFeedbackOpen, selectIsFeedbackSuccess } from 'js/redux/callFeedback/callFeedback.selectors'
import { selectUid } from 'js/redux/profile/user'
import WithSendLocation from 'js/HOCs/WithSendLocation'
import { selectIsStaff } from 'js/redux/profile/profile.selectors'

import ErrorPopup from 'js/popups/ErrorPopup'
import NewChat from 'js/pages/NewChat'
import EmailInvitationPopup from 'js/popups/EmailInvtiationPopup'
import NavigateWithSearch from 'js/components/NavigateWithSearch'
import Settings from 'js/pages/Settings'

import history from './history'

// routes:

const Assessment = lazy(() =>
    import(
        /* webpackChunkName: "Assessment" */
        './js/pages/Assessment'
    ),
)

const AboutApp = lazy(() =>
    import(
        /* webpackChunkName: "About App" */
        './js/pages/AboutApp'
    ),
)

const Care = lazy(() =>
    import(
        /* webpackChunkName: "contact mentors page" */
        './js/pages/Care'
    ),
)

const Chat = lazy(() =>
    import(
        /* webpackChunkName: "chat page" */
        './js/pages/Chat'
    ),
)

const CheckIn = lazy(() =>
    import(
        /* webpackChunkName: "CheckIn" */
        './js/pages/CheckIn'
    ),
)

const Communication = lazy(() =>
    import(
        /* webpackChunkName: "Communication" */
        './js/pages/Communication'
    ),
)

const ContactUs = lazy(() =>
    import(
        /* webpackChunkName: "contact us page" */
        './js/pages/ContactUs'
    ),
)

const Events = lazy(() => import(/* webpackChunkName: "Events" */ 'js/pages/Sessions'))

const Explore = lazy(() => import(/* webpackChunkName: "Explore" */ 'js/pages/Explore'))

const FAQ = lazy(() =>
    import(
        /* webpackChunkName: "FAQ" */
        './js/pages/FAQ'
    ),
)

const FAQQuestion = lazy(() =>
    import(
        /* webpackChunkName: "FAQ[id]" */
        './js/pages/FAQQuestion'
    ),
)

const ForgotPassword = lazy(() =>
    import(
        /* webpackChunkName: "forgot-password page" */
        './js/pages/ForgotPassword'
    ),
)

const GoalHistory = lazy(() =>
    import(
        /* webpackChunkName: "Completed goals" */
        './js/pages/GoalHistory'
    ),
)

const Help = lazy(() =>
    import(
        /* webpackChunkName: "Help" */
        './js/pages/Help'
    ),
)

const HistoryGoalDetails = lazy(() =>
    import(
        /* webpackChunkName: "Completed goal" */
        './js/pages/HistoryGoalDetails'
    ),
)

const Home = lazy(() =>
    import(
        /* webpackChunkName: "Home" */
        './js/pages/Home'
    ),
)

const Profile = lazy(() =>
    import(
        /* webpackChunkName: "Profile[id]" */
        './js/pages/Profile'
    ),
)

const ReportProblem = lazy(() =>
    import(
        /* webpackChunkName: "ReportProblem" */
        './js/pages/ReportProblem'
    ),
)

const Resources = lazy(() =>
    import(
        /* webpackChunkName: "Resources" */
        'js/pages/Resources'
    ),
)

const ResourceView = lazy(() =>
    import(
        /* webpackChunkName: "Resources[id]" */
        'js/pages/ResourceView'
    ),
)

const MyGoals = lazy(() =>
    import(
        /* webpackChunkName: "MyGoals" */
        './js/pages/MyGoals'
    ),
)

const SignIn = lazy(() =>
    import(
        /* webpackChunkName: "Sign In" */
        './js/pages/SignIn'
    ),
)

const SignUp = lazy(() =>
    import(
        /* webpackChunkName: "Sign Up" */
        './js/pages/SignUp'
    ),
)

const StartPage = lazy(() =>
    import(
        /* webpackChunkName: "StartPage " */
        './js/pages/StartPage'
    ),
)

// popups:

const UpdatePassword = lazy(() =>
    import(
        /* webpackChunkName: "forgot-password page" */
        './js/popups/UpdatePassword'
    ),
)

const commonRoutes = [
    <Route key={CARE_PATH} path={CARE_PATH} element={<Care />} exact />,
    <Route key={`${CARE_PATH}/:id`} path={`${CARE_PATH}/:id`} element={<Care />} exact />,
    <Route key={`${CHAT_PATH}/:id`} path={`${CHAT_PATH}/:id`} element={<Chat />} exact />,
    <Route key={CONTACT_US_PATH} path={CONTACT_US_PATH} element={<ContactUs />} exact />,
    <Route key={SESSIONS_PATH} path={SESSIONS_PATH} element={<Events />} exact />,
    <Route key={EXPLORE_PATH} path={EXPLORE_PATH} element={<Explore />} exact />,
    <Route key={`${EXPLORE_PATH}/:id`} path={`${EXPLORE_PATH}/:id`} element={<Explore />} exact />,
    <Route key={FAQ_PATH} path={FAQ_PATH} element={<FAQ />} exact />,
    <Route key={`${FAQ_PATH}/:id`} path={`${FAQ_PATH}/:id`} element={<FAQQuestion />} exact />,
    <Route key={HELP_PATH} path={HELP_PATH} element={<Help />} exact />,
    <Route key={INBOX_PATH} path={INBOX_PATH} element={<Communication />} />,
    <Route key={`${INBOX_PATH}/:id`} path={`${INBOX_PATH}/:id`} element={<Communication />} />,
    <Route key={JOIN_MEETING_PATH} path={JOIN_MEETING_PATH} element={<Events />} exact />,
    <Route key={`${JOIN_MEETING_PATH}/:id`} path={`${JOIN_MEETING_PATH}/:id`} element={<Events />} />,
    <Route key={NEW_SESSION_PATH} path={NEW_SESSION_PATH} element={<Events />} exact />,
    <Route key={NEW_CHAT_PATH} path={NEW_CHAT_PATH} element={<NewChat />} exact />,
    <Route key={`${PROFILE_PATH}/:id`} path={`${PROFILE_PATH}/:id`} element={<Profile />} exact />,
    <Route key={REPORT_PROBLEM_PATH} path={REPORT_PROBLEM_PATH} element={<ReportProblem />} exact />,
    <Route
        key={`${SCHEDULE_MEETING_INFO_PATH}/:id`}
        path={`${SCHEDULE_MEETING_INFO_PATH}/:id`}
        element={<Events />}
        exact
    />,
    <Route
        key={`${SCHEDULE_MEETING_EDIT_PATH}/:id`}
        path={`${SCHEDULE_MEETING_EDIT_PATH}/:id`}
        element={<Events />}
        exact
    />,
]

const staffRoutes = [
    <Route key={HOME_PATH} path={HOME_PATH} element={<Home />} exact />,
    <Route key={SCHEDULE_MEETING_PATH} path={SCHEDULE_MEETING_PATH} element={<Events />} exact />,
    <Route key={SETTINGS_PATH} path={SETTINGS_PATH} element={<Settings />} exact />,
]

const privateRoutes = [
    <Route key={ABOUT_APP_PATH} path={ABOUT_APP_PATH} element={<AboutApp />} exact />,
    <Route key={NEW_USER_OPTIONS_PATH} path={NEW_USER_OPTIONS_PATH} element={<NewUserOptions />} exact />,

    <Route key={ASSESSMENT_PATH} path={ASSESSMENT_PATH} element={<Assessment />} exact />,
    <Route key={`${CHECK_IN_PATH}/:id`} path={`${CHECK_IN_PATH}/:id`} element={<CheckIn />} exact />,
    <Route key={GOALS_HISTORY_PATH} path={GOALS_HISTORY_PATH} element={<GoalHistory />} exact />,
    <Route
        key={`${GOALS_HISTORY_PATH}/:id`}
        path={`${GOALS_HISTORY_PATH}/:id`}
        element={<HistoryGoalDetails />}
        exact
    />,
    <Route key={HOME_PATH} path={HOME_PATH} element={<Home />} exact />,
    <Route key={MY_GOALS_PATH} path={MY_GOALS_PATH} element={<MyGoals />} exact />,
    <Route key={RESOURCES_PATH} path={RESOURCES_PATH} element={<Resources />} exact />,
    <Route key={`${RESOURCES_PATH}/:id`} path={`${RESOURCES_PATH}/:id`} element={<ResourceView />} exact />,
]

const publicRoutes = [
    <Route key={FORGOT_PASSWORD_PATH} path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} exact />,
    <Route key={SIGN_IN_PATH} path={SIGN_IN_PATH} element={<SignIn />} exact />,
    <Route key={SIGN_UP_PATH} path={SIGN_UP_PATH} element={<SignUp />} exact />,
    <Route key={START_PATH} path={START_PATH} element={<StartPage />} exact />,
]

const publicRedirect = <Route key="public-redirect" path="*" element={<NavigateWithSearch to={START_PATH} replace />} />
const userRedirect = <Route key="user-redirect" path="*" element={<NavigateWithSearch to={HOME_PATH} replace />} />

/**
 * @param isAuthenticated Boolean
 * @param isStaff Boolean
 */

const getRoutes = (isAuthenticated, isStaff) => {
    if (!isAuthenticated) {
        return [...publicRoutes, publicRedirect]
    }

    return isStaff ? [...commonRoutes, ...staffRoutes, userRedirect] : [...commonRoutes, ...privateRoutes, userRedirect]
}

const Router = () => {
    const isAuth = useSelector(selectUid)
    const isFeedbackOpen = useSelector(selectIsFeedbackOpen)
    const isLoading = useSelector(selectIsLoading)
    const isIncomingMeeting = useSelector(selectIsIncomingMeeting)
    const isOutgoingCall = useSelector(selectOutgoingPopupOpen)
    const isGoalsOn = useSelector(createSelectIsOption(FEATURE_OPTIONS.goals))
    const isResourcesOn = useSelector(createSelectIsOption(FEATURE_OPTIONS.resources))
    const isStaff = useSelector(selectIsStaff)
    const isVideoCall = useSelector(selectVideoPopupOpen)
    const isFeedbackSuccess = useSelector(selectIsFeedbackSuccess)

    const [routes, setRoutes] = useState([])

    const onGetRoutes = () => {
        const res = getRoutes(isAuth, isStaff)

        setRoutes(res)
    }

    useEffect(onGetRoutes, [isAuth, isStaff])

    const turnOffResources = () => {
        if (!isStaff && !isResourcesOn && routes.length) {
            setRoutes(routes.filter(({ key }) => !key.match(RESOURCES_PATH)))
        }
    }

    useEffect(turnOffResources, [isResourcesOn])

    const turnOffGoals = () => {
        if (!isStaff && !isGoalsOn && routes.length) {
            setRoutes(
                routes.filter(
                    ({ key }) =>
                        !key.match(MY_GOALS_PATH) &&
                        !key.match(ASSESSMENT_PATH) &&
                        !key.match(CHECK_IN_PATH) &&
                        !key.match(GOALS_HISTORY_PATH),
                ),
            )
        }
    }

    useEffect(turnOffGoals, [isGoalsOn])

    return (
        <div className="app-content">
            {!!routes?.length && (
                <Suspense fallback={<Loader isOpen />}>
                    <HashRouter history={history}>
                        <WithSendLocation>
                            {isAuth && <Header />}
                            <IncomingCallPopup />
                            {isOutgoingCall && <OutgoingCallPopup />}
                            <BookmarkPopup />
                            <ErrorPopup />
                            {isFeedbackOpen && <CallFeedback />}
                            {isFeedbackSuccess && <FeedbackSuccessPopup />}
                            <ComingSoonPopup />
                            <DeclinePopup />
                            <Loader isOpen={isLoading} />
                            <PeerSupporterLearning />
                            <OverallAssessment />
                            <PHQ2 />
                            <PHQ9 />
                            <PHQStart />
                            <SuggestedGoalsPopup />
                            <UpdatePassword />
                            <EmailInvitationPopup />
                            {isIncomingMeeting && !isVideoCall && (
                                <IncomingScheduleMeetingPopup isOpen={isIncomingMeeting} />
                            )}
                            {isVideoCall && <VideoPopup />}
                            <Routes>{routes}</Routes>
                        </WithSendLocation>
                    </HashRouter>
                </Suspense>
            )}
        </div>
    )
}

export default Router

import React, { useState, useRef } from 'react'
import Slider from 'react-slick'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import AssessmentEnd from 'js/components/AssessmentEnd'
import {
    selectOverallAssessmentCurrentSlide,
    selectOverallAssessment,
} from 'js/redux/overallAssessment/overallAssessment.selectors'
import {
    completeOverallAssessment,
    saveOverallAssessment,
    toggleOverallAssessment,
} from 'js/redux/overallAssessment/overallAssessment.actions'

import { useMediaQuery } from 'react-responsive'
import OverallAssessmentQuestion from '../OverallAssessmentQuestion'

const settings = {
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const useStyles = makeStyles(({ palette }) => ({
    OverallAssessmentSlider: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: palette.white,
    },
}))

const SLIDES_AMOUNT = 10

const OverallAssessmentSlider = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isDesktop = !useMediaQuery({ query: `(max-width: 644px)` })
    let slider = useRef()
    const startSlide = useSelector(selectOverallAssessmentCurrentSlide)
    const overallAssessment = useSelector(selectOverallAssessment)
    const [slidesLimit, setSlidesLimit] = useState(startSlide + 1)

    const goToNextSlide = (limit) => () => {
        if (limit > slidesLimit) {
            setSlidesLimit(limit)
        }
        slider.slickNext()
    }

    const handleEnd = () => {
        dispatch(completeOverallAssessment())
        dispatch(toggleOverallAssessment())
        dispatch(
            saveOverallAssessment({
                ...overallAssessment,
                isCompleted: true,
                currentSlide: 2,
                isOpen: false,
            }),
        )
    }

    const createBack = (index) => () => slider.slickGoTo(index)

    return (
        <div className={classes.OverallAssessmentSlider}>
            <Slider
                ref={(c) => {
                    slider = c
                }}
                {...settings}
                initialSlide={startSlide}
            >
                <OverallAssessmentQuestion
                    goToNextSlide={goToNextSlide(2)}
                    isDesktop={isDesktop}
                    question="I have a few people in my life that I feel comfortable with, help me solve problems, and value me."
                    questionTitle="Connecting with others"
                    slidesAmount={SLIDES_AMOUNT}
                    slideNumber={0}
                />
                {slidesLimit > 1 && (
                    <OverallAssessmentQuestion
                        back={createBack(0)}
                        goToNextSlide={goToNextSlide(3)}
                        isDesktop={isDesktop}
                        question="I try to be optimistic about the future, I practice gratitude, and I avoid negative thinking."
                        questionTitle="Staying positive"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={1}
                    />
                )}
                {slidesLimit > 2 && (
                    <OverallAssessmentQuestion
                        back={createBack(1)}
                        goToNextSlide={goToNextSlide(4)}
                        isDesktop={isDesktop}
                        question="I am physically active for 60 minutes each day."
                        questionTitle="Being physically active"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={2}
                    />
                )}
                {slidesLimit > 3 && (
                    <OverallAssessmentQuestion
                        back={createBack(2)}
                        goToNextSlide={goToNextSlide(5)}
                        isDesktop={isDesktop}
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={3}
                        question="I am intentional about helping others by doing things like small acts of kindness for others, or reaching out just to check on my friends, or volunteer regularly."
                        questionTitle="Helping others"
                    />
                )}
                {slidesLimit > 4 && (
                    <OverallAssessmentQuestion
                        back={createBack(3)}
                        goToNextSlide={goToNextSlide(6)}
                        isDesktop={isDesktop}
                        question="I get 8-10 hours of sleep most nights."
                        questionTitle="Getting enough sleep"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={4}
                    />
                )}
                {slidesLimit > 5 && (
                    <OverallAssessmentQuestion
                        back={createBack(4)}
                        goToNextSlide={goToNextSlide(7)}
                        isDesktop={isDesktop}
                        question="I read or watch things that make me laugh. I make time just to do something I enjoy (art, music, dance, a game,etc.)."
                        questionTitle="Doing things you love"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={5}
                    />
                )}
                {slidesLimit > 6 && (
                    <OverallAssessmentQuestion
                        back={createBack(5)}
                        goToNextSlide={goToNextSlide(8)}
                        isDesktop={isDesktop}
                        question="I know how to eat healthy, and I regularly eat a balanced diet according to MyPlate.gov that includes fresh fruits and vegetables, grains, protein and dairy that keeps my body strong. I avoid fast foods and junk food."
                        questionTitle="Eating well"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={6}
                    />
                )}
                {slidesLimit > 7 && (
                    <OverallAssessmentQuestion
                        back={createBack(6)}
                        goToNextSlide={goToNextSlide(9)}
                        isDesktop={isDesktop}
                        question="I participate in organized religion, meditation, or connect with my spiritual side in some other way."
                        questionTitle="Taking care of my spirit"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={7}
                    />
                )}
                {slidesLimit > 8 && (
                    <OverallAssessmentQuestion
                        back={createBack(7)}
                        goToNextSlide={goToNextSlide(10)}
                        isDesktop={isDesktop}
                        question="I do things like write it out, tackle my problems, shift my thinking or get support when faced with challenges in my life."
                        questionTitle="Dealing better with hard times"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={8}
                    />
                )}
                {slidesLimit > 9 && (
                    <OverallAssessmentQuestion
                        back={createBack(8)}
                        goToNextSlide={goToNextSlide(11)}
                        isDesktop={isDesktop}
                        question="I know what to do in an immediate crisis, and I know where to find professional resources who can help me."
                        questionTitle="Get professional help if needed"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={9}
                    />
                )}

                {slidesLimit > 10 && <AssessmentEnd back={createBack(9)} handleEnd={handleEnd} isDesktop={isDesktop} />}
            </Slider>
        </div>
    )
}

export default OverallAssessmentSlider

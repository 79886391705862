import classNames from 'classnames'
import React from 'react'
import { func, number } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    dot: {
        background: theme.palette.white,
        borderRadius: '100px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        display: 'block',
        margin: '0 4px',
        height: '9.91px',
        width: '9.91px',
    },
    dotActive: {
        backgroundColor: theme.palette.primPurple,
    },
}))

const SliderDot = ({ activeIndex, currentIndex, goToIndex }) => {
    const classes = useStyles()

    const handleClick = ({
        target: {
            dataset: { index },
        },
    }) => goToIndex(index)

    return (
        <span
            className={classNames(classes.dot, {
                [classes.dotActive]: activeIndex === currentIndex,
            })}
            data-index={currentIndex}
            onClick={handleClick}
        />
    )
}

SliderDot.propTypes = {
    activeIndex: number.isRequired,
    currentIndex: number.isRequired,
    goToIndex: func.isRequired,
}

export default SliderDot

export const FIREBASE_STORAGE_URL_START =
    'https://firebasestorage.googleapis.com/v0/b/moxiegirloauthin-1559407531552.appspot.com/o/'

export const DEFAULT_AVATAR = `${FIREBASE_STORAGE_URL_START}default-avatar.svg?alt=media&token=da8f16e5-3901-4e30-b68a-a1fa09e420a4`
export const GIRL_WITH_LAPTOP = `${FIREBASE_STORAGE_URL_START}history-empty.svg?alt=media&token=ed0cf136-e249-4366-be2b-879e2841d74c`

export const DECLINE = `${FIREBASE_STORAGE_URL_START}decline.svg?alt=media&token=cbf33d86-8bbb-479c-aa30-d6120fdd2e45`
export const VIDEO_CALL = `${FIREBASE_STORAGE_URL_START}accept.svg?alt=media&token=78314044-ed03-4e5d-954a-3bc0e40d5e2d`
export const CANCEL = `${FIREBASE_STORAGE_URL_START}cancel.svg?alt=media&token=dd9b4b28-045d-4652-9070-8b175c66f23e`

export const CUP_ON_BOOK = `${FIREBASE_STORAGE_URL_START}cup-on-book.svg?alt=media&token=8bd006ef-e4dd-490d-b263-040074f0763d`

import classNames from 'classnames'
import React from 'react'
import { ArrowBackRounded } from '@mui/icons-material'
import { bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core'

import image from 'media/happy-sun.svg'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    AssessmentEnd: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingBottom: '90px',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '20px',
        width: '100%',
    },
    button: {
        ...buttons.def.mob,
        marginTop: 'auto',
        maxWidth: '422px',
        width: '100%',

        '&.isDesktop': {
            ...buttons.def.dskt,
            marginTop: 0,
            width: '100%',
        },
    },
    exit: {
        ...fonts.b2.mob,
        color: palette.linkBlue,
        marginTop: '16px',
        textDecoration: 'underline',

        '&.isDesktop': {
            ...fonts.b2.dskt,
        },
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '422px',
        minHeight: '80vh',

        '&.isDesktop': {
            justifyContent: 'center',
        },
    },
    header: {
        display: 'flex',
        marginBottom: '35px',
        width: '100%',

        '&.isDesktop': {
            '& svg': {
                height: '32px',
                width: '32px',
            },
        },
    },
    img: {
        height: 'auto',
        width: '100%',
    },
    imgWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '320px',
        justifyContent: 'center',
        width: '320px',
    },
    title: {
        ...fonts.h2.mob,
        color: palette.darkestPurple,
        paddingTop: '80px',
        paddingBottom: '12px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.h3.dskt,
            color: palette.midBlue,
            paddingTop: '24px',
        },
    },
    text: {
        ...fonts.b2.mob,
        color: palette.black,
        paddingBottom: '20px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.b2.dskt,
            paddingBottom: '40px',
        },
    },
}))

const AssessmentEnd = ({ back, handleEnd, isDesktop }) => {
    const classes = useStyles()

    return (
        <div className={classNames(classes.AssessmentEnd, { isDesktop })}>
            <div className={classNames(classes.header, { isDesktop })}>
                {back && <ArrowBackRounded onClick={back} />}
            </div>
            <div className={classNames(classes.content, { isDesktop })}>
                <div className={classes.imgWrapper}>
                    <img className={classes.img} src={image} alt="phq2-end" />
                </div>
                <p className={classNames(classes.title, { isDesktop })}>You’re Done!</p>
                <p className={classNames(classes.text, { isDesktop })}>
                    Thank you for allowing us to know more about you. We hope that we can continue improving your
                    experience!
                </p>
                <button className={classNames(classes.button, { isDesktop })} onClick={handleEnd} type="button">
                    Okay
                </button>
            </div>
        </div>
    )
}

AssessmentEnd.propTypes = {
    back: func,
    handleEnd: func.isRequired,
    isDesktop: bool,
}

AssessmentEnd.defaultProps = {
    back: null,
    isDesktop: false,
}

export default AssessmentEnd

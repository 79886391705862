import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { bool, func, string } from 'prop-types'
import { CloseRounded } from '@mui/icons-material'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import BookmarkPopup from 'js/components/InfoPopup'
import ChatUsersSearch from 'js/components/NewMessageBody/ChatUsersSearch'
import SuggestedRecipients from 'js/components/NewMessageBody/SuggestedRecipients'
import useScreenSize from 'js/hooks/useScreenSize'
import { CALL_TYPE, STAFF_HITS_MAX } from 'js/var/const'
import { checkChatExist } from 'js/utils/checkers'
import { createChatAxios } from 'js/APIs/axiosApi'
import { EXPLORE_PATH } from 'js/var/paths'
import { mindfullySearchClient, searchClient } from 'js/APIs/algoliaClient'
import { sendMessageToChat } from 'js/redux/chats/chat.actions'
import { selectChatsUsers } from 'js/redux/chats/chat.selectors'
import { selectIsStaff, selectProfile } from 'js/redux/profile/profile.selectors'
import { selectUid } from 'js/redux/profile/user'
import { showResourceBookmark } from 'js/redux/resources/resources.actions'

const useStyles = makeStyles(({ buttons, fonts, others, palette }) => ({
    Invite: {
        position: 'relative',
        backgroundColor: palette.white,
        borderRadius: '30px',
        height: '688px',
        overflow: 'hidden',
        width: '650px',

        '&.isMobile': {
            borderRadius: 0,
            height: '100vh',
            width: '100%',
        },
    },
    buttons: {
        backgroundColor: palette.white,
        bottom: 0,
        display: 'flex',
        height: '72px',
        justifyContent: 'space-between',
        left: 0,
        right: 0,
        padding: '16px',
        position: 'absolute',
        zIndex: 10,
    },
    close: {
        position: 'absolute',
        right: '29.8px',
        top: '29px',
        zIndex: 10,

        '& svg > path': {
            fill: palette.black,
        },
    },
    copyLink: {
        ...buttons.defSec.mob,
        width: 'calc(50% - 8px)',
    },
    header: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '32px',

        '&.isMobile': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '32px',
        },
    },
    list: {
        ...others.scroll,
        maxHeight: '390px',
        overflow: 'auto',
        padding: 0,
        margin: '0 16px',

        '&.isMobile': {
            maxHeight: 'calc(100vh - 223px)',
            margin: 0,
        },
    },

    sendBtn: {
        ...buttons.round,

        '&.isMobile': {
            width: '100%',
        },

        '&.isScheduled': {
            width: 'calc(50% - 8px)',
        },
    },
    text: {
        ...fonts.b2.mob,
        marginBottom: '6px',
    },
    title: {
        ...fonts.h3.dskt,
        marginBottom: '4px',
    },
}))

const Invite = ({ meetingId, isScheduled, isOpen, onClose, scheduleName, text, title }) => {
    const screenSize = useScreenSize()
    const classes = useStyles()
    const chatsWithUsersIds = useSelector(selectChatsUsers)
    const dispatch = useDispatch()
    const inputRef = useRef(null)
    const isStaff = useSelector(selectIsStaff)
    const uid = useSelector(selectUid)
    const [chatId, setChatId] = useState('')
    const [isLastSelected, setIsLastSelected] = useState(true)
    const [isSent, setIsSent] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [selectedRecipients, setSelectedRecipients] = useState([])
    const { full_name, profile_picture } = useSelector(selectProfile)

    useEffect(() => {
        const id = checkChatExist(
            chatsWithUsersIds,
            selectedRecipients.map((recipient) => recipient.uid),
        )

        setChatId(id || '')
    }, [selectedRecipients, selectedRecipients.length, chatsWithUsersIds])

    const addRecipient = (recipient) => {
        if (selectedRecipients.find(({ uid }) => recipient.uid === uid)) {
            return
        }
        const newSelectedRecipients = [...selectedRecipients, recipient]
        setSelectedRecipients(newSelectedRecipients)
        inputRef.current.focus()
        inputRef.current.textContent = ''
        setSearchString('')
    }

    const deleteRecipient = (removedUid) => {
        setSelectedRecipients(selectedRecipients.filter(({ uid }) => uid !== removedUid))
    }

    const sendMessage = async (chatId) => {
        const msg = `<span>Meeting invitation. Click to join meeting: <a>\n${
            isScheduled ? scheduleName : meetingId
        }</a></span>`

        dispatch(
            sendMessageToChat({
                msg,
                chatId,
                recipients: selectedRecipients.map(({ uid }) => uid),
                full_name,
                profile_picture,
                isInvitationSending: true,
                groupCallRoomId: isScheduled ? null : meetingId,
                invitationType: isScheduled ? CALL_TYPE.scheduledMeeting : CALL_TYPE.groupCall,
                scheduledCallRoomId: isScheduled ? meetingId : null,
            }),
        )

        dispatch(showResourceBookmark({ message: 'Meeting shared.' }))
        return onClose()
    }

    const onSendMessage = async () => {
        if (isSent) {
            return
        }
        setIsSent(true)
        if (chatId) {
            return sendMessage(chatId)
        }

        const createdChatId = await createChatAxios({ users: [...selectedRecipients.map(({ uid }) => uid), uid] })

        if (createdChatId?.data?.chatId) {
            await sendMessage(createdChatId?.data?.chatId)
        }
    }

    const handleCopyLink = () => {
        const exploreMeetingLink = `${process.env.REACT_APP_URL}/#${EXPLORE_PATH}/${meetingId}`

        navigator.clipboard.writeText(exploreMeetingLink)
        dispatch(showResourceBookmark({ message: 'Link Copied.' }))
    }

    return (
        <BlackPopup isOpen={isOpen} onClose={onClose}>
            <div className={classNames(classes.Invite, screenSize)} onClick={(e) => e.stopPropagation()}>
                <div className={classes.close} onClick={onClose}>
                    <CloseRounded />
                </div>
                <div className={classNames(classes.header, screenSize)}>
                    <p className={classes.title}>{title}</p>
                    <p className={classes.text}>{text}</p>
                    <ChatUsersSearch
                        key="ChatUsersSearch"
                        deleteRecipient={deleteRecipient}
                        inputRef={inputRef}
                        isLastSelected={isLastSelected}
                        isInvite
                        isDesktop
                        searchString={searchString}
                        selectedRecipients={selectedRecipients}
                        setIsLastSelected={setIsLastSelected}
                        setSearchString={setSearchString}
                    />
                </div>
                <div className={classNames(classes.list, screenSize)}>
                    <InstantSearch
                        indexName={isStaff ? 'users' : 'staff'}
                        searchClient={isStaff ? searchClient : mindfullySearchClient}
                    >
                        <Configure clickAnalytics hitsPerPage={STAFF_HITS_MAX} />
                        <SuggestedRecipients
                            isDesktop
                            isStaff={isStaff}
                            addRecipient={addRecipient}
                            deleteRecipient={deleteRecipient}
                            searchString={searchString}
                            selectedRecipients={selectedRecipients}
                        />
                    </InstantSearch>
                </div>
                <div className={classes.buttons}>
                    {isScheduled ? (
                        <button className={classes.copyLink} onClick={handleCopyLink} type="button">
                            Copy Invite Link
                        </button>
                    ) : (
                        <span />
                    )}
                    <button
                        className={classNames(classes.sendBtn, { ...screenSize, isScheduled })}
                        disabled={!selectedRecipients.length}
                        onClick={onSendMessage}
                        type="button"
                    >
                        Send Invite
                    </button>
                </div>
            </div>
            <BookmarkPopup />
        </BlackPopup>
    )
}

Invite.propTypes = {
    isOpen: bool.isRequired,
    isScheduled: bool,
    meetingId: string.isRequired,
    onClose: func.isRequired,
    scheduleName: string,
    text: func.isRequired,
    title: func.isRequired,
}

Invite.defaultProps = {
    scheduleName: '',
    isScheduled: false,
}

export default Invite

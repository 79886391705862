import { createAction } from 'redux-actions'

import { updatePHQ2 } from 'js/APIs/firebase/phq2.firebase'

import {
    COMPLETE_PHQ2,
    INIT_PHQ2,
    SET_PHQ2_ANSWER,
    SET_PHQ2_SLIDE,
    START_PHQ2,
    TOGGLE_PHQ2,
    UPDATE_PHQ2,
} from './phq2.types'

export const completePHQ2 = createAction(COMPLETE_PHQ2)
export const initPHQ2 = createAction(INIT_PHQ2)
export const startPHQ2 = createAction(START_PHQ2)
export const setPHQ2Answer = createAction(SET_PHQ2_ANSWER)
export const setPHQ2Slide = createAction(SET_PHQ2_SLIDE)
export const togglePHQ2 = createAction(TOGGLE_PHQ2)

const updatePHQ2Success = createAction(UPDATE_PHQ2)

export const savePHQ2 = (PHQ2) => async (dispatch) => updatePHQ2(PHQ2).then(() => dispatch(updatePHQ2Success()))

import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { getSettings, updateSettings } from 'js/APIs/firebase/settings.firebase'

import { SET_SETTINGS } from './settings.types'

export const setSettings = createAction(SET_SETTINGS)

export const loadSettings = () => async (dispatch) => {
    try {
        const {
            availability,
            away,
            blockedUsers = {},
            callSettings,
            contactMethod,
            delay,
        } = (await getSettings()) || {}

        if (away && callSettings && availability && delay !== undefined && contactMethod) {
            const preparedAway = { ...away, end: new Date(away.end || null), start: new Date(away.start || null) }

            dispatch(
                setSettings({
                    availability,
                    away: preparedAway,
                    blockedUsers,
                    callSettings: {
                        isAllClientsCall: !!callSettings?.isAllClientsCall,
                        users: Object.keys(callSettings?.users || {}),
                    },
                    delay: +delay,
                    contactMethod,
                }),
            )
        }
    } catch (error) {
        logger(`Error of settings loading: ${error}`)
    }
}

export const uploadSettings = (settings) => async (dispatch) => {
    try {
        dispatch(setSettings(settings))
        const { availability, away, blockedUsers, callSettings, contactMethod, delay } = settings

        await updateSettings({
            availability,
            away: { ...away, end: away?.end?.getTime() || null, start: away?.start?.getTime() || null },
            blockedUsers,
            callSettings: {
                isAllClientsCall: !!callSettings?.isAllClientsCall,
                users:
                    callSettings?.users.reduce((acc, curr) => {
                        acc[curr] = true

                        return acc
                    }, {}) || {},
            },
            contactMethod,
            delay,
        })
    } catch (error) {
        logger(`Error of settings uploading: ${error}`)
    }
}

import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { any, bool, func, number, object, string } from 'prop-types'
import { percentToHex } from 'js/utils/format'
import classNames from 'classnames'

const useStyles = makeStyles(({ fonts, palette }) => ({
    Input: {
        ...fonts.s16l208,
        alignItems: 'center',
        color: palette.black,
        boxSizing: 'border-box',
        display: 'flex',
        height: '40px',
        padding: '8px 16px',
        background: palette.fuchsiaBlue + percentToHex(10),
        border: 'none',
        borderBottom: `1px solid ${palette.inputBorder}`,
        outline: 'none',

        '&:focus': {
            borderBottom: `1px solid ${palette.fuchsiaBlue}`,
            boxShadow: '0px 0px 0px 4px rgba(114, 83, 204, 0.5)',
        },

        '&:placeholder': {
            ...fonts.s16l208,
            color: palette.black + percentToHex(50),
        },

        '&:disabled': {
            opacity: 0.5,
        },

        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },

        '&.isTextarea': {
            paddingBottom: '16px',
            paddingTop: '16px',
            resize: 'none',
        },
    },
}))

const Input = forwardRef(
    ({ disabled, className, isTextarea, onChange, onClick, name, max, min, placeholder, style, type, value }, ref) => {
        const classes = useStyles()

        if (type === 'button') {
            return (
                <button
                    disabled={disabled}
                    className={classes.Input}
                    onClick={onClick}
                    ref={ref}
                    style={style}
                    type="button"
                >
                    {value}
                </button>
            )
        }

        if (isTextarea) {
            return (
                <textarea
                    disabled={disabled}
                    className={classNames(classes.Input, { isTextarea, [className]: className })}
                    onChange={onChange}
                    onClick={onClick}
                    name={name}
                    placeholder={placeholder}
                    ref={ref}
                    style={style}
                    type={type}
                    min={min}
                    max={max}
                    value={value}
                />
            )
        }

        return (
            <input
                disabled={disabled}
                className={classNames(classes.Input, { [className]: className })}
                onChange={onChange}
                onClick={onClick}
                name={name}
                placeholder={placeholder}
                ref={ref}
                style={style}
                type={type}
                min={min}
                max={max}
                value={value}
            />
        )
    },
)

Input.propTypes = {
    className: string,
    disabled: bool,
    isTextarea: bool,
    onClick: func,
    onChange: func,
    name: string,
    max: number,
    min: number,
    placeholder: string,
    style: object,
    type: string,
    value: any,
}

Input.defaultProps = {
    className: '',
    disabled: false,
    isTextarea: false,
    onChange: null,
    onClick: null,
    name: '',
    max: 100,
    min: 0,
    placeholder: '',
    style: {},
    value: undefined,
    type: 'text',
}

export default Input

import React from 'react'

const IconPersonal = () => (
    <svg
        width="42"
        height="48"
        viewBox="0 0 42 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.18909 46.4352C2.18909 47.2636 2.86066 47.9352 3.68909 47.9352C4.51751 47.9352 5.18909 47.2636 5.18909 46.4352H2.18909ZM36.8109 46.4352C36.8109 47.2636 37.4824 47.9352 38.3109 47.9352C39.1393 47.9352 39.8109 47.2636 39.8109 46.4352H36.8109ZM5.18909 46.4352V38.1791H2.18909V46.4352H5.18909ZM9.68909 33.6791H32.3108V30.6791H9.68909V33.6791ZM36.8109 38.1791V46.4352H39.8109V38.1791H36.8109ZM32.3108 33.6791C34.7961 33.6791 36.8109 35.6939 36.8109 38.1791H39.8109C39.8109 34.037 36.453 30.6791 32.3108 30.6791V33.6791ZM5.18909 38.1791C5.18909 35.6939 7.20381 33.6791 9.68909 33.6791V30.6791C5.54695 30.6791 2.18909 34.037 2.18909 38.1791H5.18909Z"
            className="fill-painted"
        />
        <circle cx="21" cy="13.8682" r="11.7377" className="stroke-painted" strokeWidth="3" />
        <path
            d="M14.4335 25.2127L20.8174 25.7114L27.6999 25.4122L28.128 32.1759H13.8719L14.4335 25.2127Z"
            className="stroke-painted"
            strokeWidth="3"
        />
        <path
            d="M9.3465 14.44L2.2589 30.7812C1.97249 31.4415 2.45654 32.1791 3.17632 32.1791H17.9452"
            className="stroke-painted"
            strokeWidth="3"
        />
        <path
            d="M32.6873 14.041L39.7608 30.79C40.0392 31.4493 39.5552 32.1791 38.8396 32.1791H24.0548"
            className="stroke-painted"
            strokeWidth="3"
        />
        <path
            d="M35.1876 5.86461C35.8287 5.34002 35.9232 4.39498 35.3986 3.75382C34.874 3.11265 33.929 3.01815 33.2878 3.54274L35.1876 5.86461ZM12.8113 5.76433C14.5349 7.48798 17.6707 9.4942 21.6191 10.0823C25.6327 10.68 30.3844 9.79449 35.1876 5.86461L33.2878 3.54274C29.1301 6.94453 25.2263 7.58641 22.061 7.11499C18.8305 6.63384 16.2638 4.97423 14.9326 3.64301L12.8113 5.76433Z"
            className="fill-painted"
        />
    </svg>
)

export default IconPersonal

import React from 'react'

const IconHintTips = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.4693 0.175781H3.15055C1.54639 0.175781 0.233887 1.48828 0.233887 3.09245V29.3425L6.05264 23.5091H26.4693C28.0735 23.5091 29.386 22.1966 29.386 20.5924V3.09245C29.386 1.48828 28.0735 0.175781 26.4693 0.175781ZM16.261 17.6758H13.3443V14.7591H16.261V17.6758ZM14.8026 11.8424C15.6047 11.8424 16.261 11.1862 16.261 10.3841V7.46745C16.261 6.66536 15.6047 6.00911 14.8026 6.00911C14.0005 6.00911 13.3443 6.66536 13.3443 7.46745V10.3841C13.3443 11.1862 14.0005 11.8424 14.8026 11.8424Z"
            fill="white"
        />
    </svg>
)

export default IconHintTips

import React from 'react'
import { func } from 'prop-types'

const InterestsDelete = ({ onClick }) => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 9.5C1.5 5.3525 4.8525 2 9 2C13.1475 2 16.5 5.3525 16.5 9.5C16.5 13.6475 13.1475 17 9 17C4.8525 17 1.5 13.6475 1.5 9.5ZM11.1674 12.7251C11.4599 13.0176 11.9324 13.0176 12.2249 12.7251C12.5099 12.4326 12.5099 11.9526 12.2249 11.6676L10.0574 9.50007L12.2249 7.33257C12.5174 7.04007 12.5174 6.56757 12.2249 6.27507C11.9324 5.98257 11.4599 5.98257 11.1674 6.27507L8.99995 8.44257L6.83245 6.27507C6.53995 5.98257 6.06745 5.98257 5.77495 6.27507C5.63451 6.41519 5.55559 6.60543 5.55559 6.80382C5.55559 7.00221 5.63451 7.19244 5.77495 7.33257L7.94245 9.50007L5.77495 11.6676C5.63451 11.8077 5.55559 11.9979 5.55559 12.1963C5.55559 12.3947 5.63451 12.5849 5.77495 12.7251C6.06745 13.0176 6.53995 13.0176 6.83245 12.7251L8.99995 10.5576L11.1674 12.7251Z"
            fill="#00B3C5"
        />
    </svg>
)

InterestsDelete.propTypes = {
    onClick: func.isRequired,
}

export default InterestsDelete

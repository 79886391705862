import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import _times from 'lodash/times'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { Close } from 'js/components/icons'
import { selectIsStaff } from 'js/redux/profile/profile.selectors'
import { selectIsStaffLearningOpen } from 'js/redux/peerSupporterLearning/peerSupporterLearning.selectors'
import {
    togglePeerSupporterLearning,
    setPeerSupporterLearning,
} from 'js/redux/peerSupporterLearning/peerSupporterLearning.actions'

import BlackPopup from '../Reusable/popups/BlackPopup'
import FindYourMentor from './FindYourMentor'
import HiThere from './HiThere'
import HowItWorks from './HowItWorks'
import LastSteps from './LastSteps'
import ReachOut from './ReachOut'
import SliderDot from '../../pages/Assessment/DotsNavigation/SliderDot'
import WhatIsMentor from './WhatIsMentor'

const settings = {
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const useStyles = makeStyles(({ palette }) => ({
    PeerSupporterLearning: {
        backgroundColor: palette.white,
        height: '100vh',
        minHeight: '550px',
        opacity: '1',
        outline: 'none',
        position: 'relative',
        width: '100%',

        '&.isDesktop': {
            borderRadius: '30px',
            height: '503px',
            maxWidth: '466px',
            minHeight: '503px',
            overflow: 'hidden',
            width: '466px',
        },
    },
    close: {
        position: 'absolute',
        top: '18px',
        right: '20px',
        zIndex: 10,

        '& svg': {
            '& path': {
                fill: palette.black,
            },
        },

        '&.isDesktop': {
            right: '29px',
            top: '29px',
        },
    },
    dots: {
        width: '100%',
        position: 'absolute',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        bottom: '25px',
    },
}))

const PeerSupporterLearning = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsStaffLearningOpen)
    const isStaff = useSelector(selectIsStaff)
    const isDesktop = !useMediaQuery({ query: `(max-width: 864px)` })

    const onClose = ({ currentTarget, target }) => {
        if (currentTarget === target) {
            dispatch(togglePeerSupporterLearning())
        }
    }

    let slider = useRef()
    const [activeIndex, setActiveIndex] = useState(0)
    const maxSlidesLimit = 4

    const beforeChange = (current, next) => {
        if (!isDesktop && activeIndex === maxSlidesLimit - 1) {
            dispatch(togglePeerSupporterLearning())
        }
        setActiveIndex(next)
    }

    const next = (index) => {
        if (index < maxSlidesLimit) {
            slider.slickGoTo(index)
        }
    }

    const goToIndex = ({
        target: {
            dataset: { index },
        },
    }) => {
        next(index)
    }

    if (activeIndex === maxSlidesLimit - 1) {
        dispatch(setPeerSupporterLearning({ isPeerSupporterLearning: true }))
    }

    return (
        <BlackPopup isOpen={isOpen && !isStaff} onClose={onClose}>
            <div className={classNames(classes.PeerSupporterLearning, { isDesktop })}>
                <div className={classNames(classes.close, { isDesktop })} onClick={onClose}>
                    <Close onClick={onClose} />
                </div>
                <Slider
                    ref={(c) => {
                        slider = c
                    }}
                    {...settings}
                    initialSlide={0}
                    beforeChange={beforeChange}
                >
                    <HiThere isDesktop={isDesktop} next={() => next(1)} />
                    <WhatIsMentor isDesktop={isDesktop} next={() => next(2)} />
                    {isDesktop ? <FindYourMentor next={() => next(3)} /> : <HowItWorks />}
                    {isDesktop ? <ReachOut handleClose={onClose} /> : <LastSteps />}
                </Slider>
                <div className={classes.dots}>
                    {_times(maxSlidesLimit, (i) => (
                        <SliderDot
                            activeIndex={activeIndex}
                            currentIndex={i}
                            goToIndex={goToIndex}
                            key={`mentorQuizQuestion${i}`}
                        />
                    ))}
                </div>
            </div>
        </BlackPopup>
    )
}

export default PeerSupporterLearning

import { handleActions } from 'redux-actions'
import { notificationDeleteSuccess, subsNotificationsSuccess } from './notifications.actions'

const defaultState = {
    notifications: [],
    isQueried: false,
    newNotification: null,
}

const chatReducer = handleActions(
    {
        [notificationDeleteSuccess]: (state, { payload }) => ({
            ...state,
            notifications: state.notifications.filter(({ nid }) => nid !== payload),
        }),
        [subsNotificationsSuccess]: (state, action) => ({
            ...state,
            notifications: [...state.notifications, action.payload],
        }),
    },
    defaultState,
)

export default chatReducer

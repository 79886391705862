import React, { useState, useRef } from 'react'
import Slider from 'react-slick'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import AssessmentEnd from 'js/components/AssessmentEnd'
import { selectPHQ9CurrentSlide, selectPHQ9 } from 'js/redux/phq9/phq9.selectors'
import { completePHQ9, savePHQ9, togglePHQ9 } from 'js/redux/phq9/phq9.actions'

import PHQ9Question from '../PHQ9Question'

const settings = {
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const useStyles = makeStyles((theme) => ({
    PHQ9Slider: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.white,
    },
}))

const SLIDES_AMOUNT = 9

const PHQ9Slider = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isDesktop = !useMediaQuery({ query: `(max-width: 644px)` })
    let slider = useRef()
    const startSlide = useSelector(selectPHQ9CurrentSlide)
    const PHQ9 = useSelector(selectPHQ9)
    const [slidesLimit, setSlidesLimit] = useState(startSlide + 1)

    const goToNextSlide = (limit) => () => {
        if (limit > slidesLimit) {
            setSlidesLimit(limit)
        }
        slider.slickNext()
    }

    const handleEnd = () => {
        dispatch(completePHQ9())
        dispatch(togglePHQ9())
        dispatch(savePHQ9({ ...PHQ9, isPHQ9Completed: true, currentSlide: 2, isPHQ9Open: false }))
    }

    const createBack = (index) => () => slider.slickGoTo(index)

    return (
        <div className={classes.PHQ9Slider}>
            <Slider
                ref={(c) => {
                    slider = c
                }}
                {...settings}
                initialSlide={startSlide}
            >
                <PHQ9Question
                    goToNextSlide={goToNextSlide(2)}
                    isDesktop={isDesktop}
                    question="Over the last two weeks, how often have you experienced little interest or pleasure in doing things?"
                    questionTitle="Engagement in activities"
                    slidesAmount={SLIDES_AMOUNT}
                    slideNumber={0}
                />
                {slidesLimit > 1 && (
                    <PHQ9Question
                        back={createBack(0)}
                        goToNextSlide={goToNextSlide(3)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often have you been feeling down, depressed, or hopeless?"
                        questionTitle="Emotional well-being"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={1}
                    />
                )}
                {slidesLimit > 2 && (
                    <PHQ9Question
                        back={createBack(1)}
                        goToNextSlide={goToNextSlide(4)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often do you have trouble falling asleep, staying asleep or sleeping too much?"
                        questionTitle="Sleeping habits"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={2}
                    />
                )}
                {slidesLimit > 3 && (
                    <PHQ9Question
                        back={createBack(2)}
                        goToNextSlide={goToNextSlide(5)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often do you feel tired or have little energy?"
                        questionTitle="Energy level"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={3}
                    />
                )}
                {slidesLimit > 4 && (
                    <PHQ9Question
                        back={createBack(3)}
                        goToNextSlide={goToNextSlide(6)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often do you experience poor appetite or overeating?"
                        questionTitle="Increase or lack of appetite"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={4}
                    />
                )}
                {slidesLimit > 5 && (
                    <PHQ9Question
                        back={createBack(4)}
                        goToNextSlide={goToNextSlide(7)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often do you feel bad about yourself- or that you’re a failure or have let yourself or your family down?"
                        questionTitle="Self esteem"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={5}
                    />
                )}
                {slidesLimit > 6 && (
                    <PHQ9Question
                        back={createBack(5)}
                        goToNextSlide={goToNextSlide(8)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often do you have trouble concentrating on things, such as reading the newspaper or watching television?"
                        questionTitle="Concentration on tasks"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={6}
                    />
                )}
                {slidesLimit > 7 && (
                    <PHQ9Question
                        back={createBack(6)}
                        goToNextSlide={goToNextSlide(9)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often do you experience moving or speaking slowly that other people could have noticed. Or being so fidgety or restless that you have been moving around more than usual?"
                        questionTitle="Slowed movements or restlessness"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={7}
                    />
                )}
                {slidesLimit > 8 && (
                    <PHQ9Question
                        back={createBack(7)}
                        goToNextSlide={goToNextSlide(10)}
                        isDesktop={isDesktop}
                        question="Over the last two weeks, how often do you have thoughts that you would be better off dead or hurting yourself in someway?"
                        questionTitle="Hopelessness"
                        slidesAmount={SLIDES_AMOUNT}
                        slideNumber={8}
                    />
                )}

                {slidesLimit > 9 && <AssessmentEnd back={createBack(8)} handleEnd={handleEnd} isDesktop={isDesktop} />}
            </Slider>
        </div>
    )
}

export default PHQ9Slider

import { ANY_SERVICE } from 'js/var/const'
import { handleActions } from 'redux-actions'

import {
    setConnections,
    setFindCareSlide,
    setPreferredService,
    setPreferredSpecialties,
    setServices,
    setSpecialties,
    setStaff,
    setSuggestedStaff,
    toggleFindCare,
} from './staff.actions'

const defaultState = {
    connections: [],
    suggestedStaff: [],
    findCareSlide: 0,
    isFindCareOpen: false,
    preferredService: '',
    preferredSpecialties: {},

    services: [],
    specialties: [],
    staff: [],
}

const connectionsReducer = handleActions(
    {
        [setFindCareSlide]: (state, { payload }) => ({
            ...state,
            findCareSlide: payload,
        }),
        [setPreferredService]: (state, { payload }) => ({
            ...state,
            preferredService: payload,
        }),
        [setPreferredSpecialties]: (state, { payload }) => ({
            ...state,
            preferredSpecialties: payload,
        }),

        [setServices]: (state, { payload }) => ({
            ...state,
            services: [...payload, ANY_SERVICE],
        }),
        [setSpecialties]: (state, { payload }) => ({
            ...state,
            specialties: payload,
        }),

        [setConnections]: (state, { payload: connections }) => ({
            ...state,
            connections,
        }),
        [setStaff]: (state, { payload: staff }) => ({
            ...state,
            staff,
        }),
        [setSuggestedStaff]: (state, { payload }) => ({
            ...state,
            suggestedStaff: payload,
        }),
        [toggleFindCare]: (state) => ({
            ...state,
            isFindCareOpen: !state.isFindCareOpen,
        }),
    },
    defaultState,
)

export default connectionsReducer

import fonts from './fonts'
import palette from './palette'

const fields = {
    input: {
        dskt: {
            ...fonts.b2.dskt,
            backgroundColor: palette.softBlue,
            border: 'none',
            borderRadius: '24px',
            boxSizing: 'border-box',
            color: palette.black,
            height: '48px',
            display: 'flex',
            outline: 'none',
            padding: '12px 16px',
            width: '100%',
            '-moz-appearance': 'textfield',

            '&:autofill': {
                ...fonts.b2.dskt,
                backgroundColor: palette.softBlue,
                color: palette.black,
            },

            '&:disabled': {
                background: palette.disabledGrey,
            },

            '&:focus': {
                border: `1px solid ${palette.primBlue}`,
            },

            '&::placeholder': {
                ...fonts.b2.dskt,
                color: palette.midGrey,
            },

            '&:-webkit-autofill': {
                '-webkit-box-shadow': `0 0 0 30px ${palette.softBlue} inset !important`,
            },

            '&:-webkit-autofill::first-line': {
                ...fonts.b2.dskt,
                color: palette.black,
            },

            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },

            '&.isInvalid': {
                border: `1px solid ${palette.errorRed}`,
            },
        },
        mob: {
            ...fonts.b2.mob,
            backgroundColor: palette.softBlue,
            borderColor: palette.softBlue,
            border: 'none',
            borderRadius: '24px',
            boxSizing: 'border-box',
            color: palette.black,
            height: '40px',
            display: 'flex',
            outline: 'none',
            padding: '10px 16px',
            width: '100%',
            '-moz-appearance': 'textfield',

            '&:autofill': {
                ...fonts.b2.mob,
                backgroundColor: palette.softBlue,
                color: palette.black,
            },

            '&::placeholder': {
                ...fonts.b2.mob,
                color: palette.midGrey,
            },

            '&:focus': {
                border: `1px solid ${palette.primBlue}`,
            },

            '&:disabled': {
                background: palette.disabledGrey,
            },

            '&:-webkit-autofill': {
                ...fonts.b2.mob,
                backgroundColor: palette.softBlue,
                color: palette.black,
                '-webkit-box-shadow': `0 0 0 30px ${palette.softBlue} inset !important`,
            },

            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },

            '&.isInvalid': {
                border: `1px solid ${palette.errorRed}`,
            },
        },
    },
    label: {
        dskt: {
            ...fonts.b3.dskt,
            display: 'block',
            height: '100px',
            position: 'relative',

            '& .error': {
                ...fonts.b3.dskt,
                color: palette.errorRed,
                paddingTop: '4px',
                paddingLeft: '16px',
            },
            '& .input': {
                ...fonts.b2.dskt,
                backgroundColor: palette.softBlue,
                border: 'none',
                borderRadius: '24px',
                boxShadow: 'none',
                boxSizing: 'border-box',
                color: palette.black,
                height: '48px',
                display: 'flex',
                outline: 'none',
                padding: '12px 16px',
                width: '100%',
                '-moz-appearance': 'textfield',

                '&:autofill': {
                    ...fonts.b2.dskt,
                    backgroundColor: palette.softBlue,
                    color: palette.black,
                },

                '&:disabled': {
                    background: palette.disabledGrey,
                },

                '&:focus': {
                    border: `1px solid ${palette.primBlue}`,
                },

                '&::placeholder': {
                    ...fonts.b2.dskt,
                    color: palette.midGrey,
                },

                '&:-webkit-autofill': {
                    '-webkit-box-shadow': `0 0 0 30px ${palette.softBlue} inset !important`,
                },

                '&:-webkit-autofill::first-line': {
                    ...fonts.b2.dskt,
                    color: palette.black,
                },

                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },

                '&.isInvalid': {
                    border: `1px solid ${palette.errorRed}`,
                },
            },

            '& .inputBtn': {
                alignItems: 'center',
                display: 'flex',
                height: '24px',
                justifyContent: 'center',
                position: 'absolute',
                right: '17px',
                top: '41px',
                width: '24px',

                '& svg': {
                    height: '24px',
                    width: '24px',

                    '& path': {
                        fill: palette.darkGrey,
                    },
                },
            },

            '& .title': {
                ...fonts.b3.dskt,
                alignItems: 'center',
                color: palette.black,
                display: 'flex',
                marginBottom: '8px',
                paddingLeft: '16px',
                width: '100%',
            },
        },
        mob: {
            ...fonts.b4.mob,
            display: 'block',
            height: '80px',
            position: 'relative',

            '& .error': {
                ...fonts.b4.mob,
                color: palette.errorRed,
                paddingTop: '4px',
                paddingLeft: '16px',
            },

            '& .input': {
                ...fonts.b2.mob,
                backgroundColor: palette.softBlue,
                border: 'none',
                boxShadow: 'none',
                borderRadius: '24px',
                boxSizing: 'border-box',
                color: palette.black,
                height: '40px',
                display: 'flex',
                outline: 'none',
                padding: '10px 16px',
                width: '100%',
                '-moz-appearance': 'textfield',

                '&:autofill': {
                    ...fonts.b2.mob,
                    backgroundColor: palette.softBlue,
                    color: palette.black,
                },

                '&::placeholder': {
                    ...fonts.b2.mob,
                    color: palette.midGrey,
                },

                '&:focus': {
                    border: `1px solid ${palette.primBlue}`,
                },

                '&:disabled': {
                    background: palette.disabledGrey,
                },

                '&:-webkit-autofill': {
                    ...fonts.b2.mob,
                    backgroundColor: palette.softBlue,
                    color: palette.black,
                    '-webkit-box-shadow': `0 0 0 30px ${palette.softBlue} inset !important`,
                },

                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },

                '&.isInvalid': {
                    border: `1px solid ${palette.errorRed}`,
                },
            },

            '& .inputBtn': {
                alignItems: 'center',
                top: '31px',
                display: 'flex',
                height: '16px',
                justifyContent: 'center',
                position: 'absolute',
                right: '13px',
                width: '16px',

                '& svg': {
                    height: '16px',
                    width: '16px',

                    '& path': {
                        fill: palette.darkGrey,
                    },
                },
            },

            '& .title': {
                ...fonts.b4.mob,
                alignItems: 'center',
                color: palette.black,
                display: 'flex',
                marginBottom: '4px',
                paddingLeft: '16px',
                width: '100%',
            },
        },
    },

    mobile: {
        dateField: {
            ...fonts.b2.mob,
            alignItems: 'center',
            background: palette.softBlue,
            borderRadius: '24px',
            border: 'none',
            color: palette.midGrey,
            display: 'flex',
            height: '32px',
            justifyContent: 'center',
            outline: 'none',
            padding: 0,
            position: 'relative',
            textAlign: 'center',
            width: '100%',

            '& svg': {
                position: 'absolute',
                top: '6.3px',
                right: '19px',

                '& path': {
                    fill: palette.darkGrey,
                },
            },
        },
    },
    desktop: {
        dateField: {
            ...fonts.b2.dskt,
            alignItems: 'center',
            background: palette.softBlue,
            borderRadius: '24px',
            border: 'none',
            color: palette.midGrey,
            display: 'flex',
            height: '48px',
            justifyContent: 'flex-start',
            outline: 'none',
            padding: '12px 16px',
            position: 'relative',
            textAlign: 'start',
            width: '100%',

            '& svg': {
                position: 'absolute',
                top: '14px',
                right: '19px',

                '& path': {
                    fill: palette.darkGrey,
                },
            },
        },
    },
}

export default fields

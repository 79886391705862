import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import CallButton from 'js/components/Reusable/CallButton'
import { VIDEO_CALL, CANCEL, DEFAULT_AVATAR } from 'js/var/images'
import { selectDailyRoom, selectProfile } from 'js/redux/profile/profile.selectors'
import { announcePeerToPeer, cancelPeerToPeerAnnounce } from 'js/redux/peerToPeer/peerToPeer.actions'
import { selectDeclinePopupOpen, selectPeerToPeerRemote } from 'js/redux/peerToPeer/peerToPeer.selectors'
import classNames from 'classnames'
import useScreenSize from 'js/hooks/useScreenSize'

const useStyles = makeStyles(({ fonts, palette }) => ({
    DeclinePopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarWrapper: {
        height: '170px',
        width: '170px',
        background: 'rgba(255, 255, 255, 0.2)',
        boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatar: {
        height: '160px',
        width: '160px',
    },
    name: {
        paddingTop: '20px',
        ...fonts.h4.mob,
        color: palette.white,

        '&.isDesktop': {
            ...fonts.h3.dskt,
        },
    },
    buttons: {
        paddingTop: '124px',
        display: 'flex',
    },
    buttonMargin: {
        marginRight: '90px',
    },
}))

const DeclinePopup = () => {
    const classes = useStyles()
    const dailyRoom = useSelector(selectDailyRoom)
    const dispatch = useDispatch()
    const screenSize = useScreenSize()
    const isOpen = useSelector(selectDeclinePopupOpen)
    const profile = useSelector(selectProfile)
    const { profile_picture, uid } = useSelector(selectPeerToPeerRemote)

    const handleCancel = () => dispatch(cancelPeerToPeerAnnounce({ uid }))

    const startCall = () => dispatch(announcePeerToPeer({ currentProfile: profile, dailyRoom, remoteUid: uid }))

    return (
        <BlackPopup isOpen={isOpen}>
            <div className={classes.DeclinePopup}>
                <div className={classes.avatarWrapper}>
                    <Avatar alt="Avatar" className={classes.avatar} src={profile_picture || DEFAULT_AVATAR} />
                </div>
                <p className={classNames(classes.name, screenSize)}>Didn’t join call</p>
                <div className={classes.buttons}>
                    <CallButton
                        customClass={classes.buttonMargin}
                        icon={CANCEL}
                        isDesktop={screenSize?.isDesktop}
                        onClick={handleCancel}
                        text="cancel"
                    />
                    <CallButton
                        icon={VIDEO_CALL}
                        isDesktop={screenSize?.isDesktop}
                        onClick={startCall}
                        text="call again"
                    />
                </div>
            </div>
        </BlackPopup>
    )
}

export default DeclinePopup

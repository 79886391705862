import React from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import { any, bool, func, string } from 'prop-types'

const useStyles = makeStyles(({ palette: { darkerGrey, white } }) => ({
    content: {
        width: '100%',
    },
    line: {
        backgroundColor: darkerGrey,
        height: '3px',
        left: 'calc(50% - 51px)',
        position: 'absolute',
        right: 'calc(50% - 51px)',
        top: '8px',
        width: '102px',
    },
    paper: {
        background: white,
        borderRadius: '30px 30px 0px 0px',
        height: 'fit-content',
    },
    MobileDrawer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '31px',
        position: 'relative',
    },
}))

const MobileDrawer = ({ children, height, isOpen, onClose }) => {
    const classes = useStyles()

    return (
        <Drawer
            anchor="bottom"
            classes={{ MobileDrawer: classes.backgroundNone, paper: classes.paper }}
            open={isOpen}
            onClose={onClose}
        >
            <div className={classes.MobileDrawer} style={{ height }}>
                <div className={classes.line} />
                <div className={classes.content}>{children}</div>
            </div>
        </Drawer>
    )
}

MobileDrawer.propTypes = {
    children: any.isRequired,
    height: string,
    isOpen: bool,
    onClose: func,
}

MobileDrawer.defaultProps = {
    height: 'fit-content',
    isOpen: false,
    onClose: null,
}

export default MobileDrawer

import logger from 'js/utils/logger'
import firebase, { getTimestamp, getUid } from './firebase'

export const addGoal = (goal) => {
    const uid = getUid()

    return firebase.database().ref(`users/${uid}/goals`).push(goal)
}

export const deleteGoalFirebase = (goalId) =>
    firebase
        .database()
        .ref(`users/${getUid()}/goals`)
        .update({ [goalId]: null })

export const forbidGoalStepEditing = (goalId, stepIndex) =>
    firebase
        .database()
        .ref(`users/${getUid()}/goals/${goalId}/steps/${stepIndex}`)
        .update({ isCompleted: true, isEditable: false })

export const getGoalVariants = () =>
    firebase
        .database()
        .ref('goalVariants')
        .once('value')
        .then((snapshot) => snapshot.val())
        .catch((e) => logger(e))

export const setGoalCompleted = (goalId) =>
    firebase.database().ref(`users/${getUid()}/goals/${goalId}`).update({ isCompleted: true, endDate: getTimestamp() })

export const setGoalConfidenceFirebase = ({ confidenceRating, goalId }) =>
    firebase.database().ref(`users/${getUid()}/goals/${goalId}`).update({
        confidenceRating,
    })

export const setGoalDeadlineFirebase = (goalId, deadlineDate) =>
    firebase
        .database()
        .ref(`users/${getUid()}/goals/${goalId}`)
        .update({
            deadlineDate: typeof deadlineDate === 'number' ? deadlineDate : deadlineDate.getTime(),
        })

export const setGoalStepsFirebase = (goalId, steps) =>
    firebase
        .database()
        .ref(`users/${getUid()}/goals/${goalId}`)
        .update({ steps, hasSteps: Boolean(steps.length) })

export const signOnGoals = (callback) =>
    firebase
        .database()
        .ref(`users/${getUid()}/goals`)
        .on('value', (snapshot) => callback(snapshot.val()))

export const updateGoalCheckIn = (id, checkIn) =>
    firebase
        .database()
        .ref(`users/${getUid()}/goals/${id}/checkIn`)
        .update({ ...checkIn, date: getTimestamp() })

export const updateGoalFirebase = ({ goalId, newGoalData }) =>
    firebase
        .database()
        .ref(`users/${getUid()}/goals/${goalId}`)
        .update({ ...newGoalData })

export const updateGoalProgress = (goalId, progress) =>
    firebase.database().ref(`users/${getUid()}/goals/${goalId}`).update({ progress })

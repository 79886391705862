import classNames from 'classnames'
import React from 'react'
import { ArrowBack } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import { string, func } from 'prop-types'
import { useNavigate } from 'react-router-dom'

import useScreenSize from 'js/hooks/useScreenSize'
import { ReactComponent as NotesExport } from 'media/notes-export.svg'

const useStyles = makeStyles(({ fonts, palette }) => ({
    back: {
        height: '24px',
        left: '16px',
        position: 'absolute',
        top: '13px',
        width: '24px',

        '& svg > path': {
            fill: palette.white,
        },
    },
    ChatHeader: {
        ...fonts.s16l208,
        alignItems: 'center',
        backgroundColor: palette.fuchsiaBlue,
        color: palette.white,
        display: 'flex',
        height: '50px',
        fontWeight: 700,
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        padding: '13px 16px',
        width: '100%',

        '&.isTablet, &.isDesktop': {
            display: 'none',
        },
    },
    export: {
        height: '24px',
        right: '16px',
        position: 'absolute',
        top: '13px',
        width: '24px',

        '& svg > path': {
            fill: palette.white,
        },
    },
    title: {
        width: 'calc(100% - 60px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '21px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
}))

const ChatHeader = ({ name, toggleExport }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()
    const navigate = useNavigate()

    const handleBack = () => navigate(-1)

    return (
        <div className={classNames(classes.ChatHeader, screenSize)}>
            <i className={classes.back} onClick={handleBack}>
                <ArrowBack />
            </i>
            <p className={classes.title}>{name}</p>
            {toggleExport && (
                <i className={classes.export} onClick={toggleExport}>
                    <NotesExport />
                </i>
            )}
        </div>
    )
}

ChatHeader.propTypes = {
    toggleExport: func,
    name: string.isRequired,
}

ChatHeader.defaultProps = {
    toggleExport: null,
}

export default ChatHeader

import React from 'react'
import { makeStyles } from '@material-ui/core'

import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import classNames from 'classnames'
import Button from 'js/components/Reusable/Button'
import { bool, func, string } from 'prop-types'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    ConfirmationPopup: {
        alignItems: 'center',
        backgroundColor: palette.white,
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: '20px',
        width: '267px',

        '&.isDesktop': {
            paddingLeft: '24px',
            paddingRight: '24px',
            width: '350px',
        },
    },
    accept: {
        ...buttons.def.mob,
        marginTop: '24px',

        '&.isDesktop': {
            ...buttons.def.dskt,
        },
    },
    cancel: {
        ...buttons.roundSec,
        marginTop: '8px',
    },
    text: {
        ...fonts.b2.mob,
        color: palette.black,
        marginTop: '8px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.b2.dskt,
        },
    },
    title: {
        ...fonts.b1.mob,
        color: palette.black,
        marginTop: '40px',

        '&.isDesktop': {
            ...fonts.b1.dskt,
        },
    },
}))

const ConfirmationPopup = ({
    acceptTitle,
    cancelTitle,
    isDesktop,
    isOpen,
    onAccept,
    onCancel,
    onClose,
    text,
    title,
}) => {
    const classes = useStyles()

    return (
        <BlackPopup isOpen={isOpen} onClose={onClose}>
            <div
                className={classNames(classes.ConfirmationPopup, {
                    isDesktop,
                })}
                onClick={(e) => e.stopPropagation()}
            >
                <p className={classNames(classes.title, { isDesktop })}>{title} </p>
                <p className={classNames(classes.text, { isDesktop })}>{text}</p>
                <Button className={classNames(classes.accept, { isDesktop })} onClick={onAccept}>
                    {acceptTitle}
                </Button>
                {onCancel && (
                    <Button className={classNames(classes.cancel, { isDesktop })} onClick={onCancel}>
                        {cancelTitle}
                    </Button>
                )}
            </div>
        </BlackPopup>
    )
}

ConfirmationPopup.propTypes = {
    acceptTitle: string.isRequired,
    cancelTitle: string.isRequired,
    isDesktop: bool,
    isOpen: bool.isRequired,
    onAccept: func.isRequired,
    onCancel: func.isRequired,
    onClose: func.isRequired,
    text: string.isRequired,
    title: string.isRequired,
}

ConfirmationPopup.defaultProps = {
    isDesktop: false,
}

export default ConfirmationPopup

import * as Yup from 'yup'

export const emailSchema = Yup.string().email('Email is invalid').required('Email is required')

export const passwordSchema = Yup.string()
    .min(8, 'At least 8 characters')
    .matches(/^[^\d\n\r\u2028\u2029]*\d.*$/, 'At least 1 number')
    .matches(
        /^[-\w \x7b-\u044f]*(?:[^-\w\n\r \x7b-\u044f\u2028\u2029]|(?:[^-\w\n\r \x7b-\u044f\u2028\u2029].*)?[\n\r\u2028\u2029]).*$/,
        'At least one symbol',
    )
    .required('Password is required')

export const usernameSchema = Yup.string()
    .matches(/^[\w-]*$/, 'Your username can only contain letters, numbers, underscore and hyphen.')
    .min(5, 'Your username must be at least 5 letters long.')
    .max(50, 'Please provide a shorter username (less than 50 letters).')
    .required('Please provide a username.')

export const zipCodeSchema = Yup.string().matches(/^\d{5}$/, 'Zip code must be exactly 5 digits')

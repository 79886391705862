import React, { useEffect } from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import CallButton from 'js/components/Reusable/CallButton'
import { CANCEL, DEFAULT_AVATAR } from 'js/var/images'
import { VIDEO_CALL_ANNOUNCE_LIMIT } from 'js/var/const'
import { selectOutgoingPopupOpen, selectPeerToPeerRemote } from 'js/redux/peerToPeer/peerToPeer.selectors'
import { cancelPeerToPeerAnnounce } from 'js/redux/peerToPeer/peerToPeer.actions'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

const useStyles = makeStyles(({ fonts, palette }) => ({
    OutgoingCallPopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarWrapper: {
        height: '170px',
        width: '170px',
        background: 'rgba(255, 255, 255, 0.2)',
        boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatar: {
        height: '160px',
        width: '160px',
    },
    name: {
        paddingTop: '20px',
        ...fonts.h4.mob,
        color: palette.white,

        '&.isDesktop': {
            ...fonts.h3.dskt,
        },
    },
    info: {
        paddingTop: '4px',
        ...fonts.b4.mob,
        color: palette.secondaryGrey,

        '&.isDesktop': {
            ...fonts.b3.dskt,
        },
    },
    buttons: {
        paddingTop: '124px',
        display: 'flex',
    },
    buttonMargin: {
        marginRight: '90px',
    },
}))

const OutgoingCallPopup = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isDesktop = !useMediaQuery({ query: `(max-width: 650px)` })
    const isOutgoingCall = useSelector(selectOutgoingPopupOpen)
    const { full_name, profile_picture, uid } = useSelector(selectPeerToPeerRemote)

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(cancelPeerToPeerAnnounce({ isConnectionTimeExpired: true, uid }))
        }, VIDEO_CALL_ANNOUNCE_LIMIT)
        return () => {
            clearTimeout(timer)
        }
    }, [dispatch, uid])

    const handleCancel = () => {
        dispatch(cancelPeerToPeerAnnounce({ uid }))
    }

    return (
        <BlackPopup isOpen={isOutgoingCall}>
            <div className={classes.OutgoingCallPopup}>
                <div className={classes.avatarWrapper}>
                    <Avatar alt="Avatar" className={classes.avatar} src={profile_picture || DEFAULT_AVATAR} />
                </div>
                <p className={classNames(classes.name, { isDesktop })}>{full_name || 'Mindfully'}</p>
                <p className={classNames(classes.info, { isDesktop })}>Video Call....</p>
                <div className={classes.buttons}>
                    <CallButton icon={CANCEL} isDesktop={isDesktop} onClick={handleCancel} text="cancel" />
                </div>
            </div>
        </BlackPopup>
    )
}

export default OutgoingCallPopup

export const GET_PROFILE_DATA_START = 'GET_PROFILE_DATA_START'
export const GET_PROFILE_DATA_ERROR = 'GET_PROFILE_DATA_ERROR'
export const GET_PROFILE_DATA_SUCCESS = 'GET_PROFILE_DATA_SUCCESS'
export const REMOVE_TEMP_PROFILE_PHOTO_ERROR = 'REMOVE_TEMP_PROFILE_PHOTO_ERROR'
export const REMOVE_TEMP_PROFILE_PHOTO_START = 'REMOVE_TEMP_PROFILE_PHOTO_START'
export const REMOVE_TEMP_PROFILE_PHOTO_SUCCESS = 'REMOVE_TEMP_PROFILE_PHOTO_SUCCESS'
export const RESET_PROFILE_DATA = 'PROFILE/RESET'
export const SET_PROFILE_DATA = 'PROFILE/SET'
export const SET_STAFF_ROLE = 'SET_STAFF_ROLE'
export const SET_DAILY_ROOM = 'PROFILE/DAILY_ROOM/SET'
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_ZIP_CODE = 'UPLOAD_ZIP_CODE'
export const UPLOAD_PROFILE_PHOTO_ERROR = 'UPLOAD_PROFILE_PHOTO_ERROR'
export const UPLOAD_PROFILE_PHOTO_START = 'UPLOAD_PROFILE_PHOTO_START'
export const UPLOAD_PROFILE_PHOTO_SUCCESS = 'UPLOAD_PROFILE_PHOTO_SUCCESS'
export const UPLOAD_TEMP_PROFILE_PHOTO_ERROR = 'UPLOAD_TEMP_PROFILE_PHOTO_ERROR'
export const UPLOAD_TEMP_PROFILE_PHOTO_START = 'UPLOAD_TEMP_PROFILE_PHOTO_START'
export const UPLOAD_TEMP_PROFILE_PHOTO_SUCCESS = 'UPLOAD_TEMP_PROFILE_PHOTO_SUCCESS'

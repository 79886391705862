import { createAction } from 'redux-actions'

import { deleteRoom, getDailyCoToken, getRoom } from 'js/APIs/axiosDailyCo'
import {
    checkIsCallRoomExist,
    createGroupCallRoom,
    joinCall,
    removeCall,
    removeGroupCallRoom,
    saveGroupCallRoomId,
    setGroupCallFinished,
    signOnMeeting,
    startGroupMeetingFirebase,
    unJoinCall,
} from 'js/APIs/firebase/groupCalls.firebase'
import { setVideoCallReconnect } from 'js/APIs/firebase/peerToPeer.firebase'
import logger from 'js/utils/logger'
import { CALL_TYPE } from 'js/var/const'

import { showResourceBookmark } from '../resources/resources.actions'
import { closeVideoPopup, openVideoPopup } from '../videoPopup/videoPopup.actions'
import {
    FINISH_CREATED_MEETING,
    SET_GROUP_CALL_ROOM_ID,
    SET_JOINED_CALL,
    SET_JOINING_ERROR,
    START_CREATED_MEETING,
    UNSUBSCRIBE_MEETING,
} from './groupCalls.types'
import { setCallType, setOwner, toggleFeedbackPopup } from '../callFeedback/callFeedback.actions'
import { setError } from '../error/error.actions'

export const setGroupCallRoomId = createAction(SET_GROUP_CALL_ROOM_ID)

export const createGroupCall = () => async (dispatch) => {
    try {
        const roomId = await createGroupCallRoom()

        await saveGroupCallRoomId(roomId)
        dispatch(setGroupCallRoomId(roomId))
    } catch (e) {
        logger(`Error of group call room creation: ${e}`)
    }
}

export const setJoiningCallError = createAction(SET_JOINING_ERROR)

export const setJoinedCall = createAction(SET_JOINED_CALL)

export const signOnCall = (id) => async (dispatch) => {
    const isExist = await checkIsCallRoomExist(id)

    if (!isExist) {
        await removeCall(id)
        return
    }

    const cb = (snapshot) => {
        dispatch(setJoinedCall({ id, data: snapshot.val() }))
    }
    return signOnMeeting(id, cb)
}

export const signOnCalls = (calls) => async (dispatch) => {
    const callsPromises = []

    calls.forEach((callId) => {
        callsPromises.push(dispatch(signOnCall(callId)))
    })

    await Promise.allSettled(callsPromises)
}

export const startCreatedMeeting = createAction(START_CREATED_MEETING)

export const startMeeting =
    ({ dailyRoom, id, isOwner, userName }) =>
    async (dispatch) => {
        try {
            let roomData
            if (!dailyRoom) {
                const { data } = await getRoom()

                roomData = {
                    name: data.name,
                    url: data.url,
                }
            } else {
                roomData = {
                    name: dailyRoom,
                    url: process.env.REACT_APP_DAILY_URL + dailyRoom,
                }
            }

            await startGroupMeetingFirebase({
                id,
                roomName: roomData.name,
                url: roomData.url,
                startedCallDailyCoName: roomData.name,
            })
            await dispatch(startCreatedMeeting({ url: roomData.url, name: roomData.name }))

            const {
                data: { token },
            } = await getDailyCoToken({ roomName: roomData.name, isOwner, userName })

            roomData.url += `?t=${token}`

            await setVideoCallReconnect({
                callType: CALL_TYPE.groupCall,
                url: roomData.url,
                isOwner: true,
                id,
                roomName: roomData.name,
            })

            return dispatch(openVideoPopup({ callType: CALL_TYPE.groupCall, url: roomData.url, isOwner: true }))
        } catch (e) {
            logger(`Error of starting group meeting: ${e}`)
        }
    }

export const finishCreatedMeeting = createAction(FINISH_CREATED_MEETING)

export const finishGroupCall =
    ({ callId, roomName, isOwner, isSupporterRoom }) =>
    async (dispatch) => {
        dispatch(toggleFeedbackPopup())
        await setVideoCallReconnect(null)
        dispatch(closeVideoPopup())
        if (isOwner) {
            await setGroupCallFinished(callId)
            await saveGroupCallRoomId(null)
            dispatch(finishCreatedMeeting())

            setTimeout(async () => {
                if (!isSupporterRoom) {
                    await deleteRoom(roomName)
                }
                await removeGroupCallRoom(callId)
            }, 5000)
        }
    }

export const joinMeeting =
    ({ id }) =>
    async (dispatch) => {
        try {
            const { isExist, data } = await checkIsCallRoomExist(id)
            if (!isExist) {
                return dispatch(
                    setError({ title: 'Error of joining', message: `Session with this id doesn't exist or was ended` }),
                )
            }

            if (data?.isStarted) {
                await setVideoCallReconnect({ callType: CALL_TYPE.groupCall, url: data.roomUrl })
                dispatch(setCallType(CALL_TYPE.groupCall))
                dispatch(setOwner(data.creator))
                return dispatch(openVideoPopup({ callType: CALL_TYPE.groupCall, url: data.roomUrl }))
            }

            await joinCall(id)
            await dispatch(signOnCall(id))
            dispatch(showResourceBookmark({ message: 'You joined to meeting' }))
        } catch (e) {
            logger(`Error of joining to meeting: ${e}`)
        }
    }

export const unfolowCalls = createAction(UNSUBSCRIBE_MEETING)

export const unjoinCalls = (calls) => async (dispatch) => {
    const callsPromises = []

    calls.forEach(({ id }) => {
        callsPromises.push(unJoinCall(id))
    })

    dispatch(unfolowCalls(calls))
    await Promise.allSettled(callsPromises)
}

export const reconnectToGroupCall = (data) => (dispatch) => {
    dispatch(startCreatedMeeting({ url: data.url, name: data.roomName, id: data.id }))
    dispatch(openVideoPopup({ callType: data.callType, url: data.url, isOwner: !!data.isOwner }))
}

import moment from 'moment'
import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { CALL_TYPE } from 'js/var/const'
import {
    createChatAxios,
    getChatsAxios,
    getChatMessagesAxios,
    getChatAxios,
    exportChatToEmailAxios,
} from 'js/APIs/axiosApi'
import {
    removeChatLink,
    postMessageToChatFirebase,
    signOnUserChats,
    subscribeChat,
} from 'js/APIs/firebase/chat.firebase'
import { getTimestamp, getUid, getEmail, updateDataFirebase, getDataFirebase } from 'js/APIs/firebase/firebase'

import {
    CLEAR_CHAT_ID,
    CREATE_CHAT_START,
    CREATE_CHAT_ERROR,
    CREATE_CHAT_SUCCESS,
    CREATE_MESSAGE_START,
    CREATE_MESSAGE_ERROR,
    GET_CHATS_START,
    GET_CHATS_ERROR,
    GET_CHATS_SUCCESS,
    GET_CHAT_MESSAGES_START,
    GET_CHAT_MESSAGES_ERROR,
    GET_CHAT_MESSAGES_SUCCESS,
    ON_RECEIVED_MESSAGE,
    CHAT_SUBSCRIBED,
    CLEAR_NEW_MSG,
    SUBS_TO_USER_CHATS,
    SUBS_TO_USER_CHATS_ERROR,
    SUBS_TO_USER_CHATS_SUCCESS,
    GET_CHAT_START,
    GET_CHAT_ERROR,
    GET_CHAT_SUCCESS,
    GET_USER_DATA_IN_CHAT_SUCCESS,
    SET_EXPORT_CHAT_DATE,
} from './chat.types'
import { createNotification } from '../notifications/notifications.actions'
import { getConnectionRequest } from '../staff/staff.actions'
import { setIsLoading } from '../navigation/navigation.actions'
import { showResourceBookmark } from '../resources/resources.actions'
import { setError } from '../error/error.actions'

const createChatStart = createAction(CREATE_CHAT_START)
const createChatError = createAction(CREATE_CHAT_ERROR)
export const createChatSuccess = createAction(CREATE_CHAT_SUCCESS)

export const createChat = (data) => async (dispatch) => {
    dispatch(createChatStart())

    try {
        const res = await createChatAxios(data)
        if (res) {
            dispatch(createChatSuccess())

            return res.chatId
        }
    } catch (e) {
        dispatch(createChatError(e))
    }
}

export const onReceivedMessageSuccess = createAction(ON_RECEIVED_MESSAGE)
export const chatSubscribedSuccess = createAction(CHAT_SUBSCRIBED)

export const createOnReceiveMessage =
    ({ chatId, dispatch }) =>
    (message) =>
        dispatch(onReceivedMessageSuccess({ message, chatId }))

export const createOnReceiveConnectionRequest =
    ({ chatId, dispatch }) =>
    (connectionsRequest) =>
        dispatch(getConnectionRequest({ connectionsRequest, chatId }))

export const subsToChat = (chatId) => async (dispatch) => {
    try {
        await subscribeChat(
            chatId,
            createOnReceiveMessage({ chatId, dispatch }),
            createOnReceiveConnectionRequest({ chatId, dispatch }),
        )
        dispatch(chatSubscribedSuccess(chatId))
    } catch (e) {
        logger(`Error of subscribing on chat: ${e}`)
    }
}

export const subsToEachChats = (chats) => async (dispatch) => {
    if (!chats?.length) {
        return
    }
    await Promise.all(
        chats.map((chat) => {
            const { id } = chat

            if (!id) {
                return null
            }

            return dispatch(subsToChat(id))
        }),
    )
}

export const clearChatIdSuccess = createAction(CLEAR_CHAT_ID)

export const clearChatId = () => (dispatch) => dispatch(clearChatIdSuccess())

const getChatMessagesStart = createAction(GET_CHAT_MESSAGES_START)
const getChatMessagesError = createAction(GET_CHAT_MESSAGES_ERROR)
export const getChatMessagesSuccess = createAction(GET_CHAT_MESSAGES_SUCCESS)

export const getChatMessages = (data) => async (dispatch) => {
    dispatch(getChatMessagesStart())
    try {
        const res = await getChatMessagesAxios(data)

        if (res && res.chats) {
            dispatch(getChatMessagesSuccess(res))

            const { chats } = res

            dispatch(subsToEachChats(chats))
        }
    } catch (e) {
        dispatch(getChatMessagesError(e))
    }
}

export const createMessageStart = createAction(CREATE_MESSAGE_START)
const createMessageError = createAction(CREATE_MESSAGE_ERROR)

export const createMessage = (data) => async (dispatch) => {
    dispatch(
        createMessageStart({
            chatId: data.chatId,
            message: { data: { ...data, createdAt: new Date(), isLoading: true } },
        }),
    )
    try {
        await postMessageToChatFirebase({
            chatId: data.chatId,
            message: { ...data, chatId: null, createdAt: getTimestamp() },
        })
    } catch (e) {
        dispatch(createMessageError(e))
    }
}

export const clearNewMessageSuccess = createAction(CLEAR_NEW_MSG)

export const clearNewMessage = () => (dispatch) => dispatch(clearNewMessageSuccess())

export const getUserDataSuccess = createAction(GET_USER_DATA_IN_CHAT_SUCCESS)

export const getUserData = (uid) => async (dispatch) => {
    try {
        const res = await getUserData(uid)
        if (res) {
            dispatch(getUserDataSuccess(res))
        }
    } catch (e) {
        logger('Error of user data getting: ', e)
    }
}
const getChatStart = createAction(GET_CHAT_START)
const getChatError = createAction(GET_CHAT_ERROR)
export const getChatSuccess = createAction(GET_CHAT_SUCCESS)

const getChat =
    ({ id, uid }) =>
    async (dispatch) => {
        dispatch(getChatStart())
        try {
            const res = await getChatAxios({ id, uid })

            if (res?.chat) {
                dispatch(getChatSuccess(res.chat))

                return dispatch(subsToChat(res.chat.id))
            }
        } catch (error) {
            if (error.response.status === 404) {
                await removeChatLink({ id, uid })
            }
            logger(`Error of getting chat: ${error}`)
            dispatch(getChatError(error))
        }
    }

const subsToUserChatsStart = createAction(SUBS_TO_USER_CHATS)
const subsToUserChatsError = createAction(SUBS_TO_USER_CHATS_ERROR)
const subsToUserChatsSuccess = createAction(SUBS_TO_USER_CHATS_SUCCESS)

export const subsToUserChats =
    ({ initChatIds, uid }) =>
    (dispatch) => {
        dispatch(subsToUserChatsStart())
        try {
            const chatIds = [...initChatIds]

            signOnUserChats(async (snapshot) => {
                const id = snapshot.key
                if (chatIds.includes(id)) {
                    return
                }
                chatIds.push(id)
                dispatch(getChat({ id, uid }))
            })
            dispatch(subsToUserChatsSuccess())
        } catch (e) {
            logger(`Error of subscribing on user chats: ${e}`)
            dispatch(subsToUserChatsError(e))
        }
    }

const getChatsStart = createAction(GET_CHATS_START)
const getChatsError = createAction(GET_CHATS_ERROR)
export const getChatsSuccess = createAction(GET_CHATS_SUCCESS)

export const getChats = (uid) => async (dispatch) => {
    dispatch(getChatsStart())
    try {
        const res = await getChatsAxios({ uid })

        if (res?.chats) {
            dispatch(getChatsSuccess(res))
            dispatch(subsToEachChats(res.chats))
            dispatch(subsToUserChats({ initChatIds: res.chats.map(({ id }) => id), uid }))
        }
    } catch (e) {
        dispatch(getChatsError(e))
    }
}

const sendMessage =
    ({
        chatId,
        msg,
        recipients,
        full_name,
        profile_picture,
        isInvitationSending,
        groupCallRoomId,
        invitationType,
        scheduledCallRoomId,
    }) =>
    async (dispatch) => {
        const uid = await getUid()

        const message = { uid, msg, chatId }

        if (isInvitationSending) {
            message.isInvitation = 1
            message.invitationType = invitationType

            if (invitationType === CALL_TYPE.groupCall) {
                message.groupCallRoomId = groupCallRoomId
            }

            if (invitationType === CALL_TYPE.scheduledMeeting) {
                message.scheduledCallRoomId = scheduledCallRoomId
            }
        }

        const notificationData = {
            uid,
            msg: msg.replace(/<[^>]+(>|$)/g, ''),
            chatId,
            userData: {
                uid,
                full_name,
                profile_picture,
            },
        }

        const results = await Promise.allSettled(
            recipients.map((user) => createNotification({ uid: user, data: notificationData })),
        )
        const resultsValues = results.map(({ value }) => value)

        const cloudTaskIds = resultsValues.filter((id) => id)

        if (cloudTaskIds.length) {
            message.cloudTaskIds = cloudTaskIds.reduce((acc, curr) => {
                acc[curr] = true

                return acc
            }, {})
        }

        await dispatch(createMessage(message))
    }

export const sendMessageToChat =
    ({
        msg,
        chatId: id,
        recipients,
        full_name,
        profile_picture,
        isInvitationSending,
        groupCallRoomId,
        invitationType,
        scheduledCallRoomId,
    }) =>
    async (dispatch) => {
        try {
            const uid = await getUid()
            const chatId = id || (await createChatAxios({ users: [recipients, uid] })?.data?.chatId)

            if (!chatId) {
                return null
            }

            await dispatch(
                sendMessage({
                    msg,
                    chatId,
                    recipients,
                    full_name,
                    profile_picture,
                    isInvitationSending,
                    groupCallRoomId,
                    scheduledCallRoomId,
                    invitationType,
                }),
            )
        } catch (error) {
            logger('Error of sending message:', error)
        }
    }

export const setExportChatDate = createAction(SET_EXPORT_CHAT_DATE)

export const exportChatToEmail =
    ({ chatId, start, end }) =>
    async (dispatch) => {
        try {
            const to = getEmail()

            if (!to || !chatId) {
                return
            }

            const data = { to, chatId }

            if (start && end) {
                data.start = start
                data.end = end
            }
            dispatch(setIsLoading(true))
            const res = await exportChatToEmailAxios(data)

            if (res.status === 200) {
                const uid = getUid()

                await updateDataFirebase({ path: `users/${uid}`, data: { exportChatDate: getTimestamp() } })
                dispatch(showResourceBookmark({ message: 'Chat exported' }))
                dispatch(setExportChatDate(moment().valueOf()))
            }

            dispatch(setIsLoading(false))
        } catch (error) {
            logger(`Error of chat exporting: `, error)
            dispatch(
                setError({
                    title: 'Error of chat export',
                    message: 'Something went wrong during chat exporting process. Please try again',
                }),
            )
        }
    }

export const initExportChatDate = () => async (dispatch) => {
    const uid = getUid()
    const exportChatDate = await getDataFirebase({ path: `users/${uid}/exportChatDate` })

    if (exportChatDate) {
        dispatch(setExportChatDate(exportChatDate))
    }
}

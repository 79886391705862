import React from 'react'
import { func } from 'prop-types'

const IconStepAdd = ({ onClick }) => (
    <svg onClick={onClick} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16C0 7.168 7.168 0 16 0C24.832 0 32 7.168 32 16C32 24.832 24.832 32 16 32C7.168 32 0 24.832 0 16ZM17.6 17.6H22.4C23.28 17.6 24 16.88 24 16C24 15.12 23.28 14.4 22.4 14.4H17.6V9.6C17.6 8.72 16.88 8 16 8C15.12 8 14.4 8.72 14.4 9.6V14.4H9.6C8.72 14.4 8 15.12 8 16C8 16.88 8.72 17.6 9.6 17.6H14.4V22.4C14.4 23.28 15.12 24 16 24C16.88 24 17.6 23.28 17.6 22.4V17.6Z"
            fill="#00808D"
        />
    </svg>
)

IconStepAdd.propTypes = {
    onClick: func,
}

IconStepAdd.defaultProps = {
    onClick: null,
}

export default IconStepAdd

import { handleActions } from 'redux-actions'

import { countQuizSum } from 'js/utils/counters'

import { completePHQ9, initPHQ9, startPHQ9, setPHQ9Slide, togglePHQ9, setPHQ9Answer } from './phq9.actions'

const defaultState = {
    isPHQ9Open: false,
    isPHQ9Started: false,
    isPHQ9Completed: false,
    currentSlide: 0,
    answers: {},
}

const reducer = handleActions(
    {
        [completePHQ9]: (state) => ({ ...state, isPHQ9Completed: true }),
        [togglePHQ9]: (state) => ({
            ...state,
            isPHQ9Open: !state.isPHQ9Open,
        }),
        [initPHQ9]: (state, { payload }) => ({ ...state, ...payload }),
        [startPHQ9]: (state) => ({
            ...state,
            isPHQ9Started: !state.isPHQ9Started,
        }),
        [setPHQ9Answer]: (state, { payload: { key, data } }) => {
            const answers = { ...state.answers, [key]: data }

            return {
                ...state,
                answers,
                sum: countQuizSum(answers),
            }
        },
        [setPHQ9Slide]: (state, { payload }) => ({
            ...state,
            currentSlide: payload,
        }),
    },
    defaultState,
)

export default reducer

import classNames from 'classnames'
import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

import Input from 'js/components/Input'
import useScreenSize from 'js/hooks/useScreenSize'
import { zipCodeSchema } from 'js/var/validationSchemas'

const useStyles = makeStyles(({ fonts, palette }) => ({
    EditZipCode: {
        backgroundColor: palette.white,
        marginBottom: '8px',
        padding: '32px 16px',

        '&.isTablet, &.isDesktop': {
            marginBottom: 0,
            padding: '16px 0',
        },
    },
    error: {
        ...fonts.s16l208,
        color: palette.errorRed,
        marginTop: '4px',

        '&.isTablet, &.isDesktop': {
            ...fonts.b3.dskt,
        },
    },
    input: {
        width: '100%',
    },
    title: {
        ...fonts.s20l26,
        marginBottom: '4px',
    },
}))

const EditZipCode = ({ setZipCode, zipCode }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()
    const [error, setError] = useState('')

    const onChange = ({ target: { value } }) => {
        setZipCode(value)
        setError('')
        zipCodeSchema.validate(value).catch(({ errors }) => setError(errors[0]))
    }

    return (
        <Box className={classNames(classes.EditZipCode, screenSize)}>
            <p className={classes.title}> Edit Zip/ Postal Code</p>
            <Input className={classes.input} name="zipCode" onChange={onChange} type="number" value={zipCode} />
            {error && <p className={classNames(classes.error, screenSize)}>{error}</p>}
        </Box>
    )
}

EditZipCode.propTypes = {
    setZipCode: func.isRequired,
    zipCode: string.isRequired,
}

export default EditZipCode

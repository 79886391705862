import { createClient } from 'contentful'
import logger from 'js/utils/logger'
import { CONTENTFUL_TYPES, PEER_SUPPORTER_TITLES, RESOURCES_TYPES } from 'js/var/const'

const {
    REACT_APP_CONTENTFUL_SPACE_ID,
    REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    REACT_APP_CONTENTFUL_SPACE_ID_FAQ,
    REACT_APP_CONTENTFUL_ACCESS_TOKEN_FAQ,
    REACT_APP_STAFF_LIST_ID,
} = process.env

const contentfulClient = createClient({
    space: REACT_APP_CONTENTFUL_SPACE_ID,
    environment: 'master',
    accessToken: REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    resolveLinks: true,
})

const contentfulFAQClient = createClient({
    space: REACT_APP_CONTENTFUL_SPACE_ID_FAQ,
    environment: 'master',
    accessToken: REACT_APP_CONTENTFUL_ACCESS_TOKEN_FAQ,
    resolveLinks: true,
})

const parseResources = (resources) =>
    resources.map(({ fields: { content, date, headerImage, slug, staff = [], title }, sys: { createdAt } }) => ({
        createdAt,
        date,
        id: slug,
        imageUrl: headerImage?.fields?.file?.url || '',
        staff: staff.map(({ sys: { id } }) => id),
        text: content,
        title,
        type: RESOURCES_TYPES.article,
    }))

const parseVideoResources = (resources) =>
    resources.map(
        ({ fields: { content, date, headerImage, slug, staff = [], title, videoUrl }, sys: { createdAt } }) => ({
            createdAt,
            date,
            id: slug,
            imageUrl: headerImage?.fields?.file?.url || '',
            text: content,
            title,
            type: RESOURCES_TYPES.video,
            staff: staff.map(({ sys: { id } }) => id),
            videoUrl,
        }),
    )

const parseFaqs = (faqs) =>
    faqs.map(({ fields: { answer, importance, question }, sys: { createdAt, id } }) => ({
        answer,
        createdAt,
        id,
        importance,
        question,
    }))

export const getFeaturedResourceId = async () => {
    const contentfulRef = await contentfulClient.getEntries({ content_type: CONTENTFUL_TYPES.featuredResourceId })

    return contentfulRef?.items[0]?.fields?.resource?.fields?.slug
}

export const getResources = async () => {
    const articleResources = parseResources(
        (
            await contentfulClient.getEntries({
                content_type: CONTENTFUL_TYPES.resource,
            })
        ).items,
    )

    const videoResources = parseVideoResources(
        (
            await contentfulClient.getEntries({
                content_type: CONTENTFUL_TYPES.videoResource,
            })
        ).items,
    )

    return [...articleResources, ...videoResources]
}

export const getServices = async () => {
    const { items } = await contentfulClient.getEntries({
        content_type: CONTENTFUL_TYPES.service,
    })

    return items.map(({ fields }) => fields)
}

export const getFAQ = async () =>
    parseFaqs(
        (
            await contentfulFAQClient.getEntries({
                content_type: CONTENTFUL_TYPES.faq,
            })
        ).items,
    )

const staffToProfile = ({
    fields: {
        firebaseUid = '',
        image: { fields: { file } } = { fields: { file: { url: '' } } },
        interests,
        name,
        shortBio,
        pronouns,
        servicesOffered = [],
        specialties = [],
        title,
    },
    sys: { id: contentfulStaffId },
}) => {
    const roles = PEER_SUPPORTER_TITLES[title] ? { isSupporter: true } : { isCounselor: true }

    return {
        about: shortBio,
        contentfulStaffId,
        firebaseUid,
        full_name: name,
        profile_picture: file.url,
        roles,
        role: title,
        interests,
        pronouns,
        servicesOffered: servicesOffered.map(({ fields: { slug } }) => slug),
        specialties: specialties.map(({ fields: { name, slug }, sys: { id } }) => ({ id, name, slug })),
    }
}

export const getStaffProfile = async (id, callback) => {
    try {
        const res = await contentfulClient.getEntry(id)

        const profile = staffToProfile(res)

        if (callback) {
            callback(profile)
        }

        return profile
    } catch (e) {
        logger('Error of getting Staff content data from Contentful: ', e)
    }
}

const checkTypeIsEntry = (entry) => {
    return entry?.sys?.type === 'Entry'
}

export const getStaff = async () => {
    try {
        const filterRes = await contentfulClient.getEntries({
            'sys.id': REACT_APP_STAFF_LIST_ID,
            include: 2,
        })

        const staff = filterRes?.items[0]?.fields?.list

        if (!staff?.length) {
            throw Error('Error of staff loading')
        }

        return staff.filter(checkTypeIsEntry).map((profile) => staffToProfile(profile))
    } catch (error) {
        logger('Error of staff loading: ', error)
    }
}

export const getStaffByEmail = async (email) => {
    const res = await contentfulClient.getEntries({
        content_type: CONTENTFUL_TYPES.staff,
        'fields.email[match]': email,
        include: 2,
    })

    if (res?.items?.length !== 1) {
        return null
    }

    return staffToProfile(res?.items[0])
}

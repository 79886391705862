import React, { useRef, useEffect } from 'react'
import { any, func } from 'prop-types'

const useOutsideAlerter = ({ callback, ref }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (callback) {
                    callback()
                }
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [callback, ref])
}

const OutsideClick = ({ callback, children }) => {
    const wrapperRef = useRef(null)
    useOutsideAlerter({ ref: wrapperRef, callback })

    return <span ref={wrapperRef}>{children}</span>
}

OutsideClick.propTypes = {
    callback: func.isRequired,
    children: any.isRequired,
}

export default OutsideClick

import React from 'react'
import { makeStyles, Modal } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { selectPHQ2IsOpen } from 'js/redux/phq2/phq2.selectors'

import PHQ2Slider from './PHQ2Slider'

const useStyles = makeStyles(({ others, palette }) => ({
    PHQ2: {
        ...others.scroll,
        backgroundColor: palette.white,
        height: '100vh',
        minHeight: '750px',
        outline: 'none',
        width: '100%',
        overflowX: 'hidden',
    },
    modal: {
        display: 'block',
        position: 'absolute',
        left: '10%',
        overflow: 'scroll',
        overflowX: 'hidden',
        height: '100%',
        top: '10%',
    },
}))

const PHQ2 = () => {
    const classes = useStyles()
    const isPHQ2Open = useSelector(selectPHQ2IsOpen)

    return (
        <Modal
            className={classes.modal}
            open={isPHQ2Open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.PHQ2}>
                <PHQ2Slider />
            </div>
        </Modal>
    )
}

export default PHQ2

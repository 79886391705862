import React from 'react'
import { bool } from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useSelector, useDispatch } from 'react-redux'

import ConfirmationPopup from 'js/components/Reusable/ConfirmationPopup'
import {
    createSelectScheduleMeetingById,
    selectIncomingMeetingId,
} from 'js/redux/scheduledMeetings/scheduledMeetings.selectors'
import {
    editScheduledMeetingSuccess,
    joinScheduleMeetingVideo,
    setIncomingMeetingAction,
} from 'js/redux/scheduledMeetings/scheduledMeetings.actions'
import { setIsLoading } from 'js/redux/navigation/navigation.actions'
import { setOwner } from 'js/redux/callFeedback/callFeedback.actions'
import { selectUid } from 'js/redux/profile/user'
import { selectProfile } from 'js/redux/profile/profile.selectors'

const IncomingScheduleMeetingPopup = ({ isOpen }) => {
    const dispatch = useDispatch()
    const isDesktop = !useMediaQuery({ query: `(max-width: 650px)` })
    const uid = useSelector(selectUid)
    const { full_name } = useSelector(selectProfile)

    const meetingId = useSelector(selectIncomingMeetingId)
    const meeting = useSelector(createSelectScheduleMeetingById(meetingId))

    const isOwner = meeting?.creator === uid

    const handleClose = async () => {
        dispatch(setIsLoading(true))
        dispatch(setIncomingMeetingAction(''))
        dispatch(setIsLoading(false))
    }

    const handleStart = async () => {
        const { roomName, url } = meeting
        dispatch(setIsLoading(true))
        dispatch(setIncomingMeetingAction(''))
        dispatch(setOwner(meeting?.creator))
        dispatch(editScheduledMeetingSuccess({ id: meetingId, meetingData: { isStarted: false } }))
        await dispatch(joinScheduleMeetingVideo({ id: meetingId, isOwner: false, roomName, url, userName: full_name }))
    }

    return (
        <ConfirmationPopup
            acceptTitle="Start"
            cancelTitle="Decline"
            isDesktop={isDesktop}
            isOpen={isOpen && meeting?.isStarted}
            onAccept={handleStart}
            onCancel={handleClose}
            onClose={isOwner && handleClose}
            text={`A meeting you joined${` ${meeting?.name}`} has started.`}
            title="Join Meeting?"
        />
    )
}

IncomingScheduleMeetingPopup.propTypes = {
    isOpen: bool.isRequired,
}

export default IncomingScheduleMeetingPopup

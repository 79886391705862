import classNames from 'classnames'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { bool, element, func, instanceOf, number, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'

import ChevronLeft from 'media/chevronLeft.svg'
import ChevronRight from 'media/chevronRight.svg'

const useStyles = makeStyles(({ fonts, palette }) => ({
    date: {
        '& .react-datepicker': {
            borderRadius: '12px',
            boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
            height: '235px',
            width: '285px',

            '& .react-datepicker__triangle': {
                display: 'none',
            },

            '& .react-datepicker__navigation--previous': {
                left: 'auto',
                right: '57px',
                top: '26px',
                backgroundImage: `url(${ChevronLeft})`,
                backgroundRepeat: 'no-repeat',
                width: '6px',
                height: '11px',
                border: 'none',
                '-webkit-transform': 'none',
            },

            '& .react-datepicker__navigation--next': {
                right: '28px',
                top: '26px',
                backgroundImage: `url(${ChevronRight})`,
                backgroundRepeat: 'no-repeat',
                width: '6px',
                height: '11px',
                border: 'none',
                '-webkit-transform': 'none',
            },

            '& .react-datepicker__month-container': {
                height: '210px',
                width: '285px',
                backgroundColor: palette.white,
                boxShadow: 'none',
                borderRadius: '12px',

                '& .react-datepicker__header': {
                    height: '71px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '20px',
                    width: '285px',

                    '& .react-datepicker__current-month': {
                        ...fonts.h4.mob,
                        color: palette.black,
                        textAlign: 'start',
                    },

                    '& .react-datepicker__day-names': {
                        height: '24px',
                        marginBottom: 0,
                        marginTop: '8px',
                        padding: 0,

                        '& .react-datepicker__day-name': {
                            ...fonts.b1.mob,
                            alignItems: 'center',
                            color: palette.secondaryBlue,
                            display: 'flex',
                            height: '24px',
                            justifyContent: 'center',
                            width: '22px',
                        },
                    },
                },

                '& .react-datepicker__month': {
                    height: 'fit-content',
                    backgroundColor: palette.white,
                    paddingBottom: '20px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: 0,

                    '& .react-datepicker__week': {
                        height: '24px',
                        marginBottom: 0,
                        padding: 0,

                        '& .react-datepicker__day': {
                            ...fonts.b1.mob,
                            alignItems: 'center',
                            color: palette.black,
                            display: 'flex',
                            height: '24px',
                            justifyContent: 'center',
                            width: '22px',
                        },

                        '& .react-datepicker__day--outside-month': {
                            color: palette.secondaryBlue,
                        },

                        '& .react-datepicker__day--selected': {
                            backgroundColor: palette.primPurple,
                            borderRadius: '3px',
                            color: palette.marshmallow,
                        },
                    },
                },
            },
        },
    },
    time: {
        '& .react-datepicker': {
            backgroundColor: palette.white,
            boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
            paddingTop: '20px',
            paddingBottom: '20px',
            height: '256px',
            width: '125px',
            overflow: 'hidden',

            '& .react-datepicker__time-container': {
                width: '125px',

                '& .react-datepicker-time__header, .react-datepicker__header--time, .react-datepicker__header--time': {
                    display: 'none',
                },

                '& .react-datepicker__time': {
                    height: '216px',
                    width: '125px',

                    '& .react-datepicker__time-box ': {
                        height: '216px',
                        width: '125px',

                        '& .react-datepicker__time-list': {
                            boxSizing: 'border-box',
                            height: '216px',
                            paddingLeft: '13px',
                            paddingRight: '10px',
                            width: '125px',

                            '&::-webkit-scrollbar': {
                                border: 'none',
                                width: '8px',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: palette.primPurple,
                                borderRadius: '4px',
                            },

                            '&::-webkit-scrollbar-track': {
                                border: 'none',
                            },

                            '& li.react-datepicker__time-list-item': {
                                ...fonts.b2.dskt,
                                borderRadius: '4px',
                                height: '24px',
                                marginBottom: '24px',
                                padding: 0,
                                width: '90px',
                            },

                            '& li.react-datepicker__time-list-item--selected': {
                                backgroundColor: palette.primPurple,
                            },
                        },
                    },
                },
            },
        },
    },
    timeAndData: {
        '& .react-datepicker-wrapper': {
            height: '65px',
            display: 'block',

            borderBottom: `1px solid ${palette.softBlue}`,
        },

        '& .react-datepicker': {
            backgroundColor: palette.white,
            display: 'flex',
            borderRadius: '12px',
            boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
            height: '235px',
            width: '420px',

            '& .react-datepicker__time-container': {
                borderLeftColor: palette.offWhite,
                backgroundColor: palette.white,
                borderRadius: '0 12px 12px 0',
                paddingBottom: '10px',
                paddingTop: '10px',
                height: '235px',
                overflow: 'hidden',
                width: '125px',

                '& .react-datepicker-time__header, .react-datepicker__header--time, .react-datepicker__header--time': {
                    display: 'none',
                },

                '& .react-datepicker__time': {
                    height: '216px',
                    width: '125px',

                    '& .react-datepicker__time-box ': {
                        height: '216px',
                        width: '125px',

                        '& .react-datepicker__time-list': {
                            boxSizing: 'border-box',
                            height: '216px',
                            paddingLeft: '13px',
                            paddingRight: '10px',
                            width: '125px',

                            '&::-webkit-scrollbar': {
                                border: 'none',
                                width: '8px',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: palette.primPurple,
                                borderRadius: '4px',
                            },

                            '&::-webkit-scrollbar-track': {
                                border: 'none',
                            },

                            '& li.react-datepicker__time-list-item': {
                                ...fonts.b2.dskt,
                                borderRadius: '4px',
                                height: '24px',
                                marginBottom: '24px',
                                padding: 0,
                                width: '90px',
                            },

                            '& li.react-datepicker__time-list-item--selected': {
                                backgroundColor: palette.primPurple,
                            },
                        },
                    },
                },
            },

            '& .react-datepicker__triangle': {
                display: 'none',
            },

            '& .react-datepicker__navigation--previous': {
                left: 'auto',
                right: '182px',
                top: '26px',
                backgroundImage: `url(${ChevronLeft})`,
                backgroundRepeat: 'no-repeat',
                width: '6px',
                height: '11px',
                border: 'none',
                '-webkit-transform': 'none',

                '& svg > path': {
                    fill: palette.fuchsiaBlue,
                },
            },

            '& .react-datepicker__navigation--next': {
                right: '153px',
                top: '26px',
                backgroundImage: `url(${ChevronRight})`,
                backgroundRepeat: 'no-repeat',
                width: '6px',
                height: '11px',
                border: 'none',
                '-webkit-transform': 'none',

                '& svg > path': {
                    fill: palette.fuchsiaBlue,
                },
            },

            '& .react-datepicker__month-container': {
                boxShadow: 'none',
                height: '210px',
                width: '285px',
                backgroundColor: palette.white,
                borderRadius: '12px',

                '& .react-datepicker__header': {
                    height: '71px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '20px',
                    width: '285px',

                    '& .react-datepicker__current-month': {
                        ...fonts.h4.mob,
                        color: palette.black,
                        textAlign: 'start',
                    },

                    '& .react-datepicker__day-names': {
                        height: '24px',
                        marginBottom: 0,
                        marginTop: '8px',
                        padding: 0,

                        '& .react-datepicker__day-name': {
                            ...fonts.b1.mob,
                            alignItems: 'center',
                            color: palette.secondaryBlue,
                            display: 'flex',
                            height: '24px',
                            justifyContent: 'center',
                            width: '22px',
                        },
                    },
                },

                '& .react-datepicker__month': {
                    height: 'fit-content',
                    backgroundColor: palette.white,
                    paddingBottom: '20px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: 0,

                    '& .react-datepicker__week': {
                        height: '24px',
                        marginBottom: 0,
                        padding: 0,

                        '& .react-datepicker__day': {
                            ...fonts.b1.mob,
                            alignItems: 'center',
                            color: palette.black,
                            display: 'flex',
                            height: '24px',
                            justifyContent: 'center',
                            width: '22px',
                        },

                        '& .react-datepicker__day--outside-month': {
                            color: palette.secondaryBlue,
                        },

                        '& .react-datepicker__day--selected': {
                            backgroundColor: palette.primPurple,
                            borderRadius: '3px',
                            color: palette.marshmallow,
                        },
                    },
                },
            },
        },
    },
}))

const DatePicker = ({
    customInput,
    dateFormat,
    filterTime,
    maxDate,
    minDate,
    onChange,
    selected,
    showTimeSelect,
    showTimeSelectOnly,
    timeIntervals,
}) => {
    const classes = useStyles()

    return (
        <span
            className={classNames({
                [classes.date]: !showTimeSelect,
                [classes.timeAndData]: showTimeSelect && !showTimeSelectOnly,
                [classes.time]: showTimeSelect && showTimeSelectOnly,
            })}
        >
            <ReactDatePicker
                customInput={customInput}
                filterTime={filterTime}
                maxDate={maxDate}
                minDate={minDate}
                onChange={onChange}
                selected={selected}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                dateFormat={dateFormat}
            />
        </span>
    )
}
DatePicker.propTypes = {
    customInput: element,
    dateFormat: string,
    filterTime: func,
    maxDate: instanceOf(Date),
    minDate: instanceOf(Date),
    onChange: func,
    selected: instanceOf(Date),
    showTimeSelect: bool,
    showTimeSelectOnly: bool,
    timeIntervals: number,
}

DatePicker.defaultProps = {
    customInput: null,
    dateFormat: 'MM/dd/yyyy',
    filterTime: null,
    maxDate: null,
    minDate: null,
    onChange: null,
    selected: null,
    showTimeSelect: false,
    showTimeSelectOnly: false,
    timeIntervals: 60,
}

export default DatePicker

import moment from 'moment'

import parseRichText from './parseRichText'

export const dateToString = (date) => {
    const m = moment(date)

    return moment(m).isSame(moment(), 'day')
        ? `Today at ${m.format('h:mm A')}`
        : `${m.format('MMM D, YYYY')} at ${m.format('h:mm A')}`
}

export const formatToLength = (str, length) => (str?.length > length ? `${str.substr(0, length)}...` : str)

export const generateBadge = (length) => (length ? `${length > 5 ? '5+' : length}` : '')

export const durationToString = (duration) => {
    const durationMoment = moment.duration(Number(duration))
    const durationHours = durationMoment.hours()
    const durationMinutes = durationMoment.minutes()
    return `${durationHours ? `${durationHours} hrs` : ''} ${durationMinutes} min`
}

export const formatAbout = (about, isOneParagraph) => {
    if (!about) {
        return 'Tell us about yourself'
    }
    if (typeof about === 'string') {
        return about
    }
    return isOneParagraph ? about?.content[0]?.content[0]?.value : parseRichText(about)
}

export const percentToHex = (p) => {
    const percent = Math.max(0, Math.min(100, p))
    const intValue = Math.round((percent / 100) * 255)
    const hexValue = intValue.toString(16)

    return hexValue.padStart(2, '0').toUpperCase()
}

import React from 'react'
import { func } from 'prop-types'

const IconLevel3 = ({ onClick }) => (
    <svg onClick={onClick} width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40.2949" cy="40.0015" r="37.5" stroke="#FFB500" strokeWidth="5" />
        <circle cx="27.1156" cy="44.043" r="5.3179" fill="#FFB500" />
        <circle cx="53.9366" cy="44.043" r="5.3179" fill="#FFB500" />
        <path
            d="M30.5178 60.5919C30.5178 60.5919 33.6871 63.2841 40.5984 63.2841C47.5098 63.2841 50.0719 60.5919 50.0719 60.5919"
            stroke="#FFB500"
            strokeWidth="5"
            strokeLinecap="round"
        />
    </svg>
)

IconLevel3.propTypes = {
    onClick: func,
}

IconLevel3.defaultProps = {
    onClick: null,
}

export default IconLevel3

import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { CALL_TYPE, STATUS } from 'js/var/const'
import {
    cleanPeerToPeerChanel,
    sendToPeerToPeer,
    setUserStatus,
    setVideoCallReconnect,
} from 'js/APIs/firebase/peerToPeer.firebase'
import { getUid } from 'js/APIs/firebase/firebase'
import { closeVideoPopup, openVideoPopup } from 'js/redux/videoPopup/videoPopup.actions'
import { getDailyCoToken } from 'js/APIs/axiosDailyCo'

import { showResourceBookmark } from '../resources/resources.actions'
import {
    ANNOUNCE_PEER_TO_PEER_SUCCESS,
    CANCEL_PEER_TO_PEER_SUCCESS,
    DECLINE_PEER_TO_PEER_SUCCESS,
    FINISH_PEER_TO_PEER_SUCCESS,
    HANDLE_PEER_TO_PEER_ACCEPT_SUCCESS,
    HANDLE_PEER_TO_PEER_ANNOUNCE,
    HANDLE_PEER_TO_PEER_CANCEL,
    HANDLE_PEER_TO_PEER_DECLINE,
    SET_RECONNECT_DATA,
} from './peerToPeer.types'
import { setCallType, setOwner, toggleFeedbackPopup } from '../callFeedback/callFeedback.actions'
import { loadUserData } from '../profile/profile.actions'

const MESSAGE_TYPE = {
    announce: 'announce',
    answer: 'answer',
    cancelAnnounce: 'cancelAnnounce',
    end: 'end',
}

const ANSWER_STATUS = {
    accept: 'accept',
    decline: 'decline',
}

export const announcePeerToPeerSuccess = createAction(ANNOUNCE_PEER_TO_PEER_SUCCESS)

export const announcePeerToPeer =
    ({ currentProfile, dailyRoom, remoteUid }) =>
    async (dispatch) => {
        try {
            const { full_name, profile_picture, status } = await loadUserData(remoteUid)

            if (!(status === STATUS.online)) {
                return dispatch(showResourceBookmark({ message: `${full_name} is offline` }))
            }
            const currentUid = await getUid()
            await setUserStatus({ status: STATUS.onCall })
            dispatch(setOwner(currentUid))
            dispatch(setCallType(CALL_TYPE.peerToPeer))

            const data = {
                full_name: currentProfile.full_name,
                profile_picture: currentProfile.profile_picture,
                roomName: dailyRoom,
                type: MESSAGE_TYPE.announce,
                uid: currentUid,
            }

            if (dailyRoom) {
                data.roomName = dailyRoom
            }

            await sendToPeerToPeer({
                uid: remoteUid,
                data,
            })
            await setUserStatus({ uid: remoteUid, status: STATUS.onCall })

            dispatch(
                announcePeerToPeerSuccess({
                    isOutgoingPopupOpen: true,
                    isDeclinePopupOpen: false,
                    remote: { full_name, profile_picture, uid: remoteUid },
                    roomName: dailyRoom,
                }),
            )
        } catch (e) {
            logger('Error of peer to peer announce', e)
        }
    }

export const cancelPeerToPeerSuccess = createAction(CANCEL_PEER_TO_PEER_SUCCESS)

export const cancelPeerToPeerAnnounce =
    ({ uid, isConnectionTimeExpired }) =>
    async (dispatch) => {
        try {
            await sendToPeerToPeer({
                uid,
                data: { uid: await getUid(), type: MESSAGE_TYPE.cancelAnnounce },
            })
            await setUserStatus({ uid, status: STATUS.online })

            if (isConnectionTimeExpired) {
                await setUserStatus({ status: STATUS.online })
            }
            dispatch(cancelPeerToPeerSuccess({ isConnectionTimeExpired }))
        } catch (e) {
            logger('Error of peer to peer announce canceling', e)
        }
    }

export const declinePeerToPeerSuccess = createAction(DECLINE_PEER_TO_PEER_SUCCESS)

export const declinePeerToPeerAnnounce = (remoteUid) => async (dispatch) => {
    await sendToPeerToPeer({
        uid: remoteUid,
        data: {
            uid: await getUid(),
            type: MESSAGE_TYPE.answer,
            status: ANSWER_STATUS.decline,
        },
    })

    await setUserStatus({ uid: remoteUid, status: STATUS.online })
    dispatch(declinePeerToPeerSuccess())
}
export const finishPeerToPeerSuccess = createAction(FINISH_PEER_TO_PEER_SUCCESS)

export const finishPeerToPeerCall =
    ({ remoteUid, roomName }) =>
    async (dispatch) => {
        await setUserStatus({ status: STATUS.online })
        await sendToPeerToPeer({
            uid: remoteUid,
            data: { uid: await getUid(), roomName, type: MESSAGE_TYPE.end },
        })

        await setVideoCallReconnect(null)
        dispatch(closeVideoPopup())
        dispatch(finishPeerToPeerSuccess())
        dispatch(toggleFeedbackPopup())
    }

export const handlePeerToPeerAnnounce = createAction(HANDLE_PEER_TO_PEER_ANNOUNCE)

export const handlePeerToPeerDecline = createAction(HANDLE_PEER_TO_PEER_DECLINE)

export const handlePeerToPeerCancel = createAction(HANDLE_PEER_TO_PEER_CANCEL)

export const handlePeerToPeerAcceptSuccess = createAction(HANDLE_PEER_TO_PEER_ACCEPT_SUCCESS)

export const handlePeerToPeerAccept = (message) => async (dispatch) => {
    const { roomName, uid, isOwner } = message

    let { url } = message
    if (isOwner) {
        const {
            data: { token },
        } = await getDailyCoToken({ roomName, isOwner })

        url += `?t=${token}`
    }

    await setVideoCallReconnect({ callType: CALL_TYPE.peerToPeer, roomName, uid, url })
    dispatch(openVideoPopup({ callType: CALL_TYPE.peerToPeer, url }))
    dispatch(handlePeerToPeerAcceptSuccess({ roomName }))
}

const handlePeerToPeerFinish = () => async (dispatch) => {
    try {
        dispatch(closeVideoPopup())
        await setUserStatus({ status: STATUS.online })
        await setVideoCallReconnect(null)
        dispatch(finishPeerToPeerSuccess())
        dispatch(toggleFeedbackPopup())
    } catch (e) {
        logger(`Error of peer to peer finish:`, e)
    }
}

export const handlePeerToPeerMessage = (message) => async (dispatch) => {
    if (message.type === MESSAGE_TYPE.cancelAnnounce) {
        dispatch(handlePeerToPeerCancel())
    }
    if (message.type === MESSAGE_TYPE.announce) {
        const { uid, full_name, profile_picture, roomName } = message
        dispatch(
            handlePeerToPeerAnnounce({
                isIncomingCallPopupOpen: true,
                remote: { uid, full_name, profile_picture },
                roomName,
            }),
        )
        dispatch(setOwner(uid))
        dispatch(setCallType(CALL_TYPE.peerToPeer))
    }
    if (message.type === MESSAGE_TYPE.answer) {
        if (message.status === ANSWER_STATUS.decline) {
            dispatch(handlePeerToPeerDecline())
        }
        if (message.status === ANSWER_STATUS.accept) {
            dispatch(handlePeerToPeerAccept(message))
        }
    }
    if (message.type === MESSAGE_TYPE.end) {
        dispatch(handlePeerToPeerFinish(message))
    }

    cleanPeerToPeerChanel()
}

export const acceptPeerToPeerAnnounce = (remoteUid, dailyRoom, isOwner) => async (dispatch) => {
    let url = process.env.REACT_APP_DAILY_URL + dailyRoom
    dispatch(setOwner(remoteUid))
    await sendToPeerToPeer({
        uid: remoteUid,
        data: {
            uid: await getUid(),
            roomName: dailyRoom,
            isOwner: !isOwner,
            url,
            type: MESSAGE_TYPE.answer,
            status: ANSWER_STATUS.accept,
        },
    })

    if (isOwner) {
        const {
            data: { token },
        } = await getDailyCoToken({ roomName: dailyRoom, isOwner })

        url += `?t=${token}`
    }

    await setVideoCallReconnect({ callType: CALL_TYPE.peerToPeer, roomName: dailyRoom, uid: remoteUid, url })

    dispatch(handlePeerToPeerAcceptSuccess({ roomName: dailyRoom }))
    dispatch(openVideoPopup({ callType: CALL_TYPE.peerToPeer, url }))
}

export const setReconnectData = createAction(SET_RECONNECT_DATA)

export const reconnectToCall = (data) => (dispatch) => {
    const { callType, roomName, uid, url } = data

    dispatch(setReconnectData({ roomName, remote: { uid } }))
    dispatch(openVideoPopup({ callType, url }))
}

import algoliasearch from 'algoliasearch/lite'

const {
    REACT_APP_ALGOLIA_APP_ID,
    REACT_APP_ALGOLIA_PUB_KEY,
    REACT_APP_MINDFULLY_ALGOLIA_APP_ID,
    REACT_APP_MINDFULLY_ALGOLIA_PUB_KEY,
} = process.env

export const searchClient = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_PUB_KEY)

export const mindfullySearchClient = algoliasearch(
    REACT_APP_MINDFULLY_ALGOLIA_APP_ID,
    REACT_APP_MINDFULLY_ALGOLIA_PUB_KEY,
)

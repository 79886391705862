import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { toggleIsTipsOpen } from 'js/redux/assessment/assessment.actions'

import { selectIsTipsOpen } from 'js/redux/assessment/assessment.selectors'
import ComingSoonMobile from './ComingSoonMobile'
import ComingSoonWeb from './ComingSoonWeb'

const ComingSoonPopup = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsTipsOpen)
    const isWeb = !useMediaQuery({ query: `(max-width: 650px)` })
    const onClose = () => dispatch(toggleIsTipsOpen())

    return isWeb ? (
        <ComingSoonWeb isOpen={isOpen} onClose={onClose} />
    ) : (
        <ComingSoonMobile isOpen={isOpen} onClose={onClose} />
    )
}

export default ComingSoonPopup

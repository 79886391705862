import { createAction } from 'redux-actions'

import { updateOverallAssessment } from 'js/APIs/firebase/overallAssessment.firebase'

import {
    COMPLETE_OVERALL_ASSESSMENT,
    INIT_OVERALL_ASSESSMENT,
    SET_OVERALL_ASSESSMENT_ANSWER,
    SET_OVERALL_ASSESSMENT_SLIDE,
    START_OVERALL_ASSESSMENT,
    TOGGLE_OVERALL_ASSESSMENT,
    UPDATE_OVERALL_ASSESSMENT,
} from './overallAssessment.types'

export const completeOverallAssessment = createAction(COMPLETE_OVERALL_ASSESSMENT)
export const initOverallAssessment = createAction(INIT_OVERALL_ASSESSMENT)
export const startOverallAssessment = createAction(START_OVERALL_ASSESSMENT)
export const setOverallAssessmentAnswer = createAction(SET_OVERALL_ASSESSMENT_ANSWER)
export const setOverallAssessmentSlide = createAction(SET_OVERALL_ASSESSMENT_SLIDE)
export const toggleOverallAssessment = createAction(TOGGLE_OVERALL_ASSESSMENT)

export const updateOverallAssessmentSuccess = createAction(UPDATE_OVERALL_ASSESSMENT)

export const saveOverallAssessment = (overallAssessment) => async (dispatch) =>
    updateOverallAssessment(overallAssessment).then(() => dispatch(updateOverallAssessmentSuccess()))

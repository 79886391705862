import fonts from './fonts'
import palette from './palette'

const buttons = {
    round: {
        ...fonts.s16l208,
        alignItems: 'center',
        background: 'linear-gradient(90deg, #7253CC -3.46%, #256CF8 100%)',
        borderRadius: '20px',
        border: 'none',
        color: palette.white,
        display: 'flex',
        fontWeight: 700,
        height: '40px',
        justifyContent: 'center',
        letterSpacing: '0em',
        padding: '9px 20px',
        outline: 'none',
        textAlign: 'center',

        '&:hover': {
            cursor: 'pointer',
        },

        '&:disabled': {
            opacity: 0.5,
        },
    },
    roundSec: {
        ...fonts.s16l208,
        alignItems: 'center',
        background: palette.white,
        borderRadius: '20px',
        border: `1px solid ${palette.fuchsiaBlue}`,
        color: palette.fuchsiaBlue,
        display: 'flex',
        fontWeight: 700,
        height: '40px',
        justifyContent: 'center',
        lineHeight: '21px',
        letterSpacing: '0em',
        padding: '9px 20px',
        outline: 'none',
        textAlign: 'center',

        '&:hover': {
            cursor: 'pointer',
        },
    },
    def: {
        dskt: {
            ...fonts.b1.dskt,
            alignItems: 'center',
            backgroundColor: palette.primPurple,
            border: 'none',
            borderRadius: '30px',
            color: palette.white,
            display: 'flex',
            height: '48px',
            justifyContent: 'center',
            outline: 'none',
            padding: '16px 32px',
            textTransform: 'capitalize',

            '&:disabled': {
                backgroundColor: palette.purpleDisabled,
            },

            '&:hover': {
                color: palette.white,
            },
        },
        mob: {
            ...fonts.b1.mob,
            alignItems: 'center',
            backgroundColor: palette.primPurple,
            border: 'none',
            borderRadius: '24px',
            color: palette.white,
            display: 'flex',
            height: '40px',
            justifyContent: 'center',
            outline: 'none',
            padding: '10px 32px',
            textTransform: 'capitalize',

            '&:disabled': {
                backgroundColor: palette.purpleDisabled,
            },

            '&:hover': {
                color: palette.white,
            },
        },
    },
    defSec: {
        dskt: {
            ...fonts.b1.dskt,
            alignItems: 'center',
            backgroundColor: palette.white,
            border: `1px solid ${palette.primPurple}`,
            borderRadius: '30px',
            color: palette.primPurple,
            display: 'flex',
            height: '48px',
            justifyContent: 'center',
            outline: 'none',
            padding: '16px 32px',
            textTransform: 'capitalize',

            '&:disabled': {
                borderColor: palette.purpleDisabled,
                color: palette.purpleDisabled,
            },

            '&:hover': {
                color: palette.primPurple,
            },
        },
        mob: {
            ...fonts.b1.mob,
            alignItems: 'center',
            backgroundColor: palette.white,
            border: `1px solid ${palette.primPurple}`,
            borderRadius: '24px',
            color: palette.primPurple,
            display: 'flex',
            height: '40px',
            justifyContent: 'center',
            outline: 'none',
            padding: '10px 32px',
            textTransform: 'capitalize',

            '&:disabled': {
                borderColor: palette.purpleDisabled,
                color: palette.purpleDisabled,
            },

            '&:hover': {
                color: palette.primPurple,
            },
        },
    },
    iconLeft: {
        dskt: {
            ...fonts.b1.dskt,
            alignItems: 'center',
            backgroundColor: palette.primPurple,
            border: 'none',
            borderRadius: '24px',
            color: palette.white,
            display: 'flex',
            height: '48px',
            justifyContent: 'center',
            outline: 'none',
            padding: '12px 20px',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',

            '& svg': {
                height: '24px',
                marginRight: '3px',
                width: '24px',

                '& path': {
                    fill: palette.white,
                },
            },

            '&:disabled': {
                backgroundColor: palette.purpleDisabled,
            },

            '&:hover': {
                color: palette.white,
            },
        },
        mob: {
            ...fonts.b1.mob,
            alignItems: 'center',
            backgroundColor: palette.primPurple,
            border: 'none',
            borderRadius: '24px',
            color: palette.white,
            display: 'flex',
            height: '40px',
            justifyContent: 'center',
            outline: 'none',
            padding: '10px 20px',
            textTransform: 'capitalize',

            '& svg': {
                height: '14px',
                marginRight: '13px',
                width: '14px',

                '& path': {
                    fill: palette.white,
                },
            },

            '&:disabled': {
                backgroundColor: palette.purpleDisabled,
            },

            '&:hover': {
                color: palette.white,
            },
        },
    },
    iconLeftSec: {
        dskt: {
            ...fonts.b1.dskt,
            alignItems: 'center',
            backgroundColor: palette.white,
            border: `1px solid ${palette.primPurple}`,
            borderRadius: '24px',
            color: palette.primPurple,
            display: 'flex',
            height: '48px',
            justifyContent: 'center',
            outline: 'none',
            padding: '12px 20px',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',

            '& svg': {
                height: '24px',
                marginRight: '3px',
                width: '24px',

                '& path': {
                    fill: palette.primPurple,
                },
            },

            '&:disabled': {
                borderColor: palette.purpleDisabled,
                color: palette.purpleDisabled,
            },

            '&:hover': {
                color: palette.primPurple,
            },
        },
    },
}

export default buttons

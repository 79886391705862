import { bool, func, instanceOf, number, oneOfType, shape } from 'prop-types'

export const AVAILABILITY = shape({
    isWeekendAvailable: bool,
    weekdays: shape({
        end: number,
        start: number,
    }),
    weekends: shape({
        end: number,
        start: number,
    }),
})

export const REF = oneOfType([func, shape({ current: instanceOf(Element) })])

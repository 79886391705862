import React from 'react'
import { bool, func, number, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { savePHQ9, setPHQ9Answer, setPHQ9Slide, togglePHQ9 } from 'js/redux/phq9/phq9.actions'
import { selectPHQ9 } from 'js/redux/phq9/phq9.selectors'

import { ArrowBackRounded } from '@mui/icons-material'
import classNames from 'classnames'
import AssessmentAnswer from '../../AssessmentAnswer'

const useStyles = makeStyles(({ fonts, palette }) => ({
    PHQ9Question: {
        padding: '30px 30px 0 30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    back: {
        height: '24px',

        '&.isDesktop': {
            height: '32px',

            '& svg': {
                height: '32px',
                width: '32px',
            },
        },
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '422px',
        minHeight: '80vh',

        '&.isDesktop': {
            justifyContent: 'center',
        },
    },
    exit: {
        ...fonts.b4.mob,
        color: palette.black,
        display: 'flex',
        alignSelf: 'flex-end',

        '&.isDesktop': {
            ...fonts.b3.dskt,
        },
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    number: {
        ...fonts.progressIndicator,
        color: palette.primPurple,
        paddingTop: '26px',
        width: '100%',

        '&.isDesktop': {
            color: palette.midBlue,
            paddingTop: 0,
        },
    },
    question: {
        ...fonts.b2.mob,
        color: palette.black,
        marginBottom: '20px',
        marginTop: '15px',

        '&.isDesktop': {
            ...fonts.b2.dskt,
            marginTop: '12px',
        },
    },
    title: {
        ...fonts.h4.mob,
        color: palette.black,
        marginTop: '20px',
        width: '100%',

        '&.isDesktop': {
            ...fonts.h3.dskt,
        },
    },
}))

const PHQ9Question = ({ back, goToNextSlide, isDesktop, question, questionTitle, slideNumber, slidesAmount }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const PHQ9 = useSelector(selectPHQ9)
    const selectedAnswer = PHQ9.answers[`question${slideNumber}`]
    const answer = selectedAnswer === 0 ? selectedAnswer : selectedAnswer || -1

    const handleExit = () => {
        dispatch(setPHQ9Slide(slideNumber))
        dispatch(togglePHQ9())
        dispatch(savePHQ9({ ...PHQ9, isPHQ9Open: false }))
    }

    const handleChange = (value) => {
        goToNextSlide()
        dispatch(setPHQ9Answer({ key: `question${slideNumber}`, data: value }))
    }

    return (
        <div className={classes.PHQ9Question}>
            <div className={classes.header}>
                <span className={classNames(classes.back, { isDesktop })}>
                    {back && <ArrowBackRounded onClick={back} />}
                </span>
                <div className={classNames(classes.exit, { isDesktop })} onClick={handleExit}>
                    Save &amp; Exit
                </div>
            </div>
            <div className={classNames(classes.content, { isDesktop })}>
                <p className={classNames(classes.number, { isDesktop })}>{`${slideNumber + 1}/${slidesAmount}`}</p>
                <p className={classNames(classes.title, { isDesktop })}>{questionTitle}</p>
                <p className={classes.question}>{question}</p>
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="Not at all"
                    value={0}
                />
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="Several days"
                    value={1}
                />
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="More than half the days"
                    value={2}
                />
                <AssessmentAnswer
                    currentAnswer={answer}
                    handleClick={handleChange}
                    isDesktop={isDesktop}
                    text="Nearly everyday"
                    value={3}
                />
            </div>
        </div>
    )
}

PHQ9Question.propTypes = {
    back: func,
    goToNextSlide: func.isRequired,
    isDesktop: bool.isRequired,
    question: string.isRequired,
    questionTitle: string.isRequired,
    slideNumber: number.isRequired,
    slidesAmount: number.isRequired,
}

PHQ9Question.defaultProps = {
    back: null,
}

export default PHQ9Question

import React from 'react'
import { func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { savePHQ2, startPHQ2, togglePHQ2 } from 'js/redux/phq2/phq2.actions'
import { selectPHQ2 } from 'js/redux/phq2/phq2.selectors'
import { selectIsPHQStartOpen } from 'js/redux/popup/popup.selectors'
import { togglePHQStart } from 'js/redux/popup/popup.actions'

import BlackPopup from '../Reusable/popups/BlackPopup'
import PHQ2Start from '../PHQ2/PHQ2Start'

const PHQ2StartPopup = ({ onStart, onExit }) => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsPHQStartOpen)
    const PHQ2 = useSelector(selectPHQ2)

    const handleStart = (e) => {
        e.stopPropagation()
        dispatch(togglePHQ2())
        dispatch(startPHQ2())
        dispatch(togglePHQStart())

        if (onStart) {
            onStart()
        }
    }

    const handleExit = (e) => {
        e.stopPropagation()
        dispatch(savePHQ2({ ...PHQ2, isPHQ2Open: false }))
        dispatch(togglePHQStart())
        if (onExit) {
            onExit()
        }
    }

    return (
        <BlackPopup isOpen={isOpen} onClose={handleExit}>
            <PHQ2Start isDesktop onExit={handleExit} onStart={handleStart} />
        </BlackPopup>
    )
}

PHQ2StartPopup.propTypes = {
    onExit: func,
    onStart: func,
}

PHQ2StartPopup.defaultProps = {
    onExit: null,
    onStart: null,
}

export default PHQ2StartPopup

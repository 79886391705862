import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { number, string } from 'prop-types'

const useStyles = makeStyles(({ fonts, palette }) => ({
    step: {
        alignItems: 'start',
        display: 'flex',
        marginTop: '47px',
        paddingLeft: '28px',
        paddingRight: '28px',
        width: '100%',
    },
    stepNumber: {
        ...fonts.h1.mob,
        color: palette.primPurple,
    },
    stepText: {
        ...fonts.h4.mob,
        color: palette.black,
        paddingTop: '9px',
        paddingLeft: '14px',
    },
}))

const Step = ({ number, text }) => {
    const classes = useStyles()

    return (
        <div className={classes.step}>
            <p className={classes.stepNumber}>{number}.</p>
            <p className={classes.stepText}>{text}</p>
        </div>
    )
}

Step.propTypes = {
    number: number.isRequired,
    text: string.isRequired,
}

export default Step

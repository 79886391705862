import { createSelector } from 'reselect'

export const selectGoalsData = ({ goals }) => goals

export const selectGoals = createSelector(selectGoalsData, ({ goals }) => goals)

export const selectGoalById = (id) => createSelector(selectGoals, (goals) => goals.find((goal) => goal.id === id))

export const selectGoalVariants = createSelector(selectGoalsData, ({ goalVariants }) => goalVariants)

export const selectUncompletedGoals = createSelector(selectGoals, (goals) => goals.filter((goal) => !goal.endDate))

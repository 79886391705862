import { createSelector } from 'reselect'

export const selectAssessment = ({ assessment }) => assessment

export const selectConfidenceRating = createSelector(selectAssessment, ({ confidenceRating }) => confidenceRating)

export const selectDeadlineDate = createSelector(selectAssessment, ({ deadlineDate }) => deadlineDate)

export const selectGoalCategory = createSelector(selectAssessment, ({ goalCategory }) => goalCategory)

export const selectGoalSteps = createSelector(selectAssessment, ({ steps }) => steps)

export const selectGoalTitle = createSelector(selectAssessment, ({ title }) => title)

export const selectHasGoalSteps = createSelector(selectAssessment, ({ hasSteps }) => hasSteps)

export const selectImportanceRating = createSelector(selectAssessment, ({ importanceRating }) => importanceRating)

export const selectIsGoalVariantsOpen = createSelector(selectAssessment, ({ isGoalVariantsOpen }) => isGoalVariantsOpen)

export const selectIsTipsOpen = createSelector(selectAssessment, ({ isTipsOpen }) => isTipsOpen)

export const selectWasRedirectedToMentors = createSelector(
    selectAssessment,
    ({ wasRedirectedToMentors }) => wasRedirectedToMentors,
)

import firebase, { getUid, getTimestamp } from './firebase'

export const setHowYouFeelCheckIn = (rating, description) =>
    firebase.database().ref(`users/${getUid()}/howYouFeelCheckIns`).push({
        createdAt: getTimestamp(),
        description,
        rating,
    })

export const setHowYouFeelRating = async (rating, description = null) => {
    await setHowYouFeelCheckIn(rating, description)
    await firebase
        .database()
        .ref(`users/${getUid()}/howYouFeel`)
        .update({ description, lastCheckInDate: getTimestamp(), lastCheckInRating: rating })
}

export const signOnHowYouFeel = (callback) =>
    firebase
        .database()
        .ref(`users/${getUid()}/howYouFeel`)
        .on('value', (snapshot) => callback(snapshot.val()))

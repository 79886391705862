import classNames from 'classnames'
import React from 'react'
import { array, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import FindCareLink from 'js/components/FindCareLink'
import SectionEmpty from 'js/components/SectionEmpty'
import useScreenSize from 'js/hooks/useScreenSize'
import { CARE_PATH } from 'js/var/paths'
import { ReactComponent as ChatIcon } from 'media/chat.svg'
import { prepareStringToSearch } from 'js/utils/creators'
import { selectIsStaff } from 'js/redux/profile/profile.selectors'
import { selectSuggestion } from 'js/redux/staff/staff.selectors'

import SuggestedRecipient from './SuggestedRecipient'

const useStyles = makeStyles(({ buttons, fonts, others, palette }) => ({
    empty: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '32px 16px',
    },
    findCare: buttons.round,
    list: {
        border: `1px solid ${palette.whiteSmoke}`,
        borderRadius: '8px',
        overflow: 'hidden',
    },
    Suggested: {
        backgroundColor: palette.white,
        padding: '32px 16px',

        '&.isDesktop': {
            ...others.scroll,
            overflow: 'auto',
            padding: '32px 0',
        },
    },
    title: {
        ...fonts.s20l26,
        color: palette.black,
        marginBottom: '16px',
    },
}))

const Suggested = ({ addRecipient, allMentors, deleteRecipient, searchString, selectedRecipients }) => {
    const navigate = useNavigate()
    const classes = useStyles()
    const isStaff = useSelector(selectIsStaff)
    const screenSize = useScreenSize()
    const suggestions = useSelector(selectSuggestion)

    const filter = ({ full_name }) =>
        !full_name ? false : full_name.toLowerCase().match(prepareStringToSearch(searchString))

    const renderedMentors = searchString ? allMentors.filter(filter) : allMentors
    const isEmpty = !renderedMentors.length

    const toFindCare = () => navigate(CARE_PATH)

    return (
        <div className={classNames(classes.Suggested, screenSize)}>
            {isEmpty ? (
                <div className={classes.empty}>
                    <SectionEmpty
                        Icon={ChatIcon}
                        title={isStaff ? 'You have no connections with clients' : 'No Care providers in your Care Team'}
                        text={
                            isStaff ? 'Wait for connection requests' : 'Try connecting with one of our Care Providers'
                        }
                    />
                    {!isStaff && !suggestions?.length && (
                        <FindCareLink className={classes.findCare} onClick={toFindCare} />
                    )}
                </div>
            ) : (
                <>
                    <p className={classes.title}>My Care Team</p>
                    <div className={classes.list}>
                        {renderedMentors.map((mentor) => (
                            <SuggestedRecipient
                                {...mentor}
                                addRecipient={addRecipient}
                                deleteRecipient={deleteRecipient}
                                key={mentor.uid}
                                isAdded={!!selectedRecipients.find(({ uid }) => uid === mentor?.uid)}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

Suggested.propTypes = {
    allMentors: array.isRequired,
    addRecipient: func.isRequired,
    deleteRecipient: func.isRequired,
    searchString: string.isRequired,
    selectedRecipients: array.isRequired,
}

export default Suggested

import React from 'react'

const IconOther = () => (
    <svg
        width="40"
        height="36"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.2113 3.94741L37.1294 16.1257L23.2113 28.3041"
            className="stroke-painted"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M0.334167 33.5233C0.334167 34.6279 1.2296 35.5233 2.33417 35.5233C3.43874 35.5233 4.33417 34.6279 4.33417 33.5233H0.334167ZM37.1294 14.1257H10.3342V18.1257H37.1294V14.1257ZM0.334167 24.1257V33.5233H4.33417V24.1257H0.334167ZM10.3342 14.1257C4.81132 14.1257 0.334167 18.6029 0.334167 24.1257H4.33417C4.33417 20.812 7.02046 18.1257 10.3342 18.1257V14.1257Z"
            className="fill-painted"
        />
    </svg>
)

export default IconOther

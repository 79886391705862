import React from 'react'
import { string } from 'prop-types'

import palette from 'styles/palette'

const FileAlt = ({ color }) => (
    <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.33366 23.6668H20.667V27.0002H7.33366V23.6668ZM7.33366 17.0002H20.667V20.3335H7.33366V17.0002ZM17.3337 0.333496H4.00033C2.16699 0.333496 0.666992 1.8335 0.666992 3.66683V30.3335C0.666992 32.1668 2.15032 33.6668 3.98366 33.6668H24.0003C25.8337 33.6668 27.3337 32.1668 27.3337 30.3335V10.3335L17.3337 0.333496ZM24.0003 30.3335H4.00033V3.66683H15.667V12.0002H24.0003V30.3335Z"
            fill={color}
        />
    </svg>
)

FileAlt.propTypes = {
    color: string,
}

FileAlt.defaultProps = {
    color: palette.primPurple,
}

export default FileAlt

import { DAY } from 'js/var/time'
import moment from 'moment'

moment.updateLocale('en', {
    relativeTime: {
        s: 'sec',
        ss: '%d sec',
        m: 'min',
        mm: '%d min',
        h: 'hour',
        hh: '%d h',
    },
})

export const datediff = (firstDate, secondDate) => Math.ceil((new Date(firstDate) - new Date(secondDate)) / DAY)

const add0 = (number) => (number < 10 ? `0${number}` : number)

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const durationToString = ({ hours, minutes }) => `${hours ? `${hours} hrs ` : ''}${minutes} min`

export const timeConverter = (timestamp) => {
    const dateToConvert = new Date(timestamp)
    const month = months[dateToConvert.getMonth()]
    const date = dateToConvert.getDate()
    const hour = dateToConvert.getHours()
    const min = dateToConvert.getMinutes()
    return `${month} ${date} at ${add0(hour)}:${add0(min)}`
}

export const getTermString = (initDate, endDate) => {
    if (new Date(initDate).getMonth() === new Date(endDate || new Date()).getMonth()) {
        return `${new Date(initDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
        })} - ${new Date(endDate || new Date()).toLocaleDateString('en-US', {
            day: 'numeric',
        })}`
    }
    return `${new Date(initDate).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
    })} - ${new Date(endDate || new Date()).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
    })}`
}

export const countCheckInsDays = (feelingArray) => {
    if (!feelingArray?.length) {
        return 0
    }
    let checkInDays = 1
    let prevDay = new Date(feelingArray[0].date).getDay()

    for (let i = 1; i < feelingArray.length; i += 1) {
        const currentDay = new Date(feelingArray[i].date).getDay()
        if (currentDay !== prevDay) {
            prevDay = currentDay
            checkInDays += 1
        }
    }

    return checkInDays
}

export const get4aCAD = (date) =>
    date?.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

export const getMonthDayYearDate = (date) => moment(date).format('M/D/YYYY')

export const getMonthLDayNYearN = (date) =>
    new Date(date).toLocaleString('en', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })

export const getTime = (date = null) => new Date(date).getTime()

export const getMonthLDay = (date) =>
    date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
    })

export const isToday = (date) => moment(date).isSame(moment(), 'day')

export const isTomorrow = (date) => moment(date).isSame(moment().add(1, 'days'), 'day')

export const isYesterday = (date) => moment(date).isSame(moment().subtract(1, 'days'), 'day')

export const durationToHhMm = (milliseconds) => {
    const [hours, minutes] = moment(milliseconds)
        .format('hh:mm')
        .split(':')
        .map((i) => parseInt(i, 10))

    return { hours, minutes }
}

export const getDayName = (date) => {
    if (isToday(date)) {
        return 'Today'
    }

    if (isTomorrow(date)) {
        return 'Tomorrow'
    }

    return moment(date).format('dddd, MMMM D')
}

export const toTimeAgo = (date) => {
    const m = moment(date)
    const less24 = moment().hours(-24)
    const lessWeek = moment().day(-7)

    if (m.isAfter(less24)) {
        return m.fromNow(true)
    }

    if (m.isAfter(lessWeek)) {
        return m.format('ddd')
    }

    return m.format('D MMM')
}

export const toTimeAgoMessage = (date) => {
    const m = moment(date)
    const less24 = moment().hours(-24)
    const lessWeek = moment().day(-7)

    if (m.isAfter(less24)) {
        return m.fromNow()
    }

    if (m.isAfter(lessWeek)) {
        return m.format('ddd h:mm A')
    }

    return m.format('D MMM h:mm A')
}

export const toMMMyy = (date) => moment(date).format('MMM ‘YY')

import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { func, string, bool } from 'prop-types'
import { RemoveCircleRounded } from '@mui/icons-material'

const useStyles = makeStyles(({ fonts, palette }) => ({
    SuggestedRecipient: {
        alignItems: 'center',
        borderBottom: `1px solid ${palette.whiteSmoke}`,
        display: 'flex',
        height: '82px',
        padding: '20px 16px',
        width: '100%',
        maxWidth: '100%',

        '&:last-child': {
            border: 'none',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    delete: {
        marginLeft: '8px',

        '& svg': {
            '& path': {
                fill: palette.primPurple,
                height: '20px',
                width: '20px',
            },
        },
    },
    image: {
        width: '32px',
        height: '32px',
        marginRight: '8px',
    },
    name: {
        ...fonts.s16l208,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    role: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    },
}))

const SuggestedRecipient = ({
    about,
    addRecipient,
    role,
    pronouns,
    deleteRecipient,
    full_name,
    isAdded,
    profile_picture,
    uid,
}) => {
    const classes = useStyles()

    const add = () => addRecipient({ about, full_name, pronouns, role, profile_picture, uid })

    const handleDelete = () => deleteRecipient(uid)

    return (
        <div className={classes.SuggestedRecipient} onClick={add}>
            <Avatar alt="Profile" className={classes.image} src={profile_picture} />
            <div className={classes.body}>
                <div className={classes.name}>{full_name}</div>
                <div className={classes.role}>{role}</div>
            </div>
            {isAdded && (
                <div className={classes.delete} onClick={handleDelete}>
                    <RemoveCircleRounded />
                </div>
            )}
        </div>
    )
}

SuggestedRecipient.propTypes = {
    about: string.isRequired,
    addRecipient: func.isRequired,
    pronouns: string,
    deleteRecipient: func.isRequired,
    full_name: string.isRequired,
    isAdded: bool.isRequired,
    profile_picture: string,
    role: string.isRequired,
    uid: string.isRequired,
}

SuggestedRecipient.defaultProps = {
    pronouns: '',
    profile_picture: '',
}

export default SuggestedRecipient

import React from 'react'

const IconFamily = () => (
    <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M26.8224 25.4683C27.6508 25.4683 28.3224 26.1398 28.3224 26.9683C28.3224 27.7967 27.6508 28.4683 26.8224 28.4683V25.4683ZM3.5576 39.2761C3.5576 40.1046 2.88603 40.7761 2.0576 40.7761C1.22918 40.7761 0.557604 40.1046 0.557604 39.2761H3.5576ZM26.8224 28.4683H8.05761V25.4683H26.8224V28.4683ZM3.5576 32.9683V39.2761H0.557604V32.9683H3.5576ZM8.05761 28.4683C5.57232 28.4683 3.5576 30.483 3.5576 32.9683H0.557604C0.557604 28.8261 3.91547 25.4683 8.05761 25.4683V28.4683Z"
            className="fill-painted"
        />
        <path
            d="M6.44166 10.7165C6.44166 15.7501 10.576 19.8611 15.7138 19.8611C20.8515 19.8611 24.9859 15.7501 24.9859 10.7165C24.9859 5.68281 20.8515 1.57184 15.7138 1.57184C10.576 1.57184 6.44166 5.68281 6.44166 10.7165Z"
            className="stroke-painted"
            strokeWidth="3"
        />
        <rect
            width="8.65244"
            height="6.83616"
            transform="matrix(-1 0 0 1 19.6544 20.0657)"
            className="stroke-painted"
            strokeWidth="3"
        />
        <path
            d="M17.1735 42.251C17.1735 43.0794 17.8451 43.751 18.6735 43.751C19.502 43.751 20.1735 43.0794 20.1735 42.251H17.1735ZM40.9058 42.251C40.9058 43.0794 41.5774 43.751 42.4058 43.751C43.2342 43.751 43.9058 43.0794 43.9058 42.251H40.9058ZM20.1735 42.251V38.4789H17.1735V42.251H20.1735ZM24.6735 33.9789H36.4058V30.9789H24.6735V33.9789ZM40.9058 38.4789V42.251H43.9058V38.4789H40.9058ZM36.4058 33.9789C38.8911 33.9789 40.9058 35.9936 40.9058 38.4789H43.9058C43.9058 34.3367 40.5479 30.9789 36.4058 30.9789V33.9789ZM20.1735 38.4789C20.1735 35.9936 22.1882 33.9789 24.6735 33.9789V30.9789C20.5314 30.9789 17.1735 34.3367 17.1735 38.4789H20.1735Z"
            className="fill-painted"
        />
        <circle
            cx="30.5397"
            cy="19.2418"
            r="7.57411"
            className="stroke-painted"
            strokeWidth="3"
        />
        <rect
            x="26.4905"
            y="26.5037"
            width="8.0972"
            height="5.64149"
            className="stroke-painted"
            strokeWidth="3"
        />
    </svg>
)

export default IconFamily

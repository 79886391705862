import { combineActions, handleActions } from 'redux-actions'

import logger from 'js/utils/logger'
import { RESOURCES_TYPES } from 'js/var/const'

import {
    addBookmarkError,
    addBookmarkSuccess,
    addRecentlyViewed,
    deleteBookmarkError,
    deleteBookmarkSuccess,
    getResourcesStart,
    getResourcesError,
    getResourcesSuccess,
    setResourceBookmarkPopup,
    setResourceTab,
    setResourcesType,
} from './resources.actions'

const defaultState = {
    bookmarkPopup: {
        isOpen: false,
        marginBottom: '93px',
        message: '',
    },
    bookmarks: {},
    featuredResource: null,
    isLoading: false,
    recentlyViewed: [],
    resources: [],
    resourcesType: RESOURCES_TYPES.all,
    tab: 0,
}

const reducer = handleActions(
    {
        [combineActions(addBookmarkError, deleteBookmarkError)]: (state, { payload }) => {
            logger(payload)

            return state
        },
        [addBookmarkSuccess]: (state, { payload: { bookmark, rid } }) => {
            const { featuredResource, resources } = state

            if (featuredResource?.id === rid) {
                return { ...state, featuredResource: { ...featuredResource, isBookmarked: bookmark } }
            }

            return {
                ...state,
                resources: resources.map((resource) => {
                    if (resource.id === rid) {
                        return { ...resource, isBookmarked: bookmark }
                    }

                    return resource
                }),
            }
        },
        [addRecentlyViewed]: (state, { payload }) => {
            const { recentlyViewed } = state
            const newRecentlyViewed = [...recentlyViewed]

            if (newRecentlyViewed.includes(payload)) {
                newRecentlyViewed.push(newRecentlyViewed.splice(newRecentlyViewed.indexOf(payload), 1)[0])

                return {
                    ...state,
                    recentlyViewed: newRecentlyViewed,
                }
            }

            return {
                ...state,
                recentlyViewed: [...newRecentlyViewed.slice(-2), payload],
            }
        },
        [deleteBookmarkSuccess]: (state, { payload }) => {
            const { featuredResource, resources } = state

            if (featuredResource?.id === payload) {
                return { ...state, featuredResource: { ...featuredResource, isBookmarked: false } }
            }

            return {
                ...state,
                resources: resources.map((resource) => {
                    if (resource.id === payload) {
                        return { ...resource, isBookmarked: false }
                    }

                    return resource
                }),
            }
        },
        [getResourcesStart]: (state) => ({
            ...state,
            isLoading: true,
        }),
        [getResourcesError]: (state, { payload }) => {
            logger(payload)

            return { ...state, isLoading: false }
        },
        [getResourcesSuccess]: (state, { payload }) => ({
            ...state,
            ...payload,
            isLoading: false,
        }),
        [setResourceBookmarkPopup]: (state, { payload }) => ({
            ...state,
            bookmarkPopup: { ...state.bookmarkPopup, ...payload },
        }),
        [setResourceTab]: (state, { payload }) => ({ ...state, tab: payload }),
        [setResourcesType]: (state, { payload }) => ({ ...state, resourcesType: payload }),
    },
    defaultState,
)

export default reducer

import React from 'react'
import { bool } from 'prop-types'

import BlackPopup from '../popups/BlackPopup'
import CircularLoading from '../CircularLoading'

const Loader = ({ isOpen }) => (
    <BlackPopup isOpen={isOpen} opacity={0.1}>
        <CircularLoading size={36} />
    </BlackPopup>
)

Loader.propTypes = {
    isOpen: bool.isRequired,
}

export default Loader

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { ArrowBack } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Box from 'js/components/Box'
import Button from 'js/components/Reusable/Button'
import useScreenSize from 'js/hooks/useScreenSize'
import { CONTACT_METHODS } from 'js/var/const'
import { getUid } from 'js/APIs/firebase/firebase'
import { DEFAULT_AVAILABILITY_END, DEFAULT_AVAILABILITY_START } from 'js/var/time'
import { PROFILE_PATH } from 'js/var/paths'
import {
    selectAvailabilitySettings,
    selectAwayMessage,
    selectBlockedUsers,
    selectCallSettings,
    selectContactMethod,
    selectDelay,
} from 'js/redux/settings/settings.selectors'
import { uploadSettings } from 'js/redux/settings/settings.actions'

import { selectZipCode } from 'js/redux/profile/profile.selectors'
import { zipCodeSchema } from 'js/var/validationSchemas'
import { setError } from 'js/redux/error/error.actions'
import { updateZipCode } from 'js/redux/profile/profile.actions'
import Availability from './Availability'
import BlockUsers from './BlockUsers'
import CallSettings from './CallSettings'
import ContactMethod from './ContactMethod'
import NotificationDelay from './NotificationDelay'
import EditZipCode from './EditZipCode'
import Away from './Away'

const useStyles = makeStyles(({ fonts, palette }) => ({
    back: {
        height: '24px',
        left: '16px',
        position: 'absolute',
        top: '13px',
        width: '24px',

        '& svg > path': {
            fill: palette.white,
        },
    },
    content: {
        '&.isTablet, &.isDesktop': {
            maxWidth: '562px',
            paddingLeft: '4.5%',
        },
    },
    saveButton: {
        maxWidth: '100%',
        width: '100%',

        '&.isTablet, &.isDesktop': {
            width: 'fit-content',
        },
    },
    saveButtonWrapper: {
        backgroundColor: palette.white,
        bottom: 0,
        height: '72px',
        left: 0,
        padding: '16px',
        position: 'fixed',
        right: 0,
        zIndex: 10,

        '&.isTablet, &.isDesktop': {
            left: 'auto',
            height: 'fit-content',
            top: '115px',
            right: '54px',
            padding: 0,
            width: 'fit-content',
            borderRadius: '20px',
        },
    },
    Settings: {
        backgroundColor: palette.offWhite,
        color: palette.black,
        maxHeight: '100vh',
        minHeight: '100vh',
        overflow: 'scroll',
        width: '100%',

        '&.isMobile': {
            paddingBottom: '72px',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
        },

        '&.isTablet, &.isDesktop': {
            backgroundColor: palette.white,
            paddingTop: '76px',
        },
    },
    title: {
        ...fonts.s16l208,
        alignItems: 'center',
        backgroundColor: palette.fuchsiaBlue,
        color: palette.white,
        display: 'flex',
        height: '50px',
        fontWeight: 700,
        justifyContent: 'center',
        position: 'relative',
        padding: '13px 16px',
        width: '100%',

        '&.isTablet, &.isDesktop': {
            ...fonts.s32l42,
            backgroundColor: palette.white,
            color: palette.darkestPurple,
            fontWeight: 700,
            height: 'fit-content',
            justifyContent: 'start',
            padding: '25px 0 0 0',
        },
    },
}))

const Settings = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const initAvailabilitySettings = useSelector(selectAvailabilitySettings)
    const initAway = useSelector(selectAwayMessage)
    const initCallSettings = useSelector(selectCallSettings)
    const initBlockedUsers = useSelector(selectBlockedUsers)
    const initContactMethod = useSelector(selectContactMethod)
    const initDelay = useSelector(selectDelay)
    const initZipCode = useSelector(selectZipCode)
    const navigate = useNavigate()
    const screenSize = useScreenSize()
    const [availability, setAvailability] = useState({
        isWeekendAvailable: false,
        weekdays: {
            end: DEFAULT_AVAILABILITY_END,
            isAllDay: false,
            isOncePerDay: false,
            once: DEFAULT_AVAILABILITY_START,
            start: DEFAULT_AVAILABILITY_START,
        },
        weekends: {
            end: DEFAULT_AVAILABILITY_END,
            isAllDay: false,
            isOncePerDay: false,
            once: DEFAULT_AVAILABILITY_START,
            start: DEFAULT_AVAILABILITY_START,
        },
    })
    const [away, setAway] = useState({})
    const [blockedUsers, setBlockedUsers] = useState({})
    const [callSettings, setCallSettings] = useState({ isAllClientsCall: false, users: [] })
    const [delay, setDelay] = useState(0)
    const [contactMethod, setContactMethod] = useState(CONTACT_METHODS.pushNotifications)
    const [zipCode, setZipCode] = useState('')

    useEffect(() => {
        setAvailability(initAvailabilitySettings)
        setBlockedUsers(initBlockedUsers)
        setCallSettings(initCallSettings)
        setContactMethod(initContactMethod)
        setDelay(initDelay)
        setZipCode(initZipCode)
        setAway(initAway)
    }, [])

    const goBack = () => navigate(-1)

    const onSave = async () => {
        try {
            const uid = getUid()
            await zipCodeSchema.validate(zipCode)
            if (zipCode !== initZipCode) {
                dispatch(updateZipCode(zipCode))
            }
            dispatch(uploadSettings({ availability, away, blockedUsers, callSettings, contactMethod, delay }))
            navigate(`${PROFILE_PATH}/${uid}`)
        } catch ({ errors }) {
            dispatch(setError({ title: 'Failed of setting update', message: errors[0] }))
        }
    }

    return (
        <Box className={classNames(classes.Settings, screenSize)}>
            <div className={classNames(classes.content, screenSize)}>
                <div className={classNames(classes.title, screenSize)}>
                    {screenSize?.isMobile && (
                        <i className={classes.back}>
                            <ArrowBack onClick={goBack} />
                        </i>
                    )}
                    Account Settings
                    <div className={classNames(classes.saveButtonWrapper, screenSize)}>
                        <Button className={classNames(classes.saveButton, screenSize)} onClick={onSave}>
                            Save changes
                        </Button>
                    </div>
                </div>
                <EditZipCode setZipCode={setZipCode} zipCode={zipCode} />
                <Availability availability={availability} setAvailability={setAvailability} />
                <NotificationDelay delay={delay} setDelay={setDelay} />
                <ContactMethod contactMethod={contactMethod} setContactMethod={setContactMethod} />
                <Away away={away} setAway={setAway} />
                <CallSettings callSettings={callSettings} setCallSettings={setCallSettings} />
                <BlockUsers blockedUsers={blockedUsers} setBlockedUsers={setBlockedUsers} />
            </div>
        </Box>
    )
}

export default Settings

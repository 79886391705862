import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import Box from 'js/components/Box'
import Card from 'js/components/Card'
import Button from 'js/components/Reusable/Button'
import useScreenSize from 'js/hooks/useScreenSize'
import { selectIsEmailInvitationPopup, selectStaffUid } from 'js/redux/emailInvitation/emailInvitation.selectors'
import { selectStaff } from 'js/redux/staff/staff.selectors'
import {
    acceptEmailInvitationConnection,
    toggleEmailInvitationPopup,
} from 'js/redux/emailInvitation/emailInvitation.actions'
import { useLocation, useNavigate } from 'react-router-dom'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    buttons: {
        display: 'flex',
        marginTop: '32px',
    },
    cancel: {
        ...buttons.roundSec,
        marginLeft: '16px',
    },
    EmailInvitationPopup: {
        alignItems: 'center',
        backgroundColor: palette.white,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '650px',
    },
    submit: {
        ...buttons.round,
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '32px',
        textAlign: 'center',
    },
    title: {
        ...fonts.s20l26,
        color: palette.black,
        marginBottom: '32px',
        textAlign: 'center',
    },
}))

const EmailInvitationPopup = () => {
    const classes = useStyles()
    const isOpen = useSelector(selectIsEmailInvitationPopup)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const staffUid = useSelector(selectStaffUid)
    const screenSize = useScreenSize()
    const staff = useSelector(selectStaff)
    const [profile, setProfile] = useState({})

    useEffect(() => {
        setProfile(staff.find(({ firebaseUid }) => firebaseUid === staffUid) || {})
    }, [staffUid, staff])

    if (!isOpen || !staffUid || !profile.firebaseUid) {
        return null
    }

    const submit = () => {
        dispatch(acceptEmailInvitationConnection(staffUid))
        dispatch(toggleEmailInvitationPopup())
    }

    const decline = () => {
        dispatch(toggleEmailInvitationPopup())
        navigate(location.pathname)
    }

    return (
        <BlackPopup isOpen={isOpen}>
            <Box className={classes.EmailInvitationPopup}>
                <p className={classNames(classes.title, screenSize)}>Connections</p>
                <p className={classes.text}>
                    You created an account via the invitation of the clinician below. Would you like to establish a
                    connection?
                </p>
                <Card {...profile} isShort isSuggestion />
                <div className={classes.buttons}>
                    <Button className={classes.submit} onClick={submit}>
                        Accept
                    </Button>
                    <Button className={classes.cancel} onClick={decline}>
                        Decline
                    </Button>
                </div>
            </Box>
        </BlackPopup>
    )
}

export default EmailInvitationPopup

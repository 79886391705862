import React from 'react'
import { func } from 'prop-types'

const IconLevel0 = ({ onClick }) => (
    <svg onClick={onClick} width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40.3518" cy="40.2961" r="37.5" stroke="#FFB500" strokeWidth="5" />
        <path
            d="M30.5748 60.2543C30.5748 60.2543 33.3992 55.7793 40.3106 55.7793C47.222 55.7793 50.1288 60.2543 50.1288 60.2543"
            stroke="#FFB500"
            strokeWidth="5"
            strokeLinecap="round"
        />
        <path
            d="M50.9247 38.778C51.7355 35.9551 53.5416 38.9732 56.3645 39.784C59.1873 40.5948 61.958 38.8913 61.1472 41.7142C60.3364 44.5371 57.3907 46.1681 54.5678 45.3573C51.745 44.5465 50.1139 41.6009 50.9247 38.778Z"
            fill="#FFB500"
        />
        <path
            d="M22.0313 41.6132C21.2711 38.7763 24.3744 40.4317 27.2113 39.6716C30.0482 38.9114 31.5445 36.0236 32.3047 38.8605C33.0648 41.6974 31.3813 44.6134 28.5443 45.3736C25.7074 46.1337 22.7914 44.4502 22.0313 41.6132Z"
            fill="#FFB500"
        />
    </svg>
)

IconLevel0.propTypes = {
    onClick: func,
}

IconLevel0.defaultProps = {
    onClick: null,
}

export default IconLevel0

import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { checkEmailTaken } from 'js/APIs/firebase/auth.firebase'
import { createConnectionAxios, sendEmailInvitationAxios } from 'js/APIs/axiosApi'
import { getUid } from 'js/APIs/firebase/firebase'
import { HOME_PATH, SIGN_UP_PATH } from 'js/var/paths'

import { ACTIVATE_EMAIL_INVITATION, TOGGLE_EMAIL_INVITATION_POPUP } from './emailInvitation.types'
import { setError } from '../error/error.actions'
import { setIsLoading } from '../navigation/navigation.actions'
import { showResourceBookmark } from '../resources/resources.actions'

export const sendEmailInvitation =
    ({ email, name }) =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true))
            const isInApp = await checkEmailTaken(email)
            const path = isInApp ? HOME_PATH : SIGN_UP_PATH
            const uid = getUid()
            const url = `${process.env.REACT_APP_URL}/#${path}?staffUid=${uid}&isInvitation=true`

            await sendEmailInvitationAxios({ email, name, url })

            dispatch(showResourceBookmark({ message: `Invitation is sent to ${email}` }))
            dispatch(setIsLoading(false))
        } catch (error) {
            const message = `Error of sending invitation to email ${email}: ${error}`
            dispatch(setIsLoading(false))
            dispatch(
                setError({
                    title: 'Error',
                    message,
                }),
            )
            logger(message)
        }
    }

export const activateEmailInvitation = createAction(ACTIVATE_EMAIL_INVITATION)

export const toggleEmailInvitationPopup = createAction(TOGGLE_EMAIL_INVITATION_POPUP)

export const acceptEmailInvitationConnection = (staffUid) => async (dispatch) => {
    try {
        const uid = getUid()

        dispatch(setIsLoading(true))
        await createConnectionAxios({ users: [uid, staffUid] })
        dispatch(setIsLoading(false))
    } catch (error) {
        logger(`Error of accept email invitation: ${error}`)
    }
}

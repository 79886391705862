import React, { useEffect, useState } from 'react'
import RatingElement from '@material-ui/lab/Rating'
import { makeStyles, InputBase, Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { setFeedback, toggleFeedbackPopup } from 'js/redux/callFeedback/callFeedback.actions'

import { selectCallType, selectIsFeedbackOpen, selectOwner } from 'js/redux/callFeedback/callFeedback.selectors'
import { selectUid } from 'js/redux/profile/user'
import CallFeedbackWeb from './CallFeedbackDesktop'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    CallFeedbackMobile: {
        alignItems: 'center',
        backgroundColor: palette.white,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '180px',
        overflow: 'hidden',
        width: '100vw',
    },
    inputFocused: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: palette.primBlue,
        borderRadius: '24px',
    },
    inputInput: {},
    inputRoot: {
        backgroundColor: palette.lightBlue,
        borderRadius: '24px',
        marginBottom: '111px',
        minHeight: '120px',
        padding: '12.5px 18px',
        width: '100%',
    },
    modal: {
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%',
        display: 'block',
    },
    rating: {
        fontSize: '38px',
        marginBottom: '64px',

        '& .MuiRating-iconFilled': {
            opacity: 1,
        },

        '& .MuiRating-iconEmpty': {
            '& path': {
                opacity: 0.3,
            },
        },

        '& .MuiSvgIcon-fontSizeInherit': {
            '& path': {
                fill: palette.dustOrange,
            },
        },
    },
    skipFeedback: {
        ...fonts.b2.mob,
        color: palette.linkBlue,
        cursor: 'pointer',
        marginTop: '16px',
        textDecoration: 'underline',
    },
    submit: {
        ...buttons.def.mob,
        maxWidth: '315px',
        width: '100%',
    },
    title: {
        ...fonts.h4.mob,
        color: palette.darkestPurple,
        marginBottom: '19px',
    },
}))

const CallFeedback = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsFeedbackOpen)
    const author = useSelector(selectUid)
    const callType = useSelector(selectCallType)
    const owner = useSelector(selectOwner)

    const [isPreparedFeedback, setIsPreparedFeedback] = useState(false)
    const [feedback, updateFeedback] = useState('')
    const [rating, setRating] = useState(0)

    const onChangeRating = (_, newValue) => setRating(newValue)

    const onChangeFeedback = (e) => updateFeedback(e.target.value)

    const onSubmitFeedback = async () => {
        if (isPreparedFeedback) {
            await dispatch(setFeedback({ author, callType, feedback, owner, rating }))
        }
    }

    const onSkipFeedback = () => dispatch(toggleFeedbackPopup())

    const isDesktop = !useMediaQuery({ query: `(max-width: 864px)` })

    useEffect(() => {
        if (owner) {
            setIsPreparedFeedback(true)
        }
    }, [owner])

    return isDesktop ? (
        <CallFeedbackWeb
            onChangeFeedback={onChangeFeedback}
            onChangeRating={onChangeRating}
            onSubmitFeedback={onSubmitFeedback}
            rating={rating}
        />
    ) : (
        <Modal className={classes.modal} open={isOpen}>
            <div className={classes.CallFeedbackMobile}>
                <span className={classes.title}>How was your session?</span>
                <RatingElement
                    size="large"
                    className={classes.rating}
                    name="simple-controlled"
                    value={rating}
                    onChange={onChangeRating}
                />
                <span className={classes.title}>What was most helpful for you?</span>
                <InputBase
                    placeholder="Feedback..."
                    multiline
                    rowsMin={4}
                    rows={4}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                        focused: classes.inputFocused,
                    }}
                    onChange={onChangeFeedback}
                    inputProps={{ 'aria-label': 'search' }}
                />

                <button disabled={!rating} className={classes.submit} onClick={onSubmitFeedback} type="button">
                    Submit
                </button>
                <div className={classes.skipFeedback} onClick={onSkipFeedback}>
                    Skip Feedback
                </div>
            </div>
        </Modal>
    )
}

export default CallFeedback

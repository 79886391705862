import { string } from 'prop-types'
import React from 'react'

const EventRounded = ({ color }) => (
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="EventRoundedIcon">
        <path
            fill={color}
            d="M16 13h-3c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1zm0-10v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V3c0-.55-.45-1-1-1s-1 .45-1 1zm2 17H6c-.55 0-1-.45-1-1V9h14v10c0 .55-.45 1-1 1z"
        />
    </svg>
)
EventRounded.propTypes = {
    color: string.isRequired,
}

export default EventRounded

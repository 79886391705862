import moment from 'moment-timezone'
import app from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'

import logger from 'js/utils/logger'

const {
    REACT_APP_APP_ID,
    REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_DATABASE_URL,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID,
} = process.env

const config = {
    appId: REACT_APP_APP_ID,
    apiKey: REACT_APP_API_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE_URL,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
}

app.initializeApp(config)

export const messaging = app.messaging.isSupported() ? app.messaging() : null

export const getEmail = () => app.auth()?.currentUser?.email

export const getUid = () => {
    const currentUser = app.auth().currentUser

    return currentUser ? currentUser.uid : null
}

export const getTimestamp = () => app.database.ServerValue.TIMESTAMP

export const pushDataFirebase = ({ path, data }) => app.database().ref(path).push(data)

export const signOn = ({ path, cb, trigger = 'value' }) =>
    app
        .database()
        .ref(path)
        .on(trigger, (snapshot) => {
            cb(snapshot)
        })

export const updateDataFirebase = ({ path, data }) => app.database().ref(path).update(data)

export const getDataFirebase = ({ path, trigger = 'value' }) =>
    app
        .database()
        .ref(path)
        .once(trigger)
        .then((snapshot) => snapshot?.val())

export const getUserData = (uid) => {
    const userId = uid || getUid()

    return app
        .ref(`users/${userId}`)
        .once('value')
        .then((snapshot) => ({ uid: snapshot.key, ...snapshot.val() }))
        .catch((e) => logger(e))
}

export const updateFCMToken = ({ token, isUsed }) => {
    if (token) {
        app.database()
            .ref(`users/${getUid()}/FCMTokens`)
            .update({ [token]: isUsed })
    }
}

export const signStatusOnDisconnect = (status) =>
    app.database().ref(`users/${getUid()}`).onDisconnect().update({ status })

export const updateTimezone = () =>
    updateDataFirebase({ path: `users/${getUid()}`, data: { timezone: moment.tz.guess() } })

export default app

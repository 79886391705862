const fonts = {
    s12l16: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
    },
    s13l17: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '17px',
    },
    s16l208: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20.8px',
    },
    s20l26: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '26px',
    },
    s24l31: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '31px',
    },
    s26l34: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '26px',
        fontWeight: 400,
        lineHeight: '34px',
    },
    s32l42: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '42px',
    },
    s36l47: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '36px',
        fontWeight: 400,
        lineHeight: '47px',
    },
    h1: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '37px',
            fontWeight: 800,
            lineHeight: '56px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '32px',
            fontWeight: 800,
            lineHeight: '41px',
        },
    },
    h2: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '30px',
            fontWeight: 800,
            lineHeight: '48px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '27px',
            fontWeight: 800,
            lineHeight: '35px',
        },
    },
    h3: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '25px',
            fontWeight: 800,
            lineHeight: '40px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '23px',
            fontWeight: 800,
            lineHeight: '30px',
        },
    },
    h4: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '28px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '23px',
        },
    },
    h5: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '21px',
            fontWeight: 400,
            lineHeight: '24px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '15px',
            fontWeight: 800,
            lineHeight: '19px',
        },
    },
    h6: {
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '19px',
        },
    },
    h7: {
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '19px',
        },
    },
    b1: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '17px',
            fontWeight: 600,
            lineHeight: '24px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '19px',
        },
    },
    b2: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '17px',
            fontWeight: 400,
            lineHeight: '24px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19px',
        },
    },
    b3: {
        dskt: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '15px',
        },
    },
    b4: {
        mob: {
            fontFamily: 'Sarabun, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '15px',
        },
    },
    menu: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '13px',
    },
    progressIndicator: {
        fontFamily: 'Sarabun, sans-serif',
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '41px',
    },
}

export default fonts

import { getSettings } from 'js/APIs/firebase/settings.firebase'
import { getUid } from 'js/APIs/firebase/firebase'

import { compareChatUsers } from './comparators'

export const hasChatMessage = (chat, message) => chat.filter(({ mid }) => mid === message.mid).length !== 0

export const checkChatExist = (chatsWithUsers, newChatUsers) => {
    const existedChat = chatsWithUsers.filter(({ users }) => compareChatUsers(users, newChatUsers))

    return existedChat[0]?.id
}

export const checkIsVideoCallAvailable = async ({ myUid, remoteUid, setIsVideoCallAvailable }) => {
    const settings = await getSettings(remoteUid)

    if (settings?.callSettings?.isAllClientsCall || Object.keys(settings?.callSettings?.users || {}).includes(myUid)) {
        return setIsVideoCallAvailable(true)
    }
}

const checkUserBlockedFor = async (otherUid) => {
    const myUid = getUid()
    const settings = (await getSettings(otherUid)) || {}

    return !!settings?.blockedUsers?.[myUid] || !!settings?.away?.isPermanent
}

export const checkUserBlocked = async (users, callback) => {
    const res = await Promise.all(users.map((uid) => checkUserBlockedFor(uid)))

    const isBlocked = !!res.filter((isBlocked) => isBlocked).length

    if (callback) {
        callback(isBlocked)
    }

    return isBlocked
}

import moment from 'moment'

export const SECOND = 1000
export const HOUR = SECOND * 60 * 60
export const DAY = HOUR * 24
export const WEEK = DAY * 7
export const MONTH = WEEK * 4
export const TODAY = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
export const DEFAULT_AVAILABILITY_START = 8 * HOUR
export const DEFAULT_AVAILABILITY_END = 17 * HOUR

export const DELAYS = [
    { value: 0, name: 'Immediately' },
    { value: HOUR, name: 'After 1 hour' },
    { value: 4 * HOUR, name: 'After 4 hour' },
    { value: DAY, name: 'After 24 hour' },
]

import classNames from 'classnames'
import React, { useState } from 'react'
import { ExpandLessRounded, ExpandMoreRounded, HelpSharp, LogoutRounded, Settings } from '@mui/icons-material'
import { makeStyles, Avatar, SvgIcon } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Button from 'js/components/Reusable/Button'
import { HEADER_BUTTON_WIDTH, PATH_AFTER_SIGN_OUT } from 'js/var/const'
import { HELP_PATH, PROFILE_PATH, SETTINGS_PATH } from 'js/var/paths'
import { getFirebaseMessagingToken, getProfileImageSelector, selectUid } from 'js/redux/profile/user'
import { signOut } from 'js/APIs/firebase/auth.firebase'
import { updateFCMToken } from 'js/APIs/firebase/firebase'

import { selectIsStaff } from 'js/redux/profile/profile.selectors'
import Option from './Option'

const useStyles = makeStyles(({ fonts, others, palette }) => ({
    AccountOptions: {
        alignItems: 'center',
        background: palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '76px',
        justifyContent: 'space-between',
        margin: '0 6px',
        padding: ' 17px 0 6px 0',
        position: 'relative',
        width: HEADER_BUTTON_WIDTH,
        zIndex: 10,

        '& p': {
            color: palette.secondaryBlue,

            '& svg': {
                '& path, rect': {
                    stroke: palette.secondaryBlue,
                },
                '& path[fill="#00B3C5"]': {
                    fill: palette.secondaryBlue,
                },
                '& path[fill="white"]': {
                    fill: palette.secondaryBlue,
                },
            },
        },

        '&.isActive': {
            '& p': {
                color: palette.primPurple,
                '& svg': {
                    '& path, rect': {
                        stroke: palette.primPurple,
                    },
                    '& path[fill="#00B3C5"]': {
                        fill: palette.primPurple,
                    },
                    '& path[fill="white"]': {
                        fill: palette.primPurple,
                    },
                },
            },
        },
    },
    icon: {
        height: '4.5px',
        position: 'relative',
        width: '7.5px',

        '& svg ': {
            bottom: '-10px',
            height: '16px',
            position: 'absolute',
            width: '16px',
        },
    },
    image: {
        backgroundColor: palette.dustBlue,
        height: '31px',
        width: '31px',

        '& svg': {
            height: '40px',
            marginTop: '5px',
            width: '40px',

            '& path': {
                fill: palette.disabledGrey,
            },
        },
    },
    imageSmall: {
        backgroundColor: palette.dustBlue,
        width: '24px',
        height: '24px',

        '& svg': {
            height: '31px',
            marginTop: '3px',
            width: '31px',

            '& path': {
                fill: palette.disabledGrey,
            },
        },
    },
    options: {
        backgroundColor: palette.white,
        border: `1px solid #F0F0F0`,
        boxSizing: 'border-box',
        boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px',
        maxWidth: '300px',
        padding: '8px 0',
        position: 'absolute',
        top: '86px',
        right: '0px',
        width: '300px',
    },
    spring: others.navigationSelector,
    text: {
        ...fonts.b1.mob,
        color: palette.secondaryBlue,
        display: 'flex',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '13px',
    },
    track: {
        bottom: '2px',
        position: 'absolute',
        width: '100%',
    },
}))

const AccountOptions = () => {
    const classes = useStyles()
    const firebaseMessagingToken = useSelector(getFirebaseMessagingToken)
    const isStaff = useSelector(selectIsStaff)
    const navigate = useNavigate()
    const profileImage = useSelector(getProfileImageSelector)
    const uid = useSelector(selectUid)
    const [isOpen, setIsOpen] = useState(false)
    const { pathname } = useLocation()

    const isActivePath = pathname.match(PROFILE_PATH)

    const toggleDropdown = () => setIsOpen(!isOpen)

    const toProfile = () => {
        navigate(`${PROFILE_PATH}/${uid}`)
        toggleDropdown()
    }

    const toHelp = () => {
        navigate(HELP_PATH)
        toggleDropdown()
    }

    const toSettings = () => {
        navigate(SETTINGS_PATH)
        toggleDropdown()
    }

    const handleSignOut = async () => {
        toggleDropdown()
        updateFCMToken({ token: firebaseMessagingToken, isUsed: null })
        signOut(() => {
            window.localStorage.removeItem('isKeptSignIn')
            navigate(PATH_AFTER_SIGN_OUT)
            window.location.reload()
        })
    }

    return (
        <Button
            className={classNames(classes.AccountOptions, {
                isActive: isOpen || isActivePath,
            })}
            onClick={toggleDropdown}
        >
            <Avatar className={classes.image} src={profileImage} alt="Profile" />
            <p className={classes.text}>
                Account
                <span className={classes.icon}>
                    <SvgIcon component={isOpen ? ExpandLessRounded : ExpandMoreRounded} />
                </span>
            </p>
            {isActivePath && (
                <div className={classes.track}>
                    <div
                        style={{
                            left: `calc((${HEADER_BUTTON_WIDTH} - 46px) / 2)`,
                        }}
                        className={classes.spring}
                    />
                </div>
            )}
            {isOpen && (
                <div className={classes.options}>
                    <Option
                        icon={<Avatar className={classes.imageSmall} src={profileImage} alt="Profile" />}
                        onClick={toProfile}
                        title="View Profile"
                    />
                    {isStaff && <Option icon={<Settings />} onClick={toSettings} title="Settings" />}
                    <Option icon={<HelpSharp />} onClick={toHelp} title="Help" />
                    <Option icon={<LogoutRounded />} onClick={handleSignOut} title="Sign Out" />
                </div>
            )}
        </Button>
    )
}

export default AccountOptions

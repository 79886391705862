export const CHAT_SUBSCRIBED = 'CHAT_SUBSCRIBED'

export const CLEAR_CHAT_ID = 'CLEAR_CHAT_ID'

export const CLEAR_NEW_MSG = 'CLEAR_NEW_MSG'

export const CREATE_CHAT_START = 'CREATE_CHAT_START'
export const CREATE_CHAT_ERROR = 'CREATE_CHAT_ERROR'
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS'

export const CREATE_MESSAGE_START = 'CREATE_MESSAGE_START'
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR'

export const SET_EXPORT_CHAT_DATE = 'SET_EXPORT_CHAT_DATE'

export const GET_CHAT_MESSAGES_ERROR = 'GET_CHAT_MESSAGES_ERROR'
export const GET_CHAT_MESSAGES_START = 'GET_CHAT_MESSAGES_START'
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS'

export const GET_CHAT_ERROR = 'GET_CHAT_ERROR'
export const GET_CHAT_START = 'GET_CHAT_START'
export const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS'

export const GET_CHATS_ERROR = 'GET_CHATS_ERROR'
export const GET_CHATS_START = 'GET_CHATS_START'
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS'

export const GET_USER_DATA_IN_CHAT_SUCCESS = 'GET_USER_DATA_IN_CHAT_SUCCESS'

export const ON_RECEIVED_MESSAGE = 'ON_RECEIVED_MESSAGE'

export const SUBS_TO_USER_CHATS = 'SUBS_TO_USER_CHATS'
export const SUBS_TO_USER_CHATS_ERROR = 'SUBS_TO_USER_CHATS_ERROR'
export const SUBS_TO_USER_CHATS_SUCCESS = 'SUBS_TO_USER_CHATS_SUCCESS'

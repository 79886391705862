import ReactGA from 'react-ga'
import { connect } from 'react-redux'

import App from './App'

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)

const mapStateToProps = (state) => ({
    firebaseMessagingToken: state.firebaseMessaging.token,
})

export default connect(mapStateToProps)(App)

import firebase, { getDataFirebase, getTimestamp, getUid, signOn, updateDataFirebase } from './firebase'

export const signOnConnectionsFirebase = (callback) =>
    firebase
        .database()
        .ref(`users/${getUid()}/connections`)
        .on('value', (snapshot) => callback(snapshot.val()))

export const putConnectionRequestToChatFirebase = (chatId, requestData) =>
    updateDataFirebase({
        data: {
            connectionsRequest: requestData ? { ...requestData, createdAt: getTimestamp() } : null,
            connectionRequestTime: getTimestamp(),
        },
        path: `chats/${chatId}`,
    })

export const getConnectionRequestTime = (chatId) => getDataFirebase({ path: `chats/${chatId}/connectionRequestTime` })

export const updateSuggested = (data) => updateDataFirebase({ data, path: `users/${getUid()}` })

export const signOnSuggestion = (cb) => signOn({ cb, path: `users/${getUid()}/suggestedStaff` })

import classNames from 'classnames'
import React, { useCallback } from 'react'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Box from 'js/components/Box'
import Check from 'js/components/Check'
import DatePicker from 'js/components/DatePicker'
import Input from 'js/components/Input'
import useScreenSize from 'js/hooks/useScreenSize'
import { DAY, TODAY } from 'js/var/time'
import { AVAILABILITY } from 'js/var/propTypeShapes'

const useStyles = makeStyles(({ fonts, palette }) => ({
    fieldTitle: {
        ...fonts.s16l208,
        fontWeight: 700,
        marginBottom: '4px',
    },
    section: {
        backgroundColor: palette.white,
        marginBottom: '8px',

        '&.isTablet, &.isDesktop': {
            marginBottom: 0,
            padding: '16px 0',
        },
    },
    sectionTitle: {
        ...fonts.s20l26,
        marginBottom: '4px',
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
    },
    timeFields: {
        ...fonts.s16l208,
        alignItems: 'center',
        display: 'flex',
        marginBottom: '16px',
    },
    timeInput: {
        minWidth: '100px',
        width: '100px',
    },
    to: {
        color: palette.doveGrey,
        marginLeft: '16px',
        marginRight: '16px',
    },
}))

const START_MIN = TODAY.toDate()
const TODAY_TIMESTAMP = TODAY.valueOf()

const Availability = ({ availability, setAvailability }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()

    const { isWeekendAvailable, weekdays, weekends } = availability

    const createToggle = (field, name) => () =>
        setAvailability({ ...availability, [field]: { ...availability[field], [name]: !availability[field][name] } })

    const toggleIsWeekendAvailable = useCallback(
        () => setAvailability({ ...availability, isWeekendAvailable: !isWeekendAvailable }),
        [availability],
    )

    const createChangeDate = (field, name) => (date) =>
        setAvailability({
            ...availability,
            [field]: { ...availability[field], [name]: date.getTime() - TODAY_TIMESTAMP },
        })

    const weekdaysEnd = new Date(TODAY_TIMESTAMP + weekdays.end)
    const weekdaysOnce = new Date(TODAY_TIMESTAMP + weekdays.once)
    const weekdaysStart = new Date(TODAY_TIMESTAMP + weekdays.start)

    const weekendsEnd = new Date(TODAY_TIMESTAMP + weekends.end)
    const weekendsOnce = new Date(TODAY_TIMESTAMP + weekends.once)
    const weekendsStart = new Date(TODAY_TIMESTAMP + weekends.start)

    const filterWeekdaysEnd = (value) =>
        value.getTime() > weekdaysStart.getTime() && value.getTime() < TODAY_TIMESTAMP + DAY

    const filterWeekdaysStart = (value) => value.getTime() < weekdaysEnd.getTime()

    const filterWeekendsEnd = (value) =>
        value.getTime() > weekendsStart.getTime() && value.getTime() < TODAY_TIMESTAMP + DAY

    const filterWeekendsStart = (value) => value.getTime() < weekendsEnd.getTime()

    return (
        <Box className={classNames(classes.section, screenSize)}>
            <p className={classes.sectionTitle}>Hours of Availability</p>
            <p className={classes.text}>
                What are the hours you wish to be available for clients to contact you in the app?
            </p>
            <p className={classes.fieldTitle}>Weekdays</p>
            <Check
                isDisabled={weekdays.isOncePerDay}
                isChecked={weekdays.isAllDay}
                onChange={createToggle('weekdays', 'isAllDay')}
                style={{ marginBottom: '16px' }}
                text="Clients can contact me at any time during weekdays."
            />
            <Check
                isDisabled={weekdays.isAllDay}
                isChecked={weekdays.isOncePerDay}
                style={{ marginBottom: '16px' }}
                text="I would like to receive notifications from clients once per day during weekdays."
                onChange={createToggle('weekdays', 'isOncePerDay')}
            />
            {!weekdays.isAllDay && !weekdays.isOncePerDay && (
                <div className={classes.timeFields}>
                    <DatePicker
                        dateFormat="h:mm aa"
                        filterTime={filterWeekdaysStart}
                        customInput={<Input style={{ height: '53px', width: '105px' }} type="button" />}
                        minDate={START_MIN}
                        maxDate={weekdaysEnd}
                        onChange={createChangeDate('weekdays', 'start')}
                        selected={weekdaysStart}
                        showTimeSelect
                        showTimeSelectOnly
                    />
                    <span className={classes.to}>To</span>
                    <DatePicker
                        dateFormat="h:mm aa"
                        customInput={<Input style={{ height: '53px', width: '105px' }} type="button" />}
                        filterTime={filterWeekdaysEnd}
                        minDate={weekdaysStart}
                        onChange={createChangeDate('weekdays', 'end')}
                        selected={weekdaysEnd}
                        showTimeSelect
                        showTimeSelectOnly
                    />
                </div>
            )}
            {weekdays.isOncePerDay && (
                <div className={classes.timeFields}>
                    <DatePicker
                        dateFormat="h:mm aa"
                        customInput={<Input style={{ height: '53px', width: '105px' }} type="button" />}
                        onChange={createChangeDate('weekdays', 'once')}
                        selected={weekdaysOnce}
                        showTimeSelect
                        showTimeSelectOnly
                    />
                </div>
            )}
            <p className={classes.fieldTitle}>Weekends</p>
            <Check
                isChecked={isWeekendAvailable}
                onChange={toggleIsWeekendAvailable}
                style={{ marginBottom: '16px' }}
                text="Clients may contact me on the weekend"
            />
            {isWeekendAvailable && (
                <>
                    <Check
                        isChecked={weekends.isAllDay}
                        isDisabled={weekends.isOncePerDay}
                        onChange={createToggle('weekends', 'isAllDay')}
                        style={{ marginBottom: '16px' }}
                        text="Clients can contact me at any time during weekends."
                    />
                    <Check
                        isDisabled={weekends.isAllDay}
                        isChecked={weekends.isOncePerDay}
                        style={{ marginBottom: '16px' }}
                        text="I would like to receive notifications from clients once per day during weekends."
                        onChange={createToggle('weekends', 'isOncePerDay')}
                    />
                    {!weekends.isAllDay && !weekends.isOncePerDay && (
                        <div className={classes.timeFields} style={{ marginTop: '16px' }}>
                            <DatePicker
                                dateFormat="h:mm aa"
                                customInput={<Input style={{ height: '53px', width: '105px' }} type="button" />}
                                filterTime={filterWeekendsStart}
                                minDate={START_MIN}
                                onChange={createChangeDate('weekends', 'start')}
                                selected={weekendsStart}
                                showTimeSelect
                                showTimeSelectOnly
                            />
                            <span className={classes.to}>To</span>
                            <DatePicker
                                customInput={<Input style={{ height: '53px', width: '105px' }} type="button" />}
                                dateFormat="h:mm aa"
                                filterTime={filterWeekendsEnd}
                                minDate={weekendsStart}
                                onChange={createChangeDate('weekends', 'end')}
                                selected={weekendsEnd}
                                showTimeSelect
                                showTimeSelectOnly
                            />
                        </div>
                    )}
                    {weekends.isOncePerDay && (
                        <div className={classes.timeFields}>
                            <DatePicker
                                dateFormat="h:mm aa"
                                customInput={<Input style={{ height: '53px', width: '105px' }} type="button" />}
                                onChange={createChangeDate('weekends', 'once')}
                                selected={weekendsOnce}
                                showTimeSelect
                                showTimeSelectOnly
                            />
                        </div>
                    )}
                </>
            )}
        </Box>
    )
}

Availability.propTypes = {
    availability: AVAILABILITY.isRequired,
    setAvailability: func.isRequired,
}

export default Availability

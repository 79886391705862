import React from 'react'

const IconHintGoals = () => (
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.9298 5.897C31.4985 5.32831 31.4985 4.40965 30.9298 3.84096L27.5173 0.428817C27.2448 0.155768 26.8749 0.00231934 26.4892 0.00231934C26.1034 0.00231934 25.7335 0.155768 25.461 0.428817L22.6027 3.28685L28.0714 8.75504L30.9298 5.897ZM26.6131 10.2132L21.1443 4.74504L6.77975 19.1081C6.63392 19.254 6.561 19.4289 6.561 19.6331V24.066C6.561 24.4742 6.88184 24.795 7.29017 24.795H11.7235C11.9131 24.795 12.1027 24.7221 12.2339 24.5763L26.6131 10.2132ZM32.811 29.1696H3.64436C2.04019 29.1696 0.727692 30.4819 0.727692 32.086C0.727692 33.69 2.04019 35.0023 3.64436 35.0023H32.811C34.4152 35.0023 35.7277 33.69 35.7277 32.086C35.7277 30.4819 34.4152 29.1696 32.811 29.1696Z"
            fill="white"
        />
    </svg>
)

export default IconHintGoals

import { string } from 'prop-types'
import React from 'react'

import palette from 'styles/palette'

const IconHome = ({ color }) => (
    <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.2048 32.7811C14.7571 32.7811 15.2048 32.3334 15.2048 31.7811V22.8399C15.2048 22.2876 15.6525 21.8399 16.2048 21.8399H21.5922C22.1445 21.8399 22.5922 22.2876 22.5922 22.8399V31.7811C22.5922 32.3333 23.0399 32.7811 23.5922 32.7811H30.8264C31.3787 32.7811 31.8264 32.3334 31.8264 31.7811V19.1928C31.8264 18.6405 32.2741 18.1928 32.8264 18.1928H34.7362C35.6558 18.1928 36.0879 17.0562 35.4005 16.4453L19.5627 2.37135C19.1839 2.03469 18.6131 2.03469 18.2342 2.37135L2.39649 16.4453C1.7091 17.0562 2.14116 18.1928 3.06075 18.1928H4.97054C5.52282 18.1928 5.97054 18.6405 5.97054 19.1928V31.7811C5.97054 32.3334 6.41825 32.7811 6.97054 32.7811H14.2048Z"
            stroke={color}
            strokeWidth="3"
        />
    </svg>
)

IconHome.propTypes = {
    color: string,
}

IconHome.defaultProps = {
    color: palette.primPurple,
}

export default IconHome

import classNames from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import {
    selectBookmarkPopupMarginBottom,
    selectBookmarkPopupMessage,
    selectIsBookmarkPopupOpen,
} from 'js/redux/resources/resources.selectors'

const BookmarkPopup = () => {
    const marginBottom = useSelector(selectBookmarkPopupMarginBottom)

    const useStyles = makeStyles(({ fonts, palette }) => ({
        drawer: {
            ...fonts.b2.mob,
            alignItems: 'center',
            backgroundColor: palette.black,
            borderRadius: '4px',
            bottom: marginBottom,
            color: palette.white,
            height: '40px',
            justifyContent: 'center',
            left: 'calc(50% - 157.5px)',
            padding: '12.5px 16px',
            position: 'fixed',
            right: 'calc(50% - 157.5px)',
            zIndex: 1000,
            width: '315px',

            '&.isDesktop': {
                ...fonts.h6.mob,
                height: '48px',
                bottom: '24px',
            },
        },
        isHidden: {
            display: 'none',
        },
    }))

    const classes = useStyles()
    const isOpen = useSelector(selectIsBookmarkPopupOpen)

    const isDesktop = !useMediaQuery({ query: `(max-width: 650px)` })
    const message = useSelector(selectBookmarkPopupMessage)

    return (
        <div className={classNames(classes.drawer, { [classes.modalWeb]: isDesktop, [classes.isHidden]: !isOpen })}>
            <span>{message}</span>
        </div>
    )
}

export default BookmarkPopup

import { createAction } from 'redux-actions'

import { createGoalReminderAxios, removeGoalReminderAxios } from 'js/APIs/axiosApi'
import {
    addGoal,
    deleteGoalFirebase,
    forbidGoalStepEditing,
    getGoalVariants,
    setGoalCompleted,
    setGoalConfidenceFirebase,
    setGoalDeadlineFirebase,
    setGoalStepsFirebase,
    updateGoalCheckIn,
    updateGoalFirebase,
    updateGoalProgress,
} from 'js/APIs/firebase/goals.firebase'

import firebase, { getTimestamp, getUid } from 'js/APIs/firebase/firebase'
import logger from 'js/utils/logger'
import {
    DELETE_GOAL,
    GOALS_INIT_SUCCESS,
    GOALS_LOADING,
    GOALS_LOADING_ERROR,
    GOALS_LOADING_SUCCESS,
    GOAL_CHECK_IN_SET,
    GOAL_CHECK_IN_SET_ERROR,
    GOAL_CHECK_IN_SET_SUCCESS,
    GOAL_COMPLETED_SET,
    GOAL_DEADLINE_SET_SUCCESS,
    GOAL_PROGRESS_SET,
    GOAL_STEPS_SET_SUCCESS,
    GOAL_STEP_NOT_EDITABLE_SET,
    GOAL_STEP_STATUS_TOGGLE,
    GOAL_VARIANTS_INIT_SUCCESS,
    SET_GOAL_CONFIDENCE,
    UPDATE_GOAL,
} from './goals.types'
import { resetAssessmentData } from '../assessment/assessment.actions'

export const goalCheckInSet = createAction(GOAL_CHECK_IN_SET)
export const goalCheckInSetError = createAction(GOAL_CHECK_IN_SET_ERROR)
export const goalCheckInSetSuccess = createAction(GOAL_CHECK_IN_SET_SUCCESS)

export const setGoalCheckIn = (id, checkIn) => (dispatch) => {
    dispatch(goalCheckInSet())
    updateGoalCheckIn(id, checkIn)
        .then(() => dispatch(goalCheckInSetSuccess({ id, checkIn })))
        .catch(() => dispatch(goalCheckInSetError()))
}

export const goalCompletedSet = createAction(GOAL_COMPLETED_SET)

export const completeGoal = (goalId, reminderId) => async (dispatch) => {
    await setGoalCompleted(goalId).catch((e) => logger(`Error of goal completion: ${e}`))
    dispatch(goalCompletedSet())

    if (reminderId) {
        const uid = await getUid()

        await removeGoalReminderAxios({ goalId, reminderId, uid })
    }
}

export const goalDeadlineSetSuccess = createAction(GOAL_DEADLINE_SET_SUCCESS)

export const setGoalDeadline =
    ({ goalId, deadline }) =>
    (dispatch) =>
        setGoalDeadlineFirebase(goalId, deadline)
            .then(() => dispatch(goalDeadlineSetSuccess({ goalId, deadline })))
            .catch((e) => logger(`Error of setting goal deadline: ${e}`))

export const goalProgressSet = createAction(GOAL_PROGRESS_SET)

export const setGoalProgress = (goalId, progress) => (dispatch) =>
    updateGoalProgress(goalId, progress)
        .then(() => dispatch(goalProgressSet()))
        .catch((e) => logger(`Error of updating goal progress: ${e}`))

export const goalVariantsInitSuccess = createAction(GOAL_VARIANTS_INIT_SUCCESS)

export const initGoalVariants = () => async (dispatch) => {
    const goalVariants = await getGoalVariants()

    if (goalVariants) {
        return dispatch(goalVariantsInitSuccess(goalVariants))
    }
}

export const goalsInitSuccess = createAction(GOALS_INIT_SUCCESS)

export const initGoals = (uid) => (dispatch) => {
    firebase
        .database()
        .ref(`users/${uid}/goals`)
        .orderByChild('initDate')
        .once('value', (snapshot) => {
            const goals = []

            snapshot.forEach((childSnapshot) => {
                goals.push({ ...childSnapshot.val(), id: childSnapshot.key })
            })

            dispatch(goalsInitSuccess(goals))
        })
        .catch((e) => logger(`Error of goals init: ${e}`))
}

export const goalsLoading = createAction(GOALS_LOADING)
export const goalsLoadingError = createAction(GOALS_LOADING_ERROR)
export const goalsLoadingSuccess = createAction(GOALS_LOADING_SUCCESS)

export const loadGoals = (uid, limit, gid, startDate) => async (dispatch) => {
    dispatch(goalsLoading())

    let goalsRef
    if (gid) {
        goalsRef = firebase
            .database()
            .ref(`users/${uid}/goals`)
            .orderByChild('initDate')
            .startAt(startDate, gid)
            .limitToFirst(limit)
    } else {
        goalsRef = firebase.database().ref(`users/${uid}/goals`).orderByChild('initDate').limitToFirst(limit)
    }

    const goals = []

    await goalsRef
        .once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                goals.push({ ...childSnapshot.val(), id: childSnapshot.key })
            })
            if (gid) {
                dispatch(goalsLoadingSuccess(goals.slice(1, limit)))
            } else {
                dispatch(goalsLoadingSuccess(goals))
            }
        })
        .catch((e) => dispatch(goalsLoadingError(e)))

    if (goals.length) {
        const { id, initDate } = goals[goals.length - 1]

        return { id, initDate }
    }
}

export const goalStepNotEditableSet = createAction(GOAL_STEP_NOT_EDITABLE_SET)

export const setGoalStepNotEditable = (goalId, stepIndex) => (dispatch) => {
    forbidGoalStepEditing(goalId, stepIndex)
        .then(dispatch(goalStepNotEditableSet()))
        .catch((e) => logger(`Error of prohibition goal step editing: ${e}`))
}

export const goalStepsSetSuccess = createAction(GOAL_STEPS_SET_SUCCESS)

export const setGoalSteps =
    ({ steps, goalId }) =>
    (dispatch) =>
        setGoalStepsFirebase(goalId, steps)
            .then(() => dispatch(goalStepsSetSuccess({ goalId, steps })))
            .catch((e) => logger(`Error of setting goal steps: ${e}`))

export const toggleGoalStepStatus = createAction(GOAL_STEP_STATUS_TOGGLE)

export const deleteGoalAction = createAction(DELETE_GOAL)

export const deleteGoal = (goalId, reminderId) => async (dispatch) => {
    if (reminderId) {
        const uid = await getUid()

        await removeGoalReminderAxios({ goalId, reminderId, uid })
    }
    await deleteGoalFirebase(goalId)
    dispatch(deleteGoalAction(goalId))
}

export const setGoalConfidenceAction = createAction(SET_GOAL_CONFIDENCE)

export const setGoalConfidence =
    ({ confidenceRating, goalId }) =>
    async (dispatch) => {
        await setGoalConfidenceFirebase({ confidenceRating, goalId })
        dispatch(setGoalConfidenceAction({ confidenceRating, goalId }))
    }

export const updateGoalAction = createAction(UPDATE_GOAL)

export const updateGoal =
    ({ goalId, newGoalData }) =>
    async (dispatch) => {
        await updateGoalFirebase({ goalId, newGoalData })

        dispatch(updateGoalAction({ goalId, newGoalData }))
    }

export const createGoal = (goal) => async (dispatch) => {
    const { confidenceRating, deadlineDate, hasSteps, goalCategory, importanceRating, reminderType, steps, title } =
        goal

    const goalRef = await addGoal({
        confidenceRating: Math.floor(confidenceRating / 25),
        deadlineDate: deadlineDate.getTime(),
        hasSteps,
        reminderType: reminderType || null,
        goalCategory,
        importanceRating: Math.floor(importanceRating / 25),
        initDate: getTimestamp(),
        progress: 0,
        steps: steps.filter(({ text }) => text !== ''),
        title,
    })

    if (reminderType) {
        const uid = getUid()

        await createGoalReminderAxios({ goalId: goalRef.key, reminderType, uid })
    }

    dispatch(resetAssessmentData())
}

import { handleActions } from 'redux-actions'
import {
    deleteScheduleMeetingSuccess,
    editScheduledMeetingSuccess,
    leaveGuestListSuccess,
    setIncomingMeetingAction,
    setScheduledMeeting,
    startCreatedScheduledMeeting,
} from './scheduledMeetings.actions'

const defaultState = {
    isIncomingMeeting: false,
    incomingMeetingId: '',
    isCreatedMeetingStarted: false,
    isWaitingHost: false,
    ownerToken: '',
    scheduledMeetings: [],
    scheduledMeetingRoomUrl: '',
    startedCallDailyCoName: '',
}

const scheduledMeetingsReduces = handleActions(
    {
        [deleteScheduleMeetingSuccess]: (state, { payload: { id } }) => ({
            ...state,
            scheduledMeetings: state.scheduledMeetings.filter((meeting) => meeting.id !== id),
        }),
        [editScheduledMeetingSuccess]: (state, { payload: { id, meetingData } }) => ({
            ...state,
            scheduledMeetings: state.scheduledMeetings.map((meeting) =>
                meeting.id === id ? { ...meeting, ...meetingData } : meeting,
            ),
        }),
        [leaveGuestListSuccess]: (state, { payload: { meetingId } }) => ({
            ...state,
            scheduledMeetings: state.scheduledMeetings.filter((meeting) => meeting.id !== meetingId),
        }),
        [setIncomingMeetingAction]: (state, { payload: id }) =>
            id
                ? {
                      ...state,
                      isIncomingMeeting: true,
                      incomingMeetingId: id,
                  }
                : {
                      ...state,
                      isIncomingMeeting: false,
                      incomingMeetingId: id,
                  },
        [startCreatedScheduledMeeting]: (state, { payload: { url, name, token } }) => ({
            ...state,
            ownerToken: token,
            isCreatedMeetingStarted: true,
            startedCallDailyCoName: name,
            scheduledMeetingRoomUrl: url,
        }),
        [setScheduledMeeting]: (state, { payload }) => ({
            ...state,
            scheduledMeetings: [...state.scheduledMeetings.filter((meeting) => meeting.id !== payload.id), payload],
        }),
    },
    defaultState,
)

export default scheduledMeetingsReduces

import React from 'react'
import { array, bool } from 'prop-types'
import { connectHits } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import { createStaffSelector, selectConnections } from 'js/redux/staff/staff.selectors'
import { SUPPORTERS_TYPES } from 'js/var/const'

import Suggested from './Suggested'

const SuggestedRecipients = ({ hits, isStaff, ...props }) => {
    const connections = useSelector(selectConnections)

    const connectedStaff = useSelector(createStaffSelector({ type: SUPPORTERS_TYPES.all, isConnectedOnly: true }))

    if (!isStaff) {
        const hitsIds = new Set()
        hits.forEach(({ firebaseUid }) => hitsIds.add(firebaseUid))

        props.allMentors = connectedStaff
            .filter(({ firebaseUid }) => hitsIds.has(firebaseUid))
            .map((user) => ({ ...user, uid: user.firebaseUid }))

        return <Suggested {...props} />
    }

    const filteredHits = hits.filter((user) => {
        if (isStaff && connections.includes(user.uid)) {
            return user
        }

        return false
    })

    const uniqueIds = new Set()

    props.allMentors = filteredHits.filter(({ uid }) => {
        const isDuplicate = uniqueIds.has(uid)

        uniqueIds.add(uid)

        return !isDuplicate
    })

    return <Suggested {...props} />
}

SuggestedRecipients.propTypes = {
    hits: array.isRequired,
    isStaff: bool.isRequired,
}

export default connectHits(SuggestedRecipients)

import logger from 'js/utils/logger'

import firebase, { getUid } from './firebase'

export const updatePHQ9 = (PHQ9) =>
    firebase
        .database()
        .ref(`users/${getUid()}/phq9`)
        .update(PHQ9)
        .catch((e) => logger(`Error of updating phq9: ${e}`))

export default { updatePHQ9 }

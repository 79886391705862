import { createSelector } from 'reselect'

const selectProfile = (state) => state.profile

const selectFirebaseReducer = (state) => state.firebaseReducer

const firebaseMessagingSelector = (state) => state.firebaseMessaging

export const getUserDataSelector = createSelector(selectProfile, (data) => data)

export const getOtherUserDataSelector = createSelector(selectProfile, ({ otherUser }) => otherUser)

export const getProfileImageSelector = createSelector(selectProfile, ({ profile_picture }) => profile_picture)

export const getFirebaseMessagingToken = createSelector(firebaseMessagingSelector, ({ token }) => token)

export const getOtherUserInterestsSelector = createSelector(selectProfile, ({ otherUser }) => otherUser?.interests)

export const selectEmail = createSelector(selectFirebaseReducer, ({ auth }) => auth?.email)

export const selectUid = createSelector(selectFirebaseReducer, ({ auth }) => auth?.uid)

export const selectUserEmail = createSelector(selectFirebaseReducer, ({ auth }) => auth?.email)

export const selectUserName = createSelector(selectProfile, ({ full_name }) => full_name)

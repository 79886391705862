import classNames from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import feedbackDoneImg from 'media/feedback.svg'
import { Close } from 'js/components/icons'
import { selectIsFeedbackSuccess } from 'js/redux/callFeedback/callFeedback.selectors'
import { toggleFeedbackSuccess } from 'js/redux/callFeedback/callFeedback.actions'

import BlackPopup from '../Reusable/popups/BlackPopup'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    FeedbackSuccessPopup: {
        position: 'relative',
        height: '403px',
        width: '312px',
        backgroundColor: palette.white,
        opacity: '1',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '67px',

        '&.isDesktop': {
            backgroundColor: palette.white,
            height: '377px',
            maxWidth: '466px',
            paddingTop: '42px',
            width: '100%',
        },
    },
    button: {
        ...buttons.def.dskt,
        width: '240px',
    },
    close: {
        position: 'absolute',
        right: '34px',
        top: '33px',
        zIndex: 10,

        '& svg': {
            '& path': {
                fill: palette.black,
            },
        },

        '&.isDesktop': {
            right: '29px',
            top: '29px',
        },
    },
    img: {
        width: '100%',
        height: 'auto',
    },
    imgWrapper: {
        width: '206px',

        '&.isDesktop': {
            maxWidth: '193px',
        },
    },
    text: {
        ...fonts.h2.mob,
        color: palette.darkestPurple,
        marginTop: '20px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.h4.dskt,
            color: palette.midBlue,
            marginBottom: '32px',
            marginTop: '12px',
        },
    },
}))

const FeedbackSuccessPopup = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isDesktop = !useMediaQuery({ query: `(max-width: 650px)` })
    const isOpen = useSelector(selectIsFeedbackSuccess)

    const onClosePopup = ({ target, currentTarget }) => {
        if (target === currentTarget) {
            dispatch(toggleFeedbackSuccess())
        }
    }

    return (
        <BlackPopup isOpen={isOpen} onClose={onClosePopup}>
            <div
                className={classNames(classes.FeedbackSuccessPopup, {
                    isDesktop,
                })}
            >
                <div className={classNames(classes.close, { isDesktop })} onClick={onClosePopup}>
                    <Close onClick={onClosePopup} />
                </div>
                <div className={classNames(classes.imgWrapper, { isDesktop })}>
                    <img className={classes.img} src={feedbackDoneImg} alt="Feedback!" />
                </div>
                <p className={classNames(classes.text, { isDesktop })}>
                    Thanks for {!isDesktop && <br />} your feedback!
                </p>
                {isDesktop && (
                    <button className={classes.button} onClick={onClosePopup} type="button">
                        Done
                    </button>
                )}
            </div>
        </BlackPopup>
    )
}

export default FeedbackSuccessPopup

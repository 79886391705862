export const COMPLETE_OVERALL_ASSESSMENT = 'COMPLETE_OVERALL_ASSESSMENT'

export const INIT_OVERALL_ASSESSMENT = 'INIT_OVERALL_ASSESSMENT'

export const SET_OVERALL_ASSESSMENT_ANSWER = 'SET_OVERALL_ASSESSMENT_ANSWER'

export const SET_OVERALL_ASSESSMENT_SLIDE = 'SET_OVERALL_ASSESSMENT_SLIDE'

export const START_OVERALL_ASSESSMENT = 'START_OVERALL_ASSESSMENT'

export const TOGGLE_OVERALL_ASSESSMENT = 'TOGGLE_OVERALL_ASSESSMENT'

export const UPDATE_OVERALL_ASSESSMENT = 'UPDATE_OVERALL_ASSESSMENT'

import React from 'react'
import { bool, func } from 'prop-types'
import { FavoriteBorder } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'

import SideDrawer from '../../SideDrawer'

const useStyles = makeStyles((theme) => ({
    icon: {
        height: '36px',
        width: '41px',

        '& path': {
            fill: theme.palette.midBlue,
        },
    },
    root: {
        alignItems: 'center',
        background: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    text: {
        ...theme.fonts.b1.dskt,
        color: theme.palette.midBlue,
        marginTop: '4px',
    },
    title: {
        ...theme.fonts.h3.dskt,
        marginBottom: '226px',
        padding: '0 30px',
    },
}))

const ComingSoonWeb = ({ isOpen, onClose }) => {
    const classes = useStyles()

    return (
        <SideDrawer isOpen={isOpen} onClose={onClose}>
            <p className={classes.title}>Goal Setting Tips</p>
            <div className={classes.root}>
                <FavoriteBorder className={classes.icon} />
                <p className={classes.text}>Coming soon!</p>
            </div>
        </SideDrawer>
    )
}

ComingSoonWeb.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
}

export default ComingSoonWeb

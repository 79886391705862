import { handleActions } from 'redux-actions'
import { faqGetSuccess } from './faq.actions'

const defaultState = {
    questions: [],
}

const reducer = handleActions(
    {
        [faqGetSuccess]: (state, { payload }) => ({
            ...state,
            questions: payload,
        }),
    },
    defaultState,
)

export default reducer

import { createSelector } from 'reselect'

const chatSelector = (state) => state.chat

export const getChatIdSelector = createSelector(chatSelector, ({ chatId }) => chatId)

export const getChatDataSelector = createSelector(chatSelector, ({ chatData }) => chatData)

export const getNewChatMessageSelector = createSelector(chatSelector, ({ newMessage }) => newMessage)

export const getOuidDataSelector = createSelector(chatSelector, ({ ouidData }) => ouidData)

export const selectChatMessages = createSelector(chatSelector, ({ messages }) => messages || [])

export const selectChats = createSelector(chatSelector, ({ chats }) => chats)

export const selectChatsUsers = createSelector(chatSelector, ({ chats }) =>
    chats.map(({ id, users }) => ({
        id,
        users: users.map(({ uid }) => uid),
    })),
)

export const selectExportChatDate = createSelector(chatSelector, ({ exportChatDate }) => exportChatDate)

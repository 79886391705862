import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'

import assessment from './assessment/assessment.reducers'
import chat from './chats/chat.reducer'
import checkIn from './checkIn/checkIn.reducers'
import contactUs from './contactUs/contactUs.reducers'
import emailInvitation from './emailInvitation/emailInvitation.reducers'
import error from './error/error.reducers'
import explore from './explore/explore.reducers'
import faq from './faq/faq.reducers'
import featureOptions from './featureOptions/featureOptions.reducers'
import feedback from './callFeedback/callFeedback.reducers'
import firebaseMessaging from './firebaseCloudMessaging/firebaseCloudMessaging.reducers'
import goals from './goals/goals.reducers'
import groupCalls from './groupCalls/groupCalls.reducers'
import howYouFeel from './howYouFeel/howYouFeel.reducers'
import interests from './interests/interests.reducers'
import navigation from './navigation/navigation.reducers'
import notifications from './notifications/notifications.reducers'
import overallAssessment from './overallAssessment/overallAssessment.reducers'
import passwordPopup from './passwordUpdate/passwordUpdate.reducer'
import peerToPeer from './peerToPeer/peerToPeer.reducers'
import peerSupporterLearning from './peerSupporterLearning/peerSupporterLearning.reducers'
import phq2 from './phq2/phq2.reducers'
import phq9 from './phq9/phq9.reducers'
import popup from './popup/popup.reducers'
import profile from './profile/profile.reducers'
import resources from './resources/resources.reducers'
import scheduledMeetings from './scheduledMeetings/scheduledMeetings.reducers'
import settings from './settings/settings.reducers'
import staff from './staff/staff.reducers'
import videoPopup from './videoPopup/videoPopup.reducers'

export default combineReducers({
    assessment,
    chat,
    checkIn,
    contactUs,
    emailInvitation,
    error,
    explore,
    faq,
    featureOptions,
    feedback,
    firebaseMessaging,
    firebaseReducer,
    goals,
    groupCalls,
    howYouFeel,
    interests,
    navigation,
    notifications,
    overallAssessment,
    passwordPopup,
    peerSupporterLearning,
    peerToPeer,
    phq2,
    phq9,
    popup,
    profile,
    resources,
    scheduledMeetings,
    settings,
    staff,
    videoPopup,
})

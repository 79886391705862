import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { array, bool, func, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ChatHeader from 'js/components/ChatHeader'
import ChatInput from 'js/components/ChatInput'
import ExportChat from 'js/popups/ExportChat'
import logger from 'js/utils/logger'
import useUserBlocked from 'js/hooks/useUserBlocked'
import useScreenSize from 'js/hooks/useScreenSize'
import { deleteNotification } from 'js/redux/notifications/notifications.actions'
import { getSettings } from 'js/APIs/firebase/settings.firebase'
import { getUserData, sendMessageToChat } from 'js/redux/chats/chat.actions'
import { getOuidDataSelector, selectChats } from 'js/redux/chats/chat.selectors'
import { selectAllNotifications } from 'js/redux/notifications/notifications.selectors'
import { selectIsStaff, selectProfile } from 'js/redux/profile/profile.selectors'
import { sumNames } from 'js/utils/creators'

import ChatMessages from './ChatMessages'

const Chat = ({ chatId, initUsers, isChatCreation, onChatCreation }) => {
    const [users, setUsers] = useState(initUsers)
    const [loading, setLoading] = useState(false)
    const [isExport, setExport] = useState(false)
    const [isP2P, setP2P] = useState(false)
    const [awayMessage, setAwayMessage] = useState('')
    const dispatch = useDispatch()
    const isStaff = useSelector(selectIsStaff) || true
    const messagesEnd = useRef(null)
    const screenSize = useScreenSize()
    const userProfile = useSelector(selectProfile)

    let { id: locationChatId } = useParams()

    if (chatId) {
        locationChatId = chatId
    }

    const chats = useSelector(selectChats)
    const notifications = useSelector(selectAllNotifications)

    const currentChat = useMemo(() => chats.find((chat) => chat.id === locationChatId), [chats, locationChatId])

    const ouid = users[users.length - 1]?.uid
    const ouidData = useSelector(getOuidDataSelector)

    const scrollToBottom = useCallback(() => {
        if (messagesEnd?.current?.scrollIntoView) {
            messagesEnd.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        }
    }, [messagesEnd])

    const onSendMessage = async (msg) => {
        dispatch(
            sendMessageToChat({
                msg,
                chatId: locationChatId,
                recipients: users.map(({ uid }) => uid),
                full_name: userProfile.full_name,
                profile_picture: userProfile.profile_picture,
            }),
        )
        scrollToBottom()
    }

    const loadingTrigger = () => (ouidData ? setLoading(false) : setLoading(true))

    notifications.forEach((notification) => {
        if (notification?.data?.data?.chatId === locationChatId) {
            dispatch(deleteNotification(notification.nid))
        }
    })

    useEffect(() => {
        if (!ouidData && ouid) {
            dispatch(getUserData(ouid))
        }
    }, [dispatch, ouidData, ouid])

    useEffect(loadingTrigger, [ouidData])

    useEffect(() => {
        if (currentChat?.users) {
            setUsers(currentChat?.users)
        }
    }, [currentChat])

    useEffect(() => {
        setP2P(users?.length === 1)
    }, [users])

    const setAwayMessageHandler = (away) => {
        if (away?.isTemporary) {
            const todayMilliseconds = moment().valueOf()

            if (todayMilliseconds < away?.end && todayMilliseconds > away?.start) {
                setAwayMessage(away.temporaryMessage)
            }
        }

        if (away?.isPermanent) {
            setAwayMessage(away.permanentMessage)
        }
    }
    const checkAway = async (user) => {
        try {
            const settings = (await getSettings(user?.uid)) || {}
            setAwayMessageHandler(settings?.away)
        } catch (error) {
            logger(error)
        }
    }

    useEffect(() => {
        if (initUsers.length && isP2P) {
            checkAway(users[0])
        }
        if (!isP2P || !users[0]?.settings?.away) {
            return
        }

        const { away } = users[0].settings
        setAwayMessageHandler(away)
    }, [users, isP2P])

    const isBlocked = useUserBlocked(users.map(({ uid }) => uid) || [])

    if ((loading || !ouidData || !currentChat) && !isChatCreation) {
        return <div>...loading</div>
    }

    const toggleExport = () => setExport(!isExport)

    const name = isP2P ? users[0]?.full_name : sumNames(users)

    return (
        <>
            <ChatHeader name={name} toggleExport={isStaff && currentChat?.messages?.length ? toggleExport : null} />
            <ChatMessages
                awayMessage={awayMessage}
                chatId={currentChat?.id}
                messages={currentChat?.messages || []}
                messagesEndRef={messagesEnd}
                scrollToBottom={scrollToBottom}
            />
            <ChatInput
                isBlocked={isBlocked}
                isDesktop={screenSize?.isDesktop}
                onSendMessage={isChatCreation ? onChatCreation : onSendMessage}
                remoteUid={isP2P ? ouid : ''}
                toggleExport={isStaff && currentChat?.messages?.length && !screenSize?.isMobile ? toggleExport : null}
            />
            {isExport && (
                <ExportChat
                    chatId={locationChatId}
                    name={name}
                    messages={currentChat?.messages}
                    isOpen={isExport}
                    onClose={toggleExport}
                />
            )}
        </>
    )
}

Chat.propTypes = {
    chatId: string,
    initUsers: array,
    isChatCreation: bool,
    onChatCreation: func,
}

Chat.defaultProps = {
    chatId: '',
    initUsers: [],
    isChatCreation: false,
    onChatCreation: () => null,
}

export default Chat

import palette from './palette'

const others = {
    afterShadow: {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
    },
    navigationSelector: {
        borderRadius: '4px',
        borderTop: `4px solid ${palette.primPurple}`,
        position: 'absolute',
        transition: 'all 0.25s ease-out',
        width: '46px',
    },
    scroll: {
        '&::-webkit-scrollbar': {
            border: 'none',
            width: '8px',
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.fuchsiaBlue,
            borderRadius: '4px',
        },

        '&::-webkit-scrollbar-track': {
            border: 'none',
        },
    },
}

export default others

import classNames from 'classnames'
import React from 'react'
import { bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core'

import image from 'media/faq-link.svg'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    PHQ2End: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingBottom: '90px',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '20px',
        width: '100%',
    },
    button: {
        ...buttons.def.mob,
        marginTop: 'auto',
        maxWidth: '422px',
        width: '100%',

        '&.isDesktop': {
            ...buttons.def.dskt,
            width: '100%',
        },
    },
    exit: {
        ...fonts.b2.mob,
        color: palette.linkBlue,
        marginTop: '16px',
        textDecoration: 'underline',

        '&.isDesktop': {
            ...fonts.b2.dskt,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '422px',
    },
    img: {
        height: 'auto',
        width: '100%',
    },
    imgWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '320px',
        justifyContent: 'center',
        width: '320px',
    },
    title: {
        ...fonts.h2.mob,
        color: palette.darkestPurple,
        paddingTop: '20px',
        paddingBottom: '12px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.h3.dskt,
            color: palette.midBlue,
            paddingTop: '25x',
        },
    },
    text: {
        ...fonts.b2.mob,
        color: palette.black,
        paddingBottom: '20px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.b2.dskt,
            paddingBottom: '40px',
        },
    },
}))

const PHQ2End = ({ handleEnd, handleExit, isDesktop }) => {
    const classes = useStyles()

    return (
        <div className={classNames(classes.PHQ2End, { isDesktop })}>
            <div className={classes.content}>
                <div className={classes.imgWrapper}>
                    <img className={classes.img} src={image} alt="phq2-end" />
                </div>
                <p className={classNames(classes.title, { isDesktop })}>Hmmm...</p>
                <p className={classNames(classes.text, { isDesktop })}>
                    It looks like you have not been feeling quite like yourself lately. Can we dig in a little deeper to
                    understand what you may be going through? (We will be asking you 10 questions).
                </p>
                <button className={classNames(classes.button, { isDesktop })} onClick={handleEnd} type="button">
                    Start
                </button>
                <div className={classNames(classes.exit, { isDesktop })} onClick={handleExit}>
                    I’ll do this later
                </div>
            </div>
        </div>
    )
}

PHQ2End.propTypes = {
    isDesktop: bool,
    handleExit: func.isRequired,
    handleEnd: func.isRequired,
}

PHQ2End.defaultProps = {
    isDesktop: false,
}

export default PHQ2End

import classNames from 'classnames'
import React, { useState } from 'react'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import VideoChatIcon from '@mui/icons-material/VideoChat'
import { bool, func, string } from 'prop-types'
import { makeStyles, InputBase } from '@material-ui/core'
import { percentToHex } from 'js/utils/format'
import { useDispatch, useSelector } from 'react-redux'

import useScreenSize from 'js/hooks/useScreenSize'
import useIsCallAvailable from 'js/hooks/useIsCallAvailable'
import { announcePeerToPeer } from 'js/redux/peerToPeer/peerToPeer.actions'
import { createBlockedTooltip } from 'js/utils/creators'
import { CALL_TOOLTIP_TITLE } from 'js/var/const'
import { ReactComponent as NotesExport } from 'media/notes-export.svg'
import { selectDailyRoom, selectProfile } from 'js/redux/profile/profile.selectors'

import Button from '../Reusable/Button'
import Tooltip from '../Tooltip'

const useStyles = makeStyles(({ fonts, palette }) => ({
    ChatInput: {
        alignItems: 'center',
        backgroundColor: palette.white,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        height: '56px',
        padding: '16px',
        position: 'fixed',
        width: '100%',

        '&.isDesktop': {
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)',
            marginTop: 'auto',
            minHeight: '72px',
            padding: '16px 0',
            position: 'static',
            zIndex: 1,
        },
    },
    icon: {
        '& path': {
            fill: palette.primPurple,
        },

        '&.isBlocked': {
            opacity: 0.5,
        },
    },
    inputFocus: {
        borderBottom: `1px solid ${palette.fuchsiaBlue}`,
        boxShadow: '0px 0px 0px 4px rgba(114, 83, 204, 0.5)',
    },
    inputDisabled: {
        opacity: 0.5,
    },
    inputRoot: {
        ...fonts.s16l208,
        color: palette.black,
        boxSizing: 'border-box',
        display: 'flex',
        height: '40px',
        padding: '8px 16px',
        background: palette.fuchsiaBlue + percentToHex(10),
        border: 'none',
        borderBottom: `1px solid ${palette.inputBorder}`,
        outline: 'none',
        width: '100%',

        '&:placeholder': {
            ...fonts.s16l208,
            color: palette.black + percentToHex(50),
        },
    },
    inputInput: {
        width: '100%',
    },
    notesExport: {
        background: 'transparent',
        height: '32px',
        marginLeft: '8px',
        padding: 0,
        width: '32px',

        '& svg': {
            height: '32px',
            width: '32px',

            '& path': {
                fill: palette.primPurple,
            },
        },
    },
    tooltip: {
        ...fonts.b4.mob,
        backgroundColor: palette.white,
        borderRadius: '12px',
        color: palette.black,
        filter: 'drop-shadow(0px 6px 10px rgba(101, 101, 101, 0.25))',
        padding: '24px 32px',
    },
    videoChat: {
        background: 'transparent',
        height: '32px',
        marginRight: '8px',
        padding: 0,
        width: '32px',

        '& svg': {
            height: '32px',
            width: '32px',

            '& path': {
                fill: palette.primPurple,
            },
        },
    },
}))

const ChatInput = ({ isBlocked, initText, onSendMessage, remoteUid, toggleExport }) => {
    const dailyRoom = useSelector(selectDailyRoom)
    const screenSize = useScreenSize()
    const dispatch = useDispatch()
    const classes = useStyles()
    const profile = useSelector(selectProfile)
    const isVideoCallAvailable = useIsCallAvailable(remoteUid)

    const [msg, setMsg] = useState(initText)

    const onChangeMsg = (e) => setMsg(e.target.value)

    const sendMessage = () => {
        onSendMessage(msg)
        setMsg('')
    }

    const onSubmit = () => {
        if (!msg.length) {
            return
        }
        sendMessage()
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendMessage()
        }
    }

    const callToUser = () => {
        dispatch(announcePeerToPeer({ currentProfile: profile, dailyRoom, remoteUid }))
    }

    return (
        <div className={classNames(classes.ChatInput, screenSize)}>
            {remoteUid && (
                <Tooltip
                    disableHoverListener={isVideoCallAvailable && !isBlocked}
                    disableTouchListener={isVideoCallAvailable && !isBlocked}
                    title={isBlocked ? createBlockedTooltip('call') : CALL_TOOLTIP_TITLE}
                >
                    <Button
                        className={classes.videoChat}
                        disabled={!isVideoCallAvailable || isBlocked}
                        onClick={callToUser}
                    >
                        <VideoChatIcon />
                    </Button>
                </Tooltip>
            )}
            <InputBase
                classes={{
                    disabled: classes.inputDisabled,
                    focused: classes.inputFocus,
                    input: classes.inputInput,
                    root: classNames(classes.inputRoot, screenSize),
                }}
                endAdornment={
                    msg && (
                        <Tooltip
                            disableHoverListener={!isBlocked}
                            disableTouchListener={!isBlocked}
                            title={createBlockedTooltip('messaging')}
                        >
                            <SendRoundedIcon
                                className={classNames(classes.icon, { isBlocked })}
                                onClick={isBlocked ? null : onSubmit}
                            />
                        </Tooltip>
                    )
                }
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChangeMsg}
                onKeyDown={handleKeyDown}
                placeholder="Type Message Here..."
                value={msg}
            />
            {toggleExport && (
                <Button className={classes.notesExport} onClick={toggleExport}>
                    <NotesExport />
                </Button>
            )}
        </div>
    )
}

ChatInput.propTypes = {
    initText: string,
    isBlocked: bool,
    onSendMessage: func.isRequired,
    remoteUid: string,
    toggleExport: func,
}

ChatInput.defaultProps = {
    initText: '',
    isBlocked: false,
    remoteUid: '',
    toggleExport: null,
}

export default ChatInput

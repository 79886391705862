import logger from 'js/utils/logger'

import firebase, { getUid } from './firebase'

export const loadPeerSupporterLearning = () =>
    firebase
        .ref(`users/${getUid()}/mentorQuiz`)
        .once('value')
        .then((snapshot) => snapshot.val())
        .catch((e) => logger(e))

export const uploadPeerSupporterLearning = (data) =>
    firebase
        .database()
        .ref(`/users/${getUid()}/mentorQuiz`)
        .update(data)
        .catch((e) => logger(e))

import classNames from 'classnames'
import React from 'react'
import { bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import image from 'media/super-girl.svg'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    WhatIsMentor: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '50px',

        '&.isDesktop': {
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingTop: '62px',
        },
    },
    button: {
        ...buttons.def.dskt,
        marginTop: '40px',
        width: '240px',
    },
    img: {
        height: 'auto',
        maxHeight: '100%',
        width: '100%',
    },
    imgWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '310px',
        justifyContent: 'center',
        maxWidth: '310px',

        '&.isDesktop': {
            height: '142px',
            maxWidth: '240px',
        },
    },
    text: {
        ...fonts.b2.mob,
        color: palette.black,
        maxWidth: '312px',
        textAlign: 'center',

        '&.isDesktop': {
            ...fonts.b2.dskt,
            minHeight: '48px',
            maxWidth: '100%',
        },
    },
    title: {
        ...fonts.h2.mob,
        color: palette.darkestPurple,
        marginBottom: '20px',
        marginTop: '80px',

        '&.isDesktop': {
            ...fonts.h4.dskt,
            color: palette.midBlue,
            marginBottom: '16px',
            marginTop: '30px',
        },
    },
}))

const WhatIsMentor = ({ isDesktop, next }) => {
    const classes = useStyles()

    return (
        <div className={classNames(classes.WhatIsMentor, { isDesktop })}>
            <div className={classNames(classes.imgWrapper, { isDesktop })}>
                <img className={classes.img} src={image} alt="What is a Peer Supporter?" />
            </div>
            <p className={classNames(classes.title, { isDesktop })}>What is a Peer Supporter?</p>
            <p className={classNames(classes.text, { isDesktop })}>A trusted coach or guide.</p>
            {isDesktop && (
                <button className={classes.button} onClick={next} type="button">
                    Continue
                </button>
            )}
        </div>
    )
}

WhatIsMentor.propTypes = {
    isDesktop: bool.isRequired,
    next: func.isRequired,
}

export default WhatIsMentor

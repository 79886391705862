import { handleActions } from 'redux-actions'
import { setIsLoading } from './navigation.actions'

const defaultState = {
    isLoading: false,
}

const navigationReducer = handleActions(
    {
        [setIsLoading]: (state, { payload }) => ({
            ...state,
            isLoading: payload,
        }),
    },
    defaultState,
)

export default navigationReducer

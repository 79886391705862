import React from 'react'
import { func } from 'prop-types'

const IconLevel4 = ({ onClick }) => (
    <svg onClick={onClick} width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M40.4578 57.823C32.0556 57.823 28.2028 55.8784 28.2028 55.8784C28.4203 58.6326 31.1363 64.1411 40.2597 64.1411C49.3831 64.1411 51.8711 58.6326 51.9746 55.8784C51.9746 55.8784 48.8599 57.823 40.4578 57.823Z"
            fill="#FFB500"
            stroke="#FFB500"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="40.0886" cy="40.0015" r="37.5" stroke="#FFB500" strokeWidth="5" />
        <path
            d="M60.558 43.7344C60.558 46.6714 57.9889 44.2692 55.0519 44.2692C52.1149 44.2692 49.9222 46.6714 49.9222 43.7344C49.9222 40.7974 52.3031 38.4165 55.2401 38.4165C58.1771 38.4165 60.558 40.7974 60.558 43.7344Z"
            fill="#FFB500"
        />
        <path
            d="M32.722 43.7344C32.722 46.6714 30.1529 44.2692 27.2159 44.2692C24.2789 44.2692 22.0862 46.6714 22.0862 43.7344C22.0862 40.7974 24.4671 38.4165 27.4041 38.4165C30.3411 38.4165 32.722 40.7974 32.722 43.7344Z"
            fill="#FFB500"
        />
    </svg>
)

IconLevel4.propTypes = {
    onClick: func,
}

IconLevel4.defaultProps = {
    onClick: null,
}

export default IconLevel4

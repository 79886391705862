import { createTheme } from '@material-ui/core'

import buttons from './buttons'
import fields from './fields'
import fonts from './fonts'
import palette from './palette'
import others from './others'

const theme = createTheme({
    ...palette,
    buttons,
    fields,
    fonts,
    palette,
    others,
    titleText: {
        fontFamily: 'Raleway',
        fontSize: '35px',
        fontWeight: '600',
        lineHeight: '35px',
    },
    primaryText: {
        fontFamily: 'Raleway',
        fontSize: '16px',
        lineHeight: '19px',
    },
    smallText: {
        fontFamily: 'Raleway',
        fontSize: '13px',
        fontWeight: '400',
        lineHeight: '15px',
    },
    button: {
        alignItems: 'center',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '422px',
        outline: 'none',
        textTransform: 'capitalize',

        '&:disabled': {
            opacity: 0.5,
        },
    },
    buttonPrimary: {
        background: palette.primaryBlue,
        color: palette.white,

        '&:hover': {
            color: palette.white,
        },
    },
    buttonSecondary: {
        background: palette.white,
        border: `1px solid ${palette.primaryBlue}`,
        boxSizing: 'border-box',
        color: palette.primaryBlue,

        '&:hover': {
            color: palette.primaryBlue,
        },
    },
    buttonMobile: {
        borderRadius: '24px',
        fontWeight: 500,
        height: '40px',
        padding: '10px 32px',
    },
    buttonWeb: {
        borderRadius: '30px',
        fontWeight: 500,
        height: '48px',
        padding: '16px 32px',
    },
    popup: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: palette.primaryBlue,
        minHeight: '100px',
        borderRadius: '20px 20px 0 0',
        width: '100vw',
        position: 'relative',
        marginBottom: '-5px',
    },
})

export default theme

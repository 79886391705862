export const fillInterestsList = (interests) => {
    const interestsIds = Object.keys(interests)
    const interestsList = []

    interestsIds.forEach((id) => {
        const { text, type } = interests[id]

        interestsList.push({ id, text, type })
    })

    return interestsList
}

export const fillStructuredInterests = (interests, structuredInterests) => {
    const interestsIds = Object.keys(interests)
    const filledStructuredInterests = { ...structuredInterests }

    interestsIds.forEach((id) => {
        const { text, type } = interests[id]

        filledStructuredInterests[type].push({ id, text })
    })

    return filledStructuredInterests
}

import classNames from 'classnames'
import React from 'react'
import { bool, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ fonts, palette }) => ({
    CallButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        height: '64px',
        width: '64px',
        backgroundColor: palette.white,
    },
    text: {
        ...fonts.b2.mob,
        color: palette.white,
        textTransform: 'capitalize',
        marginTop: '12px',

        '&.isDesktop': {
            ...fonts.b2.dskt,
        },
    },
}))
const CallButton = ({ customClass, icon, isDesktop, onClick, text }) => {
    const classes = useStyles()
    return (
        <div className={classNames(classes.CallButton, customClass)} onClick={onClick}>
            <div className={classes.iconWrapper}>
                <img alt="button icon" className={classes.icon} src={icon} />
            </div>
            <p className={classNames(classes.text, { isDesktop })}>{text}</p>
        </div>
    )
}

CallButton.propTypes = {
    customClass: string,
    icon: string.isRequired,
    isDesktop: bool,
    onClick: func.isRequired,
    text: string.isRequired,
}

CallButton.defaultProps = {
    customClass: '',
    isDesktop: false,
}

export default CallButton

import React from 'react'
import { func } from 'prop-types'

const IconLevel1 = ({ onClick }) => (
    <svg onClick={onClick} width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40.442" cy="40.0259" r="37.5" stroke="#FFB500" strokeWidth="5" />
        <circle cx="27.2627" cy="44.0674" r="5.3179" fill="#FFB500" />
        <circle cx="54.0836" cy="44.0674" r="5.3179" fill="#FFB500" />
        <path
            d="M30.676 59.1192C30.676 59.1192 33.6736 58.2124 40.5043 59.2651C47.335 60.3178 50.0019 62.0976 50.0019 62.0976"
            stroke="#FFB500"
            strokeWidth="5"
            strokeLinecap="round"
        />
    </svg>
)

IconLevel1.propTypes = {
    onClick: func,
}

IconLevel1.defaultProps = {
    onClick: null,
}

export default IconLevel1

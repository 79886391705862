import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import image from 'media/boy-near-phone.svg'

import Step from '../Step'

const useStyles = makeStyles(({ fonts, palette }) => ({
    HowItWorks: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: '550px',
        padding: '50px 20px 0 20px',
        position: 'relative',
    },
    text: {
        ...fonts.h2.mob,
        color: palette.primPurple,
        height: '70px',
        textAlign: 'center',
        marginTop: '15px',
    },
    imgWrapper: {
        width: '100%',
    },
    img: {
        width: '100%',
        height: 'auto',
    },
    steps: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingBottom: '20%',
        width: '100%',
        height: '100%',
    },
}))

const HowItWorks = () => {
    const classes = useStyles()

    return (
        <div className={classes.HowItWorks}>
            <div className={classes.imgWrapper}>
                <img className={classes.img} src={image} alt="What is a Peer Supporter?" />
            </div>
            <p className={classes.text}>
                Great!
                <br />
                Here’s How it Works:
            </p>
            <div className={classes.steps}>
                <Step number={1} text="Search Peer Supporters to find who aligns with your interests." />
                <Step number={2} text="Select a Peer Supporter to contact." />
            </div>
        </div>
    )
}

export default HowItWorks

import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NewMessageBody from 'js/components/NewMessageBody'
import useScreenSize from 'js/hooks/useScreenSize'
import { addIdToPath } from 'js/utils/creators'
import { checkChatExist } from 'js/utils/checkers'
import { INBOX_PATH } from 'js/var/paths'
import { setIsLoading } from 'js/redux/navigation/navigation.actions'
import { sendMessageToChat } from 'js/redux/chats/chat.actions'
import { selectUid } from 'js/redux/profile/user'
import { createChatAxios } from 'js/APIs/axiosApi'
import { selectProfile } from 'js/redux/profile/profile.selectors'
import { selectChats, selectChatsUsers } from 'js/redux/chats/chat.selectors'

import Chat from '../Chat'

const useStyles = makeStyles(({ palette }) => ({
    chat: {
        marginLeft: '100px',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 118px)',
        position: 'relative',
        width: '632px',
    },
    content: {
        display: 'flex',
        position: 'relative',
        paddingTop: '112px',
    },
    NewChat: {
        backgroundColor: palette.white,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    sideBar: {
        height: 'fit-content',
        width: '360px',
    },
}))

const NewChat = () => {
    const classes = useStyles()
    const chats = useSelector(selectChats)
    const chatsWithUsersIds = useSelector(selectChatsUsers)
    const dispatch = useDispatch()
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const screenSize = useScreenSize()
    const uid = useSelector(selectUid)
    const [chatId, setChatId] = useState('')
    const [isSent, setIsSent] = useState(false)
    const [currentChat, setCurrentChat] = useState({})
    const [isSuggested, setIsSuggested] = useState(true)
    const [selectedRecipients, setSelectedRecipients] = useState([])
    const [searchString, setSearchString] = useState('')
    const { full_name, profile_picture } = useSelector(selectProfile)

    useEffect(() => {
        if (selectedRecipients.length > 0 || searchString) {
            setIsSuggested(false)
        }
    }, [searchString, selectedRecipients])

    useEffect(() => {
        setCurrentChat(chats.find((chat) => chat.id === chatId))
    }, [chatId, chats])

    useEffect(() => {
        const id = checkChatExist(
            chatsWithUsersIds,
            selectedRecipients.map((recipient) => recipient.uid),
        )

        setChatId(id || '')
    }, [selectedRecipients, chatsWithUsersIds])

    const toChat = (id) => navigate(addIdToPath(INBOX_PATH, id))

    const addRecipient = (recipient) => {
        if (selectedRecipients.find(({ uid }) => recipient.uid === uid)) {
            return
        }
        const newSelectedRecipients = [...selectedRecipients, recipient]
        setSelectedRecipients(newSelectedRecipients)
        inputRef.current.focus()
        inputRef.current.textContent = ''
        setSearchString('')
    }

    const deleteRecipient = (removedUid) => {
        setSelectedRecipients(selectedRecipients.filter(({ uid }) => uid !== removedUid))
    }

    const sendMessage = async (msg, chatId) => {
        dispatch(setIsLoading(true))

        await dispatch(
            sendMessageToChat({
                chatId,
                full_name,
                recipients: selectedRecipients.map(({ uid }) => uid),
                msg,
                profile_picture,
            }),
        )

        dispatch(setIsLoading(false))

        return toChat(chatId)
    }

    const onSendMessage = async (msg) => {
        if (isSent) {
            return
        }
        setIsSent(true)
        if (!msg) {
            return
        }

        if (chatId) {
            return sendMessage(msg, chatId)
        }

        const createdChatId = await createChatAxios({ users: [...selectedRecipients.map(({ uid }) => uid), uid] })

        setChatId(createdChatId?.data?.chatId)
        if (createdChatId?.data?.chatId) {
            await sendMessage(msg, createdChatId?.data?.chatId)
        }
    }

    if (screenSize.isMobile) {
        return (
            <NewMessageBody
                addRecipient={addRecipient}
                currentChat={currentChat}
                deleteRecipient={deleteRecipient}
                inputRef={inputRef}
                isSuggested={isSuggested}
                onSendMessage={onSendMessage}
                searchString={searchString}
                selectedRecipients={selectedRecipients}
                setSearchString={setSearchString}
            />
        )
    }

    return (
        <div className={classNames(classes.NewChat, screenSize)}>
            <div className={classes.content}>
                <div className={classes.sideBar}>
                    <NewMessageBody
                        addRecipient={addRecipient}
                        currentChat={currentChat}
                        deleteRecipient={deleteRecipient}
                        inputRef={inputRef}
                        isSuggested={isSuggested}
                        onSendMessage={onSendMessage}
                        searchString={searchString}
                        selectedRecipients={selectedRecipients}
                        setSearchString={setSearchString}
                    />
                </div>
                <div className={classNames(classes.chat)}>
                    {!!selectedRecipients.length && (
                        <Chat
                            chatId={currentChat?.id}
                            onChatCreation={onSendMessage}
                            isChatCreation
                            initUsers={selectedRecipients}
                            isDesktop
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default NewChat

import React from 'react'
import { bool, func } from 'prop-types'
import { FavoriteBorder } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'

import MobileDrawer from '../../MobileDrawer'

const useStyles = makeStyles((theme) => ({
    ComingSoonMobile: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '400px',
        justifyContent: 'center',

        '& svg': {
            height: '51px',
            width: '51px',

            '& path': {
                fill: theme.palette.midBlue,
            },
        },
    },
    text: {
        ...theme.fonts.h3.mob,
        color: theme.palette.midBlue,
        marginTop: '4px',
    },
}))

const ComingSoonMobile = ({ isOpen, onClose }) => {
    const classes = useStyles()

    return (
        <MobileDrawer isOpen={isOpen} onClose={onClose}>
            <div className={classes.ComingSoonMobile}>
                <FavoriteBorder />
                <p className={classes.text}>Coming soon!</p>
            </div>
        </MobileDrawer>
    )
}

ComingSoonMobile.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
}

export default ComingSoonMobile

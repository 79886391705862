import React from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { any, object } from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { makeStyles } from '@material-ui/core'
import ReactLinkify from 'react-linkify'

const useStyles = makeStyles(({ fonts, palette }) => ({
    bold: {
        fontWeight: 600,
    },
    text: {
        ...fonts.b2.mob,
        color: palette.black,
        marginBottom: '16px',
        whiteSpace: 'pre-wrap',
    },
    ul: {
        listStyle: 'inside',
        marginBottom: '16px',

        '& li': {
            '& p': {
                display: 'inline',
            },
        },
    },
    h2: {
        lineHeight: '1.3',
        marginBottom: '12px',
        fontSize: '1.5em',
        fontWeight: 'bolder',
    },
    img: {},
}))

const H2 = ({ children }) => {
    const classes = useStyles()

    return <h2 className={classes.h2}>{children}</h2>
}

H2.propTypes = {
    children: any.isRequired,
}

const Paragraph = ({ children }) => {
    const classes = useStyles()

    return <p className={classes.text}>{children}</p>
}

Paragraph.propTypes = {
    children: any.isRequired,
}

const Ul = ({ children }) => {
    const classes = useStyles()

    return <ul className={classes.ul}>{children}</ul>
}

Ul.propTypes = {
    children: any.isRequired,
}

const Bold = ({ children }) => {
    const classes = useStyles()

    return <b className={classes.bold}>{children}</b>
}

Bold.propTypes = {
    children: any.isRequired,
}

const EmbeddedContentfulAsset = ({
    data: {
        target: {
            fields: {
                title,
                file: { url },
            },
        },
    },
}) => {
    const classes = useStyles()

    return <img alt={title} className={classes.img} src={url} />
}

EmbeddedContentfulAsset.propTypes = {
    data: object,
}

EmbeddedContentfulAsset.defaultProps = {
    data: { target: { title: 'not found asset', fields: { file: { url: '' } } } },
}

const options = {
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
        [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
        [BLOCKS.UL_LIST]: (node, children) => <Ul>{children}</Ul>,
        [BLOCKS.EMBEDDED_ASSET]: EmbeddedContentfulAsset,
    },

    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
}

const parseRichText = (richTextDocument) => (
    <ReactLinkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
            <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'break-all' }}>
                {decoratedText}
            </a>
        )}
    >
        {documentToReactComponents(richTextDocument, options)}
    </ReactLinkify>
)

export default parseRichText

import { handleActions } from 'redux-actions'

import { countQuizSum } from 'js/utils/counters'

import {
    completeOverallAssessment,
    initOverallAssessment,
    startOverallAssessment,
    setOverallAssessmentSlide,
    toggleOverallAssessment,
    setOverallAssessmentAnswer,
} from './overallAssessment.actions'

const defaultState = {
    isOpen: false,
    isStarted: false,
    isCompleted: false,
    currentSlide: 0,
    answers: {},
}

const reducer = handleActions(
    {
        [completeOverallAssessment]: (state) => ({ ...state, isCompleted: true }),
        [toggleOverallAssessment]: (state) => ({
            ...state,
            isOpen: !state.isOpen,
        }),
        [initOverallAssessment]: (state, { payload }) => ({ ...state, ...payload }),
        [startOverallAssessment]: (state) => ({
            ...state,
            isStarted: !state.isStarted,
        }),
        [setOverallAssessmentAnswer]: (state, { payload: { key, data } }) => {
            const answers = { ...state.answers, [key]: data }

            return {
                ...state,
                answers,
                sum: countQuizSum(answers),
            }
        },
        [setOverallAssessmentSlide]: (state, { payload }) => ({
            ...state,
            currentSlide: payload,
        }),
    },
    defaultState,
)

export default reducer

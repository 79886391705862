import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { createNotificationAxios, removeDelayedNotificationAxios } from 'js/APIs/axiosApi'
import { removeCloudTaskIdsFromMessage, removeNotification, subsToNotifications } from 'js/APIs/firebase/chat.firebase'
import { responseStatus } from 'js/var/const'

import {
    NOTIFICATION_DELETE_SUCCESS,
    REMOVE_DELAYED_NOTIFICATION_SUCCESS,
    SUBS_NOTIFICATIONS_SUCCESS,
} from './notifications.types'

export const createNotification = async (data) => {
    try {
        const {
            data: { cloudTaskId },
        } = await createNotificationAxios({ ...data, url: process.env.REACT_APP_URL })

        return cloudTaskId
    } catch (error) {
        logger('Error of notification creation: ', error)
    }
}

export const removeDelayedNotificationSuccess = createAction(REMOVE_DELAYED_NOTIFICATION_SUCCESS)

export const removeDelayedNotification =
    ({ messageId, chatId, cloudTaskIds }) =>
    async (dispatch) => {
        try {
            const res = await removeDelayedNotificationAxios({ cloudTaskIds })

            if (res?.status === responseStatus.ok) {
                await removeCloudTaskIdsFromMessage({ messageId, chatId })
                dispatch(removeDelayedNotificationSuccess({ messageId, chatId }))
            }
        } catch (error) {
            logger('Error of delayed notification cloud tasks removing: ', error)
        }
    }

export const notificationDeleteSuccess = createAction(NOTIFICATION_DELETE_SUCCESS)

export const deleteNotification = (nid) => async (dispatch) => {
    try {
        await removeNotification(nid)
        dispatch(notificationDeleteSuccess(nid))
    } catch (e) {
        logger(`Error in notification deleting: ${e}`)
    }
}

export const subsNotificationsSuccess = createAction(SUBS_NOTIFICATIONS_SUCCESS)

export const subsNotifications = () => async (dispatch) => {
    try {
        const onReceiveNotification = (notif) => dispatch(subsNotificationsSuccess(notif))
        await subsToNotifications(onReceiveNotification)
    } catch (e) {
        logger(`Error of subscribe on notification${e}`)
    }
}

import React, { useEffect, useState } from 'react'
import { bool } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { initGoalVariants } from 'js/redux/goals/goals.actions'
import { selectGoalVariants } from 'js/redux/goals/goals.selectors'
import { setAssessmentData } from 'js/redux/assessment/assessment.actions'

import classNames from 'classnames'
import { selectGoalCategory } from 'js/redux/assessment/assessment.selectors'
import GoalVariant from './GoalVariant'

const useStyles = makeStyles(({ fonts, palette }) => ({
    SuggestedGoals: {
        padding: '0 28px 34px 28px',
    },
    SuggestedGoalsDesktop: {
        padding: '0 30px',
    },
    subtitle: {
        ...fonts.b1.mob,
        color: palette.darkestPurple,
        marginBottom: '4px',
    },
    subtitleDesktop: {
        ...fonts.h3.dskt,
        color: palette.black,
        marginBottom: '8px',
    },
    title: {
        ...fonts.h1.mob,
        color: palette.darkestPurple,
        marginBottom: '28px',
    },
    titleDesktop: {
        ...fonts.b2.dskt,
        color: palette.black,
        marginBottom: '32px',
    },
}))

const SuggestedGoals = ({ isDesktop }) => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const goalCategory = useSelector(selectGoalCategory)
    const goalVariants = useSelector(selectGoalVariants)
    const [selectedIndex, selectIndex] = useState(NaN)

    useEffect(() => {
        if (!goalVariants) {
            dispatch(initGoalVariants())
        }
    }, [dispatch, goalVariants])

    const selectGoalVariant = (i) => (title) => {
        dispatch(setAssessmentData({ title }))
        selectIndex(i)
    }

    return (
        <div className={classNames(classes.SuggestedGoals, { [classes.SuggestedGoalsDesktop]: isDesktop })}>
            <p className={classNames(classes.subtitle, { [classes.subtitleDesktop]: isDesktop })}>
                {isDesktop ? 'Suggested Goals' : 'Pick a Suggested'}
            </p>
            <p className={classNames(classes.title, { [classes.titleDesktop]: isDesktop })}>
                {isDesktop ? 'To help you get started, pick a goal.' : 'Goal'}
            </p>
            {goalVariants &&
                goalVariants[goalCategory] &&
                goalVariants[goalCategory].map((goalVariant, i) => (
                    <GoalVariant
                        isDesktop={isDesktop}
                        isSelected={i === selectedIndex}
                        key={i}
                        text={goalVariant}
                        onClick={selectGoalVariant(i)}
                    />
                ))}
        </div>
    )
}

SuggestedGoals.propTypes = {
    isDesktop: bool,
}

SuggestedGoals.defaultProps = {
    isDesktop: false,
}

export default SuggestedGoals

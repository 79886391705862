import { createSelector } from 'reselect'

export const selectProfile = ({ profile }) => profile

export const selectDailyRoom = createSelector(selectProfile, ({ dailyRoom }) => dailyRoom)

export const selectIsStaff = createSelector(selectProfile, ({ isStaff }) => isStaff)

export const selectName = createSelector(selectProfile, ({ full_name }) => full_name)

export const selectProfilePictureRefPath = createSelector(
    selectProfile,
    ({ profilePictureRefPath }) => profilePictureRefPath,
)

export const selectTempProfilePicture = createSelector(selectProfile, ({ tempProfilePicture }) => tempProfilePicture)

export const selectTempProfilePicturePath = createSelector(
    selectProfile,
    ({ tempProfilePicturePath }) => tempProfilePicturePath,
)

export const selectZipCode = createSelector(selectProfile, ({ zipCode }) => zipCode)

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { generateBadge } from 'js/utils/format'
import { selectAllNotifications, selectNewNotification } from 'js/redux/notifications/notifications.selectors'

const useNotificationsBadge = () => {
    const [badge, setBadge] = useState('')
    const [notificationIds, setNotificationIds] = useState([])

    const allNotifications = useSelector(selectAllNotifications)

    const newNotification = useSelector(selectNewNotification)
    const newNotificationId = newNotification?.nid

    useEffect(() => {
        if (allNotifications.length !== badge) {
            const notifications = allNotifications.map(({ nid }) => nid)
            setBadge(generateBadge(allNotifications.length))
            setNotificationIds(notifications)
        }
    }, [allNotifications, allNotifications.length, badge])

    useEffect(() => {
        if (newNotification && notificationIds?.indexOf(newNotificationId) === -1) {
            notificationIds.push(newNotificationId)
            setBadge(generateBadge(notificationIds.length))
        }
    }, [newNotification, newNotificationId, notificationIds])

    return badge
}

export default useNotificationsBadge

import firebase, { getTimestamp, getUid } from './firebase'

export const updateCheckInStreak = (checkInStreak) => {
    const uid = getUid()

    if (!uid) {
        return
    }

    firebase.database().ref(`users/${uid}`).update({ checkInStreak })
}

export const updateLastCheckInDate = () => {
    const uid = getUid()

    if (!uid) {
        return
    }

    return firebase.database().ref(`users/${uid}`).update({ lastCheckInDate: getTimestamp() })
}

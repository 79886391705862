import { setFeatureOptionsFirebase } from 'js/APIs/firebase/featureOptions.firebase'
import { createAction } from 'redux-actions'

import { CHANGE_FEATURE_OPTIONS } from './featureOptions.types'

export const changeFeatureOptionsAction = createAction(CHANGE_FEATURE_OPTIONS)

export const changeFeatureOptions = (options) => async (dispatch) => {
    if (!options) {
        return
    }
    await setFeatureOptionsFirebase(options)
    dispatch(changeFeatureOptionsAction(options))
}

export default { changeFeatureOptions }

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { func, string } from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import EventRounded from 'js/components/icons/EventRounded'
import FileAlt from 'js/components/icons/FileAlt'
import logo from 'media/logo.png'
import Notifications from 'js/components/icons/Notifications'
import Public from 'js/components/icons/Public'
import {
    ABOUT_APP_PATH,
    COMMUNICATION_PATH,
    SESSIONS_PATH,
    GOALS_HISTORY_PATH,
    HOME_PATH,
    INBOX_PATH,
    MY_GOALS_PATH,
    RATING_PATH,
    RESOURCES_PATH,
    CARE_PATH,
    VIDEO_CALL_PATH,
} from 'js/var/paths'
import { ContactMentors, Home, ResourcesIcon } from 'js/components/icons'
import { FEATURE_OPTIONS, HEADER_BUTTON_WIDTH, WEBSITE_URL } from 'js/var/const'
import { createSelectIsOption } from 'js/redux/featureOptions/featureOptions.selectors'
import { selectIsStaff } from 'js/redux/profile/profile.selectors'
import { selectPasswordPopup } from 'js/redux/passwordUpdate/passwordUpdate.selectors'

import AccountOptions from './AccountOptions'
import HeaderLink from './HeaderLink'

const useStyles = makeStyles(({ others: { navigationSelector }, palette: { dustBlue, primPurple, white } }) => ({
    HeaderDesktop: {
        alignItems: 'center',
        backgroundColor: white,
        borderBottom: `1px solid ${dustBlue}`,
        display: 'flex',
        height: '76px',
        justifyContent: 'space-between',
        left: 0,
        right: 0,
        paddingLeft: '4.44%',
        paddingRight: '4.44%',
        position: 'fixed',
        top: 0,
        zIndex: 100,

        '&.isHidden': {
            display: 'none',
        },
    },
    buttons: {
        display: 'flex',
        position: 'relative',
    },
    logo: {
        height: 'auto',
        width: '144px',
    },
    image: {
        width: '31px',
        height: '31px',
    },
    notificationContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '76px',
        justifyContent: 'space-between',
        padding: ' 17px 0 6px 0',
        position: 'relative',
        width: '66px',
    },
    notificationContainerLightTheme: {
        '& svg': {
            '& path': {
                fill: primPurple,
            },
        },
    },

    spring: {
        ...navigationSelector,
        zIndex: 100,
    },
    track: {
        bottom: '2px',
        position: 'absolute',
        width: '100%',
    },
}))

const HeaderDesktop = ({ badge, toInbox }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const isStaff = useSelector(selectIsStaff)
    const isGoalsOn = useSelector(createSelectIsOption(FEATURE_OPTIONS.goals))
    const isResourcesOn = useSelector(createSelectIsOption(FEATURE_OPTIONS.resources))
    const [activeSection, setActiveSection] = useState(-1)
    const { pathname } = useLocation()
    const { isPasswordUpdatePopupOpen } = useSelector(selectPasswordPopup)

    useEffect(() => {
        const checkIsActive = (activePaths, index) => {
            const isActive = !!activePaths.find((path) => pathname.match(path))

            if (isActive) {
                setActiveSection(index)
            }

            return isActive
        }
        let userPaths = [
            [HOME_PATH, GOALS_HISTORY_PATH],
            [MY_GOALS_PATH],
            [CARE_PATH],
            [RESOURCES_PATH],
            [SESSIONS_PATH],
            [COMMUNICATION_PATH, INBOX_PATH],
        ]

        if (!isResourcesOn && !isStaff) {
            userPaths = userPaths.filter((routes) => !routes.includes(RESOURCES_PATH))
        }
        if (!isGoalsOn && !isStaff) {
            userPaths = userPaths.filter((routes) => !routes.includes(MY_GOALS_PATH))
        }

        const paths = isStaff ? [[HOME_PATH], [SESSIONS_PATH], [INBOX_PATH]] : userPaths

        setActiveSection(-1)
        paths.forEach((paths, i) => checkIsActive(paths, i))
    }, [isStaff, pathname, isGoalsOn, isResourcesOn])

    const isHidden =
        (RATING_PATH === pathname && !pathname.match(VIDEO_CALL_PATH)) ||
        isPasswordUpdatePopupOpen ||
        pathname.match(ABOUT_APP_PATH)

    const isInbox = pathname.includes(INBOX_PATH)
    const isEvents = pathname.includes(SESSIONS_PATH)

    const toHome = () => navigate(HOME_PATH)

    const onInboxClick = (e) => {
        e.stopPropagation()

        setActiveSection(5)
        toInbox()
    }

    return (
        <div className={classNames(classes.HeaderDesktop, { isHidden })}>
            <img alt="logo" className={classes.logo} onClick={toHome} src={logo} />
            <div>
                <div className={classes.buttons}>
                    {activeSection !== -1 && (
                        <div className={classes.track}>
                            <div
                                style={{
                                    left: `calc((${HEADER_BUTTON_WIDTH} + 12px - 46px) / 2 + ${activeSection} * (${HEADER_BUTTON_WIDTH} + 12px) )`,
                                }}
                                className={classes.spring}
                            />
                        </div>
                    )}
                    <HeaderLink Icon={Home} isActive={activeSection === 0} path={HOME_PATH} title="Home" />
                    {!isStaff && (
                        <>
                            {isGoalsOn && (
                                <HeaderLink
                                    Icon={FileAlt}
                                    isActive={activeSection === 1}
                                    path={MY_GOALS_PATH}
                                    title="My Goals"
                                />
                            )}
                            <HeaderLink
                                Icon={ContactMentors}
                                isActive={activeSection === 2}
                                path={CARE_PATH}
                                title="Support"
                            />
                            {isResourcesOn && (
                                <HeaderLink
                                    Icon={ResourcesIcon}
                                    isActive={activeSection === 3}
                                    path={RESOURCES_PATH}
                                    title="Resources"
                                />
                            )}
                        </>
                    )}
                    <HeaderLink Icon={EventRounded} isActive={isEvents} path={SESSIONS_PATH} title="Events" />
                    <HeaderLink
                        badge={badge}
                        Icon={Notifications}
                        isActive={isInbox}
                        title="Messages"
                        onClick={onInboxClick}
                    />
                    <HeaderLink path={WEBSITE_URL} isExternalLink Icon={Public} title="Our Website" />
                    <AccountOptions />
                </div>
            </div>
        </div>
    )
}

HeaderDesktop.propTypes = {
    badge: string.isRequired,
    toInbox: func.isRequired,
}

export default HeaderDesktop

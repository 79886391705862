import { getTimestamp, pushDataFirebase } from './firebase'

export const setFeedbackFirebase = async ({ author, callType, feedback, owner, rating }) =>
    pushDataFirebase({
        path: `videoCallFeedbacks`,
        data: {
            author,
            callType,
            createdAt: getTimestamp(),
            date: new Date().toISOString(),
            feedback,
            owner,
            rating,
        },
    })

export default { setFeedbackFirebase }

import React from 'react'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import image from 'media/boy-near-phone.svg'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    FindYourMentor: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        padding: '57px 40px 66px 40px',
        width: '100%',
    },
    button: {
        ...buttons.def.dskt,
        width: '240px',
        marginTop: 'auto',
    },
    img: {
        height: 'auto',
        maxHeight: '100%',
        width: '100%',
    },
    imgWrapper: {
        height: '140px',
        marginBottom: '33px',
        maxHeight: '140px',
    },
    text: {
        ...fonts.b2.dskt,
        color: palette.black,
        textAlign: 'center',
    },
    title: {
        ...fonts.h4.dskt,
        color: palette.midBlue,
        marginBottom: '16px',
    },
}))

const FindYourMentor = ({ next }) => {
    const classes = useStyles()

    return (
        <div className={classes.FindYourMentor}>
            <div className={classes.imgWrapper}>
                <img className={classes.img} src={image} alt="Standing" />
            </div>
            <p className={classes.title}>Find your Peer Supporter</p>
            <div className={classes.text}>
                Browse through our Peer Supporters to find someone who shares your interests.
            </div>
            <button className={classes.button} onClick={next} type="button">
                Continue
            </button>
        </div>
    )
}

FindYourMentor.propTypes = {
    next: func.isRequired,
}

export default FindYourMentor

export const ADD_RESOURCE_BOOKMARK = 'ADD_RESOURCE_BOOKMARK'
export const ADD_RESOURCE_BOOKMARK_ERROR = 'ADD_RESOURCE_BOOKMARK_ERROR'
export const ADD_RESOURCE_BOOKMARK_SUCCESS = 'ADD_RESOURCE_BOOKMARK_SUCCESS'

export const DELETE_RESOURCE_BOOKMARK = 'DELETE_RESOURCE_BOOKMARK'
export const DELETE_RESOURCE_BOOKMARK_ERROR = 'DELETE_RESOURCE_BOOKMARK_ERROR'
export const DELETE_RESOURCE_BOOKMARK_SUCCESS = 'DELETE_RESOURCE_BOOKMARK_SUCCESS'

export const GET_RESOURCES = 'GET_RESOURCES'
export const GET_RESOURCES_ERROR = 'GET_RESOURCES_ERROR'
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS'

export const RESOURCE_BOOKMARK_POPUP_SET = 'RESOURCE_BOOKMARK_POPUP_SET'
export const RESOURCE_TAB_SET = 'RESOURCE_TAB_SET'
export const RESOURCE_RECENTLY_VIEWED_ADD = 'RESOURCE_RECENTLY_VIEWED_ADD'
export const RESOURCES_TYPE_SET = 'RESOURCES_TYPE_SET'

import { handleActions } from 'redux-actions'

import { compareDates } from 'js/utils/comparators'
import { hasChatMessage } from 'js/utils/checkers'

import {
    chatSubscribedSuccess,
    clearChatIdSuccess,
    clearNewMessageSuccess,
    createMessageStart,
    getChatsSuccess,
    getChatMessagesSuccess,
    getUserDataSuccess,
    onReceivedMessageSuccess,
    getChatSuccess,
    setExportChatDate,
} from './chat.actions'
import {
    getConnectionRequest,
    rejectConnectionsRequestAction,
    removeConnectionsRequestAction,
} from '../staff/staff.actions'
import { removeDelayedNotificationSuccess } from '../notifications/notifications.actions'

const defaultState = {
    chats: [],
    exportChatDate: 0,
    subscribedChatId: '',
}

const chatReducer = handleActions(
    {
        [chatSubscribedSuccess]: (state, { payload }) => ({
            ...state,
            subscribedChatId: payload,
        }),
        [clearChatIdSuccess]: (state) => ({
            ...state,
            chatId: false,
        }),
        [clearNewMessageSuccess]: (state) => ({
            ...state,
            messages: [],
            ouid: '',
            ouidData: null,
            newMessage: '',
        }),
        [createMessageStart]: (state, action) => {
            const { chats } = state
            const { chatId, message } = action.payload

            const newChats = chats.map((chat) =>
                chat.id === chatId ? { ...chat, messages: [...chat.messages, message] } : chat,
            )

            return {
                ...state,
                chats: newChats,
            }
        },
        [getConnectionRequest]: (state, { payload: { chatId, connectionsRequest } }) => ({
            ...state,
            chats: state.chats.map((chat) => {
                if (chat.id === chatId) {
                    return { ...chat, connectionsRequest }
                }
                return chat
            }),
        }),
        [getChatsSuccess]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [getChatMessagesSuccess]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [getChatSuccess]: (state, { payload: newChat }) => {
            if (state.chats.find(({ id }) => id === newChat.id)) {
                return state
            }
            return {
                ...state,
                chats: [...state.chats, newChat],
            }
        },
        [getUserDataSuccess]: (state, action) => ({
            ...state,
            ouidData: action.payload,
        }),
        [onReceivedMessageSuccess]: (state, action) => {
            const { chats } = state
            const { chatId, message } = action.payload

            const newChats = chats.map((chat) =>
                chat.id === chatId && !hasChatMessage(chat.messages, message)
                    ? {
                          ...chat,
                          messages: [...chat.messages.filter(({ data: { isLoading } }) => !isLoading), message].sort(
                              ({ data: { createdAt: a } }, { data: { createdAt: b } }) => compareDates(a, b),
                          ),
                      }
                    : chat,
            )

            return {
                ...state,
                chats: newChats,
                newMessage: message,
            }
        },

        [rejectConnectionsRequestAction]: (state, { payload: chatId }) => ({
            ...state,
            chats: state.chats.map((chat) =>
                chat.id === chatId
                    ? { ...chat, connectionsRequest: { ...chat.connectionsRequest, isRejected: true } }
                    : chat,
            ),
        }),

        [removeConnectionsRequestAction]: (state, { payload: chatId }) => ({
            ...state,
            chats: state.chats.map((chat) => (chat.id === chatId ? { ...chat, connectionsRequest: undefined } : chat)),
        }),
        [removeDelayedNotificationSuccess]: (state, { payload: { chatId, messageId } }) => ({
            ...state,
            chats: state.chats.map((chat) => {
                if (chat.id === chatId) {
                    return {
                        ...chat,
                        messages: chat.messages.map((message) => {
                            if (message.mid === messageId) {
                                return { ...message, data: { ...message.data, cloudTaskIds: undefined } }
                            }

                            return message
                        }),
                    }
                }

                return chat
            }),
        }),
        [setExportChatDate]: (state, { payload }) => ({ ...state, exportChatDate: payload }),
    },
    defaultState,
)

export default chatReducer

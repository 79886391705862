import React from 'react'
import { bool, number, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as WaterDropIcon } from 'media/water-drop.svg'
import { StarBorder } from '@mui/icons-material'

import palette from 'styles/palette'
import { percentToHex } from 'js/utils/format'

const useStyles = makeStyles(({ fonts, palette }) => ({
    Reward: {
        display: 'flex',
        margin: '8px 0',
        width: '100%',

        '& svg': {
            height: '32px',
            marginLeft: '8px',
            width: '32px',

            '& path:': {
                fill: 'inherit',
            },
        },
    },
    progressBar: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    scale: {
        borderRadius: '6px',
        height: '12px',
        padding: '3px',
        width: '100%',
    },
    progress: {
        borderRadius: '3px',
        height: '6px',
        width: '28%',
    },
    title: {
        ...fonts.s12l16,
        color: palette.doveGrey,
        marginBottom: '4px',
    },
}))

const Reward = ({ isCheckIn, isIcon, progress, title }) => {
    const classes = useStyles()

    const color = isCheckIn ? palette.dodgerBlue : palette.ronchi

    return (
        <div className={classes.Reward}>
            <div className={classes.progressBar}>
                <p className={classes.title}>{title}</p>
                <div className={classes.scale} style={{ backgroundColor: color + percentToHex(25) }}>
                    <div className={classes.progress} style={{ backgroundColor: color, width: `${progress}%` }} />
                </div>
            </div>
            {isIcon &&
                (isCheckIn ? (
                    <WaterDropIcon
                        style={{
                            fill: color,
                        }}
                    />
                ) : (
                    <StarBorder
                        style={{
                            fill: color,
                        }}
                    />
                ))}
        </div>
    )
}

Reward.propTypes = {
    isCheckIn: bool,
    isIcon: bool,
    progress: number,
    title: string.isRequired,
}

Reward.defaultProps = {
    isIcon: true,
    isCheckIn: false,
    progress: 24,
}

export default Reward

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { func, object } from 'prop-types'
import { useSelector } from 'react-redux'

import Check from 'js/components/Check'
import DatePicker from 'js/components/DatePicker'
import Input from 'js/components/Input'
import useScreenSize from 'js/hooks/useScreenSize'
import { createPermanentMessage, createTemporaryMessage } from 'js/utils/creators'
import { selectProfile } from 'js/redux/profile/profile.selectors'
import { TODAY } from 'js/var/time'

const useStyles = makeStyles(({ fonts, palette }) => ({
    Away: {
        backgroundColor: palette.white,
        marginBottom: '8px',

        '&.isTablet, &.isDesktop': {
            marginBottom: 0,
            padding: '16px 0',
        },
    },
    fieldTitle: {
        ...fonts.s16l208,
        fontWeight: 700,
        marginBottom: '4px',
    },
    textarea: {
        height: '100px',
        marginBottom: '16px',
        padding: '16px',
        width: '100%',
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
    },
    timeFields: {
        display: 'flex',
        height: '53px',
        justifyContent: 'space-between',
        marginBottom: '16px',
        width: 'fit-content',
    },
    to: {
        ...fonts.b2.dskt,
        alignItems: 'center',
        backgroundColor: palette.white,
        boxSizing: 'border-box',
        color: palette.black,
        display: 'flex',
        height: '48px',
        justifyContent: 'center',
        padding: '16px',
        width: 'fit-content',
    },
    title: {
        ...fonts.s20l26,
        marginBottom: '4px',
    },
}))

const Away = ({ away, setAway }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()
    const { full_name } = useSelector(selectProfile)
    const [wasPermanentMessageInit, setPermanentMessageInit] = useState(false)
    const [wasTemporaryMessageInit, setTemporaryMessageInit] = useState(false)

    const createToggle = (field) => () => setAway({ ...away, [field]: !away[field] })

    const toggleTemporary = () => {
        const newAway = { ...away, isTemporary: !away.isTemporary }

        if (newAway.isTemporary && !newAway.start) {
            newAway.start = TODAY.toDate()
        }

        if (newAway.isTemporary && !newAway.end) {
            newAway.end = TODAY.toDate()
        }

        setAway(newAway)
    }

    const createSet =
        (field) =>
        ({ target: { value } }) =>
            setAway({ ...away, [field]: value })

    const onTemporaryMessageChange = ({ target: { value } }) => {
        setTemporaryMessageInit(true)
        setAway({ ...away, temporaryMessage: value })
    }

    const onEndChange = (value) => setAway({ ...away, end: value })

    const onStartChange = (value) => {
        if (value.getTime() > new Date(away.end || TODAY.toDate()).getTime()) {
            return setAway({ ...away, start: value, end: value })
        }

        setAway({ ...away, start: value })
    }

    useEffect(() => {
        if (away.permanentMessage) {
            setPermanentMessageInit(true)
        }

        if (away.isPermanent && !away.permanentMessage && !wasPermanentMessageInit) {
            setAway({ ...away, permanentMessage: createPermanentMessage(full_name) })
        }

        if (away.temporaryMessage) {
            setTemporaryMessageInit(true)
        }
        if (away.isTemporary && !away.temporaryMessage && !wasTemporaryMessageInit) {
            setAway({
                ...away,
                temporaryMessage: createTemporaryMessage(full_name),
            })
        }
    }, [away, setAway])

    return (
        <Box className={classNames(classes.Away, screenSize)}>
            <p className={classes.title}>Set away message</p>
            <p className={classes.text}>Here you can set away status and away message for clients</p>
            <Check
                isDisabled={away.isPermanent}
                isChecked={away.isTemporary}
                onChange={toggleTemporary}
                style={{ marginBottom: '16px' }}
                text="My clients will see temporary away message"
            />
            {away.isTemporary && (
                <>
                    <p className={classes.fieldTitle}>Away time period</p>
                    <div className={classes.timeFields} style={{ marginTop: '16px' }}>
                        <DatePicker
                            customInput={<Input style={{ height: '53px', width: '125px' }} type="button" />}
                            dateFormat="MM/dd/yyyy"
                            formatWeekDay={(nameOfDay) => nameOfDay[0]}
                            onChange={onStartChange}
                            minDate={TODAY.toDate()}
                            selected={new Date(away.start || TODAY.toDate())}
                        />
                        <span className={classes.to}>To</span>
                        <DatePicker
                            customInput={<Input style={{ height: '53px', width: '125px' }} type="button" />}
                            dateFormat="MM/dd/yyyy"
                            formatWeekDay={(nameOfDay) => nameOfDay[0]}
                            onChange={onEndChange}
                            minDate={new Date(away.start || TODAY.toDate())}
                            selected={new Date(away.end || TODAY.toDate())}
                        />
                    </div>
                    <p className={classes.fieldTitle}>Temporary away message for clients</p>
                    <Input
                        className={classes.textarea}
                        isTextarea
                        onChange={onTemporaryMessageChange}
                        placeholder="Left message here"
                        value={away.temporaryMessage}
                    />
                </>
            )}
            <Check
                isDisabled={away.isTemporary}
                isChecked={away.isPermanent}
                onChange={createToggle('isPermanent')}
                style={{ marginBottom: '16px' }}
                text="My clients will see permanent away message"
            />
            {away.isPermanent && (
                <>
                    <p className={classes.fieldTitle}>Permanent away message for clients</p>
                    <Input
                        className={classes.textarea}
                        isTextarea
                        onChange={createSet('permanentMessage')}
                        placeholder="Left message here"
                        value={away.permanentMessage}
                    />
                </>
            )}
        </Box>
    )
}

Away.propTypes = {
    away: object.isRequired,
    setAway: func.isRequired,
}

export default Away

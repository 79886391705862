import React from 'react'
import { func } from 'prop-types'

const IconCheckMark = ({ onClick }) => (
    <svg onClick={onClick} width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.44284 10.3673L2.13305 7.05756C1.95484 6.87895 1.7129 6.77858 1.4606 6.77858C1.2083 6.77858 0.966356 6.87895 0.78815 7.05756C0.416157 7.42955 0.416157 8.03047 0.78815 8.40246L4.77516 12.3895C5.14715 12.7615 5.74806 12.7615 6.12006 12.3895L16.2116 2.29795C16.5836 1.92596 16.5836 1.32505 16.2116 0.953054C16.0334 0.774447 15.7914 0.674072 15.5391 0.674072C15.2868 0.674072 15.0449 0.774447 14.8667 0.953054L5.44284 10.3673Z" />
    </svg>
)

IconCheckMark.propTypes = {
    onClick: func,
}

IconCheckMark.defaultProps = {
    onClick: null,
}

export default IconCheckMark

import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { createConnectionAxios, sendFirstRequestNotificationAxios } from 'js/APIs/axiosApi'
import { getServices, getStaff } from 'js/APIs/contentfulClient'

import { HOME_PATH } from 'js/var/paths'
import { getUid } from 'js/APIs/firebase/firebase'
import {
    APPROVE_CONNECTIONS_REQUEST,
    GET_CONNECTIONS_REQUEST,
    REJECT_CONNECTIONS_REQUEST,
    REMOVE_CONNECTIONS_REQUEST,
    SAVE_SUGGESTED_STAFF,
    SET_CONNECTIONS,
    SET_CONNECTIONS_REQUEST,
    SET_FIND_CARE_SLIDE,
    SET_PREFERRED_SERVICE,
    SET_PREFERRED_SPECIALTIES,
    SET_SERVICES,
    SET_SPECIALTIES,
    SET_STAFF,
    SET_SUGGESTED_STAFF,
    TOGGLE_FIND_CARE,
} from './staff.types'
import {
    getConnectionRequestTime,
    putConnectionRequestToChatFirebase,
    signOnConnectionsFirebase,
    signOnSuggestion,
    updateSuggested,
} from '../../APIs/firebase/staff.firebase'

export const setConnections = createAction(SET_CONNECTIONS)
export const getConnectionRequest = createAction(GET_CONNECTIONS_REQUEST)

export const signOnConnections = () => (dispatch) =>
    signOnConnectionsFirebase((connections) => dispatch(setConnections(Object.keys(connections || {}))))

const setConnectionsRequest = createAction(SET_CONNECTIONS_REQUEST)

export const sendConnectionRequest =
    ({ chatId, requestData, staffUid }) =>
    async (dispatch) => {
        try {
            const lastRequestTime = await getConnectionRequestTime(chatId)

            if (!lastRequestTime) {
                const uid = getUid()
                const url = `${process.env.REACT_APP_URL}/#${HOME_PATH}`

                await sendFirstRequestNotificationAxios({ chatId, staffUid, uid, url })
            }
            await putConnectionRequestToChatFirebase(chatId, requestData)

            dispatch(setConnectionsRequest())
        } catch (error) {
            logger(`Error of connection request sending: ${error}`)
        }
    }

const approveConnectionsRequestAction = createAction(APPROVE_CONNECTIONS_REQUEST)

export const approveConnectionsRequest = (chatId, requestData) => async (dispatch) => {
    await putConnectionRequestToChatFirebase(chatId, { ...requestData, isApproved: true })
    await createConnectionAxios({ users: [requestData.from, requestData.to] })
    dispatch(approveConnectionsRequestAction())
}

export const rejectConnectionsRequestAction = createAction(REJECT_CONNECTIONS_REQUEST)

export const rejectConnectionsRequest = (chatId, requestData) => async (dispatch) => {
    await putConnectionRequestToChatFirebase(chatId, { ...requestData, isRejected: true })

    dispatch(rejectConnectionsRequestAction(chatId))
}

export const removeConnectionsRequestAction = createAction(REMOVE_CONNECTIONS_REQUEST)

export const removeConnectionsRequest = (chatId) => async (dispatch) => {
    await putConnectionRequestToChatFirebase(chatId, null)
    dispatch(removeConnectionsRequestAction(chatId))
}

export const setStaff = createAction(SET_STAFF)

export const setServices = createAction(SET_SERVICES)

export const loadStaff = () => async (dispatch) => {
    try {
        const staff = await getStaff()
        const services = await getServices()

        if (staff?.length) {
            const staffServices = new Set()

            staff.forEach(({ servicesOffered }) => staffServices.add(...servicesOffered))

            dispatch(setServices(services.filter(({ slug }) => staffServices.has(slug))))

            dispatch(setStaff(staff))
        }
    } catch (error) {
        logger('Error of staff loading: ', error)
    }
}

export const setPreferredService = createAction(SET_PREFERRED_SERVICE)

export const setPreferredSpecialties = createAction(SET_PREFERRED_SPECIALTIES)

export const setSuggestedStaff = createAction(SET_SUGGESTED_STAFF)

export const setSpecialties = createAction(SET_SPECIALTIES)

export const toggleFindCare = createAction(TOGGLE_FIND_CARE)

export const saveSuggested = createAction(SAVE_SUGGESTED_STAFF)

export const initSuggestions = () => async (dispatch) => {
    const handleSuggestion = (snapshot) => {
        const suggestionsIds = snapshot.val()

        if (!suggestionsIds) {
            return
        }

        dispatch(setSuggestedStaff(Object.keys(suggestionsIds)))
    }

    await signOnSuggestion(handleSuggestion)
}

export const saveSuggestedStaff = (suggestedStaff) => async (dispatch) => {
    await updateSuggested({
        suggestedStaff: suggestedStaff.reduce((acc, curr) => {
            acc[curr] = true

            return acc
        }, {}),
    })
    dispatch(saveSuggested())
}

export const setFindCareSlide = createAction(SET_FIND_CARE_SLIDE)

import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { getEmail, getUid } from 'js/APIs/firebase/firebase'

import { sendEmailAxios } from 'js/APIs/axiosApi'
import { showResourceBookmark } from '../resources/resources.actions'
import { CONTACT_US_RESET, CONTACT_US_SEND, CONTACT_US_SEND_SUCCESS } from './contactUs.types'

export const contactUsReset = createAction(CONTACT_US_RESET)

export const contactUsSend = createAction(CONTACT_US_SEND)
export const contactUsSendSuccess = createAction(CONTACT_US_SEND_SUCCESS)

export const sendContactUs =
    ({ fullName, message }) =>
    async (dispatch) => {
        dispatch(contactUsSend())
        try {
            const uid = await getUid()
            const email = await getEmail()
            const subject = `Mindfully app new FAQ message from ${fullName}`
            const text = `Hello,

            You got a new FAQ message from ${fullName} (uid: ${uid}, email: ${email}):
            
            ${message}
           `
            await sendEmailAxios({ subject, text, to: process.env.REACT_APP_CONTACT_US_EMAIL })

            dispatch(contactUsSendSuccess())
        } catch (e) {
            logger(`Error of contact us message sending: ${e}`)
            dispatch(showResourceBookmark({ message: 'Error of contact us message sending' }))
        }
    }

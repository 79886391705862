import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import DailyIframe from '@daily-co/daily-js'
import PeopleRounded from '@mui/icons-material/PeopleRounded'
import { makeStyles, Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import logger from 'js/utils/logger'
import { CALL_TYPE } from 'js/var/const'
import { JOIN_REQUEST_REJECTED, MEETING_HAS_ENDED } from 'js/var/dailyCo'
import { finishGroupCall } from 'js/redux/groupCalls/groupCalls.actions'
import { finishPeerToPeerCall } from 'js/redux/peerToPeer/peerToPeer.actions'
import { leaveGuestList, finishScheduleMeeting } from 'js/redux/scheduledMeetings/scheduledMeetings.actions'
import { selectGroupCallRoomId, selectStartedCallDailyCoName } from 'js/redux/groupCalls/groupCalls.selectors'
import {
    selectVideoPopupCallType,
    selectVideoPopupId,
    selectVideoPopupIsOwner,
    selectVideoPopupOpen,
    selectVideoPopupUrl,
} from 'js/redux/videoPopup/videoPopup.selectors'
import { selectPeerToPeerRemote, selectPeerToPeerRoomName } from 'js/redux/peerToPeer/peerToPeer.selectors'
import { setIsLoading } from 'js/redux/navigation/navigation.actions'
import { showResourceBookmark } from 'js/redux/resources/resources.actions'

import { selectDailyRoom, selectProfile } from 'js/redux/profile/profile.selectors'
import Invite from '../Invite'

const useStyles = makeStyles(({ fonts, palette }) => ({
    VideoPopup: {
        backgroundColor: palette.grey.deepNavy,
        minHeight: '100vh',
        height: 'fit-content',
    },
    endCall: {
        ...fonts.b1.mob,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.persianRed,
        borderRadius: '10px',
        border: 'none',
        color: palette.white,
        display: 'flex',
        height: '30px',
        marginRight: '20px',
        padding: '8px 27px',
        width: '77px',

        '&.isDesktop': {
            ...fonts.b1.dskt,
        },
    },
    frameWrapper: {
        overflow: 'hidden',
        height: 'calc(100vh - 54px)',
        position: 'relative',

        '& iframe': {
            border: 0,
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
        },
    },
    modal: {
        backgroundColor: palette.white,
        opacity: 1,
    },
    options: {
        display: 'flex',
        height: '54px',
        justifyContent: 'space-between',
        padding: '12px 0',
    },
    sendInvitation: {
        marginLeft: '22px',
        position: 'relative',

        '& svg': {
            '& path': {
                fill: palette.white,
            },
        },
    },
}))

const VideoPopup = () => {
    const callType = useSelector(selectVideoPopupCallType)
    const classes = useStyles()
    const dailyRoom = useSelector(selectDailyRoom)
    const dispatch = useDispatch()
    const iframeRef = useRef(null)
    const isOpen = useSelector(selectVideoPopupOpen)
    const isOwner = useSelector(selectVideoPopupIsOwner)
    const groupCallRoomId = useSelector(selectGroupCallRoomId)
    const groupCallRoomName = useSelector(selectStartedCallDailyCoName)
    const isDesktop = !useMediaQuery({ query: `(max-width: 650px)` })
    const roomName = useSelector(selectPeerToPeerRoomName)
    const url = useSelector(selectVideoPopupUrl)
    const [refVisible, setRefVisible] = useState(false)
    const { uid } = useSelector(selectPeerToPeerRemote)
    const { full_name } = useSelector(selectProfile)

    const scheduleMeetingId = useSelector(selectVideoPopupId)

    let daily
    const handleUnmount = () => {
        if (daily) {
            daily.destroy()
        }
    }

    const handleFinish = (data) => {
        dispatch(setIsLoading(true))
        if (callType === CALL_TYPE.peerToPeer) {
            dispatch(finishPeerToPeerCall({ remoteUid: uid, roomName }))
        }
        if (callType === CALL_TYPE.groupCall) {
            dispatch(
                finishGroupCall({
                    callId: groupCallRoomId,
                    roomName: groupCallRoomName,
                    isOwner,
                    isSupporterRoom: groupCallRoomName === dailyRoom,
                }),
            )
        }
        if (callType === CALL_TYPE.scheduledMeeting) {
            dispatch(
                finishScheduleMeeting({
                    isOwner,
                    isRejected: data?.isRejected,
                    meetingId: scheduleMeetingId,
                }),
            )
        }
        dispatch(setIsLoading(false))
    }

    const handleError = (error) => {
        if (error?.errorMsg === MEETING_HAS_ENDED) {
            handleFinish()
            dispatch(showResourceBookmark({ message: MEETING_HAS_ENDED }))
        }
        if (error?.errorMsg === JOIN_REQUEST_REJECTED) {
            if (callType === CALL_TYPE.scheduledMeeting) {
                dispatch(leaveGuestList(scheduleMeetingId))
            }
            handleFinish({ isRejected: true })

            dispatch(showResourceBookmark({ message: JOIN_REQUEST_REJECTED }))
        }
    }
    useEffect(() => {
        try {
            if (iframeRef.current) {
                daily = DailyIframe.wrap(iframeRef.current)

                if (url) {
                    daily.join({
                        url,
                        userName: full_name,
                    })

                    daily.on('error', handleError)
                    dispatch(setIsLoading(false))
                }
            }
        } catch (error) {
            logger(error)
        }
        return handleUnmount
    }, [refVisible])

    const [isInvitationSending, setInvitationSending] = useState(false)

    const toggleInvitationSending = () => setInvitationSending(!isInvitationSending)

    return (
        <>
            <Modal open={isOpen}>
                <div className={classes.VideoPopup}>
                    <div className={classes.options}>
                        {callType === CALL_TYPE.groupCall && isOwner && (
                            <div className={classes.sendInvitation} onClick={toggleInvitationSending}>
                                <PeopleRounded />
                            </div>
                        )}
                        <button
                            className={classNames(classes.endCall, { isDesktop })}
                            type="button"
                            onClick={handleFinish}
                        >
                            End
                        </button>
                    </div>
                    <div className={classes.frameWrapper}>
                        <iframe
                            title="video call iframe"
                            ref={(el) => {
                                iframeRef.current = el
                                setRefVisible(!!el)
                            }}
                            allow="camera; microphone; fullscreen; display-capture"
                        />
                    </div>
                </div>
            </Modal>

            {callType === CALL_TYPE.groupCall && (
                <Invite
                    isOpen={isInvitationSending}
                    onClose={toggleInvitationSending}
                    meetingId={groupCallRoomId}
                    text="Invite people to join this meeting"
                    title="Invite"
                />
            )}
        </>
    )
}

export default VideoPopup

import classNames from 'classnames'
import React, { memo, useEffect } from 'react'
import { Favorite } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as BookmarkStar } from 'media/bookmark-star.svg'
import { string, object, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import useScreenSize from 'js/hooks/useScreenSize'
import { DEFAULT_AVATAR } from 'js/var/images'
import { getChatIdSelector } from 'js/redux/chats/chat.selectors'
import { PROFILE_PATH } from 'js/var/paths'

const useStyles = makeStyles(({ fonts, palette }) => ({
    bookmark: {
        alignItems: 'center',
        background: 'rgba(255, 254, 248, 0.5)',
        borderRadius: '0px 0px 8px 8px',
        display: 'flex',
        height: '26px',
        justifyContent: 'center',
        right: '9px',
        position: 'absolute',
        top: '0px',
        width: '28px',
    },
    Card: {
        position: 'relative',
        width: '148px',

        '&.isTablet, &.isDesktop': {
            width: '172px',
        },
    },
    content: {
        backgroundColor: palette.white,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    icon: {
        alignItems: 'center',
        background: 'linear-gradient(90deg, #7253CC -3.46%, #256CF8 100%)',
        borderRadius: '4px',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        position: 'absolute',
        right: '4px',
        top: '4px',
        width: '42px',

        '& svg': {
            height: '24px',
            width: '24px',

            '& path': {
                fill: palette.white,
            },
        },
    },
    image: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '10px',
        overflow: 'hidden',
        marginBottom: '4px',

        '&::before': {
            content: '""',
            display: 'block',
            paddingTop: '100%',
        },

        '&.isShort:before': {
            paddingTop: '132px',
        },
    },
    name: {
        ...fonts.s16l208,
        color: palette.godGrey,
        fontWeight: 700,
    },
    role: {
        ...fonts.s12l16,
        color: palette.doveGrey,
    },
}))

const Card = memo(({ customStyle, firebaseUid, full_name, isShort, isSuggestion, isNoIcon, profile_picture, role }) => {
    const chatId = useSelector(getChatIdSelector)
    const classes = useStyles()
    const screenSize = useScreenSize()
    const navigate = useNavigate()

    useEffect(() => {
        if (chatId) {
            navigate(`/chat/${chatId}`)
        }
    }, [chatId, navigate])

    const toMentorPage = ({ currentTarget, target }) => {
        if (currentTarget === target) {
            navigate(`${PROFILE_PATH}/${firebaseUid}`)
        }
    }

    if (!firebaseUid) {
        return null
    }

    return (
        <div className={classNames(classes.Card, screenSize)} style={customStyle}>
            {!isNoIcon && <div className={classes.icon}>{isSuggestion ? <BookmarkStar /> : <Favorite />}</div>}
            <div
                className={classNames(classes.image, { isShort })}
                style={{ backgroundImage: `url(${profile_picture || DEFAULT_AVATAR})` }}
                onClick={toMentorPage}
            />
            <div className={classes.content}>
                <div className={classes.role}>{role}</div>
                <div className={classes.name}>{full_name}</div>
            </div>
        </div>
    )
})

Card.propTypes = {
    customStyle: object,
    full_name: string,
    firebaseUid: string.isRequired,
    isNoIcon: bool,
    isShort: bool,
    isSuggestion: bool,
    profile_picture: string.isRequired,
    role: string.isRequired,
}

Card.defaultProps = {
    isShort: false,
    customStyle: {},
    full_name: '',
    isNoIcon: false,
    isSuggestion: false,
}

export default Card

import React from 'react'

const IconStepDelete = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#00808D" />
        <path d="M9 16H22" stroke="#00B3C5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default IconStepDelete


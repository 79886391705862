import React from 'react'

const IconSport = () => (
    <svg
        width="46"
        height="51"
        viewBox="0 0 46 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.418 10.5683C19.2572 7.7455 20.7684 4.53491 23.6836 3.63078V3.63078C26.5353 2.74636 29.5438 4.44194 30.264 7.33941L38.39 40.0321C38.6347 41.0164 38.1038 42.0279 37.1549 42.3858L35.8101 42.8931C34.7981 43.2748 33.6659 42.7828 33.2546 41.7825L20.418 10.5683Z"
            className="stroke-painted"
            strokeWidth="3"
        />
        <path
            d="M34.5249 48.0008C34.0059 48.1896 33.4321 47.922 33.2433 47.403L32.4698 45.2771C32.281 44.7581 32.5486 44.1843 33.0676 43.9955L40.728 41.2084C41.247 41.0195 41.8208 41.2872 42.0096 41.8062L42.7831 43.932C42.9719 44.451 42.7043 45.0248 42.1853 45.2137L34.5249 48.0008Z"
            className="stroke-painted"
            strokeWidth="3"
        />
        <circle
            cx="10.9198"
            cy="28.5687"
            r="9.04482"
            className="stroke-painted"
            strokeWidth="3"
        />
    </svg>
)

export default IconSport

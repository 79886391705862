import React from 'react'
import { any, bool, func, number } from 'prop-types'
import { makeStyles, Modal } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
    },
    container: {
        minHeight: '100vh',
        minWidth: '100vw',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        backdropFilter: 'blur(3px)',
        outline: 'none',
    },
}))

const BlackPopup = ({ children, isOpen, onClose, opacity }) => {
    const classes = useStyles()

    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.container} style={{ background: `rgba(0, 0, 0, ${opacity})` }} onClick={onClose}>
                {children}
            </div>
        </Modal>
    )
}

BlackPopup.propTypes = {
    children: any.isRequired,
    isOpen: bool,
    onClose: func,
    opacity: number,
}

BlackPopup.defaultProps = {
    isOpen: false,
    onClose: () => {},
    opacity: 0.7,
}

export default BlackPopup

export const FEATURE_NAME = {
    communityGoals: 'community-goals',
    garden: 'garden',
    onboarding: 'onboarding',
    onboardingGoals: 'onboarding-goals',
    onboardingResources: 'onboarding-resources',
}
export const FEATURES = [
    // FEATURE_NAME.communityGoals, // turn on Community goals feature,
    // FEATURE_NAME.garden, // turn on garden feature
    FEATURE_NAME.onboarding, // turn on new user onboarding
    FEATURE_NAME.onboardingGoals, // turn on goals switch in onboarding ...
    // ...that display/hide goals setting/check-ing/history logic
    FEATURE_NAME.onboardingResources, // turn on resources switch in onboarding that display/hide resources
]

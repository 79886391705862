import logger from 'js/utils/logger'
import firebase, { getUid } from './firebase'

export const loadInterestsTypes = () =>
    firebase
        .database()
        .ref('interests')
        .once('value')
        .then((snapshot) => snapshot.val())
        .catch((e) => logger(`Error of interests types init: ${e}`))

export const signOnInterests = (callback) =>
    firebase.database().ref(`users/${getUid()}/interests`).on('value', callback)

export const uploadUserInterests = (interests) =>
    firebase
        .database()
        .ref(`users/${getUid()}`)
        .update({ interests })
        .catch((e) => logger(`Error of user interests uploading${e}`))

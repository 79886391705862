import classNames from 'classnames'
import React from 'react'
import { bool, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'

import OutsideClick from 'js/components/Reusable/OutsideClick'
import { CancelRounded } from '@mui/icons-material'

const useStyles = makeStyles(({ fonts, palette }) => ({
    AddedRecipient: {
        ...fonts.b2.mob,
        alignItems: 'center',
        borderRadius: '4px',
        display: 'flex',
        height: '23px',
        padding: '2px',

        '&.isDesktop': {
            backgroundColor: palette.white,
            border: `1px solid ${palette.primPurple}`,
            borderRadius: '10px',
            color: palette.primPurple,
            height: '31px',
            padding: '8px',
            marginRight: '8px',
            marginTop: '8px',
        },

        '&.isInvite': {
            marginTop: 0,
            marginBottom: '4px',
        },

        '&.isSelected': {
            backgroundColor: palette.primPurple,
            color: palette.white,
        },
    },
    delete: {
        marginLeft: '9px',
        height: '18px',

        '& svg': {
            height: '18px',
            width: '18px',

            '& path': {
                fill: palette.primPurple,
            },
        },
    },
}))

const AddedRecipient = ({ deleteRecipient, isLast, isInvite, isLastSelected, isDesktop, name, setIsLastSelected }) => {
    const classes = useStyles()
    const isSelected = isLast && isLastSelected

    const handleOutsideClick = () => {
        setIsLastSelected(false)
    }

    return (
        <OutsideClick callback={handleOutsideClick}>
            <div
                className={classNames(classes.AddedRecipient, {
                    isSelected,
                    isDesktop,
                    isInvite,
                })}
            >
                {name}
                {!isSelected && !isDesktop && ','}
                {isDesktop && (
                    <div className={classes.delete} onClick={deleteRecipient}>
                        <CancelRounded />
                    </div>
                )}
            </div>
        </OutsideClick>
    )
}

AddedRecipient.propTypes = {
    deleteRecipient: func.isRequired,
    isLast: bool.isRequired,
    isLastSelected: bool.isRequired,
    isInvite: bool,
    isDesktop: bool.isRequired,
    name: string.isRequired,
    setIsLastSelected: func.isRequired,
}

AddedRecipient.defaultProps = {
    isInvite: false,
}

export default AddedRecipient

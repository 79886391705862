import classNames from 'classnames'
import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { useSelector, useDispatch } from 'react-redux'

import BlackPopup from 'js/components/Reusable/popups/BlackPopup'
import CallButton from 'js/components/Reusable/CallButton'
import { VIDEO_CALL, DECLINE, DEFAULT_AVATAR } from 'js/var/images'
import { acceptPeerToPeerAnnounce, declinePeerToPeerAnnounce } from 'js/redux/peerToPeer/peerToPeer.actions'
import {
    selectIncomingCallPopupOpen,
    selectPeerToPeerRemote,
    selectPeerToPeerRoomName,
} from 'js/redux/peerToPeer/peerToPeer.selectors'
import { selectDailyRoom } from 'js/redux/profile/profile.selectors'

const useStyles = makeStyles(({ fonts, palette }) => ({
    IncomingCallPopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarWrapper: {
        height: '170px',
        width: '170px',
        background: 'rgba(255, 255, 255, 0.2)',
        boxShadow: '3.17932px 3.17932px 19.0759px rgba(0, 0, 0, 0.1)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatar: {
        height: '160px',
        width: '160px',
    },
    name: {
        paddingTop: '20px',
        ...fonts.h4.mob,
        color: palette.white,

        '&.isDesktop': {
            ...fonts.h3.dskt,
        },
    },
    info: {
        paddingTop: '4px',
        ...fonts.b4.mob,
        color: palette.secondaryGrey,

        '&.isDesktop': {
            ...fonts.b3.dskt,
        },
    },
    buttons: {
        paddingTop: '124px',
        display: 'flex',
    },
    buttonMargin: {
        marginRight: '90px',
    },
}))

const IncomingCallPopup = () => {
    const classes = useStyles()
    const dailyRoom = useSelector(selectDailyRoom)
    const peerToPeerRoom = useSelector(selectPeerToPeerRoomName)
    const dispatch = useDispatch()
    const isDesktop = !useMediaQuery({ query: `(max-width: 650px)` })
    const isOpen = useSelector(selectIncomingCallPopupOpen)
    const { full_name, profile_picture, uid } = useSelector(selectPeerToPeerRemote)

    const handleAccept = () => dispatch(acceptPeerToPeerAnnounce(uid, dailyRoom || peerToPeerRoom, !!dailyRoom))

    const handleDecline = () => dispatch(declinePeerToPeerAnnounce(uid))

    return (
        <BlackPopup isOpen={isOpen}>
            <div className={classes.IncomingCallPopup}>
                <div className={classes.avatarWrapper}>
                    <Avatar alt="Avatar" className={classes.avatar} src={profile_picture || DEFAULT_AVATAR} />
                </div>
                <p className={classNames(classes.name, { isDesktop })}>{full_name || 'Mindfully'}</p>
                <p className={classNames(classes.info, { isDesktop })}>Incoming Video Call....</p>
                <div className={classes.buttons}>
                    <CallButton
                        customClass={classes.buttonMargin}
                        icon={DECLINE}
                        isDesktop={isDesktop}
                        onClick={handleDecline}
                        text="decline"
                    />
                    <CallButton icon={VIDEO_CALL} isDesktop={isDesktop} onClick={handleAccept} text="accept call" />
                </div>
            </div>
        </BlackPopup>
    )
}

export default IncomingCallPopup

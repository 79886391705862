import React from 'react'
import { makeStyles } from '@material-ui/core'
import { bool, func, object, string } from 'prop-types'
import Switch from 'react-switch'
import palette from 'styles/palette'
import { useNavigate } from 'react-router-dom'
import { HOME_PATH } from 'js/var/paths'
import { useDispatch, useSelector } from 'react-redux'
import { createSelectIsOption } from 'js/redux/featureOptions/featureOptions.selectors'
import { changeFeatureOptions } from 'js/redux/featureOptions/featureOptions.actions'
import { useFeature } from 'flagged'
import { FEATURE_NAME } from 'featureFlags'
import Reward from './Reward'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    NewUserOption: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '32px',
        paddingRight: '32px',
        width: '100%',
    },
    button: {
        ...buttons.round,
        marginBottom: '16px',
        marginTop: '16px',
    },
    hint: {
        ...fonts.s12l16,
        color: palette.doveGrey,
        marginTop: '8px',
        textAlign: 'center',
    },
    icon: {
        '& svg': {
            height: '48px',
            marginBottom: '16px',
            width: '48px',

            '& path': {
                fill: palette.primPurple,
            },
        },
    },
    link: {
        ...fonts.s13l17,
        color: palette.blueRibbon,
        marginBottom: '16px',
    },
    switchBox: {
        border: `1px solid ${palette.fuchsiaBlue}`,
        borderRadius: '8px',
        display: 'flex',
        marginBottom: '16px',
        padding: '16px 12.5px',
    },
    switchText: {
        ...fonts.s16l208,
        color: palette.godGrey,
        marginLeft: '8px',
    },
    p: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
        textAlign: 'center',
    },
    title: {
        ...fonts.s20l26,
        color: palette.black,
        marginBottom: '16px',
        textAlign: 'center',
    },
}))

const NewUserOption = ({ hint, isCheckIn, Icon, next, option, p1, p2, progressTitle, title }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isGardenOn = useFeature(FEATURE_NAME.garden)
    const navigate = useNavigate()
    const isOption = useSelector(createSelectIsOption(option))

    const toggleSwitch = () => {
        dispatch(changeFeatureOptions({ [option]: !isOption }))
    }

    const toHome = () => navigate(HOME_PATH)

    return (
        <div className={classes.NewUserOption}>
            <i className={classes.icon}>
                <Icon />
            </i>
            <p className={classes.title}>{title}</p>
            {!isCheckIn && option && (
                <div className={classes.switchBox}>
                    <Switch
                        activeBoxShadow={`0 0 2px 3px ${palette.fuchsiaBlue}`}
                        height={24}
                        onColor={palette.fuchsiaBlue}
                        checked={isOption}
                        onChange={toggleSwitch}
                        checkedIcon={null}
                        uncheckedIcon={null}
                        width={48}
                    />
                    <span className={classes.switchText}>I’m interested in this feature</span>
                </div>
            )}
            <p className={classes.p}>{p1}</p>
            <p className={classes.p}>{p2}</p>
            {isGardenOn && <Reward isCheckIn={isCheckIn} title={progressTitle} />}
            {isCheckIn && isGardenOn && <p className={classes.hint}>{hint}</p>}
            <button className={classes.button} onClick={isCheckIn ? toHome : next} type="button">
                {isCheckIn ? 'Take me to the home screen' : 'Continue'}
            </button>
            {!isCheckIn && (
                <span className={classes.link} onClick={toHome}>
                    Thanks, that’s enough
                </span>
            )}
        </div>
    )
}

NewUserOption.propTypes = {
    hint: string,
    isCheckIn: bool,
    Icon: object.isRequired,
    next: func.isRequired,
    option: string,
    p1: string.isRequired,
    p2: string.isRequired,
    progressTitle: string.isRequired,
    title: string.isRequired,
}

NewUserOption.defaultProps = {
    hint: '',
    option: '',
    isCheckIn: false,
}

export default NewUserOption

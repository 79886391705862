import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'

const useNavigateSearchParams = (replace = false) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const params = Object.fromEntries([...searchParams])

    return (to) =>
        navigate({
            pathname: to,
            search: createSearchParams(params).toString(),
            replace,
        })
}

export default useNavigateSearchParams

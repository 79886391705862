import React from 'react'

const IconHeart = () => (
    <svg
        width="42"
        height="37"
        viewBox="0 0 42 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2688 4.77318C24.8622 0.567346 31.518 -1.14765 36.908 2.52735C39.7663 4.48735 41.563 7.79485 41.6855 11.2657C41.9509 19.1873 34.948 25.5369 24.2292 35.2553L24.0047 35.4594C22.453 36.8886 20.0642 36.8886 18.5126 35.4798L18.3084 35.2961L18.1851 35.1841C7.53583 25.5086 0.567425 19.1773 0.852161 11.2861C0.974661 7.79485 2.77133 4.48735 5.62966 2.52735C11.0197 -1.16807 17.6755 0.567346 21.2688 4.77318ZM21.2688 32.4582L21.473 32.254C31.1913 23.4544 37.6022 17.6357 37.6022 11.7353C37.6022 7.65192 34.5397 4.58942 30.4563 4.58942C27.3122 4.58942 24.2497 6.61067 23.188 9.40775H19.3701C18.288 6.61067 15.2255 4.58942 12.0813 4.58942C7.99802 4.58942 4.93552 7.65192 4.93552 11.7353C4.93552 17.6357 11.3463 23.4544 21.0647 32.254L21.2688 32.4582Z"
            className="fill-painted"
        />
    </svg>
)
export default IconHeart

const { createSelector } = require('reselect')

export const selectEmailInvitation = ({ emailInvitation }) => emailInvitation

export const selectIsEmailInvitation = createSelector(selectEmailInvitation, ({ isInvitation }) => isInvitation)

export const selectIsEmailInvitationPopup = createSelector(
    selectEmailInvitation,
    ({ isEmailInvitationPopup }) => isEmailInvitationPopup,
)

export const selectStaffUid = createSelector(selectEmailInvitation, ({ staffUid }) => staffUid)

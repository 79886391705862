export const SET_CALL_TYPE = 'SET_CALL_TYPE'

export const SET_FEEDBACK = 'SET_FEEDBACK'

export const SET_MENTOR_UID = 'SET_MENTOR_UID'

export const SET_OWNER = 'SET_OWNER'

export const SKIP_FEEDBACK = 'SKIP_FEEDBACK'

export const TOGGLE_FEEDBACK_POPUP = 'TOGGLE_FEEDBACK_POPUP'

export const TOGGLE_FEEDBACK_SUCCESS = 'TOGGLE_FEEDBACK_SUCCESS'

import { createAction } from 'redux-actions'

import {
    GOAL_STEP_DELETE,
    GOAL_STEP_SET,
    RESET_ASSESSMENT_DATA,
    SET_ASSESSMENT_DATA,
    SET_WAS_REDIRECTED_TO_MENTORS,
    TOGGLE_IS_GOAL_VARIANTS_OPEN,
    TOGGLE_IS_TIPS_OPEN,
} from './assessment.types'

export const deleteGoalStep = createAction(GOAL_STEP_DELETE)
export const setGoalStep = createAction(GOAL_STEP_SET)
export const resetAssessmentData = createAction(RESET_ASSESSMENT_DATA)
export const setAssessmentData = createAction(SET_ASSESSMENT_DATA)
export const setWasRedirectedToMentors = createAction(SET_WAS_REDIRECTED_TO_MENTORS)
export const toggleIsTipsOpen = createAction(TOGGLE_IS_TIPS_OPEN)
export const toggleIsGoalVariantsOpen = createAction(TOGGLE_IS_GOAL_VARIANTS_OPEN)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import image from 'media/boy-near-phone.svg'

import Step from '../Step'

const useStyles = makeStyles(() => ({
    LastSteps: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: '550px',
        padding: '50px 20px 0 20px',
        position: 'relative',
    },
    imgWrapper: {
        marginBottom: '85px',
        width: '100%',
    },
    img: {
        width: '100%',
        height: 'auto',
    },
    steps: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingBottom: '20%',
        width: '100%',
        height: '100%',
    },
}))

const LastSteps = () => {
    const classes = useStyles()

    return (
        <div className={classes.LastSteps}>
            <div className={classes.imgWrapper}>
                <img className={classes.img} src={image} alt="What is a Peer Supporter?" />
            </div>
            <div className={classes.steps}>
                <Step number={3} text="Send your Peer Supporter a message and set up a time to meet." />
                <Step number={4} text="Meet with your Peer Supporter and review your session." />
            </div>
        </div>
    )
}

export default LastSteps

import { createSelector } from 'reselect'

export const selectPeerToPeer = ({ peerToPeer }) => peerToPeer

export const selectDeclinePopupOpen = createSelector(selectPeerToPeer, ({ isDeclinePopupOpen }) => isDeclinePopupOpen)

export const selectIncomingCallPopupOpen = createSelector(
    selectPeerToPeer,
    ({ isIncomingCallPopupOpen }) => isIncomingCallPopupOpen,
)

export const selectOutgoingPopupOpen = createSelector(
    selectPeerToPeer,
    ({ isOutgoingPopupOpen }) => isOutgoingPopupOpen,
)

export const selectPeerToPeerRemote = createSelector(selectPeerToPeer, ({ remote }) => remote)

export const selectPeerToPeerRoomName = createSelector(selectPeerToPeer, ({ roomName }) => roomName)

import _times from 'lodash/times'
import React, { useState, useRef } from 'react'
import Slider from 'react-slick'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import PHQ2Question1 from 'media/phq2-link.svg'
import PHQ2Question2 from 'media/sad-girl.svg'
import SliderDot from 'js/pages/Assessment/DotsNavigation/SliderDot'
import { selectPHQ2CurrentSlide, selectPHQ2, selectPHQ2Sum } from 'js/redux/phq2/phq2.selectors'
import { completePHQ2, savePHQ2, togglePHQ2 } from 'js/redux/phq2/phq2.actions'
import { startPHQ9, togglePHQ9 } from 'js/redux/phq9/phq9.actions'

import { useMediaQuery } from 'react-responsive'
import AssessmentEnd from '../../AssessmentEnd'
import PHQ2End from '../PHQ2End'
import PHQ2Question from '../PHQ2Question'

const settings = {
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const useStyles = makeStyles((theme) => ({
    PHQ2Slider: {
        backgroundColor: theme.palette.white,
        minHeight: '750px',
        position: 'relative',
        width: '100%',
    },
    dots: {
        width: '100%',
        position: 'absolute',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        bottom: '55px',
    },
}))

const PHQ2Slider = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isDesktop = !useMediaQuery({ query: `(max-width: 644px)` })
    let slider = useRef()
    const startSlide = useSelector(selectPHQ2CurrentSlide)
    const PHQ2 = useSelector(selectPHQ2)
    const sum = useSelector(selectPHQ2Sum)
    const [activeIndex, setActiveIndex] = useState(0)
    const [slidesLimit, setSlidesLimit] = useState(startSlide + 1)

    const beforeChange = (current, next) => {
        setActiveIndex(next)
    }

    const goToIndex = ({
        target: {
            dataset: { index },
        },
    }) => {
        slider.slickGoTo(index)
    }

    const goToNextSlide = (limit) => () => {
        if (limit > slidesLimit) {
            setSlidesLimit(limit)
        }
        slider.slickNext()
    }

    const handleExit = () => {
        if (sum > 3) {
            dispatch(startPHQ9())
        }
        dispatch(completePHQ2())
        dispatch(togglePHQ2())
        dispatch(savePHQ2({ ...PHQ2, isPHQ2Completed: true, currentSlide: 2, isPHQ2Open: false }))
    }

    const handleEnd = () => {
        if (sum > 3) {
            dispatch(togglePHQ9())
            dispatch(startPHQ9())
        }
        handleExit()
    }

    return (
        <div className={classes.PHQ2Slider}>
            <Slider
                ref={(c) => {
                    slider = c
                }}
                {...settings}
                initialSlide={startSlide}
                beforeChange={beforeChange}
            >
                <PHQ2Question
                    goToNextSlide={goToNextSlide(2)}
                    image={PHQ2Question1}
                    isDesktop={isDesktop}
                    slideNumber={0}
                    question="Over the last 2 weeks, have you been experiencing little interest or pleasure in doing things?"
                />
                {slidesLimit > 1 && (
                    <PHQ2Question
                        back={() => slider.slickGoTo(0)}
                        goToNextSlide={goToNextSlide(3)}
                        image={PHQ2Question2}
                        isDesktop={isDesktop}
                        slideNumber={1}
                        question="Over the last 2 weeks, have you been feeling down, depressed or hopeless?"
                    />
                )}
                {slidesLimit > 2 && sum > 3 ? (
                    <PHQ2End handleEnd={handleEnd} handleExit={handleExit} isDesktop={isDesktop} />
                ) : (
                    <AssessmentEnd handleEnd={handleEnd} />
                )}
            </Slider>
            <div className={classes.dots}>
                {_times(3, (i) => (
                    <SliderDot activeIndex={activeIndex} currentIndex={i} goToIndex={goToIndex} />
                ))}
            </div>
        </div>
    )
}

export default PHQ2Slider

import { handleActions } from 'redux-actions'

import {
    goalCheckInSetSuccess,
    goalsInitSuccess,
    goalsLoading,
    goalsLoadingError,
    goalsLoadingSuccess,
    toggleGoalStepStatus,
    goalStepsSetSuccess,
    goalVariantsInitSuccess,
    deleteGoalAction,
    goalDeadlineSetSuccess,
    setGoalConfidenceAction,
    updateGoalAction,
} from './goals.actions'

const defaultState = {
    goals: [],
    goalVariants: null,
    isLoading: false,
    hasError: false,
}

const reducer = handleActions(
    {
        [deleteGoalAction]: (state, { payload: goalId }) => ({
            ...state,
            goals: state.goals.filter((goal) => goal.id !== goalId),
        }),
        [goalCheckInSetSuccess]: (state, { payload: { id, checkIn } }) => ({
            ...state,
            goals: state.goals.map((goal) => {
                if (goal.id === id) {
                    goal.checkIn = { ...checkIn, date: new Date().getTime() }
                }

                return goal
            }),
        }),
        [goalDeadlineSetSuccess]: (state, { payload: { goalId, deadline } }) => ({
            ...state,
            goals: state.goals.map((goal) => {
                if (goal.id === goalId) {
                    return { ...goal, deadline }
                }

                return goal
            }),
        }),
        [goalVariantsInitSuccess]: (state, { payload }) => ({
            ...state,
            goalVariants: payload,
        }),
        [goalsInitSuccess]: (state, { payload }) => ({
            ...state,
            goals: payload,
        }),
        [goalsLoading]: (state) => ({
            ...state,
            isLoading: true,
            hasError: false,
        }),
        [goalsLoadingError]: (state) => ({
            ...state,
            isLoading: false,
            hasError: true,
        }),
        [goalsLoadingSuccess]: (state, action) => ({
            ...state,
            goals: [...state.goals, ...action.payload],
            isLoading: false,
            hasError: false,
        }),
        [goalStepsSetSuccess]: (state, { payload: { goalId, steps } }) => ({
            ...state,
            goals: state.goals.map((goal) => {
                if (goal.id === goalId) {
                    goal.steps = steps
                    goal.hasSteps = Boolean(steps.length)
                }

                return goal
            }),
        }),
        [setGoalConfidenceAction]: (state, { payload: { confidenceRating, goalId } }) => ({
            ...state,
            goals: state.goals.map((goal) => {
                if (goal.id === goalId) {
                    return { ...goal, confidenceRating }
                }

                return goal
            }),
        }),
        [toggleGoalStepStatus]: (state, action) => {
            const { goalId, index } = action.payload

            return {
                ...state,
                goals: state.goals.map((goal) => {
                    if (goal.id === goalId && goal.steps[index].isEditable) {
                        return {
                            ...goal,
                            steps: goal.steps.map((step, stepIndex) => {
                                if (stepIndex === index) {
                                    return {
                                        ...step,
                                        isCompleted: !step.isCompleted,
                                    }
                                }

                                return step
                            }),
                        }
                    }

                    return goal
                }),
            }
        },
        [updateGoalAction]: (state, { payload: { goalId, newGoalData } }) => ({
            ...state,
            goals: state.goals.map((goal) => {
                if (goal.id === goalId) {
                    return { ...goal, ...newGoalData }
                }

                return goal
            }),
        }),
    },
    defaultState,
)

export default reducer

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SignInModal from 'js/components/SignInModal'
import useScreenSize from 'js/hooks/useScreenSize'
import { selectErrorMessage, selectErrorTitle, selectIsError } from 'js/redux/error/error.selectors'
import { closeError } from 'js/redux/error/error.actions'

const ErrorPopup = () => {
    const dispatch = useDispatch()
    const screenSize = useScreenSize()
    const isError = useSelector(selectIsError)
    const errorMessage = useSelector(selectErrorMessage)
    const errorTitle = useSelector(selectErrorTitle)

    const close = () => dispatch(closeError())

    return isError ? (
        <SignInModal
            buttonTitle="OK"
            isDesktop={!!screenSize.isDesktop}
            isOpen={isError}
            message={errorMessage}
            onClose={close}
            title={errorTitle || 'Error'}
        />
    ) : null
}

export default ErrorPopup

import classnames from 'classnames'
import React from 'react'
import { bool, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ fonts, palette }) => ({
    GoalVariant: {
        ...fonts.b2.mob,
        border: `1px solid ${palette.dustBlue}`,
        marginBottom: '16px',
        background: palette.dustBlue,
        borderRadius: '24px',
        boxSizing: 'border-box',
        outline: 'none',
        padding: '10px 20px',
        width: '100%',
        color: palette.darkGrey,

        '&.isSelected': {
            background: palette.primBlue,
            border: `1px solid ${palette.midBlue}`,
            color: palette.white,
        },
    },
    GoalVariantDesktop: {
        ...fonts.b2.dskt,
        padding: '12px 16px',
    },
}))

const GoalVariant = ({ isDesktop, isSelected, onClick, text }) => {
    const classes = useStyles()

    return (
        <p
            className={classnames(classes.GoalVariant, {
                [classes.GoalVariantDesktop]: isDesktop,
                isSelected,
            })}
            onClick={(e) => {
                e.preventDefault()
                onClick(text)
            }}
        >
            {text}
        </p>
    )
}

GoalVariant.propTypes = {
    isDesktop: bool,
    isSelected: bool.isRequired,
    onClick: func.isRequired,
    text: string.isRequired,
}

GoalVariant.defaultProps = {
    isDesktop: false,
}

export default GoalVariant

import { string } from 'prop-types'
import React from 'react'

import palette from 'styles/palette'

const ContactMentors = ({ color }) => (
    <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.2449 5.83799L32.5749 19.982C32.5749 19.982 33.1673 21.4656 32.0812 22.5536C30.995 23.6417 29.2177 22.9493 29.2177 22.9493C29.2177 22.9493 29.8101 24.6307 28.724 25.6198C27.6378 26.609 26.3542 26.2133 26.3542 26.2133C26.3542 26.2133 27.0454 27.598 25.663 28.7849C24.2806 29.9719 22.3058 28.7849 22.3058 28.7849L19.2449 30.3902L5.91485 21.07C5.91485 21.07 1.76775 17.5092 1.669 12.4649C1.57025 7.42058 5.12492 2.87076 10.8519 2.37616C16.5788 1.88156 19.2449 5.83799 19.2449 5.83799Z"
            fill="white"
            stroke={color}
            strokeWidth="3"
        />
        <path
            d="M23.9844 17.6082L29.4151 23.0482"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.1211 20.8722L26.5519 26.3122"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.0475 6.43476L12.2344 13.2595C12.2344 13.2595 13.7155 16.3257 17.5664 14.0508L22.1084 9.79768L32.9699 20.1832C32.9699 20.1832 35.3403 17.4023 36.0309 15.2377C36.8208 12.7616 35.4385 9.79768 35.4385 9.79768C35.4385 9.79768 33.9573 5.34676 28.5266 3.96203C23.0959 2.5773 19.0475 6.43476 19.0475 6.43476Z"
            fill="white"
            stroke={color}
            strokeWidth="3"
        />
        <rect
            x="1.40008"
            y="-0.195372"
            width="6.4668"
            height="3.4396"
            rx="1.7198"
            transform="matrix(0.661619 -0.80403 0.738465 0.608658 4.37683 23.4046)"
            fill="white"
            stroke={color}
            strokeWidth="2"
        />
        <rect
            x="1.40009"
            y="-0.195372"
            width="6.4668"
            height="3.4396"
            rx="1.7198"
            transform="matrix(0.66162 -0.80403 0.738466 0.608658 7.94421 26.5311)"
            fill="white"
            stroke={color}
            strokeWidth="2"
        />
        <rect
            x="1.40009"
            y="-0.195372"
            width="6.4668"
            height="3.4396"
            rx="1.7198"
            transform="matrix(0.66162 -0.80403 0.738466 0.608658 11.7733 29.5781)"
            fill="white"
            stroke={color}
            strokeWidth="2"
        />
        <rect
            x="1.40009"
            y="-0.195372"
            width="6.46681"
            height="3.4396"
            rx="1.7198"
            transform="matrix(0.66162 -0.804029 0.738466 0.608657 15.3827 32.6445)"
            fill="white"
            stroke={color}
            strokeWidth="2"
        />
    </svg>
)

ContactMentors.propTypes = {
    color: string,
}

ContactMentors.defaultProps = {
    color: palette.primPurple,
}

export default ContactMentors

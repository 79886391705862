import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import MobileDrawer from 'js/components/Reusable/popups/MobileDrawer'
import SideDrawer from 'js/components/Reusable/popups/SideDrawer'
import { toggleIsGoalVariantsOpen } from 'js/redux/assessment/assessment.actions'

import { selectIsGoalVariantsOpen } from 'js/redux/assessment/assessment.selectors'
import SuggestedGoals from './SuggestedGoals'

const SuggestedGoalsPopup = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsGoalVariantsOpen)
    const isDesktop = !useMediaQuery({ query: `(max-width: 650px)` })
    const onClose = () => dispatch(toggleIsGoalVariantsOpen())

    return isDesktop ? (
        <SideDrawer isOpen={isOpen} onClose={onClose}>
            <SuggestedGoals isDesktop={isDesktop} />
        </SideDrawer>
    ) : (
        <MobileDrawer isOpen={isOpen} onClose={onClose}>
            <SuggestedGoals />
        </MobileDrawer>
    )
}

export default SuggestedGoalsPopup

import { useEffect, useState } from 'react'
import { checkUserBlocked } from 'js/utils/checkers'

const useUserBlocked = (users) => {
    const [isBlocked, setBlocked] = useState(false)

    useEffect(() => {
        if (!users.length) {
            return
        }
        checkUserBlocked(users, setBlocked)
    }, [users])

    return isBlocked
}

export default useUserBlocked

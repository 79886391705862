import { createSelector } from 'reselect'

export const selectVideoPopup = ({ videoPopup }) => videoPopup

export const selectVideoPopupCallType = createSelector(selectVideoPopup, ({ callType }) => callType)

export const selectVideoPopupOpen = createSelector(selectVideoPopup, ({ isOpen }) => isOpen)

export const selectVideoPopupUrl = createSelector(selectVideoPopup, ({ url }) => url)

export const selectVideoPopupIsOwner = createSelector(selectVideoPopup, ({ isOwner }) => isOwner)

export const selectVideoPopupId = createSelector(selectVideoPopup, ({ id }) => id)

export const selectVideoPopupRoomName = createSelector(selectVideoPopup, ({ roomName }) => roomName)

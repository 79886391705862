import React from 'react'
import { func, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { selectSuggestedStaffIds } from 'js/redux/staff/staff.selectors'
import { toggleFindCare } from 'js/redux/staff/staff.actions'

import Button from '../Reusable/Button'

const FindCareLink = ({ className, onClick }) => {
    const dispatch = useDispatch()
    const isSuggestion = useSelector(selectSuggestedStaffIds).length !== 0

    const title = isSuggestion ? 'Update my suggested team' : 'Help me find someone'

    const toFindCare = () => {
        dispatch(toggleFindCare())
        if (onClick) {
            onClick()
        }
    }

    return (
        <Button className={className} onClick={toFindCare}>
            {title}
        </Button>
    )
}

FindCareLink.propTypes = {
    className: string.isRequired,
    onClick: func,
}

FindCareLink.defaultProps = {
    onClick: null,
}

export default FindCareLink

import classNames from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import { array, func, object, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Box from 'js/components/Box'
import Message from 'js/components/Message'
import SectionEmpty from 'js/components/SectionEmpty'
import { ReactComponent as ChatIcon } from 'media/chat.svg'
import { selectUid } from 'js/redux/profile/user'
import useScreenSize from 'js/hooks/useScreenSize'

const useStyles = makeStyles(({ fonts, others, palette }) => ({
    awayMessage: {
        ...fonts.s16l20,
        color: palette.doveGrey,
        textAlign: 'center',
        width: '100%',

        '&.isMobile': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    ChatMessages: {
        ...others.scroll,
        backgroundColor: palette.white,
        flexDirection: 'column',
        display: 'flex',
        padding: '82px 16px 56px 16px',
        width: '100%',

        '&.isDesktop': {
            backgroundColor: palette.white,
            paddingRight: '8px',
            flexGrow: 1,
            overflow: 'scroll',
            overflowX: 'hidden',
            padding: 0,
            zIndex: 1,
        },

        '&.isEmpty': {
            alignItems: 'center',
            height: '100%',
            flexGrow: 1,
            overflow: 'hidden',
            justifyContent: 'center',
        },
    },
    messages: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        justifyContent: 'flex-start',
        scrollBehavior: 'smooth',
        width: '100%',
    },
    noMessages: {
        ...fonts.b1.mob,
        color: palette.midGrey,
        textAlign: 'center',
    },
}))

const ChatMessages = ({ awayMessage, chatId, messages, messagesEndRef, scrollToBottom }) => {
    const classes = useStyles()
    const uid = useSelector(selectUid)
    const screenSize = useScreenSize()
    const [searchParams] = useSearchParams()
    const messageId = searchParams.get('mid')
    const [refVisible, setRefVisible] = useState(false)

    useEffect(() => {
        if (!refVisible || !scrollToBottom) {
            return
        }

        scrollToBottom()
    }, [refVisible, messageId, scrollToBottom])

    useEffect(() => {
        if (scrollToBottom) {
            scrollToBottom()
        }
    }, [messages.length, scrollToBottom])

    return (
        <div className={classNames(classes.ChatMessages, { ...screenSize, isEmpty: !messages?.length })}>
            {messages?.length ? (
                <div className={classes.messages}>
                    {messages.map(({ data: { uid: chatUser, isInvitation } = {}, data = {}, mid }) => (
                        <Fragment key={mid}>
                            {messageId && mid === messageId && (
                                <div
                                    key={`scroll component${mid}`}
                                    style={{ float: 'left', clear: 'both', scrollBehavior: 'smooth' }}
                                    ref={(el) => {
                                        messagesEndRef.current = el
                                        setRefVisible(!!el)
                                    }}
                                />
                            )}
                            <Message
                                {...data}
                                chatId={chatId}
                                key={mid}
                                isDesktop={screenSize?.isDesktop}
                                isMy={uid === chatUser}
                                isInvitation={!!isInvitation}
                                mid={mid}
                            />
                        </Fragment>
                    ))}
                    {!messageId && (
                        <div
                            key="scroll down"
                            style={{ float: 'left', clear: 'both', scrollBehavior: 'smooth' }}
                            ref={(el) => {
                                messagesEndRef.current = el
                                setRefVisible(!!el)
                            }}
                        />
                    )}
                </div>
            ) : (
                !awayMessage && (
                    <SectionEmpty
                        Icon={ChatIcon}
                        title="Start a new conversation."
                        text="Our Care Providers are here to help!"
                    />
                )
            )}
            {awayMessage && <Box className={classNames(classes.awayMessage, screenSize)}>{awayMessage}</Box>}
        </div>
    )
}

ChatMessages.propTypes = {
    awayMessage: string.isRequired,
    chatId: string.isRequired,
    messages: array.isRequired,
    messagesEndRef: object.isRequired,
    scrollToBottom: func.isRequired,
}

export default ChatMessages

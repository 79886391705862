import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import React from 'react'
import ReactDOM from 'react-dom'
import reduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'
import { FlagsProvider } from 'flagged'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { ThemeProvider } from '@material-ui/core/styles'

import firebase from 'js/APIs/firebase/firebase'

import './index.scss'
import { FEATURES } from 'featureFlags'
import * as serviceWorker from './service-worker'
import App from './App'
import reducers from './js/redux/reducers'
import theme from './styles/theme'

const { REACT_APP_LOG_ROCKET_APP_ID } = process.env

LogRocket.init(REACT_APP_LOG_ROCKET_APP_ID)

setupLogRocketReact(LogRocket)

const asyncDispatchMiddleware = (store) => (next) => (action) => {
    let syncActivityFinished = false
    let actionQueue = []

    const flushQueue = () => {
        actionQueue.forEach((a) => store.dispatch(a))
        actionQueue = []
    }

    const asyncDispatch = (asyncAction) => {
        actionQueue = actionQueue.concat([asyncAction])

        if (syncActivityFinished) {
            flushQueue()
        }
    }

    const actionWithAsyncDispatch = { ...action, asyncDispatch }

    next(actionWithAsyncDispatch)
    syncActivityFinished = true
    flushQueue()
}

const middleware = [reduxThunk, asyncDispatchMiddleware, LogRocket.reduxMiddleware()]

const composeEnhancers = composeWithDevTools({
    maxAge: 500,
})

const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middleware)))

const rrfConfig = {
    userProfile: 'users',
}

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
}

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <ThemeProvider theme={theme}>
                <FlagsProvider features={FEATURES}>
                    <App />
                </FlagsProvider>
            </ThemeProvider>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

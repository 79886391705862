import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { array, bool, func, object, string } from 'prop-types'
import { ArrowBack } from '@mui/icons-material'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import useScreenSize from 'js/hooks/useScreenSize'
import useUserBlocked from 'js/hooks/useUserBlocked'
import { STAFF_HITS_MAX } from 'js/var/const'
import { selectIsStaff } from 'js/redux/profile/profile.selectors'

import ChatInput from '../ChatInput'
import ChatMessages from '../../pages/Chat/ChatMessages'
import ChatUsersSearch from './ChatUsersSearch'
import SuggestedRecipients from './SuggestedRecipients'
import { mindfullySearchClient, searchClient } from '../../APIs/algoliaClient'

const useStyles = makeStyles(({ fonts, others, palette }) => ({
    back: {
        height: '24px',
        left: '16px',
        position: 'absolute',
        top: '13px',
        width: '24px',

        '& svg > path': {
            fill: palette.white,
        },
    },
    content: {
        ...others.scroll,
        overflowX: 'auto',
        maxHeight: '85vh',

        '&.isDesktop': {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.white,
            flexGrow: 1,
            position: 'relative',
            width: '100%',
            borderRadius: '0px 0px 10px 10px',
        },
    },
    NewMessageBody: {
        backgroundColor: palette.offWhite,
        height: '100vh',
        position: 'relative',

        '&.isDesktop': {
            backgroundColor: palette.white,
        },
    },
    title: {
        ...fonts.s16l208,
        alignItems: 'center',
        backgroundColor: palette.fuchsiaBlue,
        color: palette.white,
        display: 'flex',
        height: '50px',
        fontWeight: 700,
        justifyContent: 'center',
        position: 'relative',
        padding: '13px 16px',
        width: '100%',

        '&.isTablet, &.isDesktop': {
            display: 'none',
        },
    },
}))

const NewMessageBody = ({
    addRecipient,
    currentChat,
    deleteRecipient,
    inputRef,
    isSuggested,
    onSendMessage,
    searchString,
    selectedRecipients,
    setSearchString,
}) => {
    const classes = useStyles()
    const isStaff = useSelector(selectIsStaff)
    const navigate = useNavigate()
    const initText = ''

    const messagesEnd = useRef(null)
    const screenSize = useScreenSize()

    const [isLastSelected, setIsLastSelected] = useState(true)

    const scrollToBottom = () => {
        if (messagesEnd?.current?.scrollIntoView) {
            messagesEnd.current.scrollIntoView()
        }
    }

    const handleBack = () => navigate(-1)

    const isBlocked = useUserBlocked(selectedRecipients.map(({ uid }) => uid))

    return (
        <>
            <div className={classNames(classes.NewMessageBody, screenSize)}>
                <p className={classNames(classes.title, screenSize)}>
                    <i className={classes.back} onClick={handleBack}>
                        <ArrowBack />
                    </i>
                    New Message
                </p>
                <ChatUsersSearch
                    key="ChatUsersSearch"
                    deleteRecipient={deleteRecipient}
                    inputRef={inputRef}
                    isLastSelected={isLastSelected}
                    isDesktop={screenSize.isDesktop}
                    searchString={searchString}
                    selectedRecipients={selectedRecipients}
                    setIsLastSelected={setIsLastSelected}
                    setSearchString={setSearchString}
                />
                <div className={classNames(classes.content, screenSize)}>
                    <InstantSearch
                        indexName={isStaff ? 'users' : 'staff'}
                        searchClient={isStaff ? searchClient : mindfullySearchClient}
                    >
                        <Configure clickAnalytics hitsPerPage={STAFF_HITS_MAX} />
                        {isSuggested || searchString.length > 0 || screenSize.isDesktop ? (
                            <SuggestedRecipients
                                addRecipient={addRecipient}
                                deleteRecipient={deleteRecipient}
                                isDesktop={screenSize.isDesktop}
                                isStaff={isStaff}
                                searchString={searchString}
                                selectedRecipients={selectedRecipients}
                            />
                        ) : (
                            selectedRecipients.length > 0 && (
                                <ChatMessages
                                    chatId={currentChat?.id}
                                    messages={currentChat?.messages || []}
                                    messagesEndRef={messagesEnd}
                                    scrollToBottom={scrollToBottom}
                                />
                            )
                        )}
                    </InstantSearch>
                </div>
                {selectedRecipients.length !== 0 && !screenSize.isDesktop && (
                    <ChatInput
                        isBlocked={isBlocked}
                        onSendMessage={onSendMessage}
                        isDesktop={screenSize.isDesktop}
                        initText={initText}
                    />
                )}
            </div>
        </>
    )
}

NewMessageBody.propTypes = {
    addRecipient: func.isRequired,
    currentChat: object.isRequired,
    deleteRecipient: func.isRequired,
    inputRef: object.isRequired,
    isSuggested: bool.isRequired,
    onSendMessage: func.isRequired,
    searchString: string.isRequired,
    selectedRecipients: array.isRequired,
    setSearchString: func.isRequired,
}

NewMessageBody.defaultProps = {}

export default NewMessageBody

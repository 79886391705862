import classNames from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { any, bool, func, object, string } from 'prop-types'

import useScreenSize from 'js/hooks/useScreenSize'

const useStyles = makeStyles(({ buttons }) => ({
    Button: {
        ...buttons.round,
        maxWidth: '250px',
        width: '100%',

        '&.isSecondary': {
            ...buttons.roundSec,
        },
    },
}))

const Button = React.forwardRef(({ children, className, disabled, isSecondary, onClick, style, type }, ref) => {
    const screenSize = useScreenSize()
    const classes = useStyles()
    return (
        <button
            className={classNames(classes.Button, className, { isSecondary, ...screenSize })}
            disabled={disabled}
            onClick={onClick}
            ref={ref}
            style={style}
            // eslint-disable-next-line react/button-has-type
            type={type}
        >
            {children}
        </button>
    )
})

Button.propTypes = {
    className: string,
    disabled: bool,
    children: any.isRequired,
    isSecondary: bool,
    onClick: func,
    style: object,
    type: string,
}

Button.defaultProps = {
    disabled: false,
    className: '',
    isSecondary: false,
    onClick: null,
    style: {},
    type: 'button',
}

export default Button

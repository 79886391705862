import React from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import { any, bool, func } from 'prop-types'
import { ArrowBackRounded } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
    back: {
        height: '16px',
        left: '30px',
        position: 'absolute',
        top: '28px',
        width: '16px',
    },
    content: {
        width: '100%',
    },
    paper: {
        background: theme.palette.white,
        height: '948px',
        marginTop: '76px',
        width: '375px',
        zIndex: 100,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '375px',
        paddingTop: '64px',
        position: 'relative',
    },
}))

const SideDrawer = ({ children, isOpen, onClose }) => {
    const classes = useStyles()

    return (
        <Drawer anchor="right" classes={{ paper: classes.paper }} open={isOpen} variant="persistent">
            <div className={classes.root}>
                <div className={classes.back} onClick={onClose}>
                    <ArrowBackRounded />
                </div>
                <div className={classes.content}>{children}</div>
            </div>
        </Drawer>
    )
}

SideDrawer.propTypes = {
    children: any.isRequired,
    isOpen: bool,
    onClose: func,
}

SideDrawer.defaultProps = {
    isOpen: false,
    onClose: null,
}

export default SideDrawer

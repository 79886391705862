import { handleActions } from 'redux-actions'
import { contactUsReset, contactUsSend, contactUsSendSuccess } from './contactUs.actions'

const defaultState = {
    isLoading: false,
    isSent: false,
}

const reducer = handleActions(
    {
        [contactUsReset]: () => defaultState,
        [contactUsSend]: (state) => ({
            ...state,
            isLoading: true,
        }),
        [contactUsSendSuccess]: (state) => ({
            ...state,
            isLoading: false,
            isSent: true,
        }),
    },
    defaultState,
)

export default reducer

import { createSelector } from 'reselect'

export const selectOverallAssessment = ({ overallAssessment }) => overallAssessment
export const selectOverallAssessmentIsOpen = createSelector(selectOverallAssessment, ({ isOpen }) => isOpen)
export const selectIsStarted = createSelector(selectOverallAssessment, ({ isStarted }) => isStarted)
export const selectOverallAssessmentCurrentSlide = createSelector(
    selectOverallAssessment,
    ({ currentSlide }) => currentSlide,
)
export const selectisCompleted = createSelector(selectOverallAssessment, ({ isCompleted }) => isCompleted)

import logger from 'js/utils/logger'
import { handleActions } from 'redux-actions'

import {
    firebaseCloudMessagingTokenUpdateError,
    firebaseCloudMessagingTokenUpdateSuccess,
} from './firebaseCloudMessaging.actions'

const defaultState = {
    token: '',
}

const firebaseMessagingReducer = handleActions(
    {
        [firebaseCloudMessagingTokenUpdateError]: (state, { payload }) => {
            logger(`Error of updating FCM (Firebase Cloud Messaging) token: ${payload}`)

            return state
        },
        [firebaseCloudMessagingTokenUpdateSuccess]: (state, { payload: { token, isUsed } }) => ({
            ...state,
            token: isUsed ? token : null,
        }),
    },
    defaultState,
)

export default firebaseMessagingReducer

import classNames from 'classnames'
import React, { useCallback } from 'react'
import { func, number } from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Box from 'js/components/Box'
import Radio from 'js/components/Radio'
import useScreenSize from 'js/hooks/useScreenSize'
import { DELAYS } from 'js/var/time'

const useStyles = makeStyles(({ fonts, palette }) => ({
    section: {
        backgroundColor: palette.white,
        marginBottom: '8px',

        '&.isTablet, &.isDesktop': {
            marginBottom: 0,
            padding: '16px 0',
        },
    },
    sectionTitle: {
        ...fonts.s20l26,
        marginBottom: '4px',
    },
    text: {
        ...fonts.s16l208,
        color: palette.doveGrey,
        marginBottom: '16px',
    },
}))

const NotificationDelay = ({ delay, setDelay }) => {
    const classes = useStyles()
    const screenSize = useScreenSize()

    const createOnClick = useCallback((value) => () => setDelay(value), [])

    return (
        <Box className={classNames(classes.section, screenSize)}>
            <p className={classes.sectionTitle}>Communication Alerts</p>
            <p className={classes.text}>Set preferences for when you will receive alerts for unread messages.</p>
            {DELAYS.map(({ name, value }) => (
                <Radio key={name} isSelected={delay === value} onClick={createOnClick(value)} text={name} />
            ))}
        </Box>
    )
}

NotificationDelay.propTypes = {
    delay: number.isRequired,
    setDelay: func.isRequired,
}

export default NotificationDelay

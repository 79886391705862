import logger from 'js/utils/logger'

import firebase, { getUid } from './firebase'

export const updateOverallAssessment = (overallAssessment) =>
    firebase
        .database()
        .ref(`users/${getUid()}/overallAssessment`)
        .update(overallAssessment)
        .catch((e) => logger(`Error of Overall Assessment update: ${e}`))

export default { updateOverallAssessment }

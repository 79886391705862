import { createSelector } from 'reselect'

import { RESOURCES_TYPES } from 'js/var/const'

const selectResourcesData = ({ resources }) => resources

export const selectIsResourcesLoading = createSelector(selectResourcesData, ({ isLoading }) => isLoading)

export const selectResources = createSelector(selectResourcesData, ({ resources, resourcesType }) =>
    resourcesType === RESOURCES_TYPES.all ? resources : resources.filter(({ type }) => type === resourcesType),
)

export const selectFeaturedResource = createSelector(selectResourcesData, ({ featuredResource }) => featuredResource)

export const selectBookmarkedResources = createSelector(
    selectResourcesData,
    ({ featuredResource, resources, resourcesType }) => {
        const allResources = featuredResource ? [featuredResource, ...resources] : resources

        const bookmarkedResources = allResources
            .filter(({ isBookmarked }) => !!isBookmarked)
            .sort((a, b) => b?.isBookmarked?.bookmarkedAt - a?.isBookmarked?.bookmarkedAt)

        return resourcesType === RESOURCES_TYPES.all
            ? bookmarkedResources
            : bookmarkedResources.filter(({ type }) => type === resourcesType)
    },
)
export const selectAllResources = createSelector(selectResourcesData, ({ resources, featuredResource }) =>
    featuredResource ? [featuredResource, ...resources] : resources,
)

const selectBookmarkPopup = createSelector(selectResourcesData, ({ bookmarkPopup }) => bookmarkPopup)

export const selectBookmarkPopupMessage = createSelector(selectBookmarkPopup, ({ message }) => message)

export const selectIsBookmarkPopupOpen = createSelector(selectBookmarkPopup, ({ isOpen }) => isOpen)

export const selectBookmarkPopupMarginBottom = createSelector(selectBookmarkPopup, ({ marginBottom }) => marginBottom)

export const selectResourceTab = createSelector(selectResourcesData, ({ tab }) => tab)

export const selectRecentlyViewed = createSelector(selectResourcesData, ({ recentlyViewed }) => recentlyViewed)

export const selectResourcesType = createSelector(selectResourcesData, ({ resourcesType }) => resourcesType)

import { createAction } from 'redux-actions'

import logger from 'js/utils/logger'
import { getFAQ } from 'js/APIs/contentfulClient'

import { FAQ_GET_SUCCESS } from './faq.types'

export const faqGetSuccess = createAction(FAQ_GET_SUCCESS)

export const getFaq = () => async (dispatch) => {
    try {
        dispatch(faqGetSuccess(await getFAQ()))
    } catch (e) {
        logger(`Error of getting FAQ: ${e}`)
    }
}

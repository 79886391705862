const { useMediaQuery } = require('react-responsive')

const useScreenSize = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const isTablet = useMediaQuery({ maxWidth: 1199 })
    const isDesktop = useMediaQuery({ minWidth: 1200 })

    if (isMobile) {
        return { isMobile }
    }
    if (isTablet) {
        return { isTablet }
    }
    if (isDesktop) {
        return { isDesktop }
    }
    return {}
}

export default useScreenSize

import { createSelector } from 'reselect'

const selectCallFeedback = (state) => state.feedback

export const selectIsFeedbackSuccess = createSelector(selectCallFeedback, ({ isFeedbackSuccess }) => isFeedbackSuccess)

export const selectIsFeedbackOpen = createSelector(selectCallFeedback, ({ isOpen }) => isOpen)

export const selectCallType = createSelector(selectCallFeedback, ({ callType }) => callType)

export const selectOwner = createSelector(selectCallFeedback, ({ owner }) => owner)

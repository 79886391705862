import classNames from 'classnames'
import React from 'react'
import { bool, func, string } from 'prop-types'
import { makeStyles, Modal } from '@material-ui/core'

const useStyles = makeStyles(({ fonts: { b1, b2 }, palette: { black, primPurple, white } }) => ({
    button: {
        ...b1.mob,
        outline: 'none',
        marginTop: '34px',
        color: primPurple,
        border: 'none',
        backgroundColor: white,

        '&:focus': {
            color: primPurple,
        },

        '&:active': {
            color: primPurple,
        },

        '&.isDesktop': {
            ...b1.dskt,
        },
    },
    content: {
        alignItems: 'center',
        backgroundColor: white,
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '350px',
        outline: 'none',
        padding: '40px 12px 30px 12px',
        width: '72%',

        '&.isDesktop': {
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    },
    message: {
        ...b2.mob,
        color: black,
        textAlign: 'center',

        '&.isDesktop': {
            ...b2.dskt,
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        ...b1.mob,
        color: black,
        marginBottom: '8px',
        textAlign: 'center',

        '&.isDesktop': {
            ...b1.desktop,
        },
    },
}))

const SignInModal = ({ buttonTitle, isDesktop, isOpen, message, onClose, title }) => {
    const classes = useStyles()

    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classNames(classes.content, { isDesktop })}>
                <p className={classes.title}>{title}</p>
                <p className={classes.message}>{message}</p>
                <button onClick={onClose} type="button" className={classes.button}>
                    {buttonTitle}
                </button>
            </div>
        </Modal>
    )
}

SignInModal.propTypes = {
    buttonTitle: string.isRequired,
    isDesktop: bool.isRequired,
    isOpen: bool.isRequired,
    message: string.isRequired,
    onClose: func.isRequired,
    title: string.isRequired,
}

export default SignInModal

import { handleActions } from 'redux-actions'

import { CONTACT_METHODS } from 'js/var/const'
import { DEFAULT_AVAILABILITY_END, DEFAULT_AVAILABILITY_START } from 'js/var/time'

import { setSettings } from './settings.actions'

const defaultState = {
    away: {
        isPermanent: false,
        isTemporary: false,
        start: null,
        end: null,
        permanentMessage: '',
        temporaryMessage: '',
    },
    availability: {
        isWeekendAvailable: false,
        weekdays: {
            end: DEFAULT_AVAILABILITY_END,
            isAllDay: false,
            isOncePerDay: false,
            once: DEFAULT_AVAILABILITY_START,
            start: DEFAULT_AVAILABILITY_START,
        },
        weekends: {
            end: DEFAULT_AVAILABILITY_END,
            isAllDay: false,
            isOncePerDay: false,
            once: DEFAULT_AVAILABILITY_START,
            start: DEFAULT_AVAILABILITY_START,
        },
    },
    blockedUsers: {},
    callSettings: {
        isAllClientsCall: false,
        users: [],
    },
    contactMethod: CONTACT_METHODS.pushNotifications,
    delay: 0,
}

const settingsReducer = handleActions(
    {
        [setSettings]: (
            state,
            { payload: { availability, away, blockedUsers, callSettings, delay, contactMethod } },
        ) => {
            const newState = { blockedUsers }

            if (availability?.weekdays && availability?.weekends) {
                newState.availability = {
                    ...state.availability,
                    ...availability,
                    weekdays: { ...state.availability.weekdays, ...availability.weekdays },
                    weekends: { ...state.availability.weekends, ...availability.weekends },
                }
            }
            if (away) {
                newState.away = { ...state.away, ...away }
            }

            if (delay) {
                newState.delay = delay
            }

            if (callSettings) {
                newState.callSettings = { ...state.callSettings, ...callSettings }
            }

            if (contactMethod) {
                newState.contactMethod = contactMethod
            }

            return { ...state, ...newState }
        },
    },
    defaultState,
)

export default settingsReducer

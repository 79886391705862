import React from 'react'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import image from 'media/boy-near-phone.svg'

const useStyles = makeStyles(({ buttons, fonts, palette }) => ({
    ReachOut: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        padding: '57px 40px 66px 40px',
        width: '100%',
    },
    button: {
        ...buttons.def.dskt,
        width: '240px',
        marginTop: 'auto',
    },
    img: {
        height: 'auto',
        maxHeight: '100%',
        width: '100%',
    },
    imgWrapper: {
        height: '140px',
        marginBottom: '33px',
        maxHeight: '140px',
    },
    text: {
        ...fonts.b2.dskt,
        color: palette.black,
        textAlign: 'center',
    },
    title: {
        ...fonts.h4.dskt,
        color: palette.midBlue,
        marginBottom: '16px',
    },
}))

const ReachOut = ({ handleClose }) => {
    const classes = useStyles()

    return (
        <div className={classes.ReachOut}>
            <div className={classes.imgWrapper}>
                <img className={classes.img} src={image} alt="Standing" />
            </div>
            <p className={classes.title}>Reach out to your Peer Supporter</p>
            <div className={classes.text}>
                Message your Peer Supporter to make introductions. Together you can set up a time to chat through a
                call!
            </div>
            <button className={classes.button} onClick={handleClose} type="button">
                I am ready!
            </button>
        </div>
    )
}

ReachOut.propTypes = {
    handleClose: func.isRequired,
}

export default ReachOut

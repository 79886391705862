import { array } from 'prop-types'
import React from 'react'

import { Configure, connectHits, InstantSearch } from 'react-instantsearch-dom'

const withInstanceSearch = (WrappedComponent, client, indexName, config = {}) => {
    const WithFilter = (props) => {
        const { hits } = props

        return <WrappedComponent {...props} hits={hits} />
    }

    WithFilter.propTypes = {
        hits: array.isRequired,
    }

    const ComponentWithHits = connectHits(WithFilter)

    return (props) => {
        return (
            <InstantSearch indexName={indexName} searchClient={client}>
                <Configure clickAnalytics {...config} />
                <ComponentWithHits {...props} />
            </InstantSearch>
        )
    }
}

export default withInstanceSearch

import { createAction } from 'redux-actions'

import { loadInterestsTypes, uploadUserInterests } from 'js/APIs/firebase/interests.firebase'
import { INIT_INTERESTS_TYPES, SAVE_USER_INTERESTS, SET_USER_INTERESTS } from './interests.types'

export const initInterestsTypes = createAction(INIT_INTERESTS_TYPES)

export const initInterests = () => async (dispatch) => {
    const interests = await loadInterestsTypes()
    if (interests) {
        dispatch(initInterestsTypes(interests))
    }
}

export const saveUserInterests = createAction(SAVE_USER_INTERESTS)

export const saveInterests = (interests) => async (dispatch) =>
    uploadUserInterests(interests).then(() => dispatch(saveUserInterests()))

export const setUserInterests = createAction(SET_USER_INTERESTS)

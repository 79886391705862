import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { number, string } from 'prop-types'
import palette from 'styles/palette'

const CircularLoading = ({ className, color, size }) => {
    const useStyles = makeStyles(() => ({
        progress: {
            color,
            height: `${size}px`,
            width: `${size}px`,
        },
    }))

    const classes = useStyles()

    return <CircularProgress className={classNames(classes.progress, className)} size={size} />
}

CircularLoading.propTypes = {
    className: string,
    color: string,
    size: number,
}

CircularLoading.defaultProps = {
    className: '',
    color: palette.primPurple,
    size: 18,
}

export default CircularLoading

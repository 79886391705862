import { createAction } from 'redux-actions'

import { loadPeerSupporterLearning, uploadPeerSupporterLearning } from 'js/APIs/firebase/peerSupporterLearning.firebase'

import {
    LOAD_PEER_SUPPORTER_LEARNING,
    TOGGLE_PEER_SUPPORTER_LEARNING,
    UPLOAD_PEER_SUPPORTER_LEARNING,
} from './peerSupporterLearning.types'

export const loadPeerSupporterLearningSuccess = createAction(LOAD_PEER_SUPPORTER_LEARNING)

export const getPeerSupporterLearning = () => async (dispatch) => {
    const quiz = await loadPeerSupporterLearning()

    return dispatch(loadPeerSupporterLearningSuccess(quiz))
}

export const peerSupporterLearningSetSuccess = createAction(UPLOAD_PEER_SUPPORTER_LEARNING)

export const setPeerSupporterLearning = (data) => (dispatch) => {
    uploadPeerSupporterLearning(data)
    dispatch(peerSupporterLearningSetSuccess(data))
}

export const togglePeerSupporterLearning = createAction(TOGGLE_PEER_SUPPORTER_LEARNING)

import { createSelector } from 'reselect'

import { SUPPORTERS_TYPES } from 'js/var/const'

export const selectStaffData = ({ staff }) => staff

export const selectConnections = createSelector(selectStaffData, ({ connections }) => connections)

export const selectFindCareSlide = createSelector(selectStaffData, ({ findCareSlide }) => findCareSlide)

export const selectStaff = createSelector(selectStaffData, ({ staff }) => staff)

export const createStaffSelector = ({ type, isConnectedOnly }) =>
    createSelector(selectStaffData, ({ connections, staff }) => {
        let selectedStaff = staff
        if (isConnectedOnly) {
            if (!connections.length) {
                return []
            }
            selectedStaff = staff.filter(({ firebaseUid }) => connections.includes(firebaseUid))
        }
        if (type === SUPPORTERS_TYPES.all) {
            return selectedStaff
        }
        if (type === SUPPORTERS_TYPES.supporter) {
            return selectedStaff.filter(({ roles }) => roles?.isSupporter)
        }
        if (type === SUPPORTERS_TYPES.counselor) {
            return selectedStaff.filter(({ roles }) => roles?.isCounselor)
        }
    })

export const selectIsFindCareOpen = createSelector(selectStaffData, ({ isFindCareOpen }) => isFindCareOpen)

export const selectPreferredService = createSelector(selectStaffData, ({ preferredService }) => preferredService)

export const selectPreferredSpecialties = createSelector(
    selectStaffData,
    ({ preferredSpecialties }) => preferredSpecialties,
)

export const selectServices = createSelector(selectStaffData, ({ services }) => services)

export const selectSpecialties = createSelector(selectStaffData, ({ specialties }) => specialties)

export const selectSuggestedStaffIds = createSelector(selectStaffData, ({ suggestedStaff }) => suggestedStaff)

export const selectSuggestion = createSelector(selectStaffData, ({ suggestedStaff, staff }) =>
    staff.filter(({ firebaseUid }) => suggestedStaff.includes(firebaseUid)),
)

import firebase, { getUid } from './firebase'

export const getBookmarks = async () =>
    firebase
        .database()
        .ref(`users/${await getUid()}/resourcesBookmarks`)
        .once('value')
        .then((snapshot) => snapshot.val() || {})

export const updateBookmark = async ({ rid, bookmark }) =>
    firebase
        .database()
        .ref(`users/${await getUid()}/resourcesBookmarks`)
        .update({ [rid]: bookmark })

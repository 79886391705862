import React from 'react'
import { makeStyles, Modal } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { selectPHQ9IsOpen } from 'js/redux/phq9/phq9.selectors'

import PHQ9Slider from './PHQ9Slider'

const useStyles = makeStyles((theme) => ({
    PHQ9: {
        minHeight: '100vh',
        width: '100%',
        backgroundColor: theme.palette.white,
        outline: 'none',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const PHQ9 = () => {
    const classes = useStyles()
    const isPHQ9Open = useSelector(selectPHQ9IsOpen)

    return (
        <Modal
            className={classes.modal}
            open={isPHQ9Open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.PHQ9}>
                <PHQ9Slider />
            </div>
        </Modal>
    )
}

export default PHQ9

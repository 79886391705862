import { handleActions } from 'redux-actions'

import { fillInterestsList, fillStructuredInterests } from 'js/utils/interests.utils'

import { initInterestsTypes, setUserInterests } from './interests.actions'

const defaultState = {
    interestsList: [],
    structuredInterests: {
        aboutme: [],
        activism: [],
        health: [],
        family: [],
        personal: [],
        school: [],
        sports: [],
        volunteer: [],
    },
    userInterests: [],
}

const interestsReducer = handleActions(
    {
        [initInterestsTypes]: (state, { payload }) => {
            if (!state.interestsList.length)
                return {
                    ...state,
                    structuredInterests: fillStructuredInterests(payload, state.structuredInterests),
                    interestsList: fillInterestsList(payload),
                }
            return state
        },
        [setUserInterests]: (state, { payload }) => ({
            ...state,
            userInterests: payload || [],
        }),
    },
    defaultState,
)

export default interestsReducer

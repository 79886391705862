import { createAction } from 'redux-actions'

import { updatePHQ9 } from 'js/APIs/firebase/phq9.firebase'

import {
    COMPLETE_PHQ9,
    INIT_PHQ9,
    SET_PHQ9_ANSWER,
    SET_PHQ9_SLIDE,
    START_PHQ9,
    TOGGLE_PHQ9,
    UPDATE_PHQ9,
} from './phq9.types'

export const completePHQ9 = createAction(COMPLETE_PHQ9)
export const initPHQ9 = createAction(INIT_PHQ9)
export const startPHQ9 = createAction(START_PHQ9)
export const setPHQ9Answer = createAction(SET_PHQ9_ANSWER)
export const setPHQ9Slide = createAction(SET_PHQ9_SLIDE)
export const togglePHQ9 = createAction(TOGGLE_PHQ9)

export const savePHQ9Success = createAction(UPDATE_PHQ9)

export const savePHQ9 = (PHQ9) => async (dispatch) => updatePHQ9(PHQ9).then(() => dispatch(savePHQ9Success()))

import axios from 'axios'
import qs from 'qs'
import logger from 'js/utils/logger'

const apiUrl = process.env.REACT_APP_API_URL

const config = {
    baseURL: apiUrl,
    maxContentLength: 36700160,
}

const axiosInstance = axios.create(config)

export const getRequestWithFilters = (filters) =>
    Object.keys(filters).reduce((prev, key) => {
        if (typeof filters[key] !== 'undefined') {
            if (typeof filters[key] === 'number') {
                prev += `${key}=${filters[key]}&`
                return prev
            }
            if (filters[key].length > 0) {
                prev += `${key}=${filters[key]}&`
            }
        }
        return prev
    }, '')

export const GET = async (link, filters = {}) => {
    const res = await axiosInstance.get(`${link}?${getRequestWithFilters(filters)}`).catch((error) => {
        logger(`error in GET  link = ${link}, ${error}`)

        if (error.response && error.response.status === 404) {
            throw error
        }
    })
    if (res?.status === 200) {
        return res.data
    }

    return {}
}

export const POST = async (link, data = {}) => {
    const res = await axiosInstance.post(link, qs.stringify(data)).catch((error) => {
        if (error.response && error.response.status === 409) {
            throw error
        }

        logger(`error in POST link = ${link}`, error)
    })

    if (res.status === 200) {
        return res
    }
}

export const EXTERNAL_GET = async (link, filters = {}) => {
    try {
        const res = await axios.get(`${link}?${getRequestWithFilters(filters)}`)
        if (res?.status === 200) return res
    } catch (error) {
        logger(`error in external GET link = ${link}`, error)
        throw error
    }
}

export const DELETE_SCHEDULE_MEETING_SUCCESS = 'DELETE_SCHEDULE_MEETING_SUCCESS'

export const EDIT_SCHEDULED_MEETING_SUCCESS = 'EDIT_SCHEDULED_MEETING_SUCCESS'

export const LEAVE_GUEST_LIST_SUCCESS = 'LEAVE_GUEST_LIST_SUCCESS'

export const SET_INCOMING_MEETING = 'SET_INCOMING_MEETING'

export const SET_SCHEDULED_MEETING_ID = 'SET_SCHEDULED_MEETING_ID'

export const START_CREATED_SCHEDULED_MEETING = 'START_CREATED_SCHEDULED_MEETING'

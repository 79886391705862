import axios from 'axios'
import { CALL_TYPE } from 'js/var/const'

import { DAILY_CO_ROOMS, DAILY_CO_TOKENS } from 'js/var/urls'

const { REACT_APP_API_URL, REACT_APP_DAILY_CO_API_KEY } = process.env

const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${REACT_APP_DAILY_CO_API_KEY}`,
    },
}

export const deleteRoom = (roomName) => axios.delete(`${DAILY_CO_ROOMS}/${roomName}`, config)

export const getRoomData = (roomName) => axios.get(`${DAILY_CO_ROOMS}/${roomName}`, config)

export const getRoom = (callType) => {
    const roomData = {
        properties: {
            enable_prejoin_ui: false,
        },
    }
    const scheduleRoomData = {
        privacy: 'private',
        properties: {
            enable_screenshare: false,
            enable_knocking: true,
            meeting_join_hook: `${REACT_APP_API_URL}joinRoomNotification`,
        },
    }

    return axios.post(DAILY_CO_ROOMS, callType === CALL_TYPE.scheduledMeeting ? scheduleRoomData : roomData, config)
}

export const getDailyCoToken = ({ isOwner, roomName, userName }) =>
    axios.post(
        DAILY_CO_TOKENS,
        {
            properties: {
                enable_screenshare: isOwner,
                is_owner: isOwner,
                user_name: userName,
                room_name: roomName,
            },
        },
        config,
    )
